
import { MyTel } from '../controls/phone-control/phone-control.component';

import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';


export class PasswordValidation {

    static MatchPassword(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            else {
                // set error on matchingControl if validation fails
                if (control.value !== matchingControl.value) {
                    matchingControl.setErrors({ mustMatch: true });
                } else if (matchingControl.errors?.mustMatch) {
                    matchingControl.setErrors(null);
                }
            }
            return null;
        };
    }

    static ShouldNotMatchPassword(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.shouldNotMatch) {
                return;
            }
            else {
                // set error on matchingControl if validation fails
                if (control.value == matchingControl.value) {
                    matchingControl.setErrors({ shouldNotMatch: true });
                } else if (matchingControl.errors?.shouldNotMatch) {
                    matchingControl.setErrors(null);
                }
            }
            return null;
        };
    }

    /**
     * This function used to validate the password is valid or not based on entered username, firstname, middlename, lastname
     * @param controllerPassword Control to validate
     * @param userName Validate by user name control
     * @param firstFormgroup Form Group
     * @param firstName Validate by FirstName control
     * @param middleName Validate by MiddleName control
     * @param lastName Validate by LastName control
     * @returns True: is valid password, False:invalid password and set error style
     */
    static PasswordRestrict(controllerPassword: string, userName: string, sourceFormGroup: FormGroup,
        firstName: string, middleName: string, lastName: string, iscontrol: boolean = true) {
        return (formGroup: FormGroup) => {
            let password_control = formGroup.controls[controllerPassword].value?.toLowerCase();

            let user_name = userName;
            let first_name = firstName;
            let middle_name = middleName;
            let last_name = lastName;

            if (iscontrol) {
                user_name = formGroup.controls[userName].value?.toLowerCase();
                first_name = sourceFormGroup.controls[firstName].value?.toLowerCase();
                middle_name = sourceFormGroup.controls[middleName].value?.toLowerCase();
                last_name = sourceFormGroup.controls[lastName].value?.toLowerCase();
            }

            const userName_reverse = this.Reverse(user_name);
            const first_name_reverse = this.Reverse(first_name);
            const middle_name_reverse = this.Reverse(middle_name);
            const last_name_reverse = this.Reverse(last_name);
            // Check user name has error 
            if (formGroup.controls[controllerPassword].errors && !formGroup.controls[controllerPassword].errors?.isInValid) {
                return null;
            }
            if (password_control.indexOf(user_name) >= 0 || password_control.indexOf(first_name) >= 0
                || (middle_name != '' && middle_name.length > 1 && password_control.indexOf(middle_name) >= 0) || password_control.indexOf(last_name) >= 0
                || password_control.indexOf(userName_reverse) >= 0 || password_control.indexOf(first_name_reverse) >= 0
                || (middle_name_reverse != '' && middle_name_reverse.length > 1 && password_control.indexOf(middle_name_reverse) >= 0) || password_control.indexOf(last_name_reverse) >= 0) {
                formGroup.controls[controllerPassword].setErrors({ isInValid: true });
            } else if (!formGroup.controls[controllerPassword].errors
                && formGroup.controls[controllerPassword].errors?.length === 1
                && formGroup.controls[controllerPassword].errors?.isInValid) {
                formGroup.controls[controllerPassword].setErrors(null);
            }
            return null;
        }
    }

    /**
     * This function used to reverse the string content
     * @param value A string used to reverse 
     * @returns Reversed string
     */
    private static Reverse(value: string): String {
        let charArray: string[] = value.split('');

        let reverseArray: string[] = charArray.reverse();

        let reversedArray: string = reverseArray.join('');
        return reversedArray;
    }
}