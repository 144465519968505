
<ruf-dialog-panel [separator]="true" rufId="dialog_without_header">
    <ruf-dialog-header class="form_title">{{header}}</ruf-dialog-header>
    <ruf-dialog-content rufId="dialog_content">
        <table width="100%" rufMarginBottom>
            <tr id="table1" width="100%" >
                <td role="region" aria-label="receiveddate" class="label" width="40%">{{ 'DIALOGINDIVIDUALDISPUTE.RECEIVEDDATE' | translate }}</td>
                <td role="region" aria-label="dateelement" class="title" width="60%">{{disputeData.receivedDate | date:'MM/dd/yyyy'}}</td>
            </tr> 
            <tr id="table1" width="100%" >
                <td role="region" aria-label="targetdate" class="label" width="40%">{{ 'DIALOGINDIVIDUALDISPUTE.TARGETDATE' | translate }}</td>
                <td role="region" aria-label="targetdate" class="title" width="60%">{{disputeData.targetCompletionDate | date:'MM/dd/yyyy'}}</td>
            </tr>
            <tr id="table1" width="100%" >
                <td role="region" aria-label="confirmation" class="label" width="40%">{{ 'DIALOGINDIVIDUALDISPUTE.CONFIRMATION' | translate }}</td>
                <td role="region" aria-label="confirmationdate" class="title" width="60%">{{disputeData.confirmationNo}}</td>
            </tr>
            <!-- <tr id="table1" width="100%" >
                <td role="region" aria-label="description" class="label" width="40%">{{ 'DIALOGINDIVIDUALDISPUTE.DESCRIPTION' | translate }}</td>
                <td role="region" aria-label="descriptionelement" class="title" width="60%">{{disputeData.description}}</td>
            </tr> -->
        </table> 

        <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showPaginator]="false" [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="disputeData.DisputeEventItemList" [metadata]="columns">
            <ng-container *rufNoDataRow>
                <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
                <h2 role="region"  class="chex-no-record-message">{{ 'DASHBOARDDISPUTES.NOITEMS' | translate }}</h2>
              </ng-container>
          </ruf-dynamic-datatable>
    </ruf-dialog-content>
</ruf-dialog-panel>