<ruf-dialog-panel [separator]="true" rufId="dialog_attach">
  <ruf-dialog-header  #header >{{ 'DIALOGFILEATTACHMENT.ADDDOCUMENT' | translate }}&nbsp; {{confirmationNo}}
    <div role="region" aria-label="info1" class="chex-attch-doc-info-text">
      <span [innerHTML]="info1"></span>
      <!-- {{ 'DIALOGFILEATTACHMENT.INFO1' | translate }} -->
    </div>
  </ruf-dialog-header>
  <ruf-dialog-content rufId="dialog_attach_content"  rufScrollbar [autoHide]=true>
    <div>
        <form #form2="ngForm">
        <ruf-file-upload
          (error)="onError($event)"
          #rufUpload2
          [multiUpload]=muiltiUpload
          [multiSelection]=multiSelection
          name="file2Model"
          
          (upload)="saveFile($event);"
          [accept]="fileExtn"
          [rufFileType]="fileExtn"
          [rufMaxFileSize]="maxSize"
          [label]="'Upload File'"
          
          [position]="'bottom'"
          (delete)="reset2($event);"
          (abort)="reset2($event);"
          [(ngModel)]="file2" #file2Model="ngModel">
          
        </ruf-file-upload>   
        <ruf-file-error *ngIf="(validationModel.isFileSize ===false )" >
          <div>
            <p class="chex-attch-doc-error">{{ 'DIALOGFILEATTACHMENT.TOTALSIZE' | translate }}</p>
          </div>
        </ruf-file-error>
        <ruf-file-error *ngIf="(validationModel.isFileCountValid ===false )" >
          <div>
            <p class="chex-attch-doc-error">{{ 'DIALOGFILEATTACHMENT.NUMBER' | translate }}</p>
          </div>
        </ruf-file-error>
        <ruf-file-error *ngIf="file2Model.invalid && (file2Model.dirty || file2Model.touched)" >
          <div class="chex-attch-doc-error" *ngIf="file2Model?.errors?.invalidFileType">
            {{ 'DIALOGFILEATTACHMENT.ERRORINVALIDFILE' | translate }}
          </div>
        </ruf-file-error>      
        </form>
      </div>
     
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_attach_footer">
      <div>
        <button id="submitBtn" [disabled]="isDisabled" mat-raised-button fisStyle color="primary"  rufId="dialog_attach_footer_button" (click)="onSubmit()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
      </div>
      <div role="region" aria-label="info2" class="chex-attch-doc-info-text">
        <span [innerHTML]="info2"></span>
        <!-- {{ 'DIALOGFILEATTACHMENT.INFO2' | translate }} -->
      </div>
    </ruf-dialog-footer>
  </ruf-dialog-panel>