<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
    <!-- <ruf-dialog-header class="form_title">{{header}}</ruf-dialog-header> -->
    <ruf-dialog-header rufId="simple_dialog_header" #header>{{headerCaption}}</ruf-dialog-header>
    <ruf-dialog-content rufId="simple_dialog_content">
        <div class="data" style="display:flex; flex-direction:column;">
            <div class="row" fxLayout="column" fxLayout.gt-sm="row" rufMarginVertical rufId *ngFor="let y of this.itemInfo.dialogDisputeDetailsInfoItemList; let i = index">
                <div class="chex-dlg-rpt-info-label mat-subheading-1">{{y.caption}}</div>
                <div class="chex-dlg-rpt-info-value mat-subheading-1">{{y.value}}</div>
            </div>
        </div>
        
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer">
        
        <a href="javascript:void(0);"
        rufId
        (click)="disputeClick(itemInfo)"
        mat-button=""
        id="anchor"
        [disabled]="itemInfo.isSectionDisputed === true"
        fisStyle
        color="primary"
        ><mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="itemInfo.isSectionDisputed === true" rufId fisStyle fontSet="fisfont-solid" class="chex-dispute-complete-icon">
        </mat-icon>{{itemInfo.SectionButtonText}}</a>
            <button role="region" aria-label="ok" id="okBtn" mat-raised-button fisStyle color="primary" rufId="dialog_footer_button" (click)="onOk()">{{ 'BUTTONS.OK' | translate }}</button>
    </ruf-dialog-footer>
</ruf-dialog-panel>
