import { Injectable } from '@angular/core';
import { ApiService } from '../shared';
import { first, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SitecoreRequestData, SiteCoreResponseData } from '../../_models/classes/sitecore-reponse.model';
import { DashBoardCarouselResponseData } from '../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SiteCoreNodePathResponseData } from '../../_models/classes/sitecore-nodepath-reponse.model';
import { SiteCoreSearchResultData } from '../../_models/classes/sitecoresearch-reponse.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SitecoreService {
  private readonly Api_Base_URL = environment.sitecoreapiurl;

  constructor(private _apiService: ApiService) {}

  public FetchSitecoreContentByItemPath(siteCoreRequestDataModel: SitecoreRequestData): Observable<SiteCoreResponseData> {
    const url = this.Api_Base_URL.concat('/getSitecoreContentByItemPath');
    return this._apiService.post<SiteCoreResponseData>(url, siteCoreRequestDataModel);
  }

  public FetchDashBoardCarouselContent(siteCoreRequestDataModel: SitecoreRequestData): Observable<DashBoardCarouselResponseData> {
    const url = this.Api_Base_URL.concat('/getDashBoardCarouselContent');
    return this._apiService.post<DashBoardCarouselResponseData>(url, siteCoreRequestDataModel);
  }

  public FetchSitecoreContentByNodePath(siteCoreRequestDataModel: SitecoreRequestData): Observable<SiteCoreNodePathResponseData> {
    const url = this.Api_Base_URL.concat('/getSitecoreContentByNodePath');
    return this._apiService.post<SiteCoreNodePathResponseData>(url, siteCoreRequestDataModel);
  }

  public getSearchResults(searchText: string): Observable<SiteCoreSearchResultData> {
    const url = this.Api_Base_URL.concat('/getSearchResults').concat('/' + searchText);
    return this._apiService.get<SiteCoreSearchResultData>(url);
  }
}
