import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { ApiService } from '../shared/api.service';
import { dashboard } from '../../_models/classes/dashboard-dispute.model';
import { dashboardEvent } from '../../_models/classes/dashboard-dispute.model';
import { dashboardCorrs } from '../../_models/classes/dashboard-correspondence.model';
import { Output, EventEmitter } from '@angular/core';
import { AnyRecord } from 'dns';

@Injectable({
  providedIn: 'root',
})

/**
 * Used to interact with ida endpoint
 */
export class DashboardService {
  private readonly Api_Base_URL = environment.apiurl;
  constructor(private httpClient: HttpClient, private _apiService: ApiService) {
    this.loderDisputeEvent$ = new EventEmitter();
    this.loderCorrespondanceEvent$ = new EventEmitter();
  }
  public loderDisputeEvent$: EventEmitter<boolean>;
  public loderCorrespondanceEvent$: EventEmitter<boolean>;

  triggerDisputeLoadEvent() {
    this.loderDisputeEvent$.emit(false);
  }

  triggerCorrespondanceLoadEvent() {
    this.loderCorrespondanceEvent$.emit(false);
  }
  /**
   * get disputeitem
   * @param diRequest
   * @returns response of api
   */
  DisputeItemList(
    diRequest: dashboard.ViewDisputeListReqRoot
  ): Observable<dashboard.DashboardDisputeRoot> {
    const url = this.Api_Base_URL.concat('/DisputeItemList');
    return this._apiService
      .post<dashboard.DashboardDisputeRoot>(url, diRequest)
      .pipe(catchError(this.handleError));
  }

  /**
   * get disputeitem
   * @param diRequest
   * @returns response of api
   */
  DisputeItemDetail(
    diRequest: dashboardEvent.DisputeItemDetailReqRoot
  ): Observable<dashboardEvent.DashboardDisputeDetailRoot> {
    const url = this.Api_Base_URL.concat('/DisputeItemDetail');
    return this._apiService
      .post<dashboardEvent.DashboardDisputeDetailRoot>(url, diRequest)
      .pipe(catchError(this.handleError));
  }

  /**
   * get Correspondence List
   * @param ciRequest
   * @returns response of api
   */
  CorrespondenceList(
    ciRequest: dashboardCorrs.CorrespondenceDetailsRequestRoot
  ): Observable<dashboardCorrs.DashboardCorrespondenceRoot> {
    const url = this.Api_Base_URL.concat('/CorrespondenceList');
    return this._apiService
      .post<dashboardCorrs.DashboardCorrespondenceRoot>(url, ciRequest)
      .pipe(catchError(this.handleError));
  }

  /**
   * get Correspondence List Detail
   * @param ciRequest
   * @returns response of api
   */
  CorrespondenceListDetail(
    ciRequest: dashboardCorrs.CorrespondenceListDetailRequestRoot
  ): Observable<dashboardCorrs.CorrespondenceDetailRoot> {
    const url = this.Api_Base_URL.concat('/CorrespondenceListDetail');
    return this._apiService
      .post<dashboardCorrs.CorrespondenceDetailRoot>(url, ciRequest)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage: string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

  // logOutAPI(): Observable<any> {
  //   const url = environment.idpLogOutEndpoint;
  //   return this._apiService.deleteWithIdpKey<any>(
  //     url,
  //     environment.auth.idpApiKey
  //   );
  // }
}
