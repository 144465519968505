export namespace freezeExists{


  export class FreezeExistsRequestRoot {
    GovtNbr: string
    UserName: string
    RecapchaToken: string
  }
  
  export class FreezeExistsResponseRoot {
    FreezeExists: boolean
    UserName: string;
    message: string
    code: string
    ProtectedFlagInd: string
  }
}
//////////////////////////////////
