<div class="chex-simple-snackbar" rufId>
    <div class="chex-container">
        <div rufId>
            <mat-icon rufId class="fis-icon-md" fisIcon="information-solid"
                [color]="color == 'none' || ''? 'info' : color">
            </mat-icon>
        </div>
        <div class="chex-snackbar-text-container mat-body-2" rufId [innerHTML]="snackBarContent | sanitizeHtml">
        </div>
        <div rufId>
            <div><button rufId fisStyle mat-button class="chex-snackbar-button" (click)="closeSnackBar()">X</button></div>
        </div>
    </div>
</div>