import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
export class DialogPanel {
  constructor(
    public message: string,
    public button1: string,
    public button2: string,
    public title: string = ''
  ) {}
}

@Component({
  selector: 'chexsystem-ws-dialog-with-message-custom-button',
  templateUrl: './dialog-with-message-custom-button.component.html',
  styleUrls: ['./dialog-with-message-custom-button.component.scss']
})
export class DialogWithMessageCustomButtonComponent implements OnInit {
  message: string;
  button1: string;
  button2: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<DialogWithMessageCustomButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPanel
  ) {
    this.message = data.message;
    this.button1 = data.button1;
    this.button2 = data.button2;
    this.title = data.title;
  }

  ngOnInit(): void {
  }

  onButton1Click(): void {
    this.dialogRef.close( this.button1);
  }
  onButton2Click(): void {
    this.dialogRef.close( this.button2);
  }
}
