<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
    <ruf-dialog-header role="region" aria-label="header" rufId="simple_dialog_header"#header>Remove Security Freeze</ruf-dialog-header>
    <ruf-dialog-content rufId="simple_dialog_content" rufId="dialog_content"  rufScrollbar [autoHide]=true>     
        <form [formGroup]="formGroup"> 
            <div fxLayout="row" >
                <div  >
                  <div role="region" aria-label="removefreezeon" id="removetxt" >{{ 'DIALOGREMFRZ.REMOVEFREEZEON' | translate }}</div>   
                  <mat-form-field  boxStyle floatLabel="always" style="width: 250px;">
                      <input 
                      matInput formControlName="removeFreezeOnDateCtrl" [matDatepicker]="picker" placeholder="MM/DD/YYYY" [(ngModel)]="removeFreezeOnDate"
                      (dateChange)="removeFreezeOnDateChanged($event)" (focusout)="removeFreezeOnDateChanged($event)" [matDatepickerFilter]="previousDateFilter"/>
                      <mat-datepicker-toggle fisStyle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker panelClass="fis-style" fisStyle #picker ></mat-datepicker>
                      <mat-error *ngIf="formGroup.get('removeFreezeOnDateCtrl')?.hasError('required')">{{ 'DIALOGREMFRZ.DATE' | translate }}</mat-error>
                      <mat-error *ngIf="formGroup.get('removeFreezeOnDateCtrl')?.errors?.isInValid || formGroup.get('removeFreezeOnDateCtrl')?.errors?.format">{{ 'DIALOGREMFRZ.INVALIDDATE' | translate }}</mat-error>
                  </mat-form-field>  
                </div>
            </div>
        </form>
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer">
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical rufId>
            <div >
              <button role="region" aria-label="submit" id="submitBtn" mat-raised-button fisStyle color="primary"  rufId ruf-stepper-actions type="submit"  (click)="submitclick()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
              <button role="region" aria-label="reset" id="resetBtn" mat-stroked-button fisStyle color="primary"  rufId ruf-stepper-actions type="button"  (click)="resetClick()" >{{ 'BUTTONS.RESET' | translate }}</button>
            </div>
        </div>
    </ruf-dialog-footer>
</ruf-dialog-panel>
