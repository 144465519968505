import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { FormBuilder } from '@angular/forms';
export class DialogMessage {
  constructor(
    public messagetext: string,
    public buttontext: string,
    public showCancel: boolean,
    public title: string = ''
  ) {}
}

@Component({
  selector: 'chexsystem-ws-dialogwithmessage',
  templateUrl: './dialog-with-message.component.html',
  styleUrls: ['./dialog-with-message.component.scss'],
})
export class DialogwithmessageComponent implements OnInit {
  message: string;
  buttontext: string;
  showcancel: boolean = false;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<DialogwithmessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessage
  ) {
    this.message = data.messagetext;
    this.buttontext = data.buttontext;
    this.title = data.title;
    this.showcancel = data.showCancel;
  }

  ngOnInit(): void {
    console.log(this.showcancel);
    //this.showcancel = this.buttontext != 'Contact Chexsystems' ? true : false;
  }

  onSubmit(): void {
    this.dialogRef.close('OK');
  }

  onCancel(): void {
    this.dialogRef.close('cancel');
  }
}
