import { Inject, InjectionToken, Optional, Injectable } from '@angular/core';
import { MemoryStorage, OAuthStorage } from 'angular-oauth2-oidc';

/* eslint-disable @typescript-eslint/naming-convention */
export const TokenStoreStorage = new InjectionToken('TOKEN_STORE_STORAGE');
const KEY_NONCE = 'nonce';
const KEY_VERIFIER = 'PKCE_verifier';

interface Storage {
  setItem: (name: string, value: string) => void;
  getItem: (name: string) => string | null;
  removeItem: (name: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class MyTokenStore implements OAuthStorage {
  _storage: Storage;

  constructor(
    @Optional()
    @Inject(TokenStoreStorage)
    storage?: Storage
  ) {
    console.log("application token store");
    this._storage = storage || new MemoryStorage();
  }
  get storage(): Storage {
    return this._storage;
  }

  getItem(key: string): string | null {
    return this.getStorage(key).getItem(key);
  }
  removeItem(key: string) {
    this.getStorage(key).removeItem(key);
  }
  setItem(key: string, data: string) {
    this.getStorage(key).setItem(key, data);
  }

  private getStorage(key: string): Storage {
    if (
      this._storage instanceof MemoryStorage &&
      (key === KEY_NONCE || key === KEY_VERIFIER)
    ) {
     //const win = this.win || window;
      return sessionStorage;
    } else {
      return this._storage;
    }
  }
}
