
   <div role="region" aria-label="back" class="chex-page-left-right-alignment chex-top_label_link"><a  href="javascript:void(0);" (click)="backToSource()">{{ 'CORRESPONDENCEALL.BACK' | translate }}</a></div>
   <ruf-page-header title="Correspondence" description=" " ></ruf-page-header>
   <!-- <div rufMarginBottom role="region" aria-label="status" id="Heading" class="chex-page-left-right-alignment ruf-caption-2">{{ 'CORRESPONDENCEALL.STATUS' | translate }}</div> -->
   <div class="chex-page-left-right-alignment">
    <ruf-dynamic-datatable matSort [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="items" [metadata]="columns">
      <ng-container *rufNoDataRow>
        <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
        <h2 role="region" aria-label="noitems" class="chex-no-record-message">{{ 'CORRESPONDENCEALL.NOITEMS' | translate }}</h2>
      </ng-container>
      <ng-container matColumnDef="dateOfCorrespondence" >
        <th role="region" aria-label="date" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CORRESPONDENCEALL.DATE' | translate }}</th>
        <td role="region" aria-label="dateelement" id="rowClick" class="chx-datagrid-cell" (click)="openCorresLetter(element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.dateOfCorrespondence | date:'MM/dd/yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="letterName" >
        <th role="region" aria-label="communication" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CORRESPONDENCEALL.COMMUNICATION' | translate }}</th>
        <td role="region" aria-label="communicationelement" class="chx-datagrid-cell" (click)="openCorresLetter(element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.letterName}} </td>
      </ng-container>
      <ng-container matColumnDef="referenceNumber" >
        <th role="region" aria-label="reference" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CORRESPONDENCEALL.REFERENCE' | translate }}</th>
        <td role="region" aria-label="referenceelement" class="chx-datagrid-cell" (click)="openCorresLetter(element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.referenceNumber}} </td>
      </ng-container>
    </ruf-dynamic-datatable>
    </div>