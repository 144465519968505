import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { FormBuilder } from '@angular/forms';
// import jsPdf from 'jspdf'; 
import jspdf from 'jspdf';
import { Field, SitecoreRequestData } from '../../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../../_services/sitecore/sitecore.service';
import { Font } from 'jspdf';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'chexsystem-ws-dialogtermsandconditions',
  templateUrl: './dialog-terms-and-conditions.component.html',
  styleUrls: ['./dialog-terms-and-conditions.component.scss']
})
export class DialogtermsandconditionsComponent implements OnInit {
  @ViewChild('divtermsandconditions') divtermsandconditions: ElementRef<HTMLElement>;
  message: string;
  termsAndConditionsContent: string = '';
  isLoader: boolean = false;
  downloadPdfHrefLink: string = '';

  constructor(public dialogRef: MatDialogRef<DialogtermsandconditionsComponent>,
    private sitecoreService: SitecoreService) {
  }

  ngOnInit(): void {
    this.fetchTermsAndConditionsContent();
  }

  fetchTermsAndConditionsContent() {
    let manageSecurityFreezeContentPath: string = 'Terms and Conditions/Data/Content/Data';
    this.isLoader = true;
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: manageSecurityFreezeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());
        if (child.name.toLowerCase() == 'terms and conditions') {   
          this.termsAndConditionsContent = fieldObject.value.toString();
        }
        else if (child.name.toLowerCase() == 'terms and conditions pdf path') {   
          const divTermsAndConditions = document.createElement('div');
          divTermsAndConditions.innerHTML = fieldObject.value.toString();
          var anchorelements = divTermsAndConditions.querySelectorAll("a"); 
          anchorelements.forEach(anchorelement => {            
            this.downloadPdfHrefLink= "assets/pdf/Secure Portal Terms and Conditions.pdf";
            // this.downloadPdfHrefLink  = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
          });
        }
      });
      this.isLoader = false;

    },
      error => {
        this.isLoader = false;
      },
      () => {
        this.isLoader = false;
      }
    );
  }

  onSubmit(): void {
    this.dialogRef.close('OK');
  }

  // downloadPdf() {    
  //   const pdfdata = this.divtermsandconditions.nativeElement;
  //   const pdfHeaderHtml = "<h2 align='center'>ChexSystems® Terms & Conditions</h2>"
  //   var pdf = new jspdf('p', 'px',"a4");
  //   pdf.setCharSpace(0.1)
  //   pdf.setDocumentProperties({title : 'Terms and Conditions'});
  //   pdf.html(pdfHeaderHtml+pdfdata.innerHTML, {      
  //     callback(pdfdoc) {
  //       pdfdoc.save("Secure Portal Terms and Conditions.pdf");
  //     },
  //     margin : [10,20],
  //     autoPaging: "text",
  //     x: 20,
  //     y: 5, 
  //     width: 350, 
  //     windowWidth: 600,
  //   });
  // }
}