export class QuizRequest {
    idaIdentification: IdaIdentification;
    idaPerson: IdaPerson;
}

export interface IdaPerson {
    address: string;
    city: string;
    dob: string;
    firstName: string;
    idvOid: string;
    lastName: string;
    middleName: string;
    govtNbr: string;
    state: string;
    zip: string;
}

export interface IdaIdentification {
    acquirerid: string;
    configkey: string;
    customerid: string;
    locationid: string;
    productcd: string;
    productversion: string;
    staging: string;
    userdefinedtransactiontext1: string;
    userdefinedtransactiontext2: string;
    userdefinedtransactiontext3: string;
    aqrcustomerid: string;
    aqr: string;
    instancecd: string;
    msgUuid: any;
}

export class Quiz
{
    idaQuestion1 : Question;
    idaQuestion2 : Question;
    idaQuestion3 : Question;
    idaQuestion4 : Question;
    idaQuestion5 : Question;
    idaQuestion6 : Question;
    idaQuestion7 : Question;
    idaQuestion8 : Question;
    idaQuestion9 : Question;
    quizId: string;
    quizRemain: string;
    quizTime: string;
    transId: string;
    userReferenceText1: string;
    userReferenceText2: string;
    userReferenceText3: string;
    /**IDA Decision */
    errorCd?: any;
    errorTxt?: string;
}

export class Question
{
    id: string;
    questionText: string;
    answer1Id: string;
    answer1Text: string;
    answer2Id: string;
    answer2Text: string;
    answer3Id: string;
    answer3Text: string;
    answer4Id: string;
    answer4Text: string;
    answer5Id: string;
    answer5Text: string;
    answer6Id: string;
    answer6Text: string;
}

export class IdaDecisionRequest {
    idaIdentification: IdaIdentification;
    ans: Ans;
}

export interface IdaIdentification {
    acquirerid: string;
    configkey: string;
    customerid: string;
    locationid: string;
    productcd: string;
    productversion: string;
    staging: string;
    userdefinedtransactiontext1: string;
    userdefinedtransactiontext2: string;
    userdefinedtransactiontext3: string;
    aqrcustomerid: string;
    aqr: string;
    instancecd: string;
    msgUuid: any;
}

export interface Ans {
    answer1QuestionId: string;
    answer1ResponseId: string;
    answer2QuestionId: string;
    answer2ResponseId: string;
    answer3QuestionId: string;
    answer3ResponseId: string;
    answer4QuestionId: string;
    answer4ResponseId: string;
    answer5QuestionId: string;
    answer5ResponseId: string;
    answer6QuestionId: string;
    answer6ResponseId: string;
    answer7QuestionId: string;
    answer7ResponseId: string;
    answer8QuestionId: string;
    answer8ResponseId: string;
    answer9QuestionId: string;
    answer9ResponseId: string;
    quizId: string;
    transId: string;
    userDefinedTransactionText1: string;
    userDefinedTransactionText2: string;
    userDefinedTransactionText3: string;
    msgUuid: string;
}

export class IdaDecisionResponse {
    base: number;
    decision: string;
    idaOid: string;
    idaQuiz?: Quiz;
    score: number;
    userReferenceText1?: any;
    userReferenceText2?: any;
    userReferenceText3?: any;
    errorTxt:string;
    errorCd:string;
}


export class IdaQuiz{
    idaQuestion :IdaQuestion[];
    quizId: string;
    quizRemain: string;
    quizTime: string;
    transId: string;
    userReferenceText1: string;
    userReferenceText2: string;
    userReferenceText3: string;
}
export class IdaQuestion {
id: string;
questionText: string;
options: AnswerOption[];
answer:string;
tagName:string;
}

export class AnswerOption{
    value:string;
    text:string;
}

export class IdaAnswerDetail
{
    idaQuestion1:AnswerDetail;
    idaQuestion2:AnswerDetail;
    idaQuestion3:AnswerDetail;
    idaQuestion4:AnswerDetail;
    idaQuestion5:AnswerDetail;
    idaQuestion6:AnswerDetail;
    idaQuestion7:AnswerDetail;
    idaQuestion8:AnswerDetail;
    idaQuestion9:AnswerDetail;
}

export class AnswerDetail
{
    questionId: string;
    responseId: string;
}

export interface IdaQuizMockAnswer {
    quizId: string;
    transId: string;
    answerResponseIds: string[];
}