<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
  <ruf-dialog-header rufId="simple_dialog_header"#header>{{ 'DIALOGIDLETIMER.HEADER' | translate }}</ruf-dialog-header>
    <ruf-dialog-content rufId="dialog_content"  rufScrollbar [autoHide]=true>
      <div id="idletxt" role="region" aria-label="inf0" class="message_text">
        {{ 'DIALOGIDLETIMER.INFO' | translate }}
      </div>
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer" >
      
        <button role="region" aria-label="continue" id="continueBtn" mat-raised-button fisStyle color="primary"  rufId="dialog_footer_button" (click)="onContinue()">{{buttontext}}</button>
        <button role="region" aria-label="reset" id="resetBtn" mat-stroked-button fisStyle color="primary" class="form_button" rufId (click)="onReset()">{{ 'BUTTONS.RESET' | translate }}</button>
      
    </ruf-dialog-footer>
    </ruf-dialog-panel>