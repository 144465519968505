export interface ConsumerDisclosureRequest {
  DisclosureReq: DisclosureReq;
}

export interface DisclosureReq {
  CnsmrAddrDetls: CnsmrAddrDetls;
  CnsmrPrsnlDetls: CnsmrPrsnlDetls;
  CnsmrRefId: string;
  CommentTxt: string;
  PreviousAddrDetls: PreviousAddrDetls;
  RecvdDtTm: string;
  RequestSrcCode: string;
  RequestTypCode: string;
  RqstHdr: RqstHdr;
  UserName: string;
  CellPhoneNbr: string;
  CorrespondenceDeliveryPreference: string;
  NotificationEmailAddr: string;
}

export interface RqstHdr {
  RqstSubTyp: string;
  RqstTyp: string;
}

export interface PreviousAddrDetls {
  PrevAddr1: CnsmrAddr;
  PrevAddr2: CnsmrAddr;
  PrevAddr3: CnsmrAddr;
}

export interface CnsmrPrsnlDetls {
  BirthDt: string;
  CnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls;
  CnsmrNmDetls: CnsmrNmDetls;
  ConsumerId: string;
  EmailAddress: string;
  GovtNbr: string;
  PhoneNbr: string;
}

export interface CnsmrNmDetls {
  FrstNm: string;
  LastNm: string;
  MidNm: string;
  PreviousLastName: string;
  SuffixNm: string;
}

export interface CnsmrDrivLicenseDetls {
  DrivLicenseIssuSt: string;
  DrivLicenseNbr: string;
}

export interface CnsmrAddrDetls {
  CnsmrAddr: CnsmrAddr;
}

export interface CnsmrAddr {
  AddrLine1: string;
  AddrLine2: string;
  CityNm: string;
  StatCode: string;
  ZipCode: string;
}



export interface ConsumerDisclosureResponse {
  DisclosureRsp: DisclosureRsp;
}

export interface DisclosureRsp {
  cnsmrDataAsEntd: CnsmrDataAsEntd;
  discRspEncData: string;
  discRspMsg: string;
  userName: string;
  cellPhoneNbr: string;
  correspondenceDlvryPref: string;
  notificationEmailAddr: string;
  rspCd: string;
  rspMsg: string;
}

export interface CnsmrDataAsEntd {
  cnsmrRefId: string;
  cnsmrPrsnlDetls: CnsmrPersonalDetls;
  cnsmrAddrDetls: CnsmrAddressDetls;
}

export interface CnsmrAddressDetls {
  cnsmrAddr: CnsmrAddr;
  cnsmrPreviousAddr1: CnsmrPreviousAddr1;
  cnsmrPreviousAddr2: CnsmrPreviousAddr1;
  cnsmrPreviousAddr3: CnsmrPreviousAddr1;
}

export interface CnsmrPreviousAddr1 {
  addrLine1Nm: string;
  addrLine2Nm: string;
  cityNm: string;
  statCode: string;
  zipCode: string;
}

export interface CnsmrPersonalDetls {
  cnsmrNmDetls: CnsmrNameDetls;
  birthDt: string;
  govtNbr: string;
  cnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls;
}


export interface CnsmrNameDetls {
  frstNm: string;
  lastNm: string;
}

export enum DisclosureRequestheader {
  RequestSrcCode = "SECURE",
  RequestTypCode = "Disclosure",
  RqstTyp = "DS",
  RqstSubTyp = "ODR",
  CommentTxt = "Generate Consumer Disclosure",
  MailType ="DS",
  MailSybType ="D",
  AutomationRulesCheckType = "AR",
  AutomationRulesCheckSubType = "ODR"
}