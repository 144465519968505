import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { CommonLoggerService } from '../shared/logger-apis/logger-apis.service';
import { loggerModel } from '../../_models/classes/common-logger.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler  {

  

  constructor(private _logger: CommonLoggerService) {
   }

   handleError(error: any) {
    if ((error instanceof HttpErrorResponse)) {
      //console.error(`${this.formatDate(new Date())} ${error.error.statusCode}  ${error.error.message}`);
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())} ${error.error.statusCode}  ${error.error.message}`;
          logReq.errorcode = error.error.statusCode;
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
    }
    else{
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())}` + " " + error;
          logReq.errorcode = "";
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
      //console.error(`${this.formatDate(new Date())}`, error);
    }
  }
  
  handleErrorEx(error: any, sourceMethod:string | undefined) {
    if ((error instanceof HttpErrorResponse)) {
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())} ${sourceMethod} ${error.error.statusCode}  ${error.error.message}`;
          logReq.errorcode = error.error.statusCode;
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
      //console.error(`${this.formatDate(new Date())} ${sourceMethod} ${error.error.statusCode}  ${error.error.message}`);
    }
    else{
      const logReq: loggerModel.ErrorRequest =
          new loggerModel.ErrorRequest();
          logReq.error = `${this.formatDate(new Date())} ${sourceMethod}` + " " + error;
          logReq.errorcode = "";
      this._logger.logError(logReq).subscribe(
        (res) => {
          
        },
        (error) => {
          
        },
        () => { }
      );
      //console.error(`${this.formatDate(new Date())} ${sourceMethod}`, error);
    }
    
  }

  formatDate(date:Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    )
  }

  padTo2Digits(num:number) {
    return num.toString().padStart(2, '0');
  }
}
