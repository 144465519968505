import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { FormBuilder, FormGroup, Validators, FormControl, NgModel } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { LookupFreezeResponse } from '../../_models/classes/lookupfreeze-reponse.model';
import { LookupFreezeRequest } from '../../_models/classes/lookupfreeze-request.model';
import { SecurityValidationService } from '../../_services/registration/security-validations.service';
import { RufDropdownType } from '@ruf/shell';
import { GlobalVariables } from '../../common/enums/global_variables';
import { ReCaptchaV3Service } from 'ng-recaptcha';

export class DialogsecurityfreezepinData {
  constructor(public govtnumber: string, public isReg: boolean) {

  }
}
@Component({
  selector: 'chexsystem-ws-dialogsecurityfreezepin',
  templateUrl: './dialog-security-freeze-pin.component.html',
  styleUrls: ['./dialog-security-freeze-pin.component.scss']
})
export class DialogsecurityfreezepinComponent implements OnInit {

  formGroup: FormGroup;
  answertext: string;
  govtnumber: string = '';
  isReg: boolean = false;
  lookupfreeze: LookupFreezeResponse;
  freezeoutput: { [key: string]: boolean } = {};
  attemptsAllowed: number = environment.securityFreezePINAttempts;
  attemptsRemaining: number = this.attemptsAllowed;
  hasBackdrop = true;
   userName = '';
  panel = {
    type: RufDropdownType.PopoverMenu,
  };
  constructor(private _formBuilder: FormBuilder, private securityValidationService: SecurityValidationService, public dialogRef: MatDialogRef<DialogsecurityfreezepinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogsecurityfreezepinData, private recaptchaV3Service: ReCaptchaV3Service) {
    this.govtnumber = data.govtnumber;
    this.isReg = data.isReg;
    this.formGroup = this._formBuilder.group({
      txtfreezepin: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.userName = GlobalVariables.globalvars.get('UserId') || '';
  }

 async onSubmit() {

    if (this.formGroup.valid) {
      let capchaToken;
      if(this.isReg === true) {
      try {
        capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
      }  catch (error) {}
    }
        let lookupFreezeRequest: LookupFreezeRequest = <LookupFreezeRequest>{
          GovtNbr: this.govtnumber,//,//"491713274",
          PinNbr: this.answertext,
          UserName: this.userName,
          RecapchaToken: this.isReg ? capchaToken: '',
          isReg: this.isReg
        };

        this.securityValidationService.LookupFreeze(lookupFreezeRequest).subscribe(res => {
          this.lookupfreeze = res as LookupFreezeResponse;

          if (this.lookupfreeze.LookupFreeze?.ProtectedFlagInd?.toString().toUpperCase() == 'Y') {
            //if protected consumer = true
            this.freezeoutput = { protectedconsumer: true };
            this.dialogRef.close(this.freezeoutput);
          }
          else {
            if (this.lookupfreeze.LookupFreeze?.PinNbr?.toString() == this.answertext.toString()) {
              this.freezeoutput = { isfreezeanswercorrect:true };
              this.dialogRef.close(this.freezeoutput);
            }
            else {
              this.answertext='';
              this.attemptsRemaining--;
              if (this.attemptsRemaining == 0) {
                this.freezeoutput = { isfreezeanswercorrect: false };
                this.dialogRef.close(this.freezeoutput);
              }
            }
          }
        });
    }
  }

  // onCancel(): void {
  //   this.freezeoutput = { cancel: true }
  //   this.dialogRef.close(this.freezeoutput);
  // }

}


