export class Timerdetail {
    public totalTime!: number;
    public id:ReturnType<typeof setTimeout> | any;
    public type = TimerOption.Countdown;
    public title!:string;
    public minutes!: number;
    public seconds!: number;
}


export enum TimerOption{
    ShowFixedTime ="fixed",
    Countdown = "countdown",
    Clear = "clear",
    Pause = "pause"
}