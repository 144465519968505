import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogMessage{
  constructor(public messagetext: string,public buttontext:string, public showCancel:boolean, public title:string = ''){
  }
}
@Component({
  selector: 'chexsystem-ws-dialogagevalidity',
  templateUrl: './dialog-age-validity.component.html',
  styleUrls: ['./dialog-age-validity.component.scss']
})
export class DialogagevalidityComponent implements OnInit {

  message: string;
  buttontext: string;
  showcancel: boolean = false;
  title:string;
    constructor(public dialogRef: MatDialogRef<DialogagevalidityComponent>,
      @Inject(MAT_DIALOG_DATA) public data:DialogMessage) { 
        this.message = data.messagetext;
        this.buttontext = data.buttontext;
        this.title = data.title;
        this.showcancel = data.showCancel;
      }
  
    ngOnInit(): void {
      //this.showcancel = this.buttontext != 'Contact Chexsystems' ? true : false;
    }
  
    onSubmit(): void{
      this.dialogRef.close('OK');
    } 
  }

  