import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';

@Component({
  selector: 'chexsystem-ws-consumer-score-disclosure-report',
  templateUrl: './consumer-score-disclosure-report.component.html',
  styleUrls: ['./consumer-score-disclosure-report.component.scss']
})
export class ConsumerScoreDisclosureReportComponent {
  backtodashboard=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.BACKTODASHBOARD');
  date1=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.DATE1');
  date2=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.DATE2');
  consumerid=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.CONSUMERID');
  id=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.ID');
  chexscore=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.CHEXSYSTEMSCORE');
  score=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.SCORE');
  datescore=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.DATESCORE');
  info1=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.INFO1');
  note=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.NOTE');
  factorscore=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.FACTORSCORE');
  factor=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.FACTOR');
  reasoncode=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.REASONCODE');
  desc=this._translate.instant('CONSUMERSCOREDISCLOSUREREPORT.DESCRIPTION');

  constructor( private router: Router, private piConfirmService: PersonalInformationConfirmationService,
    private _translate: TranslateService) { } 
   

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
  }


  backToSource(){    
      this.router.navigate( ['dashboard']);
  }

  scoreData: consumerScoreItems[]= [
    {
      reason:'1. EG' ,
      description: 'Time Since Non-DDA Inquiry Activity',
    },

    {
      reason:'2. Ek' ,
      description: 'DDA Payment Closure History',
    },

  ];

  }


export class consumerScoreItems{
  reason: string;
  description: string;
   
}
