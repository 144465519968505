export namespace loggerModel{

  export class ErrorRequest {
    error: string
    errorcode: string
  }

  export class InfoRequest {
    Info: string
    Infocode: string
  }

}