export namespace securityalert{

  export interface SecurityAlertResponseRoot {
    code: string
    message: string
    referenceId: string
  }

  export class SecurityAlertRequestRoot {
    securityAlertRequest: SecurityAlertRequest
  }
  
  export class SecurityAlertRequest {
    rqstHdr: RqstHdr
    cnsmrPrsnlDetls: CnsmrPrsnlDetls
    cnsmrAddrDetls: CnsmrAddrDetls
    quesAndAnsDetls: QuesAndAnsDetls
    requestSrcCode: string
    recvdDtTm: string
    userName: string
    cellPhoneNbr: string
    correspondenceDeliveryPreference: string
    notificationEmailAddr: string
  }
  
  export class RqstHdr {
    rqstSubTyp: string
    rqstTyp: string
  }
  
  export class CnsmrPrsnlDetls {
    birthDt: string
    cnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls
    cnsmrNmDetls: CnsmrNmDetls
    govtNbr: string
    phoneNbr: string
  }
  
  export class CnsmrDrivLicenseDetls {
    drivLicenseNbr: string
    drivLicenseIssuSt: string
  }
  
  export class CnsmrNmDetls {
    suffixNm: string
    frstNm: string
    midNm: string
    lastNm: string
  }
  
  export class CnsmrAddrDetls {
    cnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    addrLine1: string
    addrLine2: string
    cityNm: string
    statCode: string
    zipCode: string
  }
  
  export class QuesAndAnsDetls {
    questionCode: string
    ansText: string
  }
  
  
    
}
//////////////////////////////////
