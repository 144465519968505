    <div class="chex-bottom_space">
      <ruf-page-header role="region"  class="header_text" title="{{ 'MANAGESECURITYFREEZE.TITLE' | translate }}" description=" " ></ruf-page-header>
      <div role="region"  class="chex-page-left-right-alignment mat-body-2" [innerHTML]="manageSecurityFreezeContent | sanitizeHtml">
      </div>      
      <div role="region" aria-label="personaldetails" class="chex-page-left-right-alignment mat-subheading-2 chex-mng-frz-personal_details">{{ 'MANAGESECURITYFREEZE.PERSONALDETAILS' | translate }}</div>

      <form id="formGroup" [formGroup]="formGroup" autocomplete="off"> 
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="44px"class="chex-page-left-right-alignment">
          
            <!-- <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="44px" rufMarginVertical >
              <div class="chex-form-ssn-field">
                <mat-form-field class="chex-form-field" floatLabel="always" boxStyle>
                  <mat-label role="region" id="header1" class="chex-form-field-label" fisStyle>{{ 'MANAGESECURITYFREEZE.USSOCIALSECURITY' | translate }}</mat-label>
                  <chexsystem-ws-phonecontrol id="ssnCtrl1" #ssnCtrl1 formControlName="ssnCtrl" required matInput boxStyle
                  [inputtype]="'password'"        
                  [displaymode]=true
                  [disabled]=true
                  [part1maxlength]="3" 
                  [part2maxlength]="2" 
                  [part3maxlength]="4" 
                  [part1placeholder]="'xxx'"
                  [part2placeholder]="'xx'" 
                  [part3placeholder]="'xxxx'"     
                  ></chexsystem-ws-phonecontrol>
                </mat-form-field>   
              </div> 
              <div id="maskSSN" class="chex-mng-frz-eyeIcon">
                <mat-icon [matTooltip]="eyeIconTooltipSSN" #maskEyeSSN [fisIcon]="eyeIconTypeSSN" (click)="_handleMasking('ssn', maskEyeSSN)"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" style="font-size: 20px;"></mat-icon>
              </div>
            </div>  -->

            <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="" rufMarginVertical >

            <mat-form-field  class="chex-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="pin" id="header2" class="chex-form-field-label">{{ 'MANAGESECURITYFREEZE.PIN' | translate }}</mat-label>
              <input id="pin" formControlName="txtfreezepin" fisStyle matInput placeholder="Enter PIN" required maxlength="12" (keypress)="allowOnlyNumbers($event)">
              <mat-error *ngIf="formGroup.get('txtfreezepin')?.hasError('required')">{{ 'MANAGESECURITYFREEZE.PINREQ' | translate }}</mat-error>
            </mat-form-field>
          </div>
          </div>
        
        <div role="region"  class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
            <button id="sbmtButton" mat-raised-button fisStyle color="primary"  rufId ruf-stepper-actions type="submit"  (click)="submitclick()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
            <button id="rstButton" mat-stroked-button fisStyle rufId ruf-stepper-actions type="button"  (click)="resetClick()">{{ 'BUTTONS.RESET' | translate }}</button>
        </div>
     </form>
    </div>