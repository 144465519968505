import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { dashboard } from '../../_models/classes/dashboard-dispute.model';
import { dashboardEvent } from '../../_models/classes/dashboard-dispute.model';
import { DashboardService } from '../../_services/dashboard-services/dashboard.service';
import { GlobalVariables } from "../../common/enums/global_variables";

export class DialogInfo{
  constructor(public header:string, public confNo:string, public dateReceived:Date, public targetCompletionDate:Date ){
  }  
}
@Component({
    selector: 'chexsystem-ws-dialog-individual-dispute',
    templateUrl: './dialog-individual-dispute.component.html',
    styleUrls: ['./dialog-individual-dispute.component.scss']
  })
  export class DialogIndividualDisputeComponent {
    reportedFor:string='';
  header:string ='';
  confNo:string;
  dateReceived:Date;
  targetCompletionDate:string;
  disputeData:DisputeDetailItem = new DisputeDetailItem();
  disputeRoot:dashboardEvent.DashboardDisputeDetailRoot;
  showLoadingIndicator:boolean = false;
  constructor(public dialogRef: MatDialogRef<DialogIndividualDisputeComponent>,
    public dialog: MatDialog,private http: HttpClient,
    private dashboardService:DashboardService,
    @Inject(MAT_DIALOG_DATA) public Data:DialogInfo) {

      this.header = Data.header;
      this.confNo = Data.confNo;
      this.dateReceived = Data.dateReceived;
      if(Data.targetCompletionDate){
        this.targetCompletionDate = this.ConvertValidDateFormat(Data.targetCompletionDate);
      }
      else{
        this.targetCompletionDate = "";
      }
      
      //this.disputeData = this.initDisputeData(this.confNo);
      this.showLoadingIndicator = true;
      this.getDisputeRespData().subscribe((res) => {
        this.disputeRoot = res as dashboardEvent.DashboardDisputeDetailRoot;
        var tempItems: DisputeEventItem[] = [];
        var counter:number = 0;
        for (var val of this.disputeRoot.disputeItemDetailRsp.disputeList) {
            var item:DisputeEventItem = new DisputeEventItem();
            item.eventID = val.dataMartKey;
            item.source = val.providerName;
            item.status = val.statusOfDispute;
            item.type = val.eventType;
            tempItems.push(item);
        }
        this.disputeData.confirmationNo = this.disputeRoot.disputeItemDetailRsp.cnsmrRefId;
        this.disputeData.receivedDate = new Date(this.disputeRoot.disputeItemDetailRsp.dateReceived);
        if(this.disputeRoot.disputeItemDetailRsp.targetCompletionDate.trim() !== ""){
          this.disputeData.targetCompletionDate = new Date(this.disputeRoot.disputeItemDetailRsp.targetCompletionDate);
        }
        
        this.disputeData.DisputeEventItemList = tempItems;
      }, 
      error => { this.showLoadingIndicator = false;},
      () => {this.showLoadingIndicator = false; });
  }
  
  getDisputeRespData() {
    let _userName = GlobalVariables.globalvars.get("UserId") || '';
    let dashReqRoot:dashboardEvent.DisputeItemDetailReqRoot = new dashboardEvent.DisputeItemDetailReqRoot();
    dashReqRoot.disputeItemDetailReq = new dashboardEvent.DisputeItemDetailReq();
    dashReqRoot.disputeItemDetailReq.userName = _userName;
    dashReqRoot.disputeItemDetailReq.dateReceived = this.ConvertValidDateFormat(this.dateReceived);
    dashReqRoot.disputeItemDetailReq.targetCompletionDate = this.targetCompletionDate;
    dashReqRoot.disputeItemDetailReq.cnsmrRefId = this.confNo;
    //return this.http.get('./././assets/jsondata/DisputeItemDetail-Rsp.json');
    return this.dashboardService.DisputeItemDetail(dashReqRoot);
  }

  initDisputeData(cofNo:string){
    var tempData:DisputeDetailItem;
    tempData = new DisputeDetailItem();
    tempData.confirmationNo = 'CX21321';
    tempData.receivedDate = new Date('01/01/2021');
    tempData.targetCompletionDate = new Date('01/01/2021');

    var tempEveData1:DisputeEventItem;
    tempEveData1 = new DisputeEventItem();
    tempEveData1.eventID = "1";
    tempEveData1.source = "BOA";
    tempEveData1.type = "Rep Inf";
    tempEveData1.status = "In Progress";
    tempData.DisputeEventItemList.push(tempEveData1);

    var tempEveData2:DisputeEventItem;
    tempEveData2 = new DisputeEventItem();
    tempEveData2.eventID = "1";
    tempEveData2.source = "Chane";
    tempEveData2.type = "Inquiry";
    tempEveData2.status = "In Progress";
    tempData.DisputeEventItemList.push(tempEveData2);


    return tempData;
  }
  onCancel(): void{
    //alert('HI');
    //this.dialogRef.close(this.dialogDisputeResult);
  }
  rowSelectionChange(event: any) {
  }

  onStateChange(event: any) {
  }

  columns: any[] = [
    {
      field: 'eventID',
      name: 'Event ID',
      title: 'Event ID',
      width: '25%'
    },
    {
      field: 'type',
      name: 'Type',
      width: '25%',
      title: 'Type'
    },
    {
      field: 'source',
      name: 'Source',
      title: 'Source',
      width: '25%'
    },
    // commented the status field for part of this story US1353281
    // {
    //   field: 'status',
    //   name: 'Status',
    //   title: 'Status',
    //   width: '25%'
    // }
  ];

  private ConvertValidDateFormat(dateValue: Date): string {
    if(dateValue){
      var dd = String(dateValue.getDate()).padStart(2, '0');
      var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
      var yyyy = dateValue.getFullYear();
      let validDate = mm + '/' + dd + '/' + yyyy;
      return validDate;
    }
    else{
      return "";
    }
    
  }

  } 
 
// export class DialogInfo{
//   constructor(public header:string, public confNo:string){
//   }  
// }
export class DisputeDetailItem {
  confirmationNo: string;
  receivedDate: Date;
  targetCompletionDate: Date;
  description:string="";
  DisputeEventItemList: DisputeEventItem[] = [];
}

export class DisputeEventItem {
  eventID: string;
  type:string;
  source:string;
  status: string;
}