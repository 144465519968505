import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'chexsystem-ws-common-snackbar',
  templateUrl: './common-snackbar.component.html',
  styleUrls: ['./common-snackbar.component.scss']
})
export class CommonSnackbarComponent {

  color: string = '';
  snackBarContent: string = '';
  constructor(private snackBarRef: MatSnackBarRef<CommonSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public notificationData: string) {
    this.snackBarContent = notificationData;//"test snackbar data";//notificationData;
  }

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}
