import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'chx-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})


export class ContactUsComponent {

  title=this._translate.instant('CONTACTUS.TITLE');
  consumerrelations=this._translate.instant('CONTACTUS.CONSUMER RELATIONS');
  phnnum=this._translate.instant('CONTACTUS.PHONE NUMBER');
  info1=this._translate.instant('CONTACTUS.INFO1');
  info2=this._translate.instant('CONTACTUS.INFO2');
  info3=this._translate.instant('CONTACTUS.INFO3');
  mail=this._translate.instant('CONTACTUS.MAIL');
  infoconsumer1=this._translate.instant('CONTACTUS.INFOCONSUMER1');
  infoconsumer2=this._translate.instant('CONTACTUS.INFOCONSUMER2');
  infoconsumer3=this._translate.instant('CONTACTUS.INFOCONSUMER3');
  infoconsumer4=this._translate.instant('CONTACTUS.INFOCONSUMER4');
  securityfreeze=this._translate.instant('CONTACTUS.SECURITY FREEZE');
  phnnumber=this._translate.instant('CONTACTUS.PHN NUMBER');
  infosec1=this._translate.instant('CONTACTUS.INFOSECURITY1');
  infosec2=this._translate.instant('CONTACTUS.INFO2');
  infosec3=this._translate.instant('CONTACTUS.INFOSECURITY2');
  infocon1=this._translate.instant('CONTACTUS.INFOCONSUMER1');
  infomail1=this._translate.instant('CONTACTUS.INFOCONSUMER1');
  infocon2=this._translate.instant('CONTACTUS.INFOCONSUMER3');
  infocon3=this._translate.instant('CONTACTUS.INFOCONSUMER4');

  constructor(
    private router: Router,
    private piConfirmService: PersonalInformationConfirmationService,
    private _translate: TranslateService
  ) { 

  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
  }

  submitclick() {
    this.router.navigate(['place-alert']);
  }
  // navigateToLinks(code:string){

  //   if(code === "faq"){
  //     window.open("https://cmchexqa.fisglobal.com/answers-to-frequently-asked-questions", '_blank')?.focus();
  // }


  // }

}

