<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
    <ruf-dialog-header rufId="simple_dialog_header" #header>{{title}}</ruf-dialog-header>
    <ruf-dialog-content rufId="dialog_content" rufScrollbar [autoHide]=true>
        <div role="region" aria-label="message" id="idletxt" class="message_text">
            {{message1}}
        </div>
        <div role="region" aria-label="message" id="idletxt1" class="message_text_align">
            {{message2}}<b>{{minutesLeft}}:{{secondsLeft}}</b>
        </div>
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer">
        <button role="region" aria-label="continuetxtelement" id="continueBtn" mat-raised-button fisStyle color="primary" rufId="dialog_footer_button"
            (click)="onContinue()">{{continueButtonText}}</button>
        <button role="region" aria-label="cancel" id="resetBtn" mat-stroked-button fisStyle color="primary" class="form_button" rufId
            (click)="onReset()">{{cancelButtonText}}</button>
    </ruf-dialog-footer>
</ruf-dialog-panel>