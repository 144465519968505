import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogFileUploaderComponent } from '../../../../dailog/dialog-attachment/dialog-file-attachment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogInfo, DialogIndividualDisputeComponent } from '../../../../dailog/dialog-individual-dispute/dialog-individual-dispute.component';
import { PersonalInformationConfirmationService } from '../../../../_services/shared/pi-confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'chx-view-all-documents',
  templateUrl: './view-all-documents.component.html',
  styleUrls: ['./view-all-documents.component.scss'],
})


export class ViewAllDocumentsComponent {
  confNo:string;
  backtodashboard=this._translate.instant('VIEWALLDOC.BACKTODASHBOARD');
  noitems=this._translate.instant('VIEWALLDOC.NOITEMS');
  name=this._translate.instant('VIEWALLDOC.NAME');
  date=this._translate.instant('VIEWALLDOC.DATE');
  size=this._translate.instant('VIEWALLDOC.SIZE');
  status=this._translate.instant('VIEWALLDOC.STATUS');

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private piConfirmService: PersonalInformationConfirmationService,
    private _translate: TranslateService,
  ) {
      this.confNo="CX21321";
   }

   ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
  }

  items: ViewAllDocumentsItems[] = [
    
    {
      documentName: "Report.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "Sample.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "CheckStatus.jJPG",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "Report.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "Sample.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "CheckStatus.jJPG",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },{
      documentName: "Report.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "Sample.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "CheckStatus.jJPG",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },{
      documentName: "Report.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "Sample.PDF",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    },
    {
      documentName: "CheckStatus.jJPG",
      updatedDate: new Date('16 Jan, 2022'),
      size: "300MB",
      status: "Uploaded"
    }

  ];

  
  backToSource(){
    //if(this.backSource === "d"){
      this.router.navigate( ['dashboard']);
    //}

  }

    columns: any[] = [
      {
        field: 'documentName',
        name: 'Document Name',
        width: '25%',
        title: 'Document Name',
        sticky: true,
        filterable: true
      },
      {
        field: 'updatedDate',
        name: 'Updated Date',
        title: 'Updated Date',
        width: '25%',
        dataType: {
          name: 'date',
          format: ['MM/dd/yyyy']
        },
        sticky: true
      },
      {
        field: 'size',
        name: 'Size',
        title: 'Size',
        width: '25%',
        sticky: true
      },
      {
        field: 'status',
        name: 'Status',
        width: '25%',
        title: 'Status',
        sticky: true
      }
    ];
  
    onStateChange(event: any) {
    }
    rowClickHandler(code: string, element:any){
      this.router.navigate( ['pdf-view-docs', {src: "a", id: this.confNo}]);
    }
}
  export class ViewAllDocumentsItems {
    documentName: string;
    updatedDate: Date;
    size: string;
    status: string;
  }