import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';



export class DialogMessage{
  constructor(public messagetext: string,public buttontext:string, public showCancel:boolean, public title:string = ''){
  }
}
@Component({
  selector: 'chexsystem-ws-dialogwithmessagebtn',
  templateUrl: './dialog-with-message-btn.component.html',
  styleUrls: ['./dialog-with-message-btn.component.scss']
})
export class DialogwithmessagebtnComponent implements OnInit {
  message: string;
  buttontext: string;
  showcancel: boolean = false;
  title:string;
  
    constructor(public dialogRef: MatDialogRef<DialogwithmessagebtnComponent>,
      @Inject(MAT_DIALOG_DATA) public data:DialogMessage, private router: Router) { 
        this.message = data.messagetext;
        this.buttontext = data.buttontext;
        this.title = data.title;
        this.showcancel = data.showCancel;
      }
  
    ngOnInit(): void {
      //this.showcancel = this.buttontext != 'Contact Chexsystems' ? true : false;
    }
  
    onSubmit(): void{
      this.router.navigate( ['dashboard']);
    }
  
}

  // export class DialogMessage{
  //   constructor(public messagetext: string,public buttontext:string, public showCancel:boolean, public title:string = ''){
  //   }
  // }
