export class DashBoardCarouselResponseData {
  data: Data;
}

export class Data {
  item: Item;
}

export class Item {
  id:       string;
  path:     string;
  name:     string;
  children: Child[];
}

export class Child {
  id:        string;
  path:      string;
  name:      string;
  children?: Child[];
  fields?:   Field[];
}

export class Field {
  id:    string;
  name:  string;
  value: string;
}

export enum FieldName {
  Text = "Text",
}
