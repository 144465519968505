
<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
  <ruf-dialog-header role="region" aria-label="title" rufId="simple_dialog_header" #header>{{ 'DIALOGSECURITYALERT.TITLE' | translate }}</ruf-dialog-header>
 
  <ruf-dialog-content rufId="simple_dialog_content">
    <form [formGroup]="formGroup" class="form" autocomplete="off">
      <div role="region" aria-label="info1" id="explaintxt" class="chex-dlg-ques-form_text_explain">
        {{ 'DIALOGSECURITYALERT.INFO1' | translate }}
      </div>
      <div class="alert_question" fxLayout="row">
        <div role="region" aria-label="securityques" id="questxt" class="ruf-label">{{ 'DIALOGSECURITYALERT.SECURITYQUESTION' | translate }}</div>
        <div role="region" aria-label="questxt" class="chex-dlg-ques-form_text center">
          {{questiontext}}
        </div>
      </div>
      <div class="ruf-boxed-input-wrapper">
        <label role="region" aria-label="answer" id="anstxt" class="ruf-label"><span style="color: red;">*</span>{{ 'DIALOGSECURITYALERT.ANSWER' | translate }}</label>
        <mat-form-field boxStyle>
          <input formControlName="txtsecurityalertanswer" fisStyle matInput placeholder="Security Alert Answer" required
            [(ngModel)]="answertext" maxlength="50"/>
          <mat-error *ngIf="formGroup.get('txtsecurityalertanswer')?.hasError('required')">{{ 'DIALOGSECURITYALERT.ANSWERREQ' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <mat-error class="center" *ngIf="attemptsRemaining>0 && attemptsRemaining!=attemptsAllowed">
        {{ 'DIALOGSECURITYALERT.INFO2' | translate }}
        <b>&nbsp;{{attemptsRemaining}}&nbsp;{{ 'DIALOGSECURITYALERT.ATTEMPT' | translate }}&nbsp;</b>{{ 'DIALOGSECURITYALERT.LEFT' | translate }}
      </mat-error>
    </form>
  </ruf-dialog-content>

  <ruf-dialog-footer rufId="dialog_footer">
    <button role="region" aria-label="submit" id="submitBtn" mat-raised-button fisStyle color="primary" class="form_button" rufId="dialog_footer_button"
      (click)="onSubmit()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
    <button role="region" aria-label="cancel" id="cancelBtn" mat-stroked-button fisStyle color="primary" class="form_button" rufId (click)="onCancel()">{{ 'BUTTONS.CANCEL' | translate }}</button>
  </ruf-dialog-footer>
</ruf-dialog-panel>