export namespace scoreReq{
  export class consumerScoreData {
    CnsmrScrRqst: CnsmrScrRqst;
    RqstHdr: RqstHdr;
  }
  
  
  export class RqstHdr {
    RqstSubTyp: string;
    RqstTyp: string;
  }
  
  export class CnsmrScrRqst {
    CnsmrAddrDetls: CnsmrAddrDetls;
    CnsmrPrsnlDetls: CnsmrPrsnlDetls;
    CnsmrRefId: string;
    CnsmrRequestCodes: CnsmrRequestCodes;
    RecvdDtTm: string;
    UserName: string;
    CellPhoneNbr:string;
    CorrespondenceDeliveryPreference:string;
    NotificationEmailAddr:string;

  }
  
  export class CnsmrRequestCodes {
    CommentTxt: string;
    RequestSrcCode: string;
    RequestTypCode: string;
  }
  
  export class CnsmrPrsnlDetls {
    BirthDt: string;
    ChexConsId: string;
    CnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls;
    CnsmrNmDetls: CnsmrNmDetls;
    Email: string;
    GovtNbr: string;
    PhoneNbr: string;
  }
  
  export class CnsmrNmDetls {
    FrstNm: string;
    LastNm: string;
    MaidenNm: string;
    MidNm: string;
    SuffixNm: string;
  }
  
  export class CnsmrDrivLicenseDetls {
    DrivLicenseIssuSt: string
    DrivLicenseNbr: string
  }
  
  export class CnsmrAddrDetls {
    CnsmrAddr: CnsmrAddr;
  }
  
  export class CnsmrAddr {
    AddrLine1: string;
    AddrLine2: string;
    CityNm: string;
    StatCode: string;
    ZipCode: string;
  }
}
