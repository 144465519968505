export namespace managefreeze{


  export class LookupFreezeRequest {
    GovtNbr: string
    PinNbr: string
    UserName: string;
    RecapchaToken: string;
  }
  

  export class LookupFreezeRoot {
    LookupFreeze: LookupFreeze
  }
  
  export class LookupFreeze {
    PinNbr: string
    ProtectedFlagInd: string
    CnsmrPrsnlDetls: CnsmrPrsnlDetls
    FreezeDate: FreezeDate
    LiftDates: LiftDates
    UserName: string
    code: string
    message: string
  }
  
  export class CnsmrPrsnlDetls {
    CnsmrNmDetls: CnsmrNmDetls
    BirthDt: string
    GovtNbr: string
  }
  
  export class CnsmrNmDetls {
    SuffixNm: string
    FrstNm: string
    MidNm: string
    LastNm: string
  }
  
  export class FreezeDate {
    StartDate: string
    EndDate: string
  }
  
  export class LiftDates {
    LiftDate: LiftDate[]
  }
  
  export class LiftDate {
    StartDate: string
    EndDate: string
    EnteredDate: string
  }
  
      
}
//////////////////////////////////
