export namespace addlift{

  export class LiftRequestsRoot {
    LiftRequests: LiftRequests
  }
  
  export class LiftRequests {
    ActionType: string
    GovtNbr: string
    LiftDates: LiftDates
    CnsmrPrsnlDetls: CnsmrPrsnlDetls
    CnsmrAddrDetls: CnsmrAddrDetls
    PINNumber: string
    RequestSrcCode: string
    UserName:string
    CellPhoneNbr:string
    CorrespondenceDeliveryPreference:string
    NotificationEmailAddr:string
  }
  
  export class LiftDates {
    LiftDate: LiftDate[]
  }
  
  export class LiftDate {
    EndDate: string
    StartDate: string
  }
  
  export class CnsmrPrsnlDetls {
    CnsmrNmDetls: CnsmrNmDetls
  }
  
  export class CnsmrNmDetls {
    FrstNm: string
    LastNm: string
    MidNm: string
    PreviousLastName: string
    SuffixNm: string
  }
  
  export class CnsmrAddrDetls {
    CnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    AddrLine1: string
    AddrLine2: string
    CityNm: string
    StatCode: string
    ZipCode: string
  }
  
  //////////
  export class LiftResponseRoot {
    message: string
    referenceId: string
    code: string
  }
  
      
}
//////////////////////////////////
