import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';
import { DateFilterFn } from '@angular/material/datepicker';
import { DialogRemFrzComponent } from '../../dailog/dialog-rem-frz/dialog-rem-frz.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogSchLiftComponent, DialogLiftInput } from '../../dailog/dialog-sch-lift/dialog-sch-lift.component';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../../dailog/dialog-with-message/dialog-with-message.component';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import { managefreeze } from '../../_models/classes/manage-freeze.model';
import { ManageFreezeService } from '../../_services/manage-freeze/manage-freeze.service';
import { addlift } from '../../_models/classes/add-lift.model';
import { userinformation } from '../../_models/classes/user-information.model';
import { DialogInput } from '../../dailog/dialog-rem-frz/dialog-rem-frz.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../common/enums/global_variables';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'chx-manage-freeze-details',
  templateUrl: './manage-freeze-details.component.html',
  styleUrls: ['./manage-freeze-details.component.scss'],
})
export class ManageFreezeDetailsComponent {
  remFrzTxt: string = this._translate.instant(
    'MANAGEFREEZEDETAILS.REMOVEFREEZETEXT'
  );
  schLiftText: string = this._translate.instant(
    'MANAGEFREEZEDETAILS.SCHLIFTTEXT'
  );
  remLiftTxt: string = this._translate.instant(
    'MANAGEFREEZEDETAILS.REMOVELIFTTEXT'
  );
  dialogWidth: string = '650px';
  personalInfo: MSFPersonalInfo;
  eyeIconType: string = 'eye';
  eyeIconTooltip: string = 'show';
  eyeIconTypeSSN: string = 'eye';
  eyeIconTooltipSSN: string = 'show';
  msfData: MSFItem = new MSFItem();
  title=this._translate.instant('MANAGEFREEZEDETAILS.TITLE');
  securityfreezedetails=this._translate.instant('MANAGEFREEZEDETAILS.SECURITYFREEZEDETAILS');
  currentstatus=this._translate.instant('MANAGEFREEZEDETAILS.CURRENTSTATUS');
  noitems=this._translate.instant('MANAGEFREEZEDETAILS.NOITEMS');
  date=this._translate.instant('MANAGEFREEZEDETAILS.DATE');
  action=this._translate.instant('MANAGEFREEZEDETAILS.ACTION');
  removefreeze=this._translate.instant('BUTTONS.REMOVEFREEZE');
  scheduledlifts=this._translate.instant('MANAGEFREEZEDETAILS.SCHEDULEDLIFTS');
  scheduledtemplift=this._translate.instant('MANAGEFREEZEDETAILS.SCHEDULEDTEMPLIFT');
  startdate=this._translate.instant('MANAGEFREEZEDETAILS.STARTDATE');
  enddate=this._translate.instant('MANAGEFREEZEDETAILS.ENDDATE');
  dateentered=this._translate.instant('MANAGEFREEZEDETAILS.DATEENTERED');
  removelift=this._translate.instant('BUTTONS.REMOVELIFT');
  showLoadingIndicator:boolean = false;
  userName = "";
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _translate: TranslateService,
    private piConfirmService: PersonalInformationConfirmationService,
    private manageFreezeService: ManageFreezeService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    //this.msfData = this.initData(this.getFreezeData());
    this.userName = GlobalVariables.globalvars.get('UserId') || '';
    this.reLoadFreezeData();
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
    let securityPIN = GlobalVariables.globalvars.get('PIN') as string;
    if(securityPIN === undefined || securityPIN === null || securityPIN.trim() === ""){
      this.router.navigate(['manage-sec-frz']);
      return;
    }
  }

  scheduleTmpLift() {
    const dialogLiftInput = new DialogLiftInput(
      this.ConvertValidDateFormat(
        this.msfData.MSFCurrestStatusItemList[0].endDate
      )
    );
    
    const dialogRef2 = this.dialog.open(DialogSchLiftComponent, {
      // width:this.dialogWidth,
      data: dialogLiftInput,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) == 'OK') {
      }
      this.reLoadFreezeData();
    });

    // let strTitle = this._translate.instant(
    //   'MANAGEFREEZEDETAILS.SCHLIFTTITLE'
    // );
    // const dialogMessage = new DialogMessage(this.schLiftText, strTitle,true,strTitle);
    // const dialogRef = this.dialog.open(DialogwithmessageComponent,{
    //   panelClass: ["fis-style", "custom-dialog"],
    //   data: dialogMessage,
    //   closeOnNavigation: true,
    //   disableClose: true
    //   }
    // );
    // dialogRef.afterClosed().subscribe(result => {
    //     if((result as string) == "OK"){
    //       const dialogRef2 = this.dialog.open(DialogSchLiftComponent,{
    //           // width:this.dialogWidth,
    //           disableClose: true
    //         });
    //         dialogRef2.afterClosed().subscribe((result) => {
    //           if ((result as string) == 'OK') {
    //           }
    //           this.reLoadFreezeData();
    //         });
    //     }
    // });
  }

  async reLoadFreezeData() {
    this.showLoadingIndicator = true;
    let securityPIN = GlobalVariables.globalvars.get('PIN') as string;
    let SSN = GlobalVariables.globalvars.get('SSN') as string;
    // let capchaToken: any; 
    // try {
    //   capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
    // }  catch (error) {}
    let lkfRequest: managefreeze.LookupFreezeRequest =
      new managefreeze.LookupFreezeRequest();
    lkfRequest.GovtNbr = SSN;
    lkfRequest.PinNbr = securityPIN;
    lkfRequest.UserName = this.userName;
    lkfRequest.RecapchaToken = '';
    this.manageFreezeService.LookupFreeze(lkfRequest).subscribe(
      (res) => {
        if (res.LookupFreeze.code === '200') {
          this.msfData = this.initData(res);
        } else {
          this.msfData = this.initData(new managefreeze.LookupFreezeRoot());
        }
      },
      (error) => {this.showLoadingIndicator = false; },
      () => { this.showLoadingIndicator = false;}
    );
  }

  columns: any[] = [
    {
      field: 'effectiveDate',
      name: 'Effective Date',
      width: '25%',
      title: 'Effective Date',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy'],
      },
      sticky: true,
    },
    {
      field: 'endDateForDisplay',
      name: 'Expiration Date',
      width: '25%',
      title: 'Expiration Date',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy'],
      },
      sticky: true,
    },
    {
      field: 'action',
      name: 'Action',
      title: 'Action',
      width: '25%',
    },
  ];

  columnsLifts: any[] = [
    {
      field: 'startDate',
      name: 'Start Date',
      width: '25%',
      title: 'Start Date',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy'],
      },
      sticky: true,
    },
    {
      field: 'endDate',
      name: 'End Date',
      width: '25%',
      title: 'End Date',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy'],
      },
      sticky: true,
    },
    {
      field: 'dateEntered',
      name: 'Date Entered',
      title: 'Date Entered',
      width: '25%',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy'],
      },
      sticky: true,
    },
    {
      field: 'action',
      name: 'Action',
      title: 'Action',
      width: '25%',
    },
  ];

  deleteClick(code: string, event: any) {
    const dialogInput = new DialogInput(
      this.ConvertValidDateFormat(
        this.msfData.MSFCurrestStatusItemList[0].effectiveDate
      ),
      this.ConvertValidDateFormat(
        this.msfData.MSFCurrestStatusItemList[0].endDate
      )
    );
    const dialogRef = this.dialog.open(DialogRemFrzComponent, {
      panelClass: ['fis-style', 'custom-dialog'],
      data: dialogInput,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.reLoadFreezeData();
    });
  }

  deleteLiftClick(code: string, event: MSFLiftItem) {
    let strTitle = this._translate.instant(
      'MANAGEFREEZEDETAILS.REMOVELIFTTITLE'
    );
    this.remLiftTxt = this._translate.instant(
      'MANAGEFREEZEDETAILS.REMOVELIFTTEXT'
    );
    this.remLiftTxt = this.remLiftTxt.replace('###START###', this.ConvertValidDateFormat(event.startDate));
    this.remLiftTxt = this.remLiftTxt.replace('###END###', this.ConvertValidDateFormat(event.endDate));
    this.remLiftTxt = this.remLiftTxt.replace('###BUTTON###', strTitle);
    const dialogMessage = new DialogMessage(
      this.remLiftTxt,
      strTitle,
      false,
      strTitle
    );
    // const dialogRef = this.dialog.open(DialogRemFrzComponent,{
    const dialogRef = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if ((result as string) == 'OK') {
        /////////////////////////////////000000000]
        const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
        if (strUserInfo) {
          const objUserInfo = JSON.parse(
            strUserInfo
          ) as userinformation.UserInformationRoot;
          const alertReq: addlift.LiftRequests = new addlift.LiftRequests();
          const alertReqRoot: addlift.LiftRequestsRoot =
            new addlift.LiftRequestsRoot();
          alertReq.ActionType = 'D';
          let securityPIN = GlobalVariables.globalvars.get('PIN') as string;
          alertReq.PINNumber = securityPIN;
          alertReq.GovtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
          alertReq.CnsmrAddrDetls = new addlift.CnsmrAddrDetls();
          alertReq.CnsmrAddrDetls.CnsmrAddr = new addlift.CnsmrAddr();
          alertReq.CnsmrAddrDetls.CnsmrAddr.AddrLine1 =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.AddrLine2 =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.CityNm =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.StatCode =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.ZipCode =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
          alertReq.CnsmrPrsnlDetls = new addlift.CnsmrPrsnlDetls();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls = new addlift.CnsmrNmDetls();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName.trim();
          alertReq.LiftDates = new addlift.LiftDates();
          alertReq.LiftDates.LiftDate = [];
          const objLiftDate: addlift.LiftDate = new addlift.LiftDate();
          objLiftDate.StartDate = this.ConvertValidDateFormat(event.startDate);
          objLiftDate.EndDate = this.ConvertValidDateFormat(event.endDate);
          alertReq.LiftDates.LiftDate.push(objLiftDate);
          alertReq.RequestSrcCode = 'SECURE';
          alertReq.UserName = this.userName;
          alertReq.CellPhoneNbr = objUserInfo.CellPhoneNbr.trim();
          alertReq.CorrespondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference.trim();
          alertReq.NotificationEmailAddr = objUserInfo.NotificationEmailAddr.trim();
          alertReqRoot.LiftRequests = alertReq;
          this.manageFreezeService.LiftRequest(alertReqRoot).subscribe(
            (res) => {
              let strMessage = '';
              if (res.code === '200') {
                strMessage = this._translate.instant(
                  'MANAGEFREEZEDETAILS.DELETELIFTSUCCESSMESSAGE'
                );
                strMessage = strMessage.replace('#######', res.referenceId);
              } else {
                strMessage = res.message;
              }
              //const strMessage = res.message;
              const dialogMessage = new DialogMessage(
                strMessage,
                this._translate.instant('BUTTONS.OK'),
                false,
                this._translate.instant('MANAGEFREEZEDETAILS.DELETELIFTTITLE')
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              });
              dialogRef2.afterClosed().subscribe((result) => {
                if ((result as string) == 'OK') {
                  //window.location.reload();
                }
                this.reLoadFreezeData();
              });
            },
            (error) => { },
            () => { }
          );
        }
        ///////////////////////////////000000000
      }
    });
  }
  onStateChange(event: any) {}

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

  getFreezeData() {
    let lfResp: managefreeze.LookupFreezeRoot =
      new managefreeze.LookupFreezeRoot();
    //let freezeJson = localStorage.getItem("LookupFreezeResp") || '';
    JSON.parse(
      GlobalVariables.globalvars.get('LookupFreezeResp') || '{}'
    ) as managefreeze.LookupFreezeRoot;

    return lfResp;
  }
  initData(lfResp: managefreeze.LookupFreezeRoot) {
    var tempData: MSFItem;
    tempData = new MSFItem();
    if (lfResp) {
      if (lfResp.LookupFreeze) {
        var tempEveData1: MSFCurrestStatusItem;
        tempEveData1 = new MSFCurrestStatusItem();
        //tempEveData1.secrityFreeze = "123456";
        tempEveData1.effectiveDate = new Date(
          lfResp.LookupFreeze.FreezeDate.StartDate
        );
        tempEveData1.startDate = new Date(
          lfResp.LookupFreeze.FreezeDate.StartDate
        );
        tempEveData1.endDate = new Date(lfResp.LookupFreeze.FreezeDate.EndDate);
        tempEveData1.endDateForDisplay = lfResp.LookupFreeze.FreezeDate.EndDate
        if(tempEveData1.endDateForDisplay.trim() === "01/01/9999"){
          tempEveData1.endDateForDisplay = "N/A";
        }

        tempData.MSFCurrestStatusItemList.push(tempEveData1);
        ///////////////////////////////////////////
        for (
          let i = 0;
          i < lfResp.LookupFreeze.LiftDates.LiftDate.length;
          i++
        ) {
          var tempMSFL1: MSFLiftItem;
          tempMSFL1 = new MSFLiftItem();
          tempMSFL1.dateEntered = new Date(
            lfResp?.LookupFreeze.LiftDates.LiftDate[i].EnteredDate
          );
          tempMSFL1.startDate = new Date(
            lfResp?.LookupFreeze.LiftDates.LiftDate[i].StartDate
          );
          tempMSFL1.endDate = new Date(
            lfResp?.LookupFreeze.LiftDates.LiftDate[i].EndDate
          );
          tempData.MSFLiftItemList.push(tempMSFL1);
        }

        // tempData.MSFLiftItemList = tempData.MSFLiftItemList.sort(
        //   (item1, item2) =>
        //     new Date(item2.startDate).getTime() -
        //     new Date(item1.startDate).getTime()
        // );
      }
    }

    return tempData;
  }
  ngOnDestroy() {
    // localStorage.removeItem('SSN');
    // localStorage.removeItem('PIN');
    // localStorage.removeItem('LookupFreezeResp');

    if (GlobalVariables.globalvars.has('SSN')){
      GlobalVariables.globalvars.delete('SSN');                
    }
    if (GlobalVariables.globalvars.has('PIN')){
      GlobalVariables.globalvars.delete('PIN');                
    }
    if (GlobalVariables.globalvars.has('LookupFreezeResp')){
      GlobalVariables.globalvars.delete('LookupFreezeResp');                
    }
  }
}

export class MSFPersonalInfo {
  name: string;
  ssnNo: string;
  dob: string;

  ssnNoMask: string;
  dobMask: string;

  ssnNoDisplay: string;
  dobDisplay: string;
}

export class MSFItem {
  MSFCurrestStatusItemList: MSFCurrestStatusItem[] = [];
  MSFLiftItemList: MSFLiftItem[] = [];
}

export class MSFCurrestStatusItem {
  //secrityFreeze: string;
  effectiveDate: Date;
  startDate: Date;
  endDate: Date;
  endDateForDisplay: string;
  //referenceNumber:string;
}

export class MSFLiftItem {
  dateEntered: Date;
  startDate: Date;
  endDate: Date;
}
