export namespace userinformation{

  export class UserInformationRoot {
    CnsmrPrsnlDetls: CnsmrPrsnlDetls
    CnsmrAddrDetls: CnsmrAddrDetls
    UserName: string
    CellPhoneNbr: string
    CorrespondenceDeliveryPreference: string
    NotificationEmailAddr: string
    UserID: string
  }

  export class CnsmrPrsnlDetls {
    BirthDt: string
    CnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls
    CnsmrNmDetls: CnsmrNmDetls
    GovtNbr: string
    PhoneNbr: string
  }
  
  export class CnsmrDrivLicenseDetls {
    DrivLicenseIssuSt: string
    DrivLicenseNbr: string
  }
  
  export class CnsmrNmDetls {
    SuffixNm: string
    FrstNm: string
    MidNm: string
    LastNm: string
    PreviousLastName: string
  }
  
  export class CnsmrAddrDetls {
    CnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    AddrLine1: string
    AddrLine2: string
    CityNm: string
    StatCode: string
    ZipCode: string
  }
  
}
//////////////////////////////////
