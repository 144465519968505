<chexsystem-ws-loader></chexsystem-ws-loader>
<div fxLayout="column" class="chex-footer_fix">
  <top-header
    *ngIf="!isUserLoggedin"
    style="position: sticky; top: 0px; z-index: 110"
  ></top-header>
  <after-login-header
    *ngIf="isUserLoggedin"
    style="position: sticky; top: 0px; z-index: 110"
  ></after-login-header>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <!-- <chx-subfooter></chx-subfooter> -->
</div>
<chx-footer [isLoggedIn]="isUserLoggedin"></chx-footer>
