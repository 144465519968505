<div class="chex-db_correspondance_container" fxLayout="column" fxLayoutGap="10px">

  <div role="region" *rufIfGtXs="true" id="Title" class="chex-db_correspondance_header_text mat-headline">{{ 'DASHBOARDCORRESPONDENCE.CORRESPONDENCE' | translate }}</div>
  <div role="region" *rufIfGtXs="true" class="chex-db_correspondance_recent_text mat-body-2" [innerHTML]="correspondenceText | sanitizeHtml"></div>
  <div fxLayout="row" *rufIfXs="true" rufId="ruf_if_xs">
    <div role="region" id="Title" class="chex-db_correspondance_header_text mat-headline">{{ 'DASHBOARDCORRESPONDENCE.CORRESPONDENCE' | translate }}</div>
  <button rufId="ruf_if_xs"role="region"  type="button" mat-icon-button
  [rufTrigger]="dropdownPanelDashboardDispute" #triggerDashboardDispute="rufTrigger" fisStyle tabindex="-1">
  <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
  </button>
  <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelDashboardDispute rufId
  [origin]="triggerDashboardDispute" [type]="panel.type" xPosition="center" yPosition="bottom">
  <div role="region" aria-label="DashboardDisputeportal" class="mat-body-1 chex-popover-content">
    {{ 'DASHBOARDCORRESPONDENCE.DESCRIPTIONCONTENT' | translate }}
  </div>
  </ruf-dropdown-panel> 
  </div>
  <div id="table" class="chex-db_corres_table_container">
    <ruf-dynamic-datatable matSort [showLoadingIndicator]="showLoadingIndicator" #ddt [showPaginator]="false" [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="items" [metadata]="columns">
      <ng-container *rufNoDataRow>
        <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
        <h2 role="region"  class="chex-no-record-message">{{ 'DASHBOARDCORRESPONDENCE.NOITEMS' | translate }}</h2>
      </ng-container>
      <ng-container matColumnDef="dateOfCorrespondence" >
        <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDCORRESPONDENCE.DATE' | translate }}</th>
        <td role="region"  class="chx-datagrid-cell chex-hand-cursor" (click)="openCorresLetter(element)" mat-cell *matCellDef="let element" >{{element.dateOfCorrespondence | date:'MM/dd/yyyy'}} </td>
      </ng-container> 
      <ng-container matColumnDef="letterName" >
         <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDCORRESPONDENCE.COMMUNICATION' | translate }}</th>
         <td role="region"  class="chx-datagrid-cell chex-hand-cursor" (click)="openCorresLetter(element)" mat-cell *matCellDef="let element" >{{element.letterName}} </td>
       </ng-container>
       <ng-container matColumnDef="referenceNumber" >
        <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDCORRESPONDENCE.REFERENCE' | translate }}</th>
        <td role="region"  class="chx-datagrid-cell chex-hand-cursor" (click)="openCorresLetter(element)" mat-cell *matCellDef="let element">{{element.referenceNumber}} </td>
      </ng-container>
     </ruf-dynamic-datatable>
  </div>
  <button role="region" aria-label="view"
  [disabled] ="items.length ===0"
  class="chex-db_correspondance_viewall"
  mat-stroked-button=""
  fisStyle
  (click)="openCorresDetailAll('1')"
  >
  {{ 'DASHBOARDCORRESPONDENCE.VIEW' | translate }}
</button>
</div>