import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { User } from '../../_models/classes/User.model';
import { ConsumerDisclosureRequest, ConsumerDisclosureResponse } from '../../_models/consumer-disclosure/consumer-disclosure.model';
import { ApiService } from '../shared/api.service';
import { catchError, throwError } from 'rxjs';
import { OptOutRequest } from '../../_models/classes/OptOut-request.model';
import { OptOutResponse } from '../../_models/classes/OptOut-response.model';
import { AutomationRuleResponse } from '../../_models/classes/automation-rules-check-response.model';
import { AutomationRulesCheck } from '../../_models/classes/automation-rules-check-request.model';
import { MailDisclosureReportRequest } from '../../_models/classes/mail-disclosure-report-request';
import { MailDisclosureReportResponse } from '../../_models/classes/mail-disclosure-report-response';

@Injectable({
  providedIn: 'root'
})
export class UserdisclosureService {

  private readonly Api_Base_URL = environment.apiurl;
  constructor(private _apiService: ApiService) { }

  getConsumerDisclosureReport(disclosurerequest: ConsumerDisclosureRequest): Observable<ConsumerDisclosureResponse> {
    const url = this.Api_Base_URL.concat('/ProcessDisclosure');
    return this._apiService.post<ConsumerDisclosureResponse>(url, disclosurerequest)
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateConsumerOptOut(optOutRequestDetail: OptOutRequest): Observable<OptOutResponse> {
    const url = this.Api_Base_URL.concat('/OptOutReq');
    return this._apiService.post<OptOutResponse>(url, optOutRequestDetail)
      .pipe(
        catchError(this.handleError)
      );
  }

  checkAutomationrulescheck(automationRulesCheckRequest: AutomationRulesCheck): Observable<AutomationRuleResponse> {
    const url = this.Api_Base_URL.concat('/automationrulescheck');
    return this._apiService.post<AutomationRuleResponse>(url, automationRulesCheckRequest)
      .pipe(
        catchError(this.handleError)
      );
  }

  mailDisclosureRequest(mailDisclosureRequest: MailDisclosureReportRequest): Observable<MailDisclosureReportResponse> {
    const url = this.Api_Base_URL.concat('/maildiscloser');
    return this._apiService.post<MailDisclosureReportResponse>(url, mailDisclosureRequest)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage: string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }
}
