export namespace dashboardCorrs{
  ////////////////////////////////////
  export class CorrespondenceDetailsRequestRoot {
    CorrespondenceDetailsRequest: CorrespondenceDetailsRequest
  }
  
  export class CorrespondenceDetailsRequest {
    UserName: string
  }
  
  /////////////////////////////////
  export class DashboardCorrespondenceRoot {
    CorrespondenceDetailsResponse: CorrespondenceDetailsResponse
  }
  
  export class CorrespondenceDetailsResponse {
    UserName: string
    CorrespondenceList: CorrespondenceList[]
    Code: string
    Message: string
  }
  
  export class CorrespondenceList {
    LetterName: string
    DateOfCorrespondence: string
    ConsumerReferenceId?: string
    ProcessOid: string
    DocumentOid: string
  }
  
  /////////////////////////////////////
 
  export class CorrespondenceListDetailRequestRoot {
    CorrespondenceListDetailRequest: CorrespondenceListDetailRequest
  }
  
  export class CorrespondenceListDetailRequest {
    UserName: string
    ConsumerReferenceId: string
    ProcessOid: string
    DocumentOid: string
  }
  //////////////////////////////////
 
  export class CorrespondenceDetailRoot {
    CorrespondenceListDetailResponse: CorrespondenceListDetailResponse
  }
  
  export class CorrespondenceListDetailResponse {
    UserName: string
    ConsumerReferenceId: string
    ProcessOid: string
    DocumentOid: any
    LetterDetailsEncData: string
    Code: string
    Message: string
  }
  ////////////////////////////////////////
}

