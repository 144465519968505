import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'chx-identity-theft-information',
  templateUrl: './identity-theft-information.component.html',
  styleUrls: ['./identity-theft-information.component.scss'],
})


export class IdentityTheftInformationComponent {

  identityTheftInfoContent:string = '';
  title=this._translate.instant('IDENTITYTHEFTINFO.TITLE');

  constructor(
    private router: Router,
    private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService,
  ) { 

    
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('IdentityTheftInformation');
    this.fetchIdentityTheftInfoContent();
  }
  
  // submitclick(){
  //   this.router.navigate( ['place-alert']);
  // }
  
  navigateToLinks(code:string){

    if(code === "securityalert"){
      this.router.navigate( ['place-alert']);
    }
    else if(code === "securityfreeze"){
      this.router.navigate( ['manage-sec-frz']);
    }
  }

  fetchIdentityTheftInfoContent() {
    let manageSecurityFreezeContentPath: string = 'Identity Theft Info/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: manageSecurityFreezeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'identity theft info') {
          this.identityTheftInfoContent = fieldObject.value;
        }
      });

    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }

  }

  