import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../../dailog/dialog-with-message/dialog-with-message.component';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import { scoreReq } from '../../_models/classes/consumer-score-disclosure-request.model';
import { scoreRes } from '../../_models/classes/consumer-score-disclosure-response.model';
import { userinformation } from '../../_models/classes/user-information.model';
import { ConsumerScoreDisclosureService } from '../../_services/consumer-score/consumer-score-disclosure.service';
import { Field, SitecoreRequestData } from '../../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../../_models/classes/sitecore-nodepath-reponse.model';
import { SitecoreService } from '../../_services/sitecore/sitecore.service';
import { FieldName } from '../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../common/enums/global_variables';
import { Registrationresource } from '../../_models/registration/registrationresource.model';

@Component({
  selector: 'chexsystem-ws-consumer-score-page',
  templateUrl: './consumer-score-page.component.html',
  styleUrls: ['./consumer-score-page.component.scss'],
})
export class ConsumerScorePageComponent implements OnInit {
  consumerScoreText:string = '';
  title=this._translate.instant('CONSUMERSCOREPAGE.TITLE');
  submit=this._translate.instant('BUTTONS.SUBMIT');

  constructor(private router: Router, private piConfirmService: PersonalInformationConfirmationService,
    private dialog: MatDialog,
    private consumerScoreDisclosureService:ConsumerScoreDisclosureService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService) { }

  onSubmit(){
    /////////////////////////////////////////////////////////
    const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
    let cstDateTimeNow: Date = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
    );
    if (strUserInfo) {
      const objUserInfo = JSON.parse(
        strUserInfo
      ) as userinformation.UserInformationRoot;
      const cscoreReq: scoreReq.consumerScoreData =
        new scoreReq.consumerScoreData();

      cscoreReq.RqstHdr = new scoreReq.RqstHdr();
      cscoreReq.RqstHdr.RqstSubTyp = 'CSR';
      cscoreReq.RqstHdr.RqstTyp = 'CS';

      cscoreReq.CnsmrScrRqst = new scoreReq.CnsmrScrRqst();

      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls = new scoreReq.CnsmrAddrDetls();
      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls.CnsmrAddr =
        new scoreReq.CnsmrAddr();
      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls.CnsmrAddr.AddrLine1 =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls.CnsmrAddr.AddrLine2 =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls.CnsmrAddr.CityNm =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls.CnsmrAddr.StatCode =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
      cscoreReq.CnsmrScrRqst.CnsmrAddrDetls.CnsmrAddr.ZipCode =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();

      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls = new scoreReq.CnsmrPrsnlDetls();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.BirthDt =
        objUserInfo.CnsmrPrsnlDetls.BirthDt;
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.ChexConsId = '';

      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrNmDetls =
        new scoreReq.CnsmrNmDetls();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrNmDetls.MaidenNm = '';
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();

      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.Email =
        objUserInfo.NotificationEmailAddr.trim();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.GovtNbr =
        objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.PhoneNbr =
        objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
        
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls =
        new scoreReq.CnsmrDrivLicenseDetls();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
        objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
      cscoreReq.CnsmrScrRqst.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
        objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();

      cscoreReq.CnsmrScrRqst.CnsmrRefId = '';

      cscoreReq.CnsmrScrRqst.CnsmrRequestCodes =
        new scoreReq.CnsmrRequestCodes();
      cscoreReq.CnsmrScrRqst.CnsmrRequestCodes.CommentTxt = '';
      cscoreReq.CnsmrScrRqst.CnsmrRequestCodes.RequestSrcCode = 'SECURE';
      cscoreReq.CnsmrScrRqst.CnsmrRequestCodes.RequestTypCode = 'ConsumerScore';

      cscoreReq.CnsmrScrRqst.RecvdDtTm = this.ConvertDateTime(cstDateTimeNow); // DD/MM/YYYhh:mm:ss
      let _userName = GlobalVariables.globalvars.get("UserId") || '';
      cscoreReq.CnsmrScrRqst.UserName = _userName;
      cscoreReq.CnsmrScrRqst.CellPhoneNbr = objUserInfo.CellPhoneNbr;
      cscoreReq.CnsmrScrRqst.CorrespondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference;
      cscoreReq.CnsmrScrRqst.NotificationEmailAddr = objUserInfo.NotificationEmailAddr;

      this.consumerScoreDisclosureService
        .getConsumerDisclosureScore(cscoreReq)
        .subscribe(
          (res) => {
            if (res.RspCd === '200') {
              let messageContent =
                this._translate.instant(
                  'POPUPS.CONSUMERDISCLOSURESCOREMESSAGE'
                );
                messageContent = messageContent.replace('#######', res.CnsmrScrRqst.CnsmrRefId);
              const dialogMessage = new DialogMessage(
                messageContent,
                this._translate.instant('BUTTONS.GOTODASHBOARD'),
                false,
                this._translate.instant('POPUPS.CONSUMERDISCLOSURESCORETITLE')
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              });

              dialogRef2.afterClosed().subscribe((result) => {
                //chexsystems public home page
                if ((result as string) == 'OK') {
                  this.router.navigate(['dashboard']);
                } else {
                }
              });
            }
            else if (res.RspCd === '4105') {
              let messageContent =
                this._translate.instant(
                  'CONSUMERSCOREDISCLOSUREREPORT.SCOREREPORTATTAMPTEXOHAST'
                );
              const dialogMessage = new DialogMessage(
                messageContent,
                this._translate.instant('BUTTONS.GOTODASHBOARD'),
                false,
                this._translate.instant('POPUPS.CONSUMERDISCLOSURESCORETITLE1')
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              });

              dialogRef2.afterClosed().subscribe((result) => {
                //chexsystems public home page
                if ((result as string) == 'OK') {
                  this.router.navigate(['dashboard']);
                } else {
                }
              });
            } 
            else {
              const strMessage = res.RspMsg;
              const dialogMessage = new DialogMessage(
                strMessage,
                this._translate.instant('BUTTONS.OK'),
                false,
                this._translate.instant('POPUPS.CONSUMERSCORETITLE')
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ['fis-style', 'custom-dialog'],
                data: dialogMessage,
                disableClose: true,
              });
              dialogRef2.afterClosed().subscribe((result) => {
                if ((result as string) == 'OK') {
                  //window.location.reload();
                }
              });
            }
          },
          (error) => { },
          () => { }
        );
    }
    ////////////////////////////////////////////////////////////
  }
  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('consumerscore');
    this.fetchConsumerScoreContent();
  }

  fetchConsumerScoreContent() {
    let consumerScoreTextContentPath: string = 'Consumer Score Report/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: consumerScoreTextContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'consumer score report') {
          const divnoticeofrights1 = document.createElement('div');
          divnoticeofrights1.innerHTML = fieldObject.value.toString();
          var anchorelements = divnoticeofrights1.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            if(!Registrationresource.PagesToBeRouted.some(pageToBeRouted => anchorelement.href.indexOf(pageToBeRouted) >= 0)){
            anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
          }
          });

          this.consumerScoreText = divnoticeofrights1.innerHTML;
        }

        
      });

    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }

  private ConvertDateTime(dateValue: Date): string {
    return (
      this.ConvertValidDateFormat(dateValue) +
      'T' +
      this.padLeadingZeros(dateValue.getHours(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getMinutes(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getSeconds(), 2)
    );
  }
  
  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = yyyy + '-' + mm + '-' + dd;
    return validDate;
  }
  
  private padLeadingZeros(num: number, size: number) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
  // private ConvertDateTime(dateValue: Date): string {
  //   return dateValue.toISOString();
  // }
  

  // private ConvertValidDateFormat(dateValue: Date): string {
  //   var dd = String(dateValue.getDate()).padStart(2, '0');
  //   var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
  //   var yyyy = dateValue.getFullYear();
  //   let validDate = mm + '/' + dd + '/' + yyyy;
  //   return validDate;
  // }
}