export namespace replacepin{


  export class PinReplacementResponseRoot {
    pinReplacementResponse: PinReplacementResponse
    code: string
    message: string
    referenceId: string
  }
  
  export class PinReplacementResponse {
    personalDtls: PersonalDtls
    requestSrcCode: string
    cnsmrAddrDetls: CnsmrAddrDetls
    userName: string
    cellPhoneNbr: string
    correspondenceDeliveryPreference: string
    notificationEmailAddr: string
    recvdDtTm: string
    rqstHdr: RqstHdr
  }
  
  export class PersonalDtls {
    cnsmrNmDetls: CnsmrNmDetls
    birthDt: string
    govtNbr: string
    cnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls
    phoneNbr: string
  }
  
  export class CnsmrNmDetls {
    frstNm: string
    midNm: string
    lastNm: string
    suffixNm: string
  }
  
  export class CnsmrDrivLicenseDetls {
    drivLicenseNbr: string
    drivLicenseIssuSt: string
  }
  
  export class CnsmrAddrDetls {
    cnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    addrLine1: string
    addrLine2: string
    cityNm: string
    statCode: string
    zipCode: string
  }
  
  export class RqstHdr {
    rqstTyp: string
    rqstSubTyp: string
  }
  //////////////////////////////

  export class PinReplacementRequestRoot {
    pinReplacementRequest: PinReplacementRequest
  }
  
  export class PinReplacementRequest {
    personalDtls: PersonalDtls
    requestSrcCode: string
    cnsmrAddrDetls: CnsmrAddrDetls
    userName: string
    cellPhoneNbr: string
    correspondenceDeliveryPreference: string
    notificationEmailAddr: string
    recvdDtTm: string
    rqstHdr: RqstHdr
  }
  

}
//////////////////////////////////
