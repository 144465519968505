export const environment = {
  production: true,
  homeChexSystemsUrl: 'https://www.chexsystems.com/',
  apiurl: 'https://chexsystems-api.eto.fiscloudservices.com/api/chexsystems',
  loggerApiurl:
    'https://chexsystems-api.eto.fiscloudservices.com/api/loggerapis',
  contactChexSystemsUrl: 'https://www.chexsystems.com/en/contact-us',
  threatMetrixUrl: 'content.fisglobal.com',
  threatMetrixOrgID: 'eieyc0f3',
  API_KEY:"e6e56719dfcc4bbba1973ad5fdba2417",
  auth: {
    issuer: 'https://login10.fisglobal.com/idp/ChexSystems', // the idp firm URL
    clientId: 'chexsystems', // the IdP Service provider name
    redirectUri: 'https://chexsystems.eto.fiscloudservices.com', // this should be same as Application URL configured in service provider
    logoutUrl: 'https://chexsystems.eto.fiscloudservices.com', // this should be same as Logout ACS URL configured in service provider
    responseType: 'code', // defaults to 'code'
    requireHttps: false,
    autoRefresh: true, // if true, automatically refreshes token before it expires. Default is false.
    skipIssuerCheck: true,
    interceptUrls: ['origin','https://chexsystems-api.eto.fiscloudservices.com'],
    listenToToken: 'access_token', // which token to listen to, for automatically refreshing. Possible values are 'id_token', 'access_token' and 'any'.
  },
  securityFreezePINAttempts: 2,
  securityAlertQuestionAttempts: 2,
  acquirerid: '159087',
  configkey: 'FIS_C_143849',
  customerid: '32436969',
  reCaptchaSiteKey: '6LeDFEgfAAAAAPfUGiOBDvBKVReF0yyNQa9yLREe',
  idpEndpoint: 'https://chexsystems-api.eto.fiscloudservices.com/api/idpapis',
  chexsyStemDepartmentId: 11139776,
  chexsyStemDepartmentName: 'ChexSystems',
  chexsyStemDepartmentPrimaryDept: 1,
  providerConfigurationId: -1,
  chexsyStemUserRole: 0,
  idleTime: 900,
  idleTimeout: 300,
  totalTimeout: 300000,
  chexsyStemLoginRole: 'Regular Login',
  consumerScore:
    'Your ChexSystems Consumer Score is based on information contained in your consumer file. By reviewing the key factors that impact your score, you can gain a better understanding of your score. For more information on scores, you may view the answers to the frequently asked score questions.',
  educationURLAfterLogin: 'https://www.chexsystems.com/#education-section',
  educationURLBeforeLogin: 'https://www.chexsystems.com/#education-section',
  consumerDisclosureURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/requestreports/consumerdisclosure',
  consumerScoreURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/requestreports/consumerscore',
  identityTheftInformationURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/IdentityTheft/IdentityTheftInformation',
  securityAlertURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/IdentityTheft/securityalert',
  securityFreezeInformationURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/securityfreeze/information',
  placeaFreezeURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/securityfreeze/placefreeze',
  replacementPINURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/securityfreeze/replacementpin',
  liftFreezeURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/securityfreeze/liftfreeze',
  rightsByStateURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/securityfreeze/rightsbystate',
  disputeURL:
    'https://www.chexsystems.com/web/chexsystems/consumerdebit/page/dispute',
  infoURL: 'https://www.chexsystems.com/informacion-en-espanol',
  privacyPolicyURL: 'https://www.chexsystems.com/privacy-policy',
  legalNoticesURL: 'https://www.chexsystems.com/legal-notices',
  contactUsURL: 'https://www.chexsystems.com/contactus',
  aboutUsURL: 'https://www.chexsystems.com/about-chexsystems',
  faqURL: 'https://www.chexsystems.com/answers-to-frequently-asked-questions',
  sitecoreapiurl:
    'https://chexsystems-api.eto.fiscloudservices.com/api/sitecoreapi',
  sitecorebaseurl: 'https://www.chexsystems.com/',
  loggedinSessionTimeoutSurplus: 300,
  idpLogOutEndpoint:
    'https://ih-sys.fisglobal.com/xpressng/plainrest/idpclddev/idp/ChexSystems/rest/1.0/sessions',
  snackbarautohideduration: 30000,
  idaQuizPerson: {
    idv0id: '',
  },
  aqrCustomerId: '32436969',
  instanceId: '001',
  chexSystemsOTPRegularProfileID: '11138034',
  idpEndpointBaseUrl:
    'https://ih-sys.fisglobal.com/xpressng/plainrest/idpclddev/idp/ChexSystems/rest/1.0/',
  globalLogoutUrl:
    'https://login10.fisglobal.com/idp/ChexSystems/globalLogout.html?client_id=',

};
