<!-- <ruf-app-canvas style="background-color: #E2E2E2;" >
  <ruf-card title="" class="card_alignment" style="padding-top: 21px;padding-bottom: 60;border-radius: 10px !important;"> -->
    <div class="chex-bottom_space">
      <ruf-page-header role="region" aria-label="title" class="header_text" rufId="page_header_managefreeze_detail_title" title="{{ 'MANAGEFREEZEDETAILS.TITLE' | translate }}" description=" " ></ruf-page-header>
      <!-- <div class="m-sec-fz_header_text">Manage Security Freeze</div> -->
      <!-- <div role="region" aria-label="securityfreezedetails" id="header1" class="chex-page-left-right-alignment mat-subheading-2">{{ 'MANAGEFREEZEDETAILS.SECURITYFREEZEDETAILS' | translate }}</div> -->
      <!-- <div class="m-sec-fz_content-header_text">Security Freeze Details</div> -->

      <!-- <div role="region" aria-label="currentstatus" id="subHeader" class="chex-page-left-right-alignment ruf-caption-2 chex-temp-shift-div">{{ 'MANAGEFREEZEDETAILS.CURRENTSTATUS' | translate }}</div>   -->
      <div class="chex-page-left-right-alignment chex-current-datagrid" rufMarginBottom>
        <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" id="table1" #ddt [showPaginator]="false" [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="msfData.MSFCurrestStatusItemList" [metadata]="columns">
          <ng-container *rufNoDataRow>
            <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
            <h2 role="region" aria-label="noitems" class="chex-no-record-message">{{ 'MANAGEFREEZEDETAILS.NOITEMS' | translate }}</h2>
          </ng-container>
          <ng-container matColumnDef="effectiveDate" >
            <th role="region" aria-label="date" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'MANAGEFREEZEDETAILS.DATE' | translate }}</th>
            <td role="region" aria-label="dateelement" mat-cell *matCellDef="let element">{{element.effectiveDate | date:'MM/dd/yyyy'}}</td>
          </ng-container>
          <ng-container matColumnDef="endDateForDisplay" >
            <th role="region" aria-label="date" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'MANAGEFREEZEDETAILS.EXPIRATIONDATE' | translate }}</th>
            <td role="region" aria-label="dateelement" mat-cell *matCellDef="let element">{{element.endDateForDisplay}}</td>
          </ng-container>
          <ng-container matColumnDef="action" stickyEnd>
            <th role="region" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef >{{ 'MANAGEFREEZEDETAILS.ACTION' | translate }}</th>
            <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
              <button id="remFreeze" class="chx-datagrid-cell"
                (click)="deleteClick('MSFCS', element)" mat-flat-button >
                <mat-icon role="region" aria-label="removefreeze" fisIcon="trash"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" ></mat-icon>{{ 'BUTTONS.REMOVEFREEZE' | translate }}
                </button>
            </td>
         </ng-container>
        </ruf-dynamic-datatable>
      </div>
      
      <div class="chex-page-left-right-alignment chex-current-datagrid">
        <div class="chex-temp-shift-div"  fxLayout="row">
          <div role="region" aria-label="scheduledlifts" fxFlex="flex" id="header2" class="ruf-caption-2">{{ 'MANAGEFREEZEDETAILS.SCHEDULEDLIFTS' | translate }}</div>  
          <div role="region" aria-label="scheduledtemplift" class="chex-schedule-lift-button">
          <button id="schLift"
            mat-stroked-button=""
            fisStyle 
            (click)="scheduleTmpLift()"
            >
            {{ 'MANAGEFREEZEDETAILS.SCHEDULEDTEMPLIFT' | translate }}
          </button></div>
        </div> 
        <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" id="table2" #ddt [showPaginator]="false" [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="msfData.MSFLiftItemList" [metadata]="columnsLifts">
          <ng-container *rufNoDataRow>
            <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
            <h2 role="region" aria-label="noitems" class="chex-no-record-message">{{ 'MANAGEFREEZEDETAILS.NOITEMS' | translate }}</h2>
          </ng-container>
          <ng-container matColumnDef="startDate" >
            <th role="region" aria-label="startdate" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'MANAGEFREEZEDETAILS.STARTDATE' | translate }}</th>
            <td role="region" aria-label="startdateelement" class="chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.startDate | date:'MM/dd/yyyy'}}</td>
          </ng-container>
          <ng-container matColumnDef="endDate" >
            <th role="region" aria-label="enddate" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'MANAGEFREEZEDETAILS.ENDDATE' | translate }}</th>
            <td role="region" aria-label="enddateelement" class="chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.endDate | date:'MM/dd/yyyy'}}</td>
          </ng-container>
          <ng-container matColumnDef="dateEntered" >
            <th role="region" aria-label="dateentered" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'MANAGEFREEZEDETAILS.DATEENTERED' | translate }}</th>
            <td role="region" aria-label="dateenteredelement" class="chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.dateEntered | date:'MM/dd/yyyy'}}</td>
          </ng-container>
          <ng-container matColumnDef="action" stickyEnd>
            <th role="region" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'MANAGEFREEZEDETAILS.ACTION' | translate }}</th>
            <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
              <button id="remLift"
              class="chx-datagrid-cell"
                (click)="deleteLiftClick('MSLT', element)" mat-flat-button >
                <mat-icon role="region" aria-label="removelift" fisIcon="trash"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" ></mat-icon>{{ 'BUTTONS.REMOVELIFT' | translate }}
                </button>
            </td>
         </ng-container>
        </ruf-dynamic-datatable>
      </div>
     </div>
  <!-- </ruf-card>
</ruf-app-canvas> -->