<div class="chex-db_disputes_container" fxLayout="column" fxLayoutGap="10px">
<div id="disputeTitle" *rufIfGtXs="true" role="region" class="chex-db_disputes_header_text mat-headline">{{ 'DASHBOARDDISPUTES.DISPUTES CENTER' | translate }}</div>
<div role="region" *rufIfGtXs="true" rufId="ruf_if_gtxs" class="chex-db_disputes_recent_text mat-body-2" [innerHTML]="disputesText | sanitizeHtml"></div>
<div fxLayout="row" *rufIfXs="true" rufId="ruf_if_xs">
  <div id="disputeTitle" role="region" class="chex-db_disputes_header_text mat-headline">{{ 'DASHBOARDDISPUTES.DISPUTES CENTER' | translate }}</div>

<button rufId="ruf_if_xs"role="region"  type="button" mat-icon-button
[rufTrigger]="dropdownPanelDashboardDispute" #triggerDashboardDispute="rufTrigger" fisStyle tabindex="-1">
<mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
</button>
<ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelDashboardDispute rufId
[origin]="triggerDashboardDispute" [type]="panel.type" xPosition="center" yPosition="bottom">
<div role="region" aria-label="DashboardDisputeportal" class="mat-body-1 chex-popover-content">
  {{ 'DASHBOARDDISPUTES.DISPUTESCONTENT' | translate }}
</div>
</ruf-dropdown-panel> 
</div>
<div class="chex-db_table_container">
  <ruf-dynamic-datatable matSort [showLoadingIndicator]="showLoadingIndicator" #ddt [showPaginator]="false" [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="items" [metadata]="columns">
    <ng-container *rufNoDataRow>
      <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
      <h2 role="region"  class="chex-no-record-message">{{ 'DASHBOARDDISPUTES.NOITEMS' | translate }}</h2>
    </ng-container>
    <ng-container matColumnDef="confirmationNo" >
      <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDDISPUTES.CONFIRMATION' | translate }}</th>
      <td role="region"  class="chx-datagrid-cell" mat-cell *matCellDef="let element"><a  href="javascript:void(0);" (click)="openDisputeDetailSingle(element)">{{element.confirmationNo}}</a> </td>
    </ng-container> 
    <ng-container matColumnDef="receivedDate" >
       <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDDISPUTES.DATE' | translate }}</th>
       <td role="region"  class="chx-datagrid-cell" mat-cell *matCellDef="let element" >{{element.receivedDate | date:'MM/dd/yyyy'}} </td>
     </ng-container>
     <ng-container matColumnDef="targetCompletionDate" >
      <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDDISPUTES.TARGETDATE' | translate }}</th>
      <td role="region"  class="chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.targetCompletionDate | date:'MM/dd/yyyy'}} </td>
    </ng-container>
     <!-- <ng-container matColumnDef="OverallStatus" >
      <th role="region"  class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARDDISPUTES.STATUS' | translate }}</th>
      <td class="chx-datagrid-cell" mat-cell *matCellDef="let element">
        <div [ngClass]="{'chex-db_disputes_grid_item2_shape_rej': element.OverallStatus === 'Rejected', 'chex-db_disputes_grid_item2_shape_inp' : element.OverallStatus === 'IN PROGRESS', 'chex-db_disputes_grid_item2_shape_app' : element.OverallStatus === 'CONCLUDED', 'chex-db_disputes_grid_item2_shape_sub' : element.OverallStatus === 'SUBMITTED' }">
        <p role="region"  class="chex-db_disputes_grid_status_text">{{element.OverallStatus}}</p>
      </div>
     </td>
    </ng-container> -->
   </ruf-dynamic-datatable>
</div>
<button role="region" aria-label="view"
  [disabled] ="items.length ===0"
  class="chex-db_disputes_viewall"
  mat-stroked-button=""
  fisStyle
  (click)="openDisputeDetailAll('1')"
  >
  {{ 'DASHBOARDDISPUTES.VIEW' | translate }}
</button>
</div>