import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {
  MyTel,
  PhonecontrolComponent,
} from '../common/controls/phone-control/phone-control.component';
import { SSNValidation } from '../common/validation-common/ssn-validator';
import { DialogagevalidityComponent } from '../dailog/dialog-age-validity/dialog-age-validity.component';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import { dlstatevalidations } from '../_models/classes/dlstatevalidations-response.model';
import { states } from '../_models/classes/states-response.model';
import {
  suffix,
  suffixdata,
} from '../_models/classes/suffixdata-response.model';
import { IdpUpdateUser } from '../_models/registration/IdpUser';
import {
  UserDetail,
  UserLookupRequest,
  UserProfileDetail,
} from '../_models/registration/UserDetail';
import { IdaQuizService } from '../_services/registration/ida-quiz.service';
import { Registrationresource } from '../_models/registration/registrationresource.model';
import { IdpService } from '../_services/registration/idp.service';
import {
  CnsmrAddrDetls,
  CnsmrDrivLicenseDetls,
  CnsmrNmDetls,
  CnsmrPrsnlDetls,
  DecisionSolutionUserCreationRequest,
  PreviousAddrDetls,
  RqstHdr,
  UserRegistnReq,
} from '../_models/registration/DecisionSolutionUserDetail';
import {
  PhoneType,
  UserUpdateRequesterHeader,
} from '../common/enums/registration_enum';
import { searchfreezeexitencerequest } from '../_models/classes/searchfreezeexistence-request.model';
import { SecurityValidationService } from '../_services/registration/security-validations.service';
import { searchfreezeexitenceresponse } from '../_models/classes/searchfreezeexistence-reponse.model';
import {
  DialogsecurityfreezepinComponent,
  DialogsecurityfreezepinData,
} from '../dailog/dialog-security-freeze-pin/dialog-security-freeze-pin.component';
import { Directionality } from '@angular/cdk/bidi';
import { ViewSecurityAlertRequestData } from '../_models/classes/viewsecurityalert-request.model';
import { ViewSecurityAlertResponseData } from '../_models/classes/viewsecurityalert-reponse.model';
import {
  DialogsecurityalertquestionComponent,
  DialogsecurityalertquestionData,
} from '../dailog/dialog-security-alert-question/dialog-security-alert-question.component';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import {
  PhoneNumber,
  PhonenumbercontrolComponent,
} from '../common/controls/phone-number-control/phone-number-control.component';
import { FormValidation } from '../common/validation-common/form-validator';
import { userinformation } from '../_models/classes/user-information.model';
import { StateZipCode } from '../_models/registration/statezipcode';
import stateZipCodeJson from '../../assets/jsondata/stateZipCdeRange.json';
import { RufDropdownType } from '@ruf/shell';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import e from 'express';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../common/enums/global_variables';
import { ReCaptchaV3Service } from 'ng-recaptcha';
export const MY_FORMATS = {
  parse: { dateInput: 'MM/DD/YYYY' },
  display: {
    dateInput: 'MM/DD/YYYY', monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'chexsystem-ws-userprofile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    }
  ]
})
export class UserprofileComponent implements OnInit {
  isUpdatePersonalDetail: boolean = false;
  isUpdateAddressDetail: boolean = false;
  userUpdateFromGroup: FormGroup;
  suffixes: suffix[];
  suffixData: suffixdata;
  seasonOptions = ['Mail', 'Portal'];
  favoriteSeason: string;
  states: states.item[];
  stateData: states.statedata;
  dlstates: states.item[];
  dlstatedata: states.statedata;
  birthDate: Date;
  age: number;
  ageValidation: string =
    'Age should be 18 years or older. You cannot continue with the registration.';
  dialogWidth: string = '667px';
  isAgeCriteriaMet: boolean = true;
  datepickerDisabled = false;
  filterValidationFormats: dlstatevalidations.item[];
  disabledStyles = 'true';
  addressLine1State: string;
  suffixDetail: string;
  disabledVar: boolean = false;
  userId: string;
  homeChexSystemsUrl: string = environment.homeChexSystemsUrl; //'https://www.chexsystems.com/'
  stateOfIssuance: string;
  isPersonalDetailDisabled: boolean = true;
  isAddressDetailDisabled: boolean = true;
  invalidErrorMessage: { [key: string]: string } =
    Registrationresource.invalidErrorMessage;
  position: TooltipPosition = 'below';
  disabled = false;
  showDelay = 0;
  hideDelay = 0;
  showExtraClass = false;
  userDetail: UserDetail;
  searchFreezeExistence: searchfreezeexitenceresponse;
  securityFreezePopupOutput: { [key: string]: boolean } = {};
  securityAlertPopupOutput: { [key: string]: any } = {};
  selectedOptionWithMessageDialog: string;
  protectedCustomerDialogText: string =
    'Registration could not be completed. The consumer information entered indicates this is a Protected Consumer. All communications on behalf of a Protected Consumer must be submitted in writing and must include appropriate proof of authority and proof of identity.';
  incorrectFreezeAndAlertQuesAnswerText: string =
    'Your request cannot be submitted online because the information you entered did not match what is on file. Please contact ChexSystems to submit your request using another method.';
  buttontext: string = 'Contact ChexSystems';
  contactChexSystemsUrl: string = environment.contactChexSystemsUrl; //'https://www.chexsystems.com/web/chexsystems/consumerdebit/footer/contactus/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDRxdHA1Ngg183AP83QwcXX39LIJDfYwMjMz0w_EqcDfTj8Ij7WtOQL8RVD8O4GhAQL-BOV77zYxh-nFbQJx-3B6kSD9QQRQl4W9mRNj-KFQl_h4hRkAlrj7uxkbehu6BJugKsAQxoUjCbwLQkQW5oWAQYZDpme6oqAgAPkZcHg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/'
  viewSecurityAlert: ViewSecurityAlertResponseData;
  ssnMinLength: boolean = false;
  verifySSNMinLength: boolean = false;
  ssnInvalid: boolean = false;
  verifySSNInvalid: boolean = false;
  phoneNumberMinLength: boolean = false;
  phoneNumberInvalid: boolean = false;
  ssnNoMatch: boolean = false;
  ssnModel = { message: '', value: new MyTel('', '', '') };
  phoneModel = { message: '', value: new PhoneNumber('', '', '') };
  iconPosition = 'before';
  oldGovtNbr: string ='';
  oldPhoneNumber: string = '';
  oldMobileNumber: string = '';
  userInformation: userinformation.UserInformationRoot =
    new userinformation.UserInformationRoot();
  Id: string;
  eyeIconTypeSSN: string = 'eye';
  eyeIconTooltipSSN: string = 'show';
  eyeIconTypeVerifySSN: string = 'eye';
  eyeIconTooltipVerifySSN: string = 'show';
  @ViewChild('ssnCtrl1') ssncontrol: PhonecontrolComponent;
  @ViewChild('verifyssnCtrl1') verifyssncontrol: PhonecontrolComponent;
  @ViewChild('cellphoneCtrl1') phonenumbercontrol: PhonenumbercontrolComponent;
  stateZipCodeMapping: StateZipCode[];
  isEyeIconDisabled = false;
  hasBackdrop = true;
  panel = { type: RufDropdownType.PopoverMenu };
  isUpdateDeliveryPreference: boolean = false;
  isDeliveryPreferenceDisabled: boolean = true;
  personalControlNames = ['firstName', 'lastName', 'middleName', 'ssn', 'verifySSN', 'dlNumber', 'phone', 'dateOfBirth', 'DLNumberCtrl', 'suffix', 'DLStateCtrl', 'cellphoneCtrl', 'emailCtrl'];
  addressControlNames = ['addressLine1', 'addressLine2', 'city', 'zipCode', 'addressLine1State'];
  deliveryPreferenceControlNames = ['deliveryPreference'];
  today = moment().toDate();
  flex_layout_margin = "margin-bottom: 0px";
  title=this._translate.instant('USERPROFILE.TITLE');
  updatepersonaldetails=this._translate.instant('USERPROFILE.UPDATE PERSONAL DETAILS');
  firstname=this._translate.instant('USERPROFILE.FIRST NAME');
  middlename=this._translate.instant('USERPROFILE.MIDDLE NAME');
  lastname=this._translate.instant('USERPROFILE.LAST NAME');
  suffix=this._translate.instant('USERPROFILE.SUFFIX');
  dob=this._translate.instant('USERPROFILE.DATE OF BIRTH');
  ussocialsecuritynumber=this._translate.instant('USERPROFILE.U.S.SOCIAL SECURITY NUMBER');
  verifyussocialsecuritynumber=this._translate.instant('USERPROFILE.VERIFY U.S. SOCIAL SECURITY NUMBER');
  emailaddr=this._translate.instant('USERPROFILE.EMAIL ADDRESS');
  phnnumber=this._translate.instant('USERPROFILE.PHONE NUMBER');
  dlnumber=this._translate.instant('USERPROFILE.DL NUMBER');
  stateofissurance=this._translate.instant('USERPROFILE.STATE OF ISSUANCE');
  correspondenceaddr=this._translate.instant('USERPROFILE.CORRESPONDENCE Delivery Preference');
  currentaddrline1=this._translate.instant('USERPROFILE.CURRENT ADDRESS LINE 1');
  currentaddrline2=this._translate.instant('USERPROFILE.CURRENT ADDRESS LINE 2');
  city=this._translate.instant('USERPROFILE.CITY');
  state1=this._translate.instant('USERPROFILE.STATE1');
  zipcode=this._translate.instant('USERPROFILE.ZIP CODE');
  update=this._translate.instant('BUTTONS.UPDATE');
  cancel=this._translate.instant('BUTTONS.CANCEL');
  birthDt: Date;
  userProfile: any;
  orgValue: string;
  showMaskDl = true;
  emailAddressAvailable: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    private idpService: IdpService,
    private router: Router,
    private idaQuizService: IdaQuizService,
    private securityValidationService: SecurityValidationService,
    private _dir: Directionality,
    private piConfirmService: PersonalInformationConfirmationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private _translate: TranslateService
  ) {
    this.stateZipCodeMapping = stateZipCodeJson as StateZipCode[];
    this.userUpdateFromGroup = this._formBuilder.group(
      {
        isUpdatePersonalDetailCheck: [],
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: ['', Validators.required],
        dateOfBirth: [''],
        ssn: [''],
        verifySSN: [''],
        DLStateCtrl: [''],
        isUpdateAddressDetailCheck: [],
        isUpdateAddressDetailCheck1: [],
        DLNumberCtrl: [''],
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        city: ['', Validators.required],
        zipCode: ['', Validators.required],
        addressLine1State: ['', Validators.required],
        deliveryPreference: [''],
        suffix: [''],
        cellphoneCtrl: ['', Validators.required],
        emailCtrl: ['', Validators.required],
        isUpdateDeliveryPreferenceCheck: []
      },
      {
        validator: [
          FormValidation.EmailValidation('emailCtrl'),
          FormValidation.CustomNameValidation('firstName'),
          FormValidation.CustomNameValidation('middleName'),
          FormValidation.CustomNameSpecialCharacterValidation('lastName'),
          SSNValidation.FormatValidation('dateOfBirth'),
          FormValidation.StateZipCodeValidation(
            'addressLine1State',
            'zipCode',
            this.stateZipCodeMapping
          ),
          FormValidation.InvalidNameValidation('firstName'),
          FormValidation.InvalidNameValidation('middleName'),
          FormValidation.InvalidNameValidation('lastName'),
        ],
      }
    );
  }

  ngOnInit(): void {

    this.piConfirmService.checkPersonalInformationConfirmation('profile');
    this.getSuffixData().subscribe((res) => {
      this.suffixData = res as suffixdata;
      if (this.suffixData.status) {
        this.suffixes = this.suffixData.data;
      }
    });
    this.getStateData().subscribe((res) => {
      this.stateData = res as states.statedata;
      if (this.stateData.status) {
        this.states = this.stateData.data;
      }
    });
    this.getDLStateData().subscribe((res) => {
      this.dlstatedata = res as states.statedata;
      if (this.dlstatedata.status) {
        this.dlstates = this.dlstatedata.data;
      }
    });
    // fetch user detail
    var _userName = GlobalVariables.globalvars.get('UserId') || '';
    if (_userName !== null && _userName !== '') {
      this.userId = _userName;
      this.fetchUserDetail(_userName);
    }
  }

  dateChanged(_$event: any) {
    if (this.birthDate != undefined || this.birthDate != null) {
      this.birthDate = new Date(this.birthDate);
      const now = new Date();
      if (this.birthDate < now && this.birthDate >= new Date(1899, 12)) {
        var timeDiff = Math.abs(Date.now() - this.birthDate.getTime());
        this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        if (this.age < 18) {
          const dialogMessage = new DialogMessage(
            this.ageValidation,
            'OK',
            true,
            Registrationresource.age_validation_title
          );
          const dialogRef2 = this.dialog.open(DialogagevalidityComponent, {
            panelClass: ['fis-style'],
            data: dialogMessage,
            disableClose: true,
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if (
              (result as string) == '' ||
              (result as string) == 'OK' ||
              (result as string) == 'cancel'
            ) {
              this.isAgeCriteriaMet = false;
              this.userUpdateFromGroup.controls['dateOfBirth'].setErrors({
                isInValid: true,
              });
            }
          });
        } else {
          this.isAgeCriteriaMet = true;
          this.userUpdateFromGroup.controls['dateOfBirth'].setErrors(null);
        }
      } else {
        this.isAgeCriteriaMet = false;
        this.userUpdateFromGroup.controls['dateOfBirth'].setErrors({
          isInValid: true,
        });
      }
    }
  }

  getSuffixData() {
    return this.http.get('./././assets/jsondata/suffixdata.json');
  }

  getStateData() {
    return this.http.get('./././assets/jsondata/statedata.json');
  }

  getDLStateData() {
    return this.http.get('./././assets/jsondata/dlstatedata.json');
  }

  keyPressAlphabets(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (
      charCode == 32 ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  async updateUserProfile(
    decisionSolutionRes: any, dsUserCreationRequest: any
  ) {
    if (decisionSolutionRes.UserRegistnRsp.Message.toLowerCase() === 'update success' && decisionSolutionRes.UserRegistnRsp.Code === '0') {
      // update the profile confirmation
      this.piConfirmService.piConfirmed();
      this.userInformation.CnsmrPrsnlDetls =
        new userinformation.CnsmrPrsnlDetls();
      this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls =
        new userinformation.CnsmrDrivLicenseDetls();
      this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls =
        new userinformation.CnsmrNmDetls();
      this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt;
      this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr;
      this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim() ==
          'None'
          ? ''
          : dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls
            .CnsmrNmDetls.SuffixNm;
      this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm;
      this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm;
      this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm;
      this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName = this.userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName;
      this.userInformation.CnsmrPrsnlDetls.GovtNbr =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.GovtNbr;
      this.userInformation.CnsmrPrsnlDetls.BirthDt =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.BirthDt;
      this.userInformation.CnsmrAddrDetls = new userinformation.CnsmrAddrDetls();
      this.userInformation.CnsmrAddrDetls.CnsmrAddr = new userinformation.CnsmrAddr();
      this.userInformation.CnsmrAddrDetls.CnsmrAddr.AddrLine1 =
        dsUserCreationRequest.UserRegistnReq.CnsmrAddrDetls.CnsmrAddr.AddrLine1;
      this.userInformation.CnsmrAddrDetls.CnsmrAddr.AddrLine2 =
        dsUserCreationRequest.UserRegistnReq.CnsmrAddrDetls.CnsmrAddr.AddrLine2;
      this.userInformation.CnsmrAddrDetls.CnsmrAddr.CityNm =
        dsUserCreationRequest.UserRegistnReq.CnsmrAddrDetls.CnsmrAddr.CityNm;
      this.userInformation.CnsmrAddrDetls.CnsmrAddr.StatCode =
        dsUserCreationRequest.UserRegistnReq.CnsmrAddrDetls.CnsmrAddr.StatCode;
      this.userInformation.CnsmrAddrDetls.CnsmrAddr.ZipCode =
        dsUserCreationRequest.UserRegistnReq.CnsmrAddrDetls.CnsmrAddr.ZipCode;
      this.userInformation.UserName =
        dsUserCreationRequest.UserRegistnReq.UserName;
      this.userInformation.UserID = this.Id;
      this.userInformation.CorrespondenceDeliveryPreference =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CorrespondencDeliveryPreference;
      this.userInformation.NotificationEmailAddr =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.EmailAddr;

      this.userInformation.CellPhoneNbr =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.CellPhoneNbr;
      this.userInformation.CnsmrPrsnlDetls.PhoneNbr =
        dsUserCreationRequest.UserRegistnReq.CnsmrPrsnlDetls.PhoneNbr;
      // localStorage.setItem('UserInformation', JSON.stringify(this.userInformation));
      if (GlobalVariables.globalvars.has('UserInformation')){
        GlobalVariables.globalvars.delete('UserInformation');                
      }
      GlobalVariables.globalvars.set('UserInformation', JSON.stringify(this.userInformation));  
  
      let messageText =
        'Your profile has been successfully updated.';
      const dialogMessage = new DialogMessage(
        messageText,
        'Go to Dashboard',
        false,
        Registrationresource.userprofile_complete_title
      );
      const dialogRef2 = this.dialog.open(
        DialogwithmessageComponent,
        {
          data: dialogMessage,
          panelClass: ['fis-style', 'custom-dialog'],
          disableClose: true,
        }
      );
      dialogRef2.afterClosed().subscribe((result) => {
        //chexsystems public home page
        if ((result as string) == 'OK') {
          this.piConfirmService.piConfirmed();
          this.router.navigate(['dashboard']);
        }
      });
    } else if (decisionSolutionRes.UserRegistnRsp.Code === '4113') {
      let messageText = Registrationresource.DuplicateUserUpdate;
      const dialogMessage = new DialogMessage(
        messageText,
        'Go to Dashboard',
        false,
        Registrationresource.userprofile_complete_title
      );
      const dialogRef2 = this.dialog.open(
        DialogwithmessageComponent,
        {
          data: dialogMessage,
          panelClass: ['fis-style', 'custom-dialog'],
          disableClose: true,
        }
      );
      dialogRef2.afterClosed().subscribe((result) => {
        //chexsystems public home page
        if (
          (result as string) == 'OK' ||
          (result as string) == ''
        ) {
          this.router.navigate(['dashboard']);
        }
      });
    }
   }

  cancelUserProfile(event: any) {
    this.piConfirmService.checkPersonalInformationGoToDashboard();
  }

  LoadDLValidation(flag: boolean) {
    var statecode = this.userUpdateFromGroup.get('DLStateCtrl')?.value;
    var dlnumber = flag ? this.orgValue: this.userUpdateFromGroup.get('DLNumberCtrl')?.value;
    var validation = false;

    if (statecode == '' && dlnumber != '') {
      this.userUpdateFromGroup
        .get('DLStateCtrl')
        ?.setErrors({ stateselect: true });
    } else if (statecode != '' && dlnumber == '') {
      this.userUpdateFromGroup
        .get('DLNumberCtrl')
        ?.setErrors({ DLNumberEnter: true });
    } else if (statecode == '' && dlnumber == '') {
      this.userUpdateFromGroup
        .get('DLStateCtrl')
        ?.setErrors({ stateselect: null });
      this.userUpdateFromGroup.get('DLStateCtrl')?.updateValueAndValidity();
      this.userUpdateFromGroup
        .get('DLNumberCtrl')
        ?.setErrors({ patterndoesnotmatch: null });
      this.userUpdateFromGroup
        .get('DLNumberCtrl')
        ?.setErrors({ DLNumberEnter: null });
      this.userUpdateFromGroup.get('DLNumberCtrl')?.updateValueAndValidity();
    } else {
      this.getStateValidationsData().subscribe((res: any) => {
        let dlstatevalidationsdata =
          res as dlstatevalidations.dlstatevalidationsdata;
        if (dlstatevalidationsdata.status) {
          let dlstatevalidationformats =
            dlstatevalidationsdata.data as dlstatevalidations.item[];
          this.filterValidationFormats = dlstatevalidationformats.filter(
            (item) => item.state === statecode
          );

          validation =
            this.filterValidationFormats?.length === 0 ? true : false;
          this.filterValidationFormats[0]?.validationformats.forEach(
            (validationformat) => {
              let regexPattern = RegExp(validationformat);
              if (regexPattern.test(dlnumber)) {
                validation = true;
              }
            }
          );
          if (!validation) {
            this.userUpdateFromGroup
              .get('DLNumberCtrl')
              ?.setErrors({ patterndoesnotmatch: true });
          } else {
            this.userUpdateFromGroup
              .get('DLNumberCtrl')
              ?.setErrors({ patterndoesnotmatch: null });
            this.userUpdateFromGroup
              .get('DLNumberCtrl')
              ?.updateValueAndValidity();
          }
        }
      });
    }
  }

  disablePersonalDetails(event: any) {
    this.isUpdatePersonalDetail = event;
    this.isPersonalDetailDisabled = !this.isUpdatePersonalDetail;
    if(this.isUpdatePersonalDetail)
    {
      this.checkforEmailAddressAvailability();
      this.enablePersonalDetails();

    }else
    { 
      this.diablePersonalDetails();
    }
  }
  disableAddressDetailSection(event: any) {
    this.isUpdateAddressDetail = event;
    this.isAddressDetailDisabled = !this.isUpdateAddressDetail;
    if (this.isUpdateAddressDetail) {
      this.enableAddressDetails();
    } else {
      this.disableAddressDetails();
    }
  }

  getStateValidationsData() {
    return this.http.get('./././assets/jsondata/dlstatevalidationsregex.json');
  }

  // getUserDetail() {
  //   return this.http.get<UserProfileDetail[]>(
  //     './././assets/jsondata/userdetail.json'
  //   );
  // }
  fetchUserDetail(userId: string) {
    let lookupRequest: UserLookupRequest = new UserLookupRequest();
    lookupRequest.UserLookupReq = { UserName: userId, Cid: '' };
    this.idaQuizService
      .getUserDetailFromDecisionSolution(lookupRequest)
      .subscribe(
        (res) => {
          this.userDetail = res;
          this.Id = res.UserLookupRsp.Id;
          this.userUpdateFromGroup
            .get('firstName')
            ?.setValue(
              res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim()
            );
          this.userUpdateFromGroup
            .get('lastName')
            ?.setValue(
              res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim()
            );
          this.userUpdateFromGroup
            .get('middleName')
            ?.setValue(
              res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim()
            );
          this.userUpdateFromGroup
            .get('addressLine1')
            ?.setValue(
              res.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim()
            );
          this.userUpdateFromGroup
            .get('addressLine2')
            ?.setValue(
              res.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim()
            );
          this.userUpdateFromGroup
            .get('city')
            ?.setValue(
              res.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.CityNm.trim()
            );
          this.userUpdateFromGroup
            .get('zipCode')
            ?.setValue(
              res.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim()
            );
          this.userUpdateFromGroup
            .get('DLNumberCtrl')
            ?.setValue(
              res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim()
            );
            this.orgValue =  res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
            this.handleDlMask(true);
            this.showMaskDl = true; 
          this.userUpdateFromGroup
            .get('emailCtrl')
            ?.setValue(res.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr.trim());
            this.checkforEmailAddressAvailability();
          this.addressLine1State =
            res.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.StatCode;
          this.favoriteSeason =
            res.UserLookupRsp.CnsmrPrsnlDetls.CorrespondenceDlvryPref;
          this.suffixDetail =
            res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim() == ''
              ? 'None'
              : res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm;
          this.birthDate = new Date(res.UserLookupRsp.CnsmrPrsnlDetls.BirthDt);
          // for mandatory error if it empty
          if(res.UserLookupRsp.CnsmrPrsnlDetls.BirthDt == ''){
            this.birthDate = this.birthDt;
          }
          this.stateOfIssuance =
            res.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
          let ssn = this.ConvertUSSSNFormate(
            res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr
          );
          this.oldGovtNbr = res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr;
          if(res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr.trim() != ''){
          this.userUpdateFromGroup.get('ssn')?.setValue(ssn);
          this.userUpdateFromGroup.get('verifySSN')?.setValue(ssn);
        }
          // check the phone type          
          let cellPhoneNumber: MyTel = new MyTel('', '', '');
          let phonetype: string = '';
          if (res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() !== '') {
            cellPhoneNumber = this.ConvertPhoneFormate(
              res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr
            );
            this.oldMobileNumber =
              cellPhoneNumber.area +
              cellPhoneNumber.exchange +
              cellPhoneNumber.subscriber;
          }
          if (
            res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr !== '' &&
            res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() === ''
          ) {
            cellPhoneNumber = this.ConvertPhoneFormate(
              res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr
            );
          }
          if (res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr !== '') {
            this.oldPhoneNumber =
              cellPhoneNumber.area +
              cellPhoneNumber.exchange +
              cellPhoneNumber.subscriber;
          }
          // set Phone type on empty periority
          phonetype = PhoneType.Mobile;
          if (
            res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr !== '' &&
            res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() === ''
          ) {
            phonetype = PhoneType.Phone;
          }

          this.phonenumbercontrol.parts.controls['phonetype'].setValue(
            phonetype
          );
          if(!(cellPhoneNumber.area.trim() == '' && cellPhoneNumber.exchange.trim() == '' && cellPhoneNumber.subscriber.trim() == '')) {
          this.userUpdateFromGroup
            .get('cellphoneCtrl')
            ?.setValue(
              new PhoneNumber(
                cellPhoneNumber.area,
                cellPhoneNumber.exchange,
                cellPhoneNumber.subscriber,
                phonetype
              )
            );
            }
            const personalDetailFormControls = [
              this.userUpdateFromGroup.get('firstName')?.value,
              this.userUpdateFromGroup.get('lastName')?.value,             
              cellPhoneNumber.area+''+cellPhoneNumber.exchange+''+cellPhoneNumber.subscriber,
              (this.birthDate !=undefined) ? this.birthDate?.toString():'',
              ssn.area+''+ssn.exchange+''+ssn.subscriber,
              ssn.area+''+ssn.exchange+''+ssn.subscriber,
              this.userUpdateFromGroup.get('emailCtrl')?.value,
            ];
            if(personalDetailFormControls.some(x=> x.length ==0)){
              this.isUpdatePersonalDetail = true;
            }
            // address 
          const addressFormControls = [
            this.userUpdateFromGroup.get('addressLine1')?.value,
            this.userUpdateFromGroup.get('city')?.value,
            this.addressLine1State,
            this.userUpdateFromGroup.get('zipCode')?.value,
          ];
          if(addressFormControls.some(x=> x.length ==0)){
            this.isUpdateAddressDetail = true;
          }
        },
        (error) => { },
        () => { }
      );
    this.diablePersonalDetails();
    this.disableAddressDetails();
    this.disableDeliveryPreference();
  }

  diablePersonalDetails() {
    this.toDisableFormControls(this.personalControlNames);
    this.isEyeIconDisabled = false;
  }
  disableAddressDetails() {
    this.toDisableFormControls(this.addressControlNames);
  }
  enablePersonalDetails() {
    this.toEnableFormControls(this.personalControlNames);
    this.isEyeIconDisabled = true;
  }
  enableAddressDetails() {
    this.toEnableFormControls(this.addressControlNames);
  }

  private ConvertUSSSNFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{2})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatedPhoneNumber[0],
      formatedPhoneNumber[1],
      formatedPhoneNumber[2]
    );
  }

  private ConvertPhoneFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatedPhoneNumber[0],
      formatedPhoneNumber[1],
      formatedPhoneNumber[2]
    );
  }

  private ConvertDateTime(dateValue: Date): string {
    return (
      this.ConvertValidDateFormat(dateValue) +
      '' +
      this.padLeadingZeros(dateValue.getHours(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getMinutes(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getSeconds(), 2)
    );
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

  featureDateFilter = (d: Date | null): boolean => {
    if (!d) {
      return true;
    }
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return d.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
  };

  addSSNErrorMessage(data: any) {
    this.ssnModel = data;
    this.ssnMinLength = false;
    this.ssnInvalid = false;

    if (this.ssnModel.message == 'minlength') {
      this.ssnMinLength = true;
    } else {
      if (!this.securityValidationService.IsValidSSN(this.ssnModel.value.area, this.ssnModel.value.exchange, this.ssnModel.value.subscriber)) {
        this.ssnInvalid = true;
        this.ssncontrol.parts.controls['area'].setErrors({ invalid: true });
      }
      else {
        this.ssncontrol.parts.controls['area'].setErrors({ invalid: null });
        this.ssncontrol.parts.controls['area'].updateValueAndValidity();
        if (this.ssnModel.message == 'checkformatch') {
          let verifyssnarea = this.verifyssncontrol.parts.controls['area']?.value;
          let verifyssnsubscriber =
            this.verifyssncontrol.parts.controls['subscriber']?.value;
          let verifyssnexchange =
            this.verifyssncontrol.parts.controls['exchange']?.value;

          if (
            !(
              verifyssnarea == '' &&
              verifyssnsubscriber == '' &&
              verifyssnexchange == ''
            ) &&
            !(
              verifyssnarea == this.ssnModel.value.area &&
              verifyssnsubscriber == this.ssnModel.value.subscriber &&
              verifyssnexchange == this.ssnModel.value.exchange
            )
          ) {
            this.ssnNoMatch = true;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: true,
            });
          } else {
            this.ssnNoMatch = false;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: null,
            });
            this.verifyssncontrol.parts.controls['area'].updateValueAndValidity();
          }
        }
      }
    }
    if (this.ssnModel.message == '' || this.ssnMinLength || this.ssnInvalid) {
      this.ssncontrol.ChangeErrorStyles(true);
    }
    else {
      this.ssncontrol.ChangeErrorStyles(false);
    }

    if(this.ssnNoMatch || this.verifySSNMinLength || this.verifySSNInvalid || this.verifyssncontrol.errorState){
      this.verifyssncontrol.ChangeErrorStyles(true);
    }
    else {
      this.verifyssncontrol.ChangeErrorStyles(false);
    }
  }

  addVerifySSNErrorMessage(data: any) {
    this.ssnModel = data;
    this.verifySSNMinLength = false;
    this.verifySSNInvalid = false;

    if (this.ssnModel.message == 'minlength') {
      this.verifySSNMinLength = true;
    }
    else {
      if (!this.securityValidationService.IsValidSSN(this.ssnModel.value.area, this.ssnModel.value.exchange, this.ssnModel.value.subscriber)) {
        this.verifySSNInvalid = true;
        this.verifyssncontrol.parts.controls['area'].setErrors({ invalid: true });
      }
      else {
        this.verifyssncontrol.parts.controls['area'].setErrors({ invalid: null });
        this.verifyssncontrol.parts.controls['area'].updateValueAndValidity();
        if (this.ssnModel.message == 'checkformatch') {
          let ssnarea = this.ssncontrol.parts.controls['area']?.value;
          let ssnsubscriber = this.ssncontrol.parts.controls['subscriber']?.value;
          let ssnexchange = this.ssncontrol.parts.controls['exchange']?.value;

          if (
            !(ssnarea == '' && ssnsubscriber == '' && ssnexchange == '') &&
            !(
              ssnarea == this.ssnModel.value.area &&
              ssnsubscriber == this.ssnModel.value.subscriber &&
              ssnexchange == this.ssnModel.value.exchange
            )
          ) {
            this.ssnNoMatch = true;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: true,
            });
          } else {
            this.ssnNoMatch = false;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: null,
            });
            this.verifyssncontrol.parts.controls['area'].updateValueAndValidity();
          }
        }
      }
    }
    if (this.ssnModel.message == '' || this.verifySSNMinLength || this.verifySSNInvalid || this.ssnNoMatch) {
      this.verifyssncontrol.ChangeErrorStyles(true);
    }
    else {
      this.verifyssncontrol.ChangeErrorStyles(false);
    }
  }

  addPhoneNumberErrorMessage(data: any) {
    this.phoneModel = data;
    this.phoneNumberMinLength = false;
    this.phoneNumberInvalid = false;

    if (this.phoneModel.message == 'minlength') {
      this.phoneNumberMinLength = true;
    }
    else {
      if (!this.securityValidationService.IsValidPhoneNumber(this.phoneModel.value.area, this.phoneModel.value.exchange, this.phoneModel.value.subscriber)) {
        this.phoneNumberInvalid = true;
        this.phonenumbercontrol.parts.controls['area'].setErrors({ invalid: true });
      }
      else {
        this.phonenumbercontrol.parts.controls['area'].setErrors({ invalid: null });
        this.phonenumbercontrol.parts.controls['area'].updateValueAndValidity();
      }
    }

    if (this.phoneNumberMinLength || this.phoneModel.message == '' || this.phoneNumberInvalid) {
      this.phonenumbercontrol.ChangeErrorStyles(true);
    }
    else {
      this.phonenumbercontrol.ChangeErrorStyles(false);
    }
  }

  async securityFreezeValidation(event: any) {
    if(this.emailAddressAvailable && !!this.isPersonalDetailDisabled){
      const dialogMessage = new DialogMessage(
        Registrationresource.uniqueEmailText,
        'OK',
        false,
        Registrationresource.uniqueEmailTitle
      );
      const dialogRef2 = this.dialog.open(DialogagevalidityComponent, {
        panelClass: ['fis-style'],
        data: dialogMessage,
        disableClose: true,
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if (
          (result as string) == '' ||
          (result as string) == 'OK' 
        ) {
        //  this.disablePersonalDetails(true);
        }
      });
    } else if (this.userUpdateFromGroup.valid && !this.ssnNoMatch) {
      let ssn = this.userUpdateFromGroup.controls['ssn'].value as MyTel;
      let govtnumber = (ssn.area + ssn.exchange + ssn.subscriber) as string;
      let searchFreezeExitenceRequestData: searchfreezeexitencerequest = {
        GovtNbr: this.oldGovtNbr,
        UserName:  this.userId,
        RecapchaToken: ''
      };
      const dialogMessage = new DialogMessage('', '', true);
      this.securityValidationService
        .SrchFrzExistence(searchFreezeExitenceRequestData)
        .subscribe(async (res) => {
          this.searchFreezeExistence = res as searchfreezeexitenceresponse;

          //if protected consumer, display protected consumer pop-up
          if (this.searchFreezeExistence.ProtectedFlagInd == 'Y') {
            dialogMessage.messagetext = this.protectedCustomerDialogText;
            dialogMessage.buttontext = this.buttontext;
            dialogMessage.showCancel = false;
            dialogMessage.title =
              Registrationresource.request_could_not_processed;
            const dialogRefThreatMetrix = this.dialog.open(
              DialogwithmessageComponent,
              {
                data: dialogMessage,
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                disableClose: true,
              }
            );
            dialogRefThreatMetrix.afterClosed().subscribe((result) => {
              if ((result as string) == '' || (result as string) == 'OK') {
                window.location.href = this.contactChexSystemsUrl + "/#header";
              }
            });
          } else {
            //if freeze PIN exist, display freeze PIN pop-up
            if (this.searchFreezeExistence.FreezeExists) {
              const dialogData = new DialogsecurityfreezepinData(this.oldGovtNbr, false);
              const dialogRef = this.dialog.open(
                DialogsecurityfreezepinComponent,
                {
                  direction: this._dir.value,
                  panelClass: ['fis-style'],
                  data: dialogData,
                  disableClose: true,
                }
              );

              dialogRef.afterClosed().subscribe(async (result) => {
                this.securityFreezePopupOutput = result as {
                  [key: string]: boolean;
                };
                if (
                  this.securityFreezePopupOutput &&
                  !this.securityFreezePopupOutput.cancel
                ) {
                  //freeze PIN incorrect
                  if (!this.securityFreezePopupOutput?.isfreezeanswercorrect) {
                    dialogMessage.messagetext =
                      this.incorrectFreezeAndAlertQuesAnswerText;
                    dialogMessage.buttontext = this.buttontext;
                    dialogMessage.title =
                      Registrationresource.request_could_not_processed;
                    dialogMessage.showCancel = false;
                    const dialogRefThreatMetrix = this.dialog.open(
                      DialogwithmessageComponent,
                      {
                        data: dialogMessage,
                        panelClass: [
                          'fis-style',
                          'custom-dialog-without-close',
                        ],
                        disableClose: true,
                      }
                    );
                    dialogRefThreatMetrix.afterClosed().subscribe((result) => {
                      //contact chexsystems
                      if (
                        (result as string) == '' ||
                        (result as string) == 'OK'
                      ) {
                        window.location.href = this.contactChexSystemsUrl + "/#header";
                      }
                    });
                  } else {
                    //proceed with secuirty alert check
                    let updateUserProfileObj: any = {};
                    updateUserProfileObj = await this.prepareUpdateUserObj(govtnumber);
                    let viewSecurityAlertRequestData: ViewSecurityAlertRequestData = {
                      viewSecurityAlertRequest: { 
                        govtNbr: this.oldGovtNbr ,
                        userName: this.userId,
                        recapchaToken: ''
                      },
                    };
                    updateUserProfileObj.viewSecurityAlertReq = viewSecurityAlertRequestData;                    
                    this.securityValidationService
                      .ViewSecurityAlert(updateUserProfileObj)
                      .subscribe((res) => {
                        this.viewSecurityAlert =
                          res;

                        //if security alert question exists, display alert question pop-up
                        if (this.viewSecurityAlert?.securityAlert == 'Y') {
                          const dialogData =
                            new DialogsecurityalertquestionData(
                              this.oldGovtNbr,
                              this.viewSecurityAlert.questionCode,
                              this.viewSecurityAlert.questionText,
                              false,
                              updateUserProfileObj
                            );
                          const dialogRef = this.dialog.open(
                            DialogsecurityalertquestionComponent,
                            {
                              direction: this._dir.value,
                              panelClass: ['fis-style'],
                              data: dialogData,
                              disableClose: true,
                            }
                          );
                          dialogRef.afterClosed().subscribe((result) => {
                            this.securityAlertPopupOutput = result as {
                              [key: string]: any;
                            };
                            if (
                              this.securityAlertPopupOutput &&
                              !this.securityAlertPopupOutput.cancel
                            ) {
                              if (
                                !this.securityAlertPopupOutput
                                  ?.issecurityanswercorrect
                              ) {
                                dialogMessage.messagetext =
                                  this.incorrectFreezeAndAlertQuesAnswerText;
                                dialogMessage.buttontext = this.buttontext;
                                dialogMessage.title =
                                  Registrationresource.request_could_not_processed;
                                dialogMessage.showCancel = false;
                                const dialogRefThreatMetrix = this.dialog.open(
                                  DialogwithmessageComponent,
                                  {
                                    data: dialogMessage,
                                    panelClass: [
                                      'fis-style',
                                      'custom-dialog-without-close',
                                    ],
                                    disableClose: true,
                                  }
                                );
                                dialogRefThreatMetrix
                                  .afterClosed()
                                  .subscribe((result) => {
                                    //contact chexsystems
                                    if (
                                      (result as string) == '' ||
                                      (result as string) == 'OK'
                                    ) {
                                      window.location.href =
                                        this.contactChexSystemsUrl + "/#header";
                                    }
                                  });
                              } else {
                                this.updateUserProfile(this.securityAlertPopupOutput?.dsUpdateUserResp, updateUserProfileObj.dsUserUpdate);
                                return;
                              }
                            } else {
                              window.location.reload();
                            }
                          });
                        } else {
                          this.updateUserProfile(res, updateUserProfileObj.dsUserUpdate);
                          return;
                        }
                      });
                  }
                }
              });
            } else {
              //if freeze pin does not exist, proceed with secuirty alert check
              let updateUserProfileObj: any = {};
              updateUserProfileObj = await this.prepareUpdateUserObj(govtnumber); 
                let viewSecurityAlertRequestData: ViewSecurityAlertRequestData = {
                  viewSecurityAlertRequest: { 
                    govtNbr: this.oldGovtNbr ,
                    userName: this.userId,
                    recapchaToken: ''
                  },
                };
                updateUserProfileObj.viewSecurityAlertReq = viewSecurityAlertRequestData;                
              this.securityValidationService
                .ViewSecurityAlert(updateUserProfileObj)
                .subscribe((res) => {
                  this.viewSecurityAlert = res;

                  //if security alert question exists
                  if (this.viewSecurityAlert.securityAlert == 'Y') {
                    const dialogData = new DialogsecurityalertquestionData(
                      this.oldGovtNbr,
                      this.viewSecurityAlert.questionCode,
                      this.viewSecurityAlert.questionText,
                      false,
                      updateUserProfileObj
                    );
                    const dialogRef = this.dialog.open(
                      DialogsecurityalertquestionComponent,
                      {
                        direction: this._dir.value,
                        panelClass: ['fis-style'],
                        data: dialogData,
                        disableClose: true,
                      }
                    );

                    dialogRef.afterClosed().subscribe((result) => {
                      this.securityAlertPopupOutput = result as {
                        [key: string]: any;
                      };
                      if (
                        this.securityAlertPopupOutput &&
                        !this.securityAlertPopupOutput.cancel
                      ) {
                        //TO-DO
                        if (
                          !this.securityAlertPopupOutput
                            ?.issecurityanswercorrect
                        ) {
                          dialogMessage.messagetext =
                            this.incorrectFreezeAndAlertQuesAnswerText;
                          dialogMessage.buttontext = this.buttontext;
                          dialogMessage.title =
                            Registrationresource.request_could_not_processed;
                          dialogMessage.showCancel = false;
                          const dialogRefThreatMetrix = this.dialog.open(
                            DialogwithmessageComponent,
                            {
                              data: dialogMessage,
                              panelClass: [
                                'fis-style',
                                'custom-dialog-without-close',
                              ],
                              disableClose: true,
                            }
                          );
                          dialogRefThreatMetrix
                            .afterClosed()
                            .subscribe((result) => {
                              //contact chexsystems
                              if (
                                (result as string) == '' ||
                                (result as string) == 'OK'
                              ) {
                                window.location.href =
                                  this.contactChexSystemsUrl + "/#header";
                              }
                            });
                        } else {
                          this.updateUserProfile(this.securityAlertPopupOutput?.dsUpdateUserResp, updateUserProfileObj?.dsUserUpdate);
                          return;
                        }
                      }
                    });
                  } else {
                    this.updateUserProfile(res, updateUserProfileObj?.dsUserUpdate);
                    return;
                  }
                });
            }
          }
        });
    } else {
      if (!this.isPersonalDetailDisabled) {
        this.ssncontrol.handleValidation();
        this.verifyssncontrol.handleValidation();
        this.phonenumbercontrol.handleValidation();
      }
    }
  }

  handleMasking(code: string) {
    if (code === 'ssn') {
      if (this.eyeIconTypeSSN === 'eye') {
        this.eyeIconTypeSSN = 'eye-hide';
        this.eyeIconTooltipSSN = 'hide';
        this.ssncontrol.handleMasking('text');
      } else if (this.eyeIconTypeSSN === 'eye-hide') {
        this.eyeIconTypeSSN = 'eye';
        this.eyeIconTooltipSSN = 'show';
        this.ssncontrol.handleMasking('password');
      }
    } else if (code === 'verifyssn') {
      if (this.eyeIconTypeVerifySSN === 'eye') {
        this.eyeIconTypeVerifySSN = 'eye-hide';
        this.eyeIconTooltipVerifySSN = 'hide';
        this.verifyssncontrol.handleMasking('text');
      } else if (this.eyeIconTypeVerifySSN === 'eye-hide') {
        this.eyeIconTypeVerifySSN = 'eye';
        this.eyeIconTooltipVerifySSN = 'show';
        this.verifyssncontrol.handleMasking('password');
      }
    } else if(code === 'dl') {
      if (this.eyeIconTypeSSN === 'eye') {
        this.eyeIconTypeSSN = 'eye-hide';
        this.eyeIconTooltipSSN = 'hide';
        this.handleDlMask(false);
      } else if (this.eyeIconTypeSSN === 'eye-hide') {
        this.eyeIconTypeSSN = 'eye';
        this.eyeIconTooltipSSN = 'show';
        this.handleDlMask(true);
      }

    }
  }

  handleDlMask(value: boolean) {
    let formattedvalue = '';
    let dlnum = this.userUpdateFromGroup.get('DLNumberCtrl')?.value;
    if(value ) {
      this.showMaskDl = true;
      formattedvalue  =  dlnum !== "" ?dlnum
      .slice(0, dlnum.length - 4)
      .replace(/([a-zA-Z0-9])/g, '*') + dlnum.slice(-4): '';
    this.userUpdateFromGroup.get('DLNumberCtrl')?.setValue(formattedvalue);
    } else {
    this.userUpdateFromGroup.get('DLNumberCtrl')?.setValue(this.orgValue);
    this.showMaskDl = false;
  }
  }
  onChangeDL() {
    this.orgValue = this.userUpdateFromGroup.get('DLNumberCtrl')?.value;
  }
  private padLeadingZeros(num: number, size: number) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
  disableDeliveryPreferenceSection(event: any) {
    this.isDeliveryPreferenceDisabled = !this.isDeliveryPreferenceDisabled;
    if (this.isDeliveryPreferenceDisabled) {
      this.disableDeliveryPreference();
    } else {
      this.enableDeliveryPreference();
    }
  }
  private disableDeliveryPreference(): void {
    this.toDisableFormControls(this.deliveryPreferenceControlNames);
  }
  private enableDeliveryPreference(): void {
    this.toEnableFormControls(this.deliveryPreferenceControlNames);
  }

  private toEnableFormControls(addressControlNames: string[]) {
    const userUdpateFormControl = this.userUpdateFromGroup.controls;
    for (const name in userUdpateFormControl) {
      if (addressControlNames.some(x => x == name)) {
        this.userUpdateFromGroup.get(name)?.enable();
        this.userUpdateFromGroup.get(name)?.markAsTouched();
      }
    }
    if(this.ssncontrol?.empty){
      this.ssncontrol?.handleValidation();
    }
    if(this.verifyssncontrol?.empty){
      this.verifyssncontrol?.handleValidation();
    }
    if(this.phonenumbercontrol?.empty){
      this.phonenumbercontrol?.handleValidation();
    }
  }
  private toDisableFormControls(addressControlNames: string[]) {
    const userUdpateFormControl = this.userUpdateFromGroup.controls;
    for (const name in userUdpateFormControl) {
      if (addressControlNames.some(x => x == name)) {
        this.userUpdateFromGroup.get(name)?.disable();
      }
    }
    if(this.ssncontrol?.empty){ 
      this.ssncontrol?.ChangeErrorStyles(false);       
    }       
    if(this.verifyssncontrol?.empty){       
      this.verifyssncontrol?.ChangeErrorStyles(false);       
    }       
    if(this.phonenumbercontrol?.empty){       
      this.phonenumbercontrol?.ChangeErrorStyles(false);       
    }
  }

  featureDatesFilter = (m: _moment.Moment | null): boolean => {
    const year = (m || moment()).toDate();
    const startDate = moment([1899, 11, 31]).toDate();
    return year <= this.today && year > startDate;
  }

  async prepareUpdateUserObj(govtnumber: any): Promise<any>{
    const isEmptyString = (data: string): boolean => typeof data === 'string' && data.trim().length == 0;
    if (this.userUpdateFromGroup.valid) {

      const userProfileInformation = GlobalVariables.globalvars.get("UserInformation");
      if (userProfileInformation != null && !isEmptyString(userProfileInformation)) {
        this.userProfile = JSON.parse(userProfileInformation) as userinformation.UserInformationRoot;
        let phoneArea = this.phonenumbercontrol.parts.controls['area']?.value;
        let phoneExchange = this.phonenumbercontrol.parts.controls['exchange']?.value;
        let phoneSubscriber = this.phonenumbercontrol.parts.controls['subscriber']?.value;
        let phoneType = this.phonenumbercontrol.parts.controls['phonetype']?.value;
        let phoneNumber = (phoneArea + phoneExchange + phoneSubscriber) as string;
        let phoneNbr: string = '', cellPhoneNumber: string = '';
        if (phoneType === PhoneType.Mobile) {
          cellPhoneNumber = phoneNumber;
        }
        else if (phoneType === PhoneType.Phone) {
          phoneNbr = phoneNumber;
        }

        let requestHeader: RqstHdr = {
          RqstTyp: UserUpdateRequesterHeader.RequestType,
          RqstSubTyp: UserUpdateRequesterHeader.UpdateRequestSubType,
        };
        let consumerAddressDetail: CnsmrAddrDetls = {
          CnsmrAddr: {
            AddrLine1: this.userUpdateFromGroup.get('addressLine1')?.value.trim(),
            AddrLine2: this.userUpdateFromGroup.get('addressLine2')?.value.trim(),
            CityNm: this.userUpdateFromGroup.get('city')?.value.trim(),
            StatCode: this.userUpdateFromGroup.get('addressLine1State')?.value.trim(),
            ZipCode: this.userUpdateFromGroup.get('zipCode')?.value.trim(),
          },
        };

        let consumerDriveLicenseDetail: CnsmrDrivLicenseDetls = {
          DrivLicenseIssuSt: this.userUpdateFromGroup.get('DLStateCtrl')?.value.trim(),
          DrivLicenseNbr: this.orgValue,
        };
        let userSuffix = this.userUpdateFromGroup.get('suffix')?.value == 'None'
          ? ''
          : this.userUpdateFromGroup.get('suffix')?.value;
        let consumerNameDetail: CnsmrNmDetls = {
          FrstNm: this.userUpdateFromGroup.get('firstName')?.value.trim(),
          LastNm: this.userUpdateFromGroup.get('lastName')?.value.trim(),
          MidNm: this.userUpdateFromGroup.get('middleName')?.value.trim(),
          SuffixNm: userSuffix,
        };
        let consumerPersonalDetail: CnsmrPrsnlDetls = {
          BirthDt: this.ConvertValidDateFormat(this.birthDate), //MM/DD/YYY
          CnsmrDrivLicenseDetls: consumerDriveLicenseDetail,
          CnsmrNmDetls: consumerNameDetail,
          EmailAddr: this.userUpdateFromGroup.get('emailCtrl')?.value.trim(),
          GovtNbr: govtnumber,
          PhoneNbr: phoneNbr,
          CellPhoneNbr: cellPhoneNumber,
          CorrespondencDeliveryPreference: this.userUpdateFromGroup.get('deliveryPreference')?.value
        };

        let previousAddressDetail: PreviousAddrDetls = {
          PreviousAddr1: {
            PreviousAddr1Line1: '',
            PreviousAddr1Line2: '',
            PreviousCityNm1:'',
            PreviousStatCode1: '',
            PreviousZipCode1: '',
          },
          PreviousAddr2: {
            PreviousAddr2Line1: '',
            PreviousAddr2Line2: '',
            PreviousCityNm2: '',
            PreviousStatCode2: '',
            PreviousZipCode2: '',
          },
          PreviousAddr3: {
            PreviousAddr3Line1: '',
            PreviousAddr3Line2: '',
            PreviousCityNm3: '',
            PreviousStatCode3: '',
            PreviousZipCode3: '',
          },
        };
        let userRegisterRequest: UserRegistnReq = {
          RqstHdr: requestHeader,
          CnsmrAddrDetls: consumerAddressDetail,
          CnsmrPrsnlDetls: consumerPersonalDetail,
          PreviousAddrDetls: previousAddressDetail,
          UserName: this.userId.toString(),
          RecvdDtTm: this.ConvertDateTime(new Date()), // DD/MM/YYYhh:mm:ss
        };
        let dsUserCreationRequest: DecisionSolutionUserCreationRequest = {
          UserRegistnReq: userRegisterRequest,
        };

        //update user first name, last name, mid name in IDP
        let userNameDetail: IdpUpdateUser = {
          firstName: this.userUpdateFromGroup.get('firstName')?.value,
          middleName: this.userUpdateFromGroup.get('middleName')?.value,
          loginName: this.userId.split("'").join("''"),
          lastName: this.userUpdateFromGroup.get('lastName')?.value,
          phone: (!isEmptyString(phoneNbr) ? '+1' + phoneNbr : ''),
          emailAddress: this.userUpdateFromGroup.get('emailCtrl')?.value,
          mobile: (!isEmptyString(cellPhoneNumber) ? '+1' + cellPhoneNumber : ''),
        };
		let updateUserProfileObj = {
		dsUserUpdate: dsUserCreationRequest,
		idpUserUpdate: userNameDetail,
		}
		return  updateUserProfileObj;
		}
		}

  }

  checkforEmailAddressAvailability() {
    let emailaddress = this.userUpdateFromGroup.get('emailCtrl')?.value.trim();
    if ( emailaddress !== '' && emailaddress !== undefined) {
      // call idp webapi here for username availability
      this.idpService.CheckUserNameCount(emailaddress).subscribe(
        (res:any) => {
          if (res.total > 1 ) {
            this.emailAddressAvailable = true;
            this.userUpdateFromGroup
              .get('emailCtrl')
              ?.setErrors({ emailAddressAvailable: true });
              } else if(res.total == 1) {
                    if(res.data.filter((resp:any) => (resp.userName != this.userId)).length == 1){
                      this.emailAddressAvailable = true;
                    this.userUpdateFromGroup
                  .get('emailCtrl')
                  ?.setErrors({ emailAddressAvailable: true });
              }
              }
          else {
            this.emailAddressAvailable = false;
            this.userUpdateFromGroup
              .get('emailCtrl')
              ?.setErrors({ emailAddressAvailable: null });
            this.userUpdateFromGroup.get('emailCtrl')?.updateValueAndValidity();
          }
        },
        (err) => {},
        () => {}
      );
    }
  }
  
}
