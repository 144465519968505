<div class="chex-bottom_space">
  <ruf-page-header role="region"  class="header_text" rufId="page_header_disputeTab_title" title="{{ 'SECURITYFREEZEINFO.TITLE' | translate }}"
    description=" "></ruf-page-header>
    <div role="region"  class="chex-page-left-right-alignment mat-body-2" [innerHTML]="securityFreezeInfoContent | sanitizeHtml">
    </div>
  <div class="chex-page-left-right-alignment">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex>
        <mat-card fxLayout="column" class="mail-info">
          <mat-icon class="icon_phone" rufId fontSet="fisfont-solid" fisIcon="telephone"></mat-icon>
          <div role="region"  class="chex-page-left-right-alignment mat-body-2 mail-info" [innerHTML]="securityFreezeContactInfoContent | sanitizeHtml">
          </div>
        </mat-card>
      </div>
      <div rufId fxFlex>
        <mat-card fxLayout="column" class="mail-info">
          <mat-icon class="icon_mail" rufId fontSet="fisfont-solid" fisIcon="email"></mat-icon>
          <div role="region" class="chex-page-left-right-alignment mat-body-2 mail-info" [innerHTML]="securityFreezeMailInfoContent | sanitizeHtml">
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="chex-page-left-right-alignment expansion-panel">
    <mat-expansion-panel fisStyle>
      <mat-expansion-panel-header fisStyle class="expansion-header-title">
        <mat-panel-title fisStyle class="expansion-title">{{ 'SECURITYFREEZEINFO.INFO1' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-body-2" [innerHTML]="hawaiiAndNewHampshireContent | sanitizeHtml">
      </div>
    </mat-expansion-panel>  
  </div>
</div>