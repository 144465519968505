import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@ruf/idp-auth';
import moment from 'moment';
import { environment } from '../../environments/environment';
import { GlobalVariables } from '../common/enums/global_variables';
import { PasswordValidation } from '../common/validation-common/password-validator';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import {
  ChangePassword,
  FieldMaskingShowHide,
  PasswordType,
} from '../_models/registration/IdpUser';
import { Registrationresource } from '../_models/registration/registrationresource.model';
import { DashboardService } from '../_services/dashboard-services/dashboard.service';
import { IdpService } from '../_services/registration/idp.service';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';

@Component({
  selector: 'chexsystem-ws-changepassword',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  disabled = false;
  showExtraClass = false;
  invalidErrorMessage: { [key: string]: string } =
    Registrationresource.invalidErrorMessage;
  dialogWidth: string = '596px';
  currentPasswordEyeIconType: string = FieldMaskingShowHide.Eye;
  currentPasswordEyeIconTooltip: string = FieldMaskingShowHide.Show;
  currentPasswordType = PasswordType.Password;
  newPasswordEyeIconType: string = FieldMaskingShowHide.Eye;
  newPasswordEyeIconTooltip: string = FieldMaskingShowHide.Show;
  newPasswordType = PasswordType.Password;
  confirmPasswordEyeIconType: string = FieldMaskingShowHide.Eye;
  confirmPasswordEyeIconTooltip: string = FieldMaskingShowHide.Show;
  confirmPasswordType = PasswordType.Password;
  title = this._translate.instant('CHANGE PASSWORD.TITLE');
  currentpswd = this._translate.instant('CHANGE PASSWORD.CURRENT PASSWORD');
  newpswd = this._translate.instant('CHANGE PASSWORD.NEW PASSWORD');
  confirmnewpswd = this._translate.instant(
    'CHANGE PASSWORD.CONFIRM NEW PASSWORD'
  );
  changepswd = this._translate.instant('BUTTONS.CHANGE PASSWORD');
  cancel = this._translate.instant('BUTTONS.CANCEL');

  constructor(
    private _formBuilder: FormBuilder,
    private idpService: IdpService,
    private dialog: MatDialog,
    private auth: AuthService,
    private piConfirmService: PersonalInformationConfirmationService,
    private router: Router,
    private dashboardService: DashboardService,
    private _translate: TranslateService
  ) {
    console.log(
      'firstname value: ' + GlobalVariables.globalvars.get('FirstName')
    );
    this.changePasswordFormGroup = this._formBuilder.group(
      {
        currentpwdCtrl: ['', Validators.required],
        pwdCtrl: ['', Validators.required],
        confirmpwdCtrl: ['', Validators.required],
      },
      {
        validators: [
          PasswordValidation.MatchPassword('pwdCtrl', 'confirmpwdCtrl'),
          PasswordValidation.ShouldNotMatchPassword(
            'currentpwdCtrl',
            'pwdCtrl'
          ),
          PasswordValidation.PasswordRestrict(
            'pwdCtrl',
            GlobalVariables.globalvars.get('UserId') || '',
            this.changePasswordFormGroup,
            GlobalVariables.globalvars.get('FirstName') || '',
            GlobalVariables.globalvars.get('MiddleName') || '',
            GlobalVariables.globalvars.get('LastName') || '',
            false
          ),
        ],
      }
    );
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('chpassword');
  }

  ChangePassword() {
    if (this.changePasswordFormGroup.valid) {
      let changePasswordRequest: ChangePassword = <ChangePassword>{
        oldPassword: this.changePasswordFormGroup.controls['currentpwdCtrl'].value,
        newPassword: this.changePasswordFormGroup.controls['pwdCtrl'].value,
        confirmPassword: this.changePasswordFormGroup.controls['confirmpwdCtrl'].value
      };

      this.idpService.changePassword(changePasswordRequest).subscribe(
        (changePasswordResponse) => {
          const dialogMessage = new DialogMessage(
            'Password has been successfully changed. <br> Click OK to log in with your new password.',
            'OK',
            false,
            Registrationresource.changepassword_complete_title
          );
          const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
            data: dialogMessage,
            panelClass: ['fis-style', 'custom-dialog-without-close'],
            disableClose: true,
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if ((result as string) == 'OK') {
              GlobalVariables.globalvars.clear();
              localStorage.clear();
              sessionStorage.removeItem('ProfileConfirmed');
              let qp = moment().unix();
              window.location.href =
                environment.globalLogoutUrl +
                environment.auth.clientId +
                '&random=' +
                qp;
            }
          });
        },
        (changePasswordError) => {}
      );
    } else {
    }
  }

  Cancel() {
    this.router.navigate(['dashboard']);
  }
  _handleCurrentMasking(code: string, control4: any) {
    if (code === 'password') {
      if (this.currentPasswordEyeIconType === FieldMaskingShowHide.Eye) {
        this.currentPasswordEyeIconType = FieldMaskingShowHide.Normal;
        this.currentPasswordEyeIconTooltip = FieldMaskingShowHide.Hide;
        this.currentPasswordType = PasswordType.Text;
      } else if (
        this.currentPasswordEyeIconType === FieldMaskingShowHide.Normal
      ) {
        this.currentPasswordEyeIconType = FieldMaskingShowHide.Eye;
        this.currentPasswordEyeIconTooltip = FieldMaskingShowHide.Show;
        this.currentPasswordType = PasswordType.Password;
      }
    }
  }
  _handleNewPasswordMasking(code: string, control4: any) {
    if (code === 'password') {
      if (this.newPasswordEyeIconType === FieldMaskingShowHide.Eye) {
        this.newPasswordEyeIconType = FieldMaskingShowHide.Normal;
        this.newPasswordEyeIconTooltip = FieldMaskingShowHide.Hide;
        this.newPasswordType = PasswordType.Text;
      } else if (this.newPasswordEyeIconType === FieldMaskingShowHide.Normal) {
        this.newPasswordEyeIconType = FieldMaskingShowHide.Eye;
        this.newPasswordEyeIconTooltip = FieldMaskingShowHide.Show;
        this.newPasswordType = PasswordType.Password;
      }
    }
  }
  _handleConfirmPasswordMasking(code: string, control4: any) {
    if (code === 'password') {
      if (this.confirmPasswordEyeIconType === FieldMaskingShowHide.Eye) {
        this.confirmPasswordEyeIconType = FieldMaskingShowHide.Normal;
        this.confirmPasswordEyeIconTooltip = FieldMaskingShowHide.Hide;
        this.confirmPasswordType = PasswordType.Text;
      } else if (
        this.confirmPasswordEyeIconType === FieldMaskingShowHide.Normal
      ) {
        this.confirmPasswordEyeIconType = FieldMaskingShowHide.Eye;
        this.confirmPasswordEyeIconTooltip = FieldMaskingShowHide.Show;
        this.confirmPasswordType = PasswordType.Password;
      }
    }
  }
}
