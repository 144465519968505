    <div  class="chex-bottom_space">
      <ruf-page-header role="region" aria-label="title" class="header_text" rufId="page_header_disputeTab_title" title="{{ 'PLACESECURITYALERT.TITLE' | translate }}" description=" " ></ruf-page-header>

      <div role="region" aria-label="placeSecurityAlertContent" class="chex-page-left-right-alignment mat-body-2" [innerHTML]="placeSecurityAlertContent | sanitizeHtml">
      </div>

      <form [formGroup]="formGroup"> 
        <div role="region"  class="chex-page-left-right-alignment mat-body-2"><h3>{{ 'PLACESECURITYALERT.QUESANS' | translate }}</h3></div>
        <div role="region"  class="chex-page-left-right-alignment mat-body-2">{{ 'PLACESECURITYALERT.INFO11' | translate }}</div>
        <div class="chex-page-left-right-alignment mat-body-2" rufMarginBottom>
          <mat-radio-group  fisStyle name="more_options"   formControlName="questionCtrl" 
          rufId="question_radio_group" [(ngModel)]="selectedQuestion">
                <mat-radio-button 
                class="chex-page-left-right-alignment chex-question-radiolist mat-body-2" 
                *ngFor="let alertQ of items"  
                name="more_options" 
                [value]="alertQ.questionCode"
                [checked]="alertQ.checked"
                (change)="radioChange(alertQ)"
                >
                  {{alertQ.question}}
                </mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="invalidQuestion">{{ 'PLACESECURITYALERT.QUESREQ' | translate }}</mat-error>
        </div>
        
        <div class="chex-page-left-right-alignment mat-body-2" >
         
          <div role="region"  class="mat-body-2">{{ 'PLACESECURITYALERT.INFO12' | translate }}</div>
          <mat-form-field class="chex-answer-form_box" boxStyle floatLabel="always">
            <input class="pin_input" id="textAnswer" formControlName="txtAnswer" fisStyle matInput placeholder="Your Answer" required maxlength="50" />
            <mat-error *ngIf="formGroup.get('txtAnswer')?.hasError('required')">{{ 'PLACESECURITYALERT.ANSREQ' | translate }}</mat-error>
            <mat-error
                *ngIf="
                formGroup.controls['txtAnswer'].errors
                    ?.invalidChar
                "
                >{{ invalidCharErrorMessage }}</mat-error>
          </mat-form-field>                
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical rufId>
          <div role="region" aria-label="submit" class="chex-page-left-right-alignment">
            <button id="submitBtn" mat-raised-button fisStyle color="primary" rufId ruf-stepper-actions type="submit"  (click)="submitclick()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
