import { Component, HostListener, OnInit } from '@angular/core';
// import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { AuthService } from '@ruf/idp-auth';
// import { RegistrationComponent } from './registration/registration.component';
import { TranslateService } from '@ngx-translate/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { DialogIdleTimerComponent } from './dailog/dialog-idle-timer/dialog-idle-timer.component';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogIdleTimerAfterLoginComponent,
  DialogIdleTimerMessage,
} from './dailog/dialog-idle-timer-after-login/dialog-idle-timer-after-login.component';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { DashboardService } from './_services/dashboard-services/dashboard.service';
import { GlobalVariables } from './common/enums/global_variables';
import { IdaQuizService } from './_services/registration/ida-quiz.service';
import {
  UserDetail,
  UserLookupRequest,
} from './_models/registration/UserDetail';
import { userinformation } from './_models/classes/user-information.model';
import { MyTel } from './common/controls/phone-control/phone-control.component';
import moment from 'moment';
import { createHistogram } from 'perf_hooks';
// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function;

@Component({
  selector: 'chexsystem-ws-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'chexsystem';
  auths = new Set();
  firstname: string;
  lastname: string;
  mname: string;
  claimsReturned: any;
  claims$: any;
  label = 'Jon Doe';
  userId: string;
  isUserLoggedin = false;
  idleWatchStarted = false;
  idleTimerStarted = false;
  sessionTimeoutTimer: any;
  isScreenIdlePopupOpened: boolean = false;
  idleText: string = '';
  userDisclosureDetail: UserDetail;
  userInformation: userinformation.UserInformationRoot =
    new userinformation.UserInformationRoot();
  ssnNo: string;

  constructor(
    private auth: AuthService,
    public translate: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog,
    private router: Router,
    private dashboardService: DashboardService,
    private idaQuizService: IdaQuizService
  ) {
    // Google Analytics code
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        /** START : Code to Track Page View  */
        let isReg = event.url == '/registration' ? true : false;
        if (isReg) {
          localStorage.setItem('isReg', 'true');
        } else {
          localStorage.setItem('isReg', 'false');
        }
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
        /** END */
      });
    // end of Google Analytics code

    this.auth.authorizations$.pipe().subscribe((auths) => (this.auths = auths));
    this.claims$ = this.auth.claims$;
    if (this.claims$ != undefined) {
      this.claims$.subscribe((value: any) => {
        this.claimsReturned = JSON.parse(value);
        console.log('claims*****', this.claimsReturned);
        this.firstname = this.claimsReturned.FirstName;
        this.lastname = this.claimsReturned.LastName;
        this.mname = this.claimsReturned.middleName;
        this.label = this.firstname + ' ' + this.lastname;
        this.userId = this.claimsReturned.LoginName;

        if (GlobalVariables.globalvars.has('UserId')) {
          GlobalVariables.globalvars.delete('UserId');
        }
        GlobalVariables.globalvars.set('UserId', this.userId);

        if (GlobalVariables.globalvars.has('FirstName')) {
          GlobalVariables.globalvars.delete('FirstName');
        }
        GlobalVariables.globalvars.set('FirstName', this.firstname);

        if (GlobalVariables.globalvars.has('LastName')) {
          GlobalVariables.globalvars.delete('LastName');
        }
        GlobalVariables.globalvars.set('LastName', this.lastname);

        if (GlobalVariables.globalvars.has('MiddleName')) {
          GlobalVariables.globalvars.delete('MiddleName');
        }
        GlobalVariables.globalvars.set('MiddleName', this.mname);

        this.isUserLoggedin = true;

        localStorage.setItem('isLoggedin', 'true');

        let expiryTime =
          Math.floor(this.auth.tokenExpirationTime() / 1000) -
          Math.floor(new Date().getTime() / 1000);

        if (!this.idleWatchStarted) {
          // sets an idle timeout before idp session timeout
          idle.setIdle(expiryTime - environment.loggedinSessionTimeoutSurplus);

          // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
          idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

          idle.onIdleStart.subscribe(() => {
            //check and allow to open the idle popup
            if (this.isScreenIdlePopupOpened) return;
            this.idleTimerStarted = true;
            this.sessionTimeoutTimer = setTimeout(() => {
              this.logOutAndClean();
            }, 1000 * environment.loggedinSessionTimeoutSurplus);

            this.idleText =
              'Your session will expire in ' +
              environment.loggedinSessionTimeoutSurplus / 60 +
              ' minute due to inactivity.';
            const dialogMessage = new DialogIdleTimerMessage(
              this.idleText,
              'Resume Session',
              'Logout',
              'Session Timeout Warning'
            );
            const dialogRef = this.dialog.open(
              DialogIdleTimerAfterLoginComponent,
              {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              }
            );
            this.isScreenIdlePopupOpened = true;
            dialogRef.afterClosed().subscribe((result) => {
              // set false while close
              this.isScreenIdlePopupOpened = false;

              if ((result as string) === 'resume') {
                this.auth.refreshToken();
                clearTimeout(this.sessionTimeoutTimer);
                this.reset();
              } else if ((result as string) === 'logout') {
                clearTimeout(this.sessionTimeoutTimer);
                this.logOutAndClean();
              }
            });
          });

          //this interval is set to call keep alive session before idp session timeout when user is active
          keepalive.interval(15);
          this.reset();
        }
        this.setUserInformation();
      });
    }

    

    // Register translation languages
    translate.addLangs(['en']);
    // Set default language
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        if (
          localStorage.isLoggedin == undefined &&
          localStorage.isReg != 'true'
        ) {
          this.logOutAndClean();
          // GlobalVariables.globalvars.clear();
          // localStorage.clear();
          // window.location.reload();
        }
      }
    });
  }

  logOutAndClean() {
    GlobalVariables.globalvars.clear();
    localStorage.clear();
    sessionStorage.removeItem('ProfileConfirmed');
    let qp = moment().unix();
    let logoutUrl =
      environment.globalLogoutUrl + environment.auth.clientId + '&random=' + qp;
    window.location.href = logoutUrl;
  }

  reset() {
    this.idle.watch();
    this.idleWatchStarted = true;
  }

  setUserInformation() {
    if (!GlobalVariables.globalvars.has('UserInformation')) {
      var _userName = GlobalVariables.globalvars.get('UserId') || '';
      let lookupRequest: UserLookupRequest = new UserLookupRequest();
      lookupRequest.UserLookupReq = { UserName: _userName, Cid: '' };
      this.idaQuizService
        .getUserDetailFromDecisionSolution(lookupRequest)
        .subscribe(
          (res) => {
            this.userDisclosureDetail = res;
            this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
            /////////////////////SuffixNm
            //alert(this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls);
            this.userInformation.CnsmrPrsnlDetls =
              new userinformation.CnsmrPrsnlDetls();
            this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls =
              new userinformation.CnsmrDrivLicenseDetls();
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls =
              new userinformation.CnsmrNmDetls();
            if (
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls
                ?.CnsmrDrivLicenseDetls
            ) {
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
                this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls?.CnsmrDrivLicenseDetls?.DrivLicenseIssuSt;
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
                this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls?.CnsmrDrivLicenseDetls?.DrivLicenseNbr;
            } else {
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
                '';
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
                '';
            }
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm;
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm;
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm;
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastNm;
            this.userInformation.CnsmrPrsnlDetls.GovtNbr =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr;
            //this.userInformation.CnsmrPrsnlDetls.GovtNbr = "491713274";//Harsh
            //this.userInformation.CnsmrPrsnlDetls.PhoneNbr = this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr;
            this.userInformation.CnsmrPrsnlDetls.BirthDt =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.BirthDt;
            this.userInformation.CnsmrAddrDetls =
              this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls;
            //this.userInformation.CellPhoneNbr = this.ConvertPhoneFormate(this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr);
            this.userInformation.UserName = _userName;
            this.userInformation.UserID =
              this.userDisclosureDetail.UserLookupRsp.Id;
            this.userInformation.CorrespondenceDeliveryPreference =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CorrespondenceDlvryPref;
            this.userInformation.NotificationEmailAddr =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr;
            //localStorage.setItem("UserInformation", JSON.stringify(this.userInformation));
            //////////////////////
            let usSSNumber = this.ConvertUSSSNFormate(
              res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr
            );

            let cellPhoneNumber: MyTel = new MyTel('', '', '');
            let phoneType: string = '';
            if (res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() !== '') {
              phoneType = 'Mobile';
              cellPhoneNumber = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr
              );
            } else if (res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr !== '') {
              phoneType = 'Phone';
              cellPhoneNumber = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr
              );
            }
            let cellularPhoneFormat: MyTel = new MyTel('', '', '');
            let homePhoneFormat: MyTel = new MyTel('', '', '');
            let cellularPhone: string = '';
            let homePhone: string = '';
            if (res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() !== '') {
              cellularPhoneFormat = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr
              );
              cellularPhone =
                cellularPhoneFormat.area +
                cellularPhoneFormat.exchange +
                cellularPhoneFormat.subscriber;
            }
            if (res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr.trim() !== '') {
              homePhoneFormat = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr
              );
              homePhone =
                homePhoneFormat.area +
                homePhoneFormat.exchange +
                homePhoneFormat.subscriber;
            }

            this.userInformation.CellPhoneNbr = cellularPhone;
            this.userInformation.CnsmrPrsnlDetls.PhoneNbr = homePhone;
            // localStorage.setItem(
            //   'UserInformation',
            //   JSON.stringify(this.userInformation)
            // );

            if (GlobalVariables.globalvars.has('UserInformation')) {
              GlobalVariables.globalvars.delete('UserInformation');
            }
            GlobalVariables.globalvars.set(
              'UserInformation',
              JSON.stringify(this.userInformation)
            );
          },
          (error) => {},
          () => {}
        );
    }
  }

  private ConvertUSSSNFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{2})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatedPhoneNumber[0],
      formatedPhoneNumber[1],
      formatedPhoneNumber[2]
    );
  }
  private ConvertPhoneFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formatePhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatePhoneNumber[0],
      formatePhoneNumber[1],
      formatePhoneNumber[2]
    );
  }
  getLandingPage() {
    return 'registration';
  }
  onActivate(event: any) {
    window.scrollTo(0, 0);
  }
}
