export class SiteCoreNodePathResponseData {
  data: Data;
}

export class Data {
  item: Item;
}

export class Item {
  id:        string;
  path:      string;
  name:      string;
  children?: Item[];
  fields?:   Field[];
}

export class Field {
  id:    string;
  name:  string;
  value: string;
}

export class State {
  name: string;
  link: string;
}

export class States{
  states: State[];
}
