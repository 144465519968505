<div class="chex-bottom_space">
    <ruf-page-header role="region" aria-label="title" class="header_text" title="{{ 'SECURITYFREEZENOTICE.TITLE' | translate }}" description=" " ></ruf-page-header>
<div role="region"  class="chex-page-left-right-alignment mat-body-2" [innerHTML]="topText | sanitizeHtml">
</div>
<ruf-statusbar role="region" aria-label="info2" class="chex-page-left-right-alignment chex-notice-information_text" color="info" [closeable]="false" [border]="false" [icon]="'information'">
    <div class="mat-body-2" [innerHTML]="statusBarText | sanitizeHtml"></div>
</ruf-statusbar>
<div>
    <div role="region"  class="chex-page-left-right-alignment mat-subheading-2">{{ 'SECURITYFREEZENOTICE.INFO6' | translate }}</div>
    <div fxLayout="row">
        <div class="chex-page-left-right-alignment mat-body-2" *ngFor="let column of columns">
            <div fxLayout="column">
                <a [href]="state.link" target="_blank" *ngFor="let state of statesLink.slice(column*10,(column*10)+10)">
                    <u>{{state.name}}</u>
                </a>
            </div>
        </div>
    </div>
</div>
</div>