<div class="chex-bottom_space">
  <ruf-page-header role="region" aria-label="title" rufId="page_header_aboutus_title" [title]="aboutustitle" description=" "></ruf-page-header>
  <div role="region" aria-label="aboutusdesc" class="chex-page-left-right-alignment mat-body-2 chx-body-text">
    <div>{{ 'ABOUTUS.DESCRIPTION' | translate }} </div>
  </div>
  <div role="region" aria-label="faqAriaDesc" class="chex-page-left-right-alignment mat-body-2 chx-body-text">
    <div [innerHTML]="faqDesc"></div>
  </div>
  <ruf-statusbar role="region" aria-label="aboutusstatusbar" class="chex-page-left-right-alignment information_text" color="info" [closeable]="false" [border]="false" [icon]="'information'">
    {{ 'ABOUTUS.STATUSBAR' | translate }}
  </ruf-statusbar>
  <div role="region" aria-label="aboutusinfo" class="chex-page-left-right-alignment mat-subheading-2 chx-aboutus-subheading-div">{{ 'ABOUTUS.INFO' | translate }}</div>
  <div role="region" aria-label="aboutusinfo1" class="chex-page-left-right-alignment">
    <ul>
      <li class="points mat-body-2"><span>{{ 'ABOUTUS.INFO1' | translate }}</span></li>
      <li class="points mat-body-2"><span>{{ 'ABOUTUS.INFO2' | translate }}</span></li>
      <li class="points mat-body-2"><span>{{ 'ABOUTUS.INFO3' | translate }}</span></li>
    </ul>
  </div> 