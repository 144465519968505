<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
  <ruf-dialog-header role="region" aria-label="title" rufId="simple_dialog_header" #header>{{ 'DIALOGTERMSANDCONDITIONS.TITLE' | translate }}</ruf-dialog-header>
    <ruf-dialog-content role="region" aria-label="terms" id="termstxt" rufId="dialog_content"  rufScrollbar [autoHide]=true>
      
      <div role="region" #divtermsandconditions aria-label="termsAndConditionsContent" class="chex-page-left-right-alignment mat-body-2" [innerHTML]="termsAndConditionsContent | sanitizeHtml">
      </div>
      
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer" class="center">
      
        <button role="region" aria-label="close" id="closeBtn" mat-raised-button fisStyle color="primary"  rufId="dialog_footer_button" (click)="onSubmit()">{{ 'BUTTONS.CLOSE' | translate }}</button>
        <a [attr.href]="downloadPdfHrefLink" target="_blank" class="anchorLink" #downloadpdf><button role="region" aria-label="downloadpdf" id="downloadBtn" mat-raised-button fisStyle color="primary"  rufId="dialog_footer_button">{{ 'BUTTONS.DOWNLOADPDF' | translate }}</button></a>
     
    </ruf-dialog-footer>
  </ruf-dialog-panel>
