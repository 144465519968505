import { Injectable } from '@angular/core';
import { ApiService } from '../shared/';
import {  Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import { searchfreezeexitenceresponse } from '../../_models/classes/searchfreezeexistence-reponse.model';
import { searchfreezeexitencerequest } from '../../_models/classes/searchfreezeexistence-request.model';
import { LookupFreezeResponse } from '../../_models/classes/lookupfreeze-reponse.model';
import { LookupFreezeRequest } from '../../_models/classes/lookupfreeze-request.model';
import { ViewSecurityAlertRequestData } from '../../_models/classes/viewsecurityalert-request.model';
import { ViewSecurityAlertResponseData } from '../../_models/classes/viewsecurityalert-reponse.model';
import { ValidateSecurityAlertRequestData } from '../../_models/classes/validatesecurityalert-request.model';
import { ValidateSecurityAlertResponseData } from '../../_models/classes/validatesecurityalert-reponse.model';
import { TMSessionData } from '../../_models/classes/threatmetrixsession-reponse.model';
import { Registrationresource } from '../../_models/registration/registrationresource.model';

@Injectable({
  providedIn: 'root',
})
export class SecurityValidationService {
  private readonly Api_Base_URL = environment.apiurl;

  constructor(private _apiService: ApiService) {}

  public GetTMSession(sessionid: string): Observable<TMSessionData> {
    const url = this.Api_Base_URL.concat('/session').concat('/' + sessionid);
    return this._apiService.get<TMSessionData>(url);
  }

  public SrchFrzExistence(
    searchFreezeExitenceRequestModel: searchfreezeexitencerequest
  ): Observable<searchfreezeexitenceresponse> {
    const url = this.Api_Base_URL.concat('/SrchFrzExistence');
    return this._apiService.post<searchfreezeexitenceresponse>(
      url,
      searchFreezeExitenceRequestModel
    );
  }

  public LookupFreeze(
    lookupFreezeRequestModel: LookupFreezeRequest
  ): Observable<LookupFreezeResponse> {
    const url = lookupFreezeRequestModel.isReg ? this.Api_Base_URL.concat('/LookupFreezeForReg'): this.Api_Base_URL.concat('/LookupFreeze');
    return lookupFreezeRequestModel.isReg ? this._apiService.postWithCustomHeader<LookupFreezeResponse>(
      url,
      lookupFreezeRequestModel,
      lookupFreezeRequestModel?.RecapchaToken
    ) : this._apiService.post<LookupFreezeResponse>(
      url,
      lookupFreezeRequestModel)
  }

  public ViewSecurityAlert(
    viewSecurityAlertRequestData: any
  ): Observable<any> {
    const url = this.Api_Base_URL.concat('/ViewSecurityAlert');
    return this._apiService.post<any>(
      url,
      viewSecurityAlertRequestData
    );
  }

  public ValidateSecurityAlert(
    validateSecurityAlertRequestData: any
  ): Observable<any> {
    const url = validateSecurityAlertRequestData?.validateSecurityAlertObj?.validateSecurityAlertRequest.isReg ? this.Api_Base_URL.concat('/ValidateSecurityAlertForReg') : this.Api_Base_URL.concat('/ValidateSecurityAlert');
    return validateSecurityAlertRequestData?.validateSecurityAlertObj?.validateSecurityAlertRequest.isReg? this._apiService.postWithCustomHeader<any>(
      url,
      validateSecurityAlertRequestData,
      validateSecurityAlertRequestData?.validateSecurityAlertObj?.validateSecurityAlertRequest?.recapchaToken
    ): this._apiService.post<any>(
      url,
      validateSecurityAlertRequestData
    );
  }

public SrchFrzExistenceForReg(
    searchFreezeExitenceRequestModel: searchfreezeexitencerequest
  ): Observable<searchfreezeexitenceresponse> {
    const url = this.Api_Base_URL.concat('/SrchFrzExistenceForReg');
    return this._apiService.postWithCustomHeader<searchfreezeexitenceresponse>(
      url,
      searchFreezeExitenceRequestModel,
      searchFreezeExitenceRequestModel?.RecapchaToken
    );
  }

  public ViewSecurityAlertForReg(
    viewSecurityAlertRequestData: ViewSecurityAlertRequestData
  ): Observable<ViewSecurityAlertResponseData> {
    const url = this.Api_Base_URL.concat('/ViewSecurityAlertForReg');
    return this._apiService.postWithCustomHeader<ViewSecurityAlertResponseData>(
      url,
      viewSecurityAlertRequestData,
      viewSecurityAlertRequestData?.viewSecurityAlertRequest?.recapchaToken
    );
  }

  // public GetTMSessionWithCaptcha(
  //   sessionid: string,
  //   reCaptchaToken: any,    
  // ): Observable<TMSessionData> {
  //   const url = this.Api_Base_URL.concat('/session').concat('/' + sessionid);
  //   return this._apiService.getWithCustomHeader<TMSessionData>(
  //     url,
  //     reCaptchaToken,      
  //   );
  // }

  public GetTMSessionWithCaptcha(
    model: any,  
  ): Observable<TMSessionData> {
    const url = this.Api_Base_URL.concat('/session');
    return this._apiService.postWithCustomHeader<TMSessionData>(
      url,
      model,
      model.recaptchaToken     
    );
  }

  public IsValidSSN(area: string,exchange: string,subscriber: string): boolean {
    let ssnValue = area + '-' + exchange + '-' + subscriber;
    if(Registrationresource.InvalidSSN.some(invalidValue => invalidValue == ssnValue)) {
      return false;
    }
    if(area.indexOf('000') > -1 || exchange.indexOf('00') > -1 || subscriber.indexOf('0000') > -1) {
      return false;
    }
    for(let i=0;i<Registrationresource.InvalidChars.length;i++){
      if(ssnValue.indexOf(Registrationresource.InvalidChars[i]) > -1){
        return false;
      }
    }
    return true;
  }

  public IsValidPhoneNumber(area: string,exchange: string,subscriber: string): boolean {
    let phoneNumber = area + '-' + exchange + '-' + subscriber;
    if(Registrationresource.InvalidPhoneNumber.some(invalidValue => invalidValue == phoneNumber)) {
      return false;
    }
    for(let i=0;i<Registrationresource.InvalidChars.length;i++){
      if(phoneNumber.indexOf(Registrationresource.InvalidChars[i]) > -1){
        return false;
      }
    }
    return true;
  }
}
