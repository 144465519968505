import { Component, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { Timerdetail, TimerOption } from '../../_models/classes/timerdetail.model';

@Component({
  selector: 'quiz-timer',
  templateUrl: './quiz-timer.component.html',
  styleUrls: [ './quiz-timer.component.scss' ]
})
export class QuizTimerComponent  {
  private _info!: Timerdetail;
  private totalTimeInSeconds: number = 0;
  minutesLeft: number = 0;
  secondsLeft: number = 0;
  time=this._translate.instant('QUIZTIMER.TIME');
  minutes=this._translate.instant('QUIZTIMER.MINUTES');
  second=this._translate.instant('QUIZTIMER.SECONDS');

  @Input()
  set info(value:Timerdetail){
    this._info = value;
    this.checkType(this._info.type);
  }
  @Output()
  timerEnd: EventEmitter<number> = new EventEmitter<number>();
  get info():Timerdetail
  {
    return this._info;
  }

  constructor(
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }
  public checkType(timerOption: TimerOption){
    if(timerOption === TimerOption.Countdown)
      this.startTimer();
    else if(timerOption === TimerOption.Clear)
      this.clear();
    else if(timerOption === TimerOption.Pause)
      this.pause();
  }
  pause() {
    if(this.info.id){
      this.info.minutes = this._info.minutes;
      this.info.seconds = this._info.seconds;
      clearInterval(this.info.id);
      this.info.id = undefined;
    }
  }
  clear() {
    if(this.info.id){
      clearInterval(this.info.id);
    }
    this.clearInterval();
  }
  clearInterval() {   
    if(this.info.id){
      clearInterval(this.info.id);
    }
      
    this.info.id = undefined;
  }
  startTimer() {
    this.totalTimeInSeconds = this._info.totalTime;
    this.clearInterval();
    this._info.id = setInterval( () => {
      if(this.totalTimeInSeconds > 0) {
        this.totalTimeInSeconds--;
        this.minutesLeft = Math.floor(this.totalTimeInSeconds / 60);
        this.secondsLeft = this.totalTimeInSeconds - this.minutesLeft * 60;
        this.info.minutes = this.minutesLeft;
        this.info.seconds = this.secondsLeft;
      } else {
        this.clearInterval(); 
        this.timerEnd.emit(this.totalTimeInSeconds);       
      }
    }, 1000);
  }
}
