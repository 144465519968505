import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<any>> = new Map();
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(req.method.toUpperCase() === "POST" && req.url.toLowerCase().indexOf('sitecore') > -1)) {
      return next.handle(req);
    }
    let itemPath = (<SitecoreRequestData>req.body).itempath;
    let key = new Date().getHours().toString() + itemPath;
    const cachedResponse: HttpResponse<any> | undefined = this.cache.get(key);
    if (cachedResponse) {
      return of(cachedResponse.clone())
    } else {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            for (var i = 0; i <= 23; i++) {
              let keyValue = i + itemPath;
              if (this.cache.has(keyValue))
                this.cache.delete(keyValue);
            }
            this.cache.set(key, event);
          }
        }));
    }
  }
}
