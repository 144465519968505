import {Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector:'[dlMasking]'
})
export class DLMaskingDirective{
  private value: any;  //the variable where we save the "true value"
  private element: HTMLInputElement
  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }
  ngOnInit() { 
    this.value = this.element.value;
  }
  @HostListener('input') onChange() {  
    this.value = this.element.value;      
  }
  @HostListener('blur') onBlur() { 
    this.formatValue();
  }
  @HostListener('focus') onFocus() { 
    this.element.value = this.value;
  }
  formatValue() {
    this.element.value  = this.element.value
      .slice(0, this.element.value.length - 4)
      .replace(/([a-zA-Z0-9])/g, '*') + this.element.value.slice(-4);

   
  }

}