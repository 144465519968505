import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
  Output, EventEmitter
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';

/** Data structure for holding telephone number. */
export class MyTel {
  constructor(
    public area: string,
    public exchange: string,
    public subscriber: string
  ) { }
}

@Component({
  selector: 'chexsystem-ws-phonecontrol',
  templateUrl: './phone-control.component.html',
  styleUrls: ['./phone-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: PhonecontrolComponent },
  ],
})
export class PhonecontrolComponent
  implements ControlValueAccessor, MatFormFieldControl<MyTel>, OnDestroy {
  static nextId = 0;
  @ViewChild('area') areaInput!: ElementRef<HTMLElement>;
  @ViewChild('exchange') exchangeInput!: ElementRef<HTMLElement>;
  @ViewChild('subscriber') subscriberInput!: ElementRef<HTMLElement>;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  // controlType = 'chexsystem-ws-phonecontrol';
  id = `chexsystem-ws-phonecontrol-${PhonecontrolComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };
  _placeholder!: string;
  formBuilder: FormBuilder;
  eyeIconType: string = 'eye';
  eyeIconTooltip: string = 'show';
  allowedcharacters: Array<number> = [8, 9, 10, 16, 37, 38, 39, 40, 46];

  public get empty() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return !area && !exchange && !subscriber;
  }

  public get full() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return area.length == this.part1maxlength && exchange.length == this.part2maxlength && subscriber.length == this.part3maxlength;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby')
  userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get part1maxlength(): number {
    return this._part1maxlength;
  }
  set part1maxlength(value: number) {
    this._part1maxlength = value;
    this.parts.controls['area'].setValidators([
      Validators.required,
      Validators.minLength(value),
      Validators.maxLength(value),
    ]);
    this.stateChanges.next();
  }
  private _part1maxlength: number;

  @Input()
  get part2maxlength(): number {
    return this._part2maxlength;
  }
  set part2maxlength(value: number) {
    this._part2maxlength = value;
    this.parts.controls['exchange'].setValidators([
      Validators.required,
      Validators.minLength(value),
      Validators.maxLength(value),
    ]);
    this.stateChanges.next();
  }
  private _part2maxlength: number;

  @Input()
  get part3maxlength(): number {
    return this._part3maxlength;
  }
  set part3maxlength(value: number) {
    this._part3maxlength = value;
    this.parts.controls['subscriber'].setValidators([
      Validators.required,
      Validators.minLength(value),
      Validators.maxLength(value),
    ]);
    this.stateChanges.next();
  }
  private _part3maxlength: number;

  @Input()
  get part1placeholder(): string {
    return this._part1placeholder;
  }
  set part1placeholder(value: string) {
    this._part1placeholder = value;
    this.stateChanges.next();
  }
  private _part1placeholder: string;

  @Input()
  get part2placeholder(): string {
    return this._part2placeholder;
  }
  set part2placeholder(value: string) {
    this._part2placeholder = value;
    this.stateChanges.next();
  }
  private _part2placeholder: string;

  @Input()
  get part3placeholder(): string {
    return this._part3placeholder;
  }
  set part3placeholder(value: string) {
    this._part3placeholder = value;
    this.stateChanges.next();
  }
  private _part3placeholder: string;

  @Input()
  get inputtype(): string {
    return this._inputtype;
  }
  set inputtype(value: string) {
    this._inputtype = value;
    this.stateChanges.next();
  }
  private _inputtype: string;

  @Input()
  get displaymode(): boolean {
    return this._displaymode;
  }
  set displaymode(value: boolean) {
    this._displaymode = value;
    this.stateChanges.next();
  }
  private _displaymode: boolean = false;

  @Input()
  get value(): MyTel | null {
    if (this.parts.valid) {
      const {
        value: { area, exchange, subscriber },
      } = this.parts;
      return new MyTel(area, exchange, subscriber);
    }
    return null;
  }

  @Output() errorMessage = new EventEmitter<{ message: string, value: MyTel }>();
  subscriberinputtype: string = '';

  set value(tel: MyTel | null) {
    const { area, exchange, subscriber } = tel || new MyTel('', '', '');
    this.parts.setValue({ area, exchange, subscriber });
    this.subscriberinputtype = this.displaymode ? 'text' : this.inputtype;
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      area: [
        null,
        [
          Validators.required,
          Validators.minLength(this.part1maxlength),
          Validators.maxLength(this._part1maxlength),
        ],
      ],
      exchange: [
        null,
        [
          Validators.required,
          Validators.minLength(this.part2maxlength),
          Validators.maxLength(this.part2maxlength),
        ],
      ],
      subscriber: [
        null,
        [
          Validators.required,
          Validators.minLength(this.part3maxlength),
          Validators.maxLength(this.part3maxlength),
        ],
      ],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOninit() {
    this.subscriberinputtype = this.displaymode ? 'text' : this.inputtype;
   }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.chex-ssncontrol-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
  }

  writeValue(tel: MyTel | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleValidation(): void {
    this.areaInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-area-error');
    this.exchangeInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-exchange-error');
    this.subscriberInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-subscriber-error');
    this._focusMonitor.focusVia(this.subscriberInput, 'program');
    this.subscriberInput.nativeElement.blur();
  }

  public handleMasking(inputtype: string): void {
    if (this.displaymode) {
      this.areaInput.nativeElement.setAttribute('type', inputtype);
      this.exchangeInput.nativeElement.setAttribute('type', inputtype);
    }
    else {
      this.areaInput.nativeElement.setAttribute('type', inputtype);
      this.exchangeInput.nativeElement.setAttribute('type', inputtype);
      this.subscriberInput.nativeElement.setAttribute('type', inputtype);
    }
  }

  public ChangeErrorStyles(error: boolean): void {
    if (error) {
      this.areaInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-area-error');
      this.exchangeInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-exchange-error');
        this.subscriberInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-subscriber-error');
      }
    else {
      this.areaInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-area');
      this.exchangeInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-exchange');
      this.subscriberInput.nativeElement.setAttribute('class', 'chex-ssncontrol-input-element mat-body-2 chex-subscriber');
    }
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (control.errors?.minlength) {
      this.errorMessage.emit({ message: 'minlength', value: new MyTel(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.empty) {
      this.errorMessage.emit({ message: '', value: new MyTel(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.full) {
      this.errorMessage.emit({ message: 'checkformatch', value: new MyTel(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }

    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  onBlur(control: AbstractControl) {
    if (control.errors?.minlength) {
      this.errorMessage.emit({ message: 'minlength', value: new MyTel(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.empty) {
      this.errorMessage.emit({ message: '', value: new MyTel(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.full) {
      this.errorMessage.emit({ message: 'checkformatch', value: new MyTel(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && this.allowedcharacters.indexOf(charCode) == -1) {
      event.preventDefault();
      return false;
    } else {
      if (event.target.type == 'password' && this.allowedcharacters.indexOf(charCode) == -1) {
        event.target.type = 'text';
        window.setTimeout(() => {
          event.target.type = 'password';
        }, 500);
      }
      return true;
    }
  }

}
