import { Component, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import {  OnInit, DoCheck, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { RufDynamicMenubarItem } from '@ruf/shell';
import { MatDialog } from '@angular/material/dialog';
import { PersonalInformationConfirmationService } from '../../../_services/shared/pi-confirmation.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'login-menubar-on-header',
  templateUrl: './after-login-menu.component.html',
  styleUrls: ['./after-login-menu.component.scss'],
})
export class AfterLoginMenuOnHeaderComponent {
  selectedPath: string="COMMON";
  dialogWidth:string = "477px";
  dialogheight: string = "500px";
  menuState:boolean = true;
  educationURLAfterLogin: string = environment.educationURLAfterLogin;

  constructor(private router: Router,public dialog: MatDialog,
    private piConfirmService: PersonalInformationConfirmationService,
    private ref: ChangeDetectorRef){
    piConfirmService.confirmPIEvent$.subscribe(item => this.setMenuState(item));
    piConfirmService.selectedMenuEvent$.subscribe(item => this.setMenuSelected(item));

  }

  ngAfterViewInit(){
    //to hide first element of the menu
    const child = document.querySelector<HTMLElement>("#mat-tab-label-0-0");
      if (child) {
        child.style.display ='none';
      }
  }
  
  setMenuState(val:boolean){
    this.menuState = val;
  }

  setMenuSelected(val:string){
    if(val === "COMMON")
    {
      this.selectedPath = "COMMON";
    }
    else if(val === "dashboard"){
      this.selectedPath = "#/dashboard";
    }
    else if(val === "RequestReports"){
      this.selectedPath = "#/RequestReports";
    }
    else if(val === "consumerdisclosure"){
      this.selectedPath = "#/disclosure-report-info";
    }
    else if(val === "consumerscore"){
      this.selectedPath = "#/consumer-score-page";
    }
    else if(val === "IdentityTheft"){
      this.selectedPath = "#/IdentityTheft";
    }
    else if(val === "IdentityTheftInformation"){
      this.selectedPath = "#/identity-theft-info";
    }
    else if(val === "PlaceaSecurityAlert"){
      this.selectedPath = "#/place-alert";
    }
    else if(val === "SecurityFreeze"){
      this.selectedPath = "#/SecurityFreeze";
    }
    else if(val === "SecurityFreezeInformation"){
      this.selectedPath = "#/security-freeze-info";
    }
    else if(val === "PlaceAFreeze"){
      this.selectedPath = "#/place-freeze";
    }
    else if(val === "ManageSecurityFreeze"){
      this.selectedPath = "#/manage-sec-frz";
    }
    else if(val === "ReplaceSecurityPIN"){
      this.selectedPath = "#/replace-sec-pin";
    }
    else if(val === "NoticeofRights"){
      this.selectedPath = "#/security-freeze-notice";
    }
    else if(val === "disputeTabs"){
      this.selectedPath = "#/discloser-dispute-trans";
    }
    else if(val === "OptOut"){
      this.selectedPath = "#/opt-out";
    }
    else{
      this.selectedPath = val;
    }
    this.ref.detectChanges();
  }
  
  items: RufDynamicMenubarItem[] = [
    
    {
      label: '',
      path: 'COMMON',
    },
    {
      label: 'Home',
      path: '#/dashboard',
    },
    {
      label: 'Request Reports',
      path: '#/RequestReports',
      children: [
        {
          label: 'Consumer Disclosure',
          path: '#/disclosure-report-info',
        },
        {
          label: 'Consumer Score Report',
          path: '#/consumer-score-page',
        }
      ]
    },    
    {
      label: 'Identity Theft',
      path: '#/IdentityTheft',
      children: [
        {
          label: 'Identity Theft Information',
          path: '#/identity-theft-info'
        },
        {
          label: 'Security Alert',
          path: '#/place-alert'
        }
      ]
    },
    {
      label: 'Security Freeze',
      path: '#/SecurityFreeze',
      children: [
        {
          label: 'Security Freeze Information',
          path: '#/security-freeze-info'
        },
        {
          label: 'Place a Freeze',
          path: '#/place-freeze'
        },
        {
          label: 'Manage Security Freeze',
          path: '#/manage-sec-frz'
        },
        {
          label: 'Replacement PIN',
          path: '#/replace-sec-pin'
        },
        {
          label: 'Notice of Rights',
          path: '#/security-freeze-notice'
        }
      ]
    },
    {
      label: 'Education',
      path: this.educationURLAfterLogin,
    },
    {
      label: 'Dispute',
      path: '#/discloser-dispute-trans',
    },
    {
      label: 'Opt Out',
      path: '#/opt-out',
    }
  ];
 
  openbasic(event: any) {
    
    this.selectedPath = event.path;    
    if(event.path ==="#/dashboard")
    {
      this.router.navigate(['dashboard'])
    }else if(event.path ==="#/disclosure-report-info")
    {
      //this.router.navigate( ['pdf-view-docs', {src: "disclosure", id: 23343432432}]);
      this.router.navigate( ['disclosure-report-info']);
    }
    else if(event.path ==="#/consumer-score-page"){
      this.router.navigate(['consumer-score-page'])

    }
    else if(event.path ==="#/discloser-dispute-trans")
    {
      this.router.navigate( ['discloser-dispute-trans']);
    }
    else if(event.path ==="#/opt-out"){
      this.router.navigate(['opt-out'])
    }
    else if(event.path ==="#/manage-sec-frz")
    {
      this.router.navigate( ['manage-sec-frz']);
    }
    else if(event.path ==="#/security-freeze-info")
    {
      this.router.navigate( ['security-freeze-info']);
    }
    else if(event.path ==="#/place-freeze")
    {
      this.router.navigate( ['place-freeze']);
    }
    else if(event.path ==="#/replace-sec-pin")
    {
      this.router.navigate( ['replace-sec-pin']);
    }
    else if(event.path ==="#/security-freeze-notice")
    {
      this.router.navigate( ['security-freeze-notice']);
    }
    else if(event.path ===this.educationURLAfterLogin)
    {
      let winRef = window.open(this.educationURLAfterLogin, '_blank');
    }
    else if(event.path ==="#/place-alert")
    {
      this.router.navigate( ['place-alert']);
    }
    else if(event.path ==="#/identity-theft-info")
    {
      this.router.navigate( ['identity-theft-info']);
    }
  }

  
}