import { Component, ViewChild, TemplateRef, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { dashboardCorrs } from '../../../_models/classes/dashboard-correspondence.model';
import { DashboardService } from '../../../_services/dashboard-services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../enums/global_variables';
import { RufDropdownType } from '@ruf/shell';

@Component({
  selector: 'chx-dashboard-correspondence',
  templateUrl: './dashboard-correspondence.component.html',
  styleUrls: ['./dashboard-correspondence.component.scss'],
})


export class DashboardCorrespondenceComponent implements OnInit {

  panel = { type: RufDropdownType.PopoverMenu };
  hasBackdrop = true;
  corresRoot:dashboardCorrs.DashboardCorrespondenceRoot;
  correspondenceTextValue: string='';
  title=this._translate.instant('DASHBOARDCORRESPONDENCE.CORRESPONDENCE');
  noitems=this._translate.instant('DASHBOARDCORRESPONDENCE.NOITEMS');
  date=this._translate.instant('DASHBOARDCORRESPONDENCE.DATE');
  communication=this._translate.instant('DASHBOARDCORRESPONDENCE.COMMUNICATION');
  reference=this._translate.instant('DASHBOARDCORRESPONDENCE.REFERENCE');
  view=this._translate.instant('DASHBOARDCORRESPONDENCE.VIEW');
  showLoadingIndicator:boolean = false;
  @Input()
  get correspondenceText(): string {
    return this.correspondenceTextValue;
  }

  set correspondenceText(value: string) {
    this.correspondenceTextValue = value;
  }
  
  constructor(
    private router: Router,private http: HttpClient,
    private dashboardService: DashboardService,
    private _translate: TranslateService,
  ) { 
    // this.initData();
      
  }
  
  getCorrespondenceRespData() {
    let _userName = GlobalVariables.globalvars.get("UserId") || '';
    let dcReqRoot: dashboardCorrs.CorrespondenceDetailsRequestRoot = new dashboardCorrs.CorrespondenceDetailsRequestRoot();
    dcReqRoot.CorrespondenceDetailsRequest = new dashboardCorrs.CorrespondenceDetailsRequest();
    dcReqRoot.CorrespondenceDetailsRequest.UserName = _userName;
    return this.dashboardService.CorrespondenceList(dcReqRoot);
    //return this.http.get('./././assets/jsondata/CorrespondenceList-Rsp.json');
  }

  ngOnInit(): void {
    this.initData();
  }
  initData(){
    this.showLoadingIndicator = true;
    this.getCorrespondenceRespData().subscribe((res) => {
      this.corresRoot = res as dashboardCorrs.DashboardCorrespondenceRoot;
      var tempItems: DashboardCorrespondenceItems[] = [];
      var counter:number = 0;
      ///Make the list in decending prder
      this.corresRoot.CorrespondenceDetailsResponse.CorrespondenceList = 
          this.corresRoot.CorrespondenceDetailsResponse.CorrespondenceList.sort((item1,item2) =>  new Date(item2.DateOfCorrespondence).getTime() - new Date(item1.DateOfCorrespondence).getTime());
          
 
      for (var val of this.corresRoot.CorrespondenceDetailsResponse.CorrespondenceList) {
        counter++;
        if(counter <=3){
          var item:DashboardCorrespondenceItems = new DashboardCorrespondenceItems();
          if(val.ConsumerReferenceId !== ""){
            item.referenceNumber = val.ConsumerReferenceId as string;
            item.letterName = val.LetterName;
            item.dateOfCorrespondence = new Date(val.DateOfCorrespondence);
            item.ProcessOid = val.ProcessOid;
            item.DocumentOid = val.DocumentOid;
            tempItems.push(item);
          }
          
        }
        
      }
      this.items = tempItems;
      //this.dashboardService.triggerCorrespondanceLoadEvent();
    }, 
    error => {
      this.dashboardService.triggerCorrespondanceLoadEvent();
      this.showLoadingIndicator = false;
    },
    () => {
      this.dashboardService.triggerCorrespondanceLoadEvent();
      this.showLoadingIndicator = false;
    });

  }
  items: DashboardCorrespondenceItems[] = [
    
    // {
    //   referenceNumber: '23432432',
    //   letterName: 'Letter of Authorization',
    //   dateOfCorrespondence: new Date('1/25/2021')
    // },
    // {
    //   referenceNumber: '32423432',
    //   letterName: 'Approval Letter',
    //   dateOfCorrespondence: new Date('1/25/2021')
    // },
    // {
    //   referenceNumber: '23432432',
    //   letterName: 'Rejection Letter',
    //   dateOfCorrespondence: new Date('1/25/2021')
    // }

  ];

    openCorresDetailAll(confirNo: string){
      //alert(confirNo);
      this.router.navigate( ['correspondence-all']);
      
      //this.router.navigateByUrl('/disputedetail')
    }

    openCorresLetter(dCorrespondenceItem: DashboardCorrespondenceItems){
      //alert(confirNo);
      // localStorage.setItem('DashboardCorrespondenceItems', JSON.stringify(dCorrespondenceItem));

      if (GlobalVariables.globalvars.has('DashboardCorrespondenceItems')){
        GlobalVariables.globalvars.delete('DashboardCorrespondenceItems');                
      }
      GlobalVariables.globalvars.set('DashboardCorrespondenceItems', JSON.stringify(dCorrespondenceItem));  

      this.router.navigate( ['pdf-view-docs', {src: "dashboard"}]);
      //this.router.navigate( ['pdf-view-docs', {src: "d", id: dCorrespondenceItem.referenceNumber}]);
      
      //this.router.navigateByUrl('/disputedetail')
    }
    
    columns: any[] = [
      {
        field: 'dateOfCorrespondence',
        name: 'Date of Correspondence',
        title: 'Date of Correspondence',
        width: '25%',
        dataType: {
          name: 'date',
          format: ['MM/dd/yyyy']
        },
        sticky: true
      },
      {
        field: 'letterName',
        name: 'Communication Type',
        width: '25%',
        title: 'Communication Type',
        sticky: true,
        filterable: true
      },
      {
        field: 'referenceNumber',
        name: 'Reference Number',
        title: 'Reference Number',
        width: '25%',
        sticky: true
      }
    ];

    rowSelectionChange(event: any) {
    }
  
    onStateChange(event: any) {
    }
    rowClickHandler(code: string, element:any){
      //this.router.navigate( ['pdf-view-docs', {src: "a", id: this.confNo}]);
    }
    
  }

  export class DashboardCorrespondenceItems {
    referenceNumber: string;
    letterName: string="";
    dateOfCorrespondence: Date;
    ProcessOid: string;
    DocumentOid: string;
  }