<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
  <ruf-dialog-header role="region" aria-label="header" rufId="simple_dialog_header" #header>{{
    title
    }}</ruf-dialog-header>
  <ruf-dialog-content rufId="simple_dialog_content">
    <!-- {{message}} -->
    <span [innerHTML]="message"></span>
  </ruf-dialog-content>
  <ruf-dialog-footer rufId="dialog_footer">
    <button mat-raised-button fisStyle class="submit-btn" id="chexsystemsBtn" role="region"
      aria-label="submit" color="primary" rufId="dialog_footer_button" (click)="onButton1Click()">
      {{ button1 }}
    </button>
    <button mat-stroked-button fisStyle class="form_button" id="okBtn" role="region" aria-label="no" rufId
      (click)="onButton2Click()">
      {{ button2 }}
    </button>

  </ruf-dialog-footer>
</ruf-dialog-panel>