<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
    <ruf-dialog-header role="region" aria-label="dispute" rufId="simple_dialog_header"#header>{{ 'DIALOGDISPUTE.DISPUTE' | translate }} {{section}}</ruf-dialog-header>
     <ruf-dialog-content rufId="simple_dialog_content">
        <div class="chex-dlg-dispute-content-area-div">
        <form [formGroup]="formGroup" autocomplete="off">
            
                <label role="region" aria-label="disputereason" id="disputetxt" class="chex-dialog-dispute-pi-caption">{{ 'DIALOGDISPUTE.DISPUTEREASON' | translate }}</label>
                <mat-form-field class="chex-dlg-dispute-dropdown" boxStyle floatLabel="always">
                    
                        <mat-select  fisStyle placeholder="Select Reason for Dispute" formControlName="reasonctrl">
                            <mat-option *ngFor="let reason of reasonList" [value]="reason.ReasonName">
                            {{reason.ReasonName}}
                            </mat-option>  
                        </mat-select> 
                    
                    <mat-error *ngIf="formGroup.get('reasonctrl')?.hasError('required')">{{ 'DIALOGDISPUTE.REASONREQ' | translate }}</mat-error>        
                </mat-form-field>
            
        
            <div fxLayout="column" class="chex-dlg-dispute-text1" fxLayoutGap="19px">
                <div fxLayout="column">
                    <div fxLayout="row">
                    <mat-label role="region" aria-label="comments" id="commentstxt" class="chex-dialog-dispute-pi-caption">{{ 'DIALOGDISPUTE.COMMENTS' | translate }}</mat-label>
                    <button role="region"  type="button" mat-icon-button
            [rufTrigger]="dropdownPanelCorrespondence" #triggerCorrespondence="rufTrigger" fisStyle tabindex="-1">
            <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
          </button>
          <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelCorrespondence rufId
            [origin]="triggerCorrespondence" [type]="panel.type" xPosition="center" yPosition="bottom">
            <div role="region" aria-label="correspondenceportal" class="mat-body-1 chex-popover-content">
               Allowable characters are a-z A-Z 0-9~`!@#$%^&*()-+={{'{'}}{{'}'}}[]/\|:_;'",.?
            </div>
          </ruf-dropdown-panel>
        </div>
                    <mat-form-field boxStyle class="ruf-form-field-textarea chex-dlg-dispute-text-area" floatLabel="always">
                        <textarea [(ngModel)]="comments" #textArea matInput formControlName="textAreactrl"
                        placeholder="Enter Comments" 
                        rows="9" [maxLength]="250"></textarea> 
                        <mat-error rufMarginBottom *ngIf="formGroup.controls['textAreactrl'].errors?.invalidSplChar">{{'DIALOGDISPUTE.COMMENTSREQ1' | translate}}</mat-error>        
                    </mat-form-field>
                </div>
                <div rufMarginTop >{{ textArea.value.length }} / 250</div>
            </div>       
        </form>  
    </div>
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="simple_dialog_footer">
    <button role="region" aria-label="dispute" id="SubmitBtn" mat-raised-button fisStyle color="primary" class="form_button" rufId="dialog_footer_button" type="submit" (click)="onSubmit()">{{ 'BUTTONS.DISPUTE' | translate }} {{section}}</button>
    <button role="region" aria-label="cancel" id="cancelBtn" mat-stroked-button fisStyle color="primary" class="form_button" rufId (click)="onCancel()">{{ 'BUTTONS.CANCEL' | translate }}</button>
    </ruf-dialog-footer>
  </ruf-dialog-panel>
  