import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'chx-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})


export class AboutUsComponent {

  aboutusdesc=this._translate.instant('ABOUTUS.DESCRIPTION');
  aboutustitle=this._translate.instant('ABOUTUS.TITLE');
  aboutusstatusbar=this._translate.instant('ABOUTUS.STATUSBAR');
  aboutusinfo=this._translate.instant('ABOUTUS.INFO');
  aboutusinfo1=this._translate.instant('ABOUTUS.INFO1');
  aboutusdesc1=this._translate.instant('ABOUTUS.DESCRIPTION1');
  aboutusdesc2=this._translate.instant('ABOUTUS.DESCRIPTION2');
  faqAriaDesc= this._translate.instant('ABOUTUS.DESCRIPTION3');
  faqDesc: string = '';

  constructor(
    private router: Router,
    private piConfirmService: PersonalInformationConfirmationService,
    private _translate: TranslateService,
  ) { 

    
  };

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
    this.faqDesc = this._translate.instant('ABOUTUS.DESCRIPTION1') + "<a href='" + environment.faqURL + "' target='_blank'>Frequently Asked Questions</a>" + this._translate.instant('ABOUTUS.DESCRIPTION2');
  }
  
  submitclick(){
    this.router.navigate( ['place-alert']);
  }
  navigateToLinks(code:string){

    if(code === "faq"){
      let winRef = window.open("https://cmchexqa.fisglobal.com/answers-to-frequently-asked-questions/#header", '_blank');
    }
    

  }

  }

  