export namespace DashBoardCarousel {
    export class DashBoardCarouselData {
        items: item[] = [];
    }

    export class item {
        carouselimagepath: string;
        carouseltext: string;
        index: number;
    }
}