import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PersonalInformationConfirmationService {
  dialogWidth: string = '596px';

  public confirmPIEvent$: EventEmitter<boolean>;
  public selectedMenuEvent$: EventEmitter<string>;

  constructor(private router: Router, public dialog: MatDialog) {
    this.confirmPIEvent$ = new EventEmitter();
    this.selectedMenuEvent$ = new EventEmitter();
  }

  checkPersonalInformationConfirmation(pageCode: string = 'COMMON') {
    var boolResult: boolean = true;
    var skipPiConfirm: boolean = true;
    if (
      pageCode.toUpperCase() === 'profile'.toUpperCase() ||
      pageCode.toUpperCase() === 'chpassword'.toUpperCase()
    ) {
      skipPiConfirm = false;
      pageCode = 'COMMON';
    }
    if (sessionStorage.ProfileConfirmed === undefined && skipPiConfirm) {
      boolResult = false;
      this.router.navigate(['confirm-pi']);
    } else {
      this.selectedMenuEvent$.emit(pageCode);
    }
    return boolResult;
  }

  checkPersonalInformationGoToDashboard() {
    if (sessionStorage.ProfileConfirmed === undefined) {
      this.router.navigate(['confirm-pi']);
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  public piConfirmed(): void {
    sessionStorage.setItem('ProfileConfirmed', 'confirmed');
    this.confirmPIEvent$.emit(true);
  }

  public piUnConfirmed(): void {
    sessionStorage.removeItem('ProfileConfirmed');
    this.confirmPIEvent$.emit(false);
  }
}
