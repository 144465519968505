import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  isLoading = new Subject<boolean>();
  loaderCount:number = 0;
  constructor() {
  }

  show() {
     this.isLoading.next(true);
     this.loaderCount++;
  }

  hide() {
    this.loaderCount--;
    if(this.loaderCount=== 0){
      this.isLoading.next(false);
    }
  }
}
