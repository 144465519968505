import { Component, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../../../dailog/dialog-with-message/dialog-with-message.component';
import { Registrationresource } from '../../../_models/registration/registrationresource.model';

@Component({
  selector: 'top-header',
  templateUrl: './before-loggedin-header.component.html',
  styleUrls: ['./before-loggedin-header.component.scss'],
})
export class TopHeaderComponent {
  logoUrl = 'assets/chex.png';
  cancelpopuptext: string =
    'This will end the registration process. Are you sure you want to cancel the registration process?';
  homeChexSystemsUrl: string = environment.homeChexSystemsUrl; //'https://www.chexsystems.com/'

  constructor(public dialog: MatDialog, private _translate: TranslateService) {}

  cancelclick(): void {
    const dialogMessage = new DialogMessage(
      this._translate.instant('POPUPS.CANCELREGISTRATIONTEXT'),
      this._translate.instant('BUTTONS.YES'),
      true,
      this._translate.instant('POPUPS.CANCELREGISTRATIONTITLE')
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      //chexsystems public home page
      if ((result as string) == 'OK') {
        window.location.href = this.homeChexSystemsUrl + "#head";
      }
    });
  }
}
