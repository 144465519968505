        <div class="chex-bottom_space" >
            <ruf-page-header role="region" class="header_text" rufId="page_header_disputeTab_title" title="{{ 'PLACEFREEZE.TITLE' | translate }}" description=" " ></ruf-page-header>
            <div class="chex-page-left-right-alignment mat-body-2 " #placefreezecontent [innerHTML]="contentText1 | sanitizeHtml">
            </div>       
            <div rufMarginTop class="chex-page-left-right-alignment" fxLayout="row" fxLayoutGap="10px">
              <button role="region" aria-label="continue" id="continueBtn" mat-raised-button fisStyle color="primary" rufId ruf-stepper-actions type="submit"  (click)="continueclick()">{{ 'BUTTONS.CONTINUE' | translate }}</button>
              <button role="region" aria-label="cancel" id="cancelBtn" mat-stroked-button fisStyle type="button"  (click)="onCancel()" >{{ 'BUTTONS.CANCEL' | translate }}</button>
            </div>
            <div role="region"  class="chex-page-left-right-alignment chex-plc-frz-information mat-body-2">
              {{ 'PLACEFREEZE.INFO' | translate }}
            </div>
        </div>
