 <div role="region" aria-label="backtodashboard" class="chex-page-left-right-alignment chex-a-backtosource"><a  href="javascript:void(0);" (click)="backToSource()">{{ 'VIEWALLDOC.BACKTODASHBOARD' | translate }}</a></div>
    <ruf-page-header title="Documents for {{confNo}}" description=" " ></ruf-page-header>
           <div class="chex-page-left-right-alignment">
             <ruf-dynamic-datatable #ddt [showFilter]="true" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="items" [metadata]="columns">
              <ng-container *rufNoDataRow>
                <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
                <h2 role="region" aria-label="noitems" class="chex-no-record-message">{{ 'VIEWALLDOC.NOITEMS' | translate }}</h2>
              </ng-container>
              <ng-container matColumnDef="documentName" stickyEnd>
                <th role="region" aria-label="name" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'VIEWALLDOC.NAME' | translate }}</th>
                <td role="region" aria-label="nameelement" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" class="ruf-demo-density-2">{{element.documentName}} </td>
              </ng-container> 
              <ng-container matColumnDef="updatedDate" stickyEnd>
                 <th role="region" aria-label="date" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'VIEWALLDOC.DATE' | translate }}</th>
                 <td role="region" aria-label="dateelement" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" class="ruf-demo-density-2">{{element.updatedDate | date:'MM/dd/yyyy'}} </td>
               </ng-container>
               <ng-container matColumnDef="size" stickyEnd>
                <th role="region" aria-label="size" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'VIEWALLDOC.SIZE' | translate }}</th>
                <td role="region" aria-label="sizeelement" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" class="ruf-demo-density-2">{{element.size}} </td>
              </ng-container>
              <ng-container matColumnDef="status" stickyEnd>
                <th role="region" aria-label="status" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef>{{ 'VIEWALLDOC.STATUS' | translate }}</th>
                <td role="region" aria-label="statuselement" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" class="ruf-demo-density-2">{{element.status}} </td>
             </ng-container>
             </ruf-dynamic-datatable>
            </div>
          <!------------------------------------------------------------------
             -->

<!-- </ruf-card>
</ruf-app-canvas> -->