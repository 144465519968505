export namespace removefreeze{
  export class RemoveFreezeRoot {
    RemoveFreeze: RemoveFreeze
  }
  
  export class RemoveFreeze {
    CnsmrPrsnlDetls: CnsmrPrsnlDetls
    CnsmrAddrDetls: CnsmrAddrDetls
    FreezeDate: FreezeDate
    PinNbr: string
    RequestSrcCode: string
    UserName:string
    CellPhoneNbr:string
    CorrespondenceDeliveryPreference:string
    NotificationEmailAddr:string
  }
  
  export class CnsmrPrsnlDetls {
    BirthDt: string
    CnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls
    CnsmrNmDetls: CnsmrNmDetls
    ConsumerId: string
    EmailAddress: string
    GovtNbr: string
    PhoneNbr: string
  }
  
  export class CnsmrDrivLicenseDetls {
    DrivLicenseIssuSt: string
    DrivLicenseNbr: string
  }
  
  export class CnsmrNmDetls {
    FrstNm: string
    LastNm: string
    MidNm: string
    PreviousLastName: string
    SuffixNm: string
  }
  
  export class CnsmrAddrDetls {
    CnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    AddrLine1: string
    AddrLine2: string
    CityNm: string
    StatCode: string
    ZipCode: string
  }
  
  export class FreezeDate {
    EndDate: string
    StartDate: string
  }
  
  ////////////////////////////////////
 
  /////////////////////
  export class RemoveFreezeResponseRoot {
    message: string
    code: string
    referenceId: string
  }
  

}
//////////////////////////////////
