export interface Department {
    departmentId: number;
    departmentName: string;
    primaryDept: number;
}

export interface IdpUser {
    firstName: string;
    lastName: string;
    emailAddress: string;
    loginName: string;
    providerConfigurationID: number;
    departments: Department[];
    role: number;
    password: string;
    autoGeneratePassword: boolean;
    loginRole: string,
    phone: string,
    userProfile: string,
    profileRegToLogins: ProfileRegToLogin[];
    type:number,
    typeCode:string,
    mobile:string,
    sendPasswordEmailNotification:boolean,
    successfulLogin: boolean,
    passwordUpdate: boolean,
    emailUpdate: boolean,
    mobileNumberUpdate: boolean,
    passwordViolation: boolean,
    otPViolation: boolean
    accountLockViolation : boolean,
    rejectedLogin: boolean,
    timezone:string,
    captchaToken:any 
}

export interface UserDetail {
    _metadata: Metadata;
    id: number;
    firstName: string;
    lastName: string;
    middleName: string;
    managerLoginName: string;
    createdDate: Date;
    modifiedDate: Date;
    loginModifiedDate: Date;
    type: string;
    typeCode: string;
    currency: string;
    loginName: string;
    password: string;
    role: number;
    rewardPoints: number;
    pwdNeverExpires: boolean;
    hardwareAuthenticator: boolean;
    optedOutMFA: boolean;
    active: number;
    autoSuspended: number;
    adminSuspended: number;
    statusNewLoginDate: Date;
    forcePasswordChange: boolean;
    forceLoginNameChange: boolean;
    passwordResetDate: Date;
    otPViolation: boolean;
    passwordViolation: boolean;
    accountLockViolation: boolean;
    challengeViolation: boolean;
    mobileBiometricsViolation: boolean;
    nbFailedLogin: number;
    providerConfigurationID: number;
    otpEnabled: boolean;
    forgetPasswordEnabled: boolean;
    challengeQuestionEnabled: boolean;
    autoGenerate: boolean;
    autoGeneratePassword: boolean;
    sendPasswordEmailNotification: boolean;
    sendEmailNotification: boolean;
    assignableId: number;
    sessionTimeOut: number;
    sessionTrace: boolean;
    departments: Department[];
    modifiedLoginName: string;
    createdLoginName: string;
    emailAddress: string;
    authorizationRegToLogins: any[];
    profileRegToLogins: any[];
    userToWorkgroups: any[];
    userDisplayName: string;
    authProvider: string;
    otpType: number;
    customInfos: CustomInfos;
    guid: string;
    sendPwdResetLinkAtLogin: boolean;
    passwordUpdate: boolean;
    successfulLogin: boolean;
    rejectedLogin: boolean;
    emailUpdate: boolean;
    loginNameChange: boolean;
    userPatternViolation: boolean;
    authenticationFactor: number;
    alternateOTPMode: number;
    mobileNumberUpdate: boolean;
    challengeQuestionsUpdate: boolean;
    googleAuthenticatorUpdate: boolean;
    mobileBiometricsUpdate: boolean;
    registrationValidateViolation: boolean;
    editable: boolean;
    policyAgreementResponse: boolean;
    totalFailedPin: number;
    pinStatus: number;
    pinStatusCode: string;
    nbFailedExternalValidation: number;
    externalValidationViolation: boolean;
    externalTokenProviderName: string;
    externalTokenViolation: boolean;
    loginRole: string;
}

export interface Metadata {
    actions: Actions;
    links: Link[];
}

export interface Actions {
    Add: boolean;
    Delete: boolean;
    ImportTool: boolean;
    OtpReset: boolean;
    UpdateActiveStatus: boolean;
    UpdatePhone: boolean;
    Update: boolean;
    UpdateEntitlements: boolean;
    Read: boolean;
    AutoResetPassword: boolean;
    UpdateAlias: boolean;
    UpdateEmail: boolean;
    UpdateSuspendStatus: boolean;
}

export interface Link {
    rel: string;
    href: string;
}

export interface CustomInfos {
}


export interface IdpUpdateUser {
    firstName: string;
    lastName: string;
    middleName:string;
    phone:string,
    emailAddress: string;
    loginName:string,
    mobile:string
}

export interface ProfileRegToLogin {
    id: string;
    exclusion: number;
    description: string;
    profileRegistryName: string;
    restricted: boolean;
}

export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    fieldName: string;
    loginName: string;
}

export interface UserLookupResponse {
    total: number;
    data: UserDetail[];
}

export enum FieldMaskingShowHide{
    Eye ="eye",
    Normal = "eye-hide",
    Show = "show",
    Hide = "hide",
}

export enum PasswordType{
    Text = "text",
    Password = "password"
}
