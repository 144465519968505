import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { managefreeze } from '../../_models/classes/manage-freeze.model';
import { ApiService } from '../shared/api.service';
import { securityalert } from '../../_models/classes/security-alert.model';

@Injectable({
  providedIn: 'root'
})

/**
 * Used to interact with ida endpoint
 */
export class SecurityAlertService{
  private readonly Api_Base_URL = environment.apiurl;
  constructor(private httpClient : HttpClient, private _apiService: ApiService) { 
   
  }

 

  /**
   * add the security alert
   * @param saRequest
   * @returns alert response
   */
   AddSecurityAlert(saRequest:securityalert.SecurityAlertRequestRoot):Observable<securityalert.SecurityAlertResponseRoot>{
      const url = this.Api_Base_URL.concat('/AddSecurityAlert');
      return this._apiService.post<securityalert.SecurityAlertResponseRoot>(url,saRequest)
      .pipe(      
        catchError(this.handleError)
      );
   }
  
   private handleError(error: any) {
    //let errorMessage:string = `Backend returned code ${error.status}, body was: ${error.error}`;
    //return throwError(() => new Error(errorMessage));
    return throwError(error);
  }

  
}
