export class Address {
    currentaddressline1: string;
    currentaddressline2: string;
    city: string;
    state: string;
    zipcode: string;
}

export class UserProfileDetail{
    suffix: string;
    dateofbirth: string;
    usssn: string;
    militaryidnumber: string;
    state: string;
    phone: string;
    correspondencedeliverypreference: string;
    ddl: string;
    address: Address;
}


export interface CnsmrAddr {
    AddrLine1: string;
    AddrLine2: string;
    CityNm: string;
    StatCode: string;
    ZipCode: string;
}

export interface CnsmrAddrDetls {
    CnsmrAddr: CnsmrAddr;
}

export interface CnsmrDrivLicenseDetls {
    DrivLicenseIssuSt: string;
    DrivLicenseNbr: string;
}

export interface CnsmrNmDetls {
    FrstNm: string;
    LastNm: string;
    MidNm: string;
    SuffixNm: string;
    PreviousLastNm: string;
}

export interface CnsmrPrsnlDetls {
    BirthDt: string;
    CnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls;
    CnsmrNmDetls: CnsmrNmDetls;
    EmailAddr: string;
    GovtNbr: string;
    PhoneNbr: string;
    CellPhoneNbr: string;
    CorrespondenceDlvryPref: string;    
}

export interface UserLookupRsp {
    CnsmrAddrDetls: CnsmrAddrDetls;
    CnsmrPrsnlDetls: CnsmrPrsnlDetls;
    UserName: string;
    Cid: string;
    Code: string;
    Message: string;
    Id: string;
}

export class UserDetail {
    UserLookupRsp: UserLookupRsp;
}


export interface UserLookupDetail {
    UserName: string;
    Cid: string;
}

export class UserLookupRequest {
    UserLookupReq: UserLookupDetail;
}