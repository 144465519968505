import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import { OptOutRequest } from '../_models/classes/OptOut-request.model';
import { OptOutResponse } from '../_models/classes/OptOut-response.model';
import { HttpClient } from '@angular/common/http';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { IdaQuizService } from '../_services/registration/ida-quiz.service';
import { UserLookupRequest } from '../_models/registration/UserDetail';
import { UserdisclosureService } from '../_services/disclosure/user-disclosure.service';
import { MyTel } from '../common/controls/phone-control/phone-control.component';
import { DialogwithmessagebtnComponent } from '../dailog/dialog-with-message-btn/dialog-with-message-btn.component';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../common/enums/global_variables';
import { userinformation } from '../_models/classes/user-information.model';
import { DisclosureRequestheader } from '../_models/consumer-disclosure/consumer-disclosure.model';

@Component({
  selector: 'chexsystem-ws-opt-out',
  templateUrl: './opt-out.component.html',
  styleUrls: ['./opt-out.component.scss'],
})
export class OptOutComponent implements OnInit {
  dialogWidth: string = '596px';
  text: string = '';
  termsnconditions: boolean = false;
  optOut: OptOutResponse;

  oputOutServiceErrorMessage: string = "Something went wrong, please try again later";
  oputOutServiceErrorActionButton: string = "Go to Dashboard";
  oputOutServiceErrorTitle: string = "Opt Out";
  optOutStatusBarContent: string = '';
  optOutContent: string = '';
  title=this._translate.instant('OPTOUT.TITLE');
  submit=this._translate.instant('BUTTONS.SUBMIT');

  constructor(private router: Router, public dialog: MatDialog, private http: HttpClient, private piConfirmService: PersonalInformationConfirmationService,
    private decisionSolutionService: IdaQuizService,
    private optOutService: UserdisclosureService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService ) {}

  items: OptOutItems = new OptOutItems();

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('OptOut');
    this.fetchOptOutContent();
  }

  fetchOptOutContent() {
    let manageSecurityFreezeContentPath: string = 'Opt-Out/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: manageSecurityFreezeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'opt-out') {
          const divOptOut = document.createElement('div');
          divOptOut.innerHTML = fieldObject.value.toString();
          var anchorelements = divOptOut.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            // anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
            anchorelement.href="assets/pdf/ChexSystemsPermanentOptOutElectionForm.pdf";
          });

          this.optOutContent = divOptOut.innerHTML;
        }
        if (child.name.toLowerCase() == 'status bar') {
          this.optOutStatusBarContent = fieldObject.value;
        }
      });

    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }

  submitClick() {
    const userProfileInfo = GlobalVariables.globalvars.get('UserInformation');
    if (userProfileInfo) {
      const userProfile = JSON.parse(userProfileInfo) as userinformation.UserInformationRoot;
      let consumerOptOutRequest: OptOutRequest = {
        OptOutReq: {
          CallOut: 'T',
          CellPhoneNbr: userProfile.CellPhoneNbr,
          CnsmrAddrDetls: {
            CnsmrAddr: {
              AddrLine1:userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim(),
              CityNm:userProfile.CnsmrAddrDetls.CnsmrAddr.CityNm.trim(),
              StatCode:userProfile.CnsmrAddrDetls.CnsmrAddr.StatCode.trim(),
              ZipCode:userProfile.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim(),
            },
          },
          CnsmrPrsnlDetls: {
            BirthDt: userProfile.CnsmrPrsnlDetls.BirthDt.trim(),
            ConsumerId: '',
            EmailAddress:userProfile.NotificationEmailAddr.trim(),
            GovtNbr: userProfile.CnsmrPrsnlDetls.GovtNbr.trim(),
            PhoneNbr: userProfile.CnsmrPrsnlDetls.PhoneNbr.trim(),
            CnsmrDrivLicenseDetls: {
              DrivLicenseIssuSt:userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim(),
              DrivLicenseNbr:userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim(),
            },
            CnsmrNmDetls: {
              FrstNm:userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
              LastNm:userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
              MidNm:userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim(),
              SuffixNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim()
            },
          },
          CorrespondenceDeliveryPreference:userProfile.CorrespondenceDeliveryPreference,
          NotificationEmailAddr: userProfile.NotificationEmailAddr,
          RequestSrcCode: DisclosureRequestheader.RequestSrcCode,
          UserName: userProfile.UserName
        },
      };
      this.optOutService
        .UpdateConsumerOptOut(consumerOptOutRequest)
        .subscribe(
          (optOutResponse) => {
            if (optOutResponse.code === '200') {
              const opt_out_message =
                this._translate.instant('POPUPS.OPTOUTTEXT1') +
              
                this._translate.instant('POPUPS.OPTOUTTEXT3') +
                optOutResponse.referenceId +
                this._translate.instant('POPUPS.OPTOUTTEXT2');
              const dialogMessage = new DialogMessage(
                opt_out_message,
                this._translate.instant('BUTTONS.GOTODASHBOARD'),
                false,
                this._translate.instant('POPUPS.OPTOUTREQUESTSUBMITTED')
              );
              const dialogRef2 = this.dialog.open(
                DialogwithmessagebtnComponent,
                {
                  panelClass: ['fis-style', 'custom-dialog-without-close'],
                  data: dialogMessage,
                  disableClose: true,
                }
              );
              dialogRef2.afterClosed().subscribe((result) => {
                if (
                  (result as string) == 'OK' ||
                  (result as string) == ''
                ) {
                  this.router.navigate(['dashboard']);
                }
              });
            } else {
              this.failedToGenerateReport();
            }
          },
          (error) => {
            this.failedToGenerateReport();
          },
          () => {}
        );
    }
  }

  private failedToGenerateReport() {
    const dialogMessage = new DialogMessage(
      this._translate.instant('BUTTONS.OPTOUTSERVICEERRORMESSAGE'),
      this._translate.instant('BUTTONS.GOTODASHBOARD'),
      false,
      this._translate.instant('POPUPS.OPTOUTTITLE')
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) === 'OK') {
        this.router.navigate(['dashboard']);
      }
    });
  }

  // onCancel(): void {
  //   this.router.navigate(['dashboard']);
  // }

  private ConvertPhoneFormate(phoneNumber: string): string {
    if (phoneNumber === undefined || phoneNumber == '' || phoneNumber == ' ')
      return '';
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(',', 3);
    return (
      formatedPhoneNumber[0] + formatedPhoneNumber[1] + formatedPhoneNumber[2]
    );
  }
}

export class OptOutItems {
  userName: string;
  cellPhoneNbr: string;
  correspondenceDeliveryPreference: string;
  notificationEmailAddr: string;
}
