import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { PhoneNumberType } from '../../../_models/classes/phonecountrycode';
import { PhonenumberService } from '../../../_services/registration/phonenumber.service';

/** Data structure for holding telephone number. */
export class PhoneNumber {
  constructor(
    public area: string,
    public exchange: string,
    public subscriber: string,
    public phonetype?: string
  ) { }
}

@Component({
  selector: 'chexsystem-ws-phonenumbercontrol',
  templateUrl: './phone-number-control.component.html',
  styleUrls: ['./phone-number-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: PhonenumbercontrolComponent },
  ]
})
export class PhonenumbercontrolComponent implements ControlValueAccessor, MatFormFieldControl<PhoneNumber>, OnDestroy {
  static nextId = 0;
  @ViewChild('phonetype') phonetypeInput!: ElementRef<HTMLElement>;
  @ViewChild('area') areaInput!: ElementRef<HTMLElement>;
  @ViewChild('exchange') exchangeInput!: ElementRef<HTMLElement>;
  @ViewChild('subscriber') subscriberInput!: ElementRef<HTMLElement>;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  // controlType = 'chexsystem-ws-phonecontrol';
  id = `chexsystem-ws-phonenumbercontrol-${PhonenumbercontrolComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };
  _placeholder!: string;
  formBuilder: FormBuilder;
  eyeIconType: string = 'eye';
  eyeIconTooltip: string = 'show';

  public get empty() {
    const {
      value: { phonetype, area, exchange, subscriber },
    } = this.parts;

    return !area && !exchange && !subscriber;
  }

  public get full() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return area.length == this.part1maxlength && exchange.length == this.part2maxlength && subscriber.length == this.part3maxlength;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby')
  userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get part1maxlength(): number {
    return this._part1maxlength;
  }
  set part1maxlength(value: number) {
    this._part1maxlength = value;
    this.parts.controls['area'].setValidators([
      Validators.required,
      Validators.minLength(value),
      Validators.maxLength(value),
    ]);
    this.stateChanges.next();
  }
  private _part1maxlength: number;

  @Input()
  get part2maxlength(): number {
    return this._part2maxlength;
  }
  set part2maxlength(value: number) {
    this._part2maxlength = value;
    this.parts.controls['exchange'].setValidators([
      Validators.required,
      Validators.minLength(value),
      Validators.maxLength(value),
    ]);
    this.stateChanges.next();
  }
  private _part2maxlength: number;

  @Input()
  get part3maxlength(): number {
    return this._part3maxlength;
  }
  set part3maxlength(value: number) {
    this._part3maxlength = value;
    this.parts.controls['subscriber'].setValidators([
      Validators.required,
      Validators.minLength(value),
      Validators.maxLength(value),
    ]);
    this.stateChanges.next();
  }
  private _part3maxlength: number;

  @Input()
  get part1placeholder(): string {
    return this._part1placeholder;
  }
  set part1placeholder(value: string) {
    this._part1placeholder = value;
    this.stateChanges.next();
  }
  private _part1placeholder: string;

  @Input()
  get part2placeholder(): string {
    return this._part2placeholder;
  }
  set part2placeholder(value: string) {
    this._part2placeholder = value;
    this.stateChanges.next();
  }
  private _part2placeholder: string;

  @Input()
  get part3placeholder(): string {
    return this._part3placeholder;
  }
  set part3placeholder(value: string) {
    this._part3placeholder = value;
    this.stateChanges.next();
  }
  private _part3placeholder: string;

  @Input()
  get inputtype(): string {
    return this._inputtype;
  }
  set inputtype(value: string) {
    this._inputtype = value;
    this.stateChanges.next();
  }
  private _inputtype: string;

  @Input()
  get value(): PhoneNumber | null {
    if (this.parts.valid) {
      const {
        value: { area, exchange, subscriber, phonetype },
      } = this.parts;
      return new PhoneNumber(area, exchange, subscriber, phonetype);
    }
    return null;
  }

  @Output() errorMessage = new EventEmitter<{ message: string, value: PhoneNumber }>();

  set value(tel: PhoneNumber | null) {
    const { area, exchange, subscriber, phonetype } = tel || new PhoneNumber('', '', '', '');
    this.parts.setValue({ area, exchange, subscriber, phonetype });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  //@Input('options') options: PhoneNumberType[];
  phoneNumberType: PhoneNumberType[] = [
    {
      text: "Mobile", value: "mobile"
    },
    {
      text: "Phone", value: "phone"
    }
  ];
  selectedPhoneNumberType: string;

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      phonetype: ['', Validators.required],
      area: [
        null,
        [
          Validators.required,
          Validators.minLength(this.part1maxlength),
          Validators.maxLength(this._part1maxlength),
        ],
      ],
      exchange: [
        null,
        [
          Validators.required,
          Validators.minLength(this.part2maxlength),
          Validators.maxLength(this.part2maxlength),
        ],
      ],
      subscriber: [
        null,
        [
          Validators.required,
          Validators.minLength(this.part3maxlength),
          Validators.maxLength(this.part3maxlength),
        ],
      ],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.selectedPhoneNumberType = 'mobile';
    this.parts.get('phonetype')?.setValue(this.selectedPhoneNumberType);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.chex-phonecontrol-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
  }

  writeValue(tel: PhoneNumber | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleValidation(): void {
    this.areaInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-area-error');
    this.exchangeInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-exchange-error');
    this.subscriberInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-subscriber-error');
    this._focusMonitor.focusVia(this.subscriberInput, 'program');
    this.subscriberInput.nativeElement.blur();
  }

  public ChangeErrorStyles(error: boolean): void {
    if (error) {
      this.areaInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-area-error');
      this.exchangeInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-exchange-error');
      this.subscriberInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-subscriber-error');
    }
    else {
      this.areaInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-area');
      this.exchangeInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-exchange');
      this.subscriberInput.nativeElement.setAttribute('class', 'chex-phonecontrol-input-element mat-body-2 chex-subscriber');
    }
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (control.errors?.minlength) {
      this.errorMessage.emit({ message: 'minlength', value: new PhoneNumber(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.empty) {
      this.errorMessage.emit({ message: '', value: new PhoneNumber(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.full) {
      this.errorMessage.emit({ message: 'checkformatch', value: new PhoneNumber(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  onBlur(control: AbstractControl) {
    if (control.errors?.minlength) {
      this.errorMessage.emit({ message: 'minlength', value: new PhoneNumber(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.empty) {
      this.errorMessage.emit({ message: '', value: new PhoneNumber(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
    else if (this.full) {
      this.errorMessage.emit({ message: 'checkformatch', value: new PhoneNumber(this.parts.controls['area'].value, this.parts.controls['exchange'].value, this.parts.controls['subscriber'].value) });
    }
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      if (event.target.type == 'password') {
        event.target.type = 'text';
        window.setTimeout(() => {
          event.target.type = 'password';
        }, 500);
      }
      return true;
    }
  }

  updatePhoneType() {
    var phoneType = this.parts.get('phonetype')?.value;
    this.selectedPhoneNumberType = phoneType;
  }
}