<div class="footerContainer" ngClass.gt-sm="enh-desktop-footer" ngClass.lt-md="enh-mobile-footer">

  <div class="desktop-footer-section">
    <ruf-footer class="footer-container">
        <div role="region" aria-label="info1" class="footer_menulist ">
          <a href="javascript:void(0);" (click)="navigateToLinks('AboutUs')" [class]="menuState ? 'lower-link chex-hand-cursor-enabled' : 'lower-link chex-hand-cursor-disabled'">{{ 'FOOTERS.ABOUT US' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('ContactUs')" [class]="menuState ? 'lower-link chex-hand-cursor-enabled' : 'lower-link chex-hand-cursor-disabled'">{{ 'FOOTERS.CONTACT US' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('LegalNotices')" class="lower-link">{{ 'FOOTERS.LEGAL NOTICES' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('PrivacyPolicy')" class="lower-link">{{ 'FOOTERS.PRIVACY POLICY' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('Info')" class="lower-link">{{ 'FOOTERS.INFORMATION' | translate }}</a>
        </div>
    </ruf-footer>
    <ruf-footer class="footer-container-copyright">
      <div role="region" aria-label="copyright" class="copyright-text ">
        © {{copyrightYear}}, {{ 'FOOTERS.COPYRIGHT' | translate }}
      </div>
    </ruf-footer>
  </div>
  
  <div class="mobile-footer-section">
    <ruf-footer class="footer-container">
        <div role="region" aria-label="info2" class="footer_menulist">
          <a href="javascript:void(0);" (click)="navigateToLinks('AboutUs')" [class]="menuState ? 'lower-link chex-hand-cursor-enabled' : 'lower-link chex-hand-cursor-disabled'">{{ 'FOOTERS.ABOUT US' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('ContactUs')" [class]="menuState ? 'lower-link chex-hand-cursor-enabled' : 'lower-link chex-hand-cursor-disabled'">{{ 'FOOTERS.CONTACT US' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('LegalNotices')" class="lower-link">{{ 'FOOTERS.LEGAL NOTICES' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('PrivacyPolicy')" class="lower-link">{{ 'FOOTERS.PRIVACY POLICY' | translate }}</a>
          <a href="javascript:void(0);" (click)="navigateToLinks('Info')" class="lower-link">{{ 'FOOTERS.INFORMATION' | translate }}</a>
        </div>
    </ruf-footer>
    <ruf-footer class="footer-container-copyright">
      <div role="region" aria-label="copyright1" class="copyright-text">
        © {{copyrightYear}}, {{ 'FOOTERS.COPYRIGHT' | translate }}
      </div>
    </ruf-footer>
  </div>
</div>