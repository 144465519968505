

<div>
    <div class="chex-page-left-right-alignment">
        <ruf-carousel #carouseldashboard *ngIf="carouselImgPath.length>0" [autoplay]="true"
            [loop]="true" [hideArrows]="true" [hideIndicators]="carouselImgPath.length<=1" [ngClass.gt-sm]="'carousel-image-gt-xs'">
            <div rufCarouselSlide  *ngFor="let carouselitem of carouselImgPath">
                <div class="chx-carouselfont chx-carouseltext img-style" [innerHTML]="carouselitem.carouseltext | sanitizeHtml" [style.background-image]="'url(' + carouselitem.carouselimagepath + ')'">                        
                </div>
            </div>
        </ruf-carousel>
    </div>
    <div class="chex-dashboard-seperacarouseltoediv" *ngIf="carouselImgPath.length<=1"></div>
    <div class="chex-page-left-right-alignment chex-dashboard-disputes">
        <chx-dashboard-disputes [disputesText]="disputesText"></chx-dashboard-disputes>
    </div> 
    <div class="chex-dashboard-seperatoediv"></div>
    <div class="chex-page-left-right-alignment chex-dashboard-correspondance">
        <chx-dashboard-correspondence [correspondenceText]="correspondenceText"></chx-dashboard-correspondence>
    </div>  
</div>
