import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorMessageService } from "../_services/error-service/errormessage.service";
import { GlobalErrorHandler } from '../_services/shared/global-error-handler.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private errorMessageService: ErrorMessageService, private globalErrorHandler: GlobalErrorHandler){}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let strMessage = req.url.split("/")?.pop()?.toString()  || '';
        this.globalErrorHandler.handleErrorEx(error, strMessage);
        this.errorMessageService.showErrorPopUp(error);
        return throwError(error);
      })
    );
  }
}


