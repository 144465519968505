import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autocompleteOff]'
})
export class AutocompleteOffDirective {

  constructor(private _el: ElementRef) { 
    const edge = [
      'microsoft edge','chromium-based edge'
  ];
    if(edge.some(x=> x == this.detectBrowser().toLowerCase()))
      this._el.nativeElement.setAttribute('autocomplete', 'none');
    else
      this._el.nativeElement.setAttribute('autocomplete', 'off');
  }

  detectBrowser() { 
    const agent = window.navigator.userAgent.toLowerCase();
    const browser =
      agent.indexOf('edge') > -1 ? 'Microsoft Edge'
        : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
        : agent.indexOf('opr') > -1 ? 'Opera'
        : agent.indexOf('chrome') > -1 ? 'Chrome'
        : agent.indexOf('trident') > -1 ? 'Internet Explorer'
        : agent.indexOf('firefox') > -1 ? 'Firefox'
        : agent.indexOf('safari') > -1 ? 'Safari'
        : 'other';

    return browser;
  }

}
