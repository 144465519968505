<!-- <ruf-app-canvas style="background-color: #E2E2E2;">
    <ruf-card   title="" class="card_alignment"  fxLayout="column"> -->
        <div class="chex-bottom_space">
            <div role="region" aria-label="backtodashboard" class="chex-page-left-right-alignment"><a  href="javascript:void(0);" (click)="backToSource()"><u>{{ 'CONSUMERSCOREDISCLOSUREREPORT.BACKTODASHBOARD' | translate }}</u></a></div>
            <!-- <div class="title">Consumer Score Disclosure Report</div> -->
            <ruf-page-header class="header_text" rufId="page_header_disputeTab_title" title="{{ 'CONSUMERSCOREDISCLOSUREREPORT.TITLE' | translate }}" description=" " ></ruf-page-header>
            <div fxLayout="row" >
                <div fxLayout="column">
                    <div role="region" aria-label="date1" class="input">{{ 'CONSUMERSCOREDISCLOSUREREPORT.DATE1' | translate }}</div>
                    <div role="region" aria-label="date2" class="data">{{ 'CONSUMERSCOREDISCLOSUREREPORT.DATE2' | translate }}</div>
                </div>
                <div fxLayout="column">
                    <div role="region" aria-label="consumerid" class="input">{{ 'CONSUMERSCOREDISCLOSUREREPORT.CONSUMERID' | translate }}</div>
                    <div role="region" aria-label="id" class="data">{{ 'CONSUMERSCOREDISCLOSUREREPORT.ID' | translate }}</div>
                </div>
            </div>
            <div role="region" aria-label="chexscore" class="chex-page-left-right-alignment mat-subheading-2">{{ 'CONSUMERSCOREDISCLOSUREREPORT.CHEXSYSTEMSCORE' | translate }}</div>
            <div fxLayout="row" >
                <div fxLayout="column">
                    <div role="region" aria-label="score" class="input">{{ 'CONSUMERSCOREDISCLOSUREREPORT.SCORE' | translate }}</div>
                    <div role="region" aria-label="date2" class="data">{{ 'CONSUMERSCOREDISCLOSUREREPORT.DATE2' | translate }}</div>
                </div>
                <div fxLayout="column">
                    <div role="region" aria-label="datescore" class="input">{{ 'CONSUMERSCOREDISCLOSUREREPORT.DATESCORE' | translate }}</div>
                    <div role="region" aria-label="id" class="data">{{ 'CONSUMERSCOREDISCLOSUREREPORT.ID' | translate }}</div>
                </div>
            </div>
            <div role="region" aria-label="info1" class="chex-page-left-right-alignment mat-body-2">
                {{ 'CONSUMERSCOREDISCLOSUREREPORT.INFO1' | translate }}
                <!-- {{consumerScoreText}} -->
            </div>
            <!-- <div class="note">
                Note: A score of 9999 is an indication that there is insufficient information in ChexSystems files to create a consumer score.
            </div> -->
            <ruf-statusbar [title]="'Please Note'" role="region" aria-label="note" class="information_text chex-page-left-right-alignment" color="info">
                {{ 'CONSUMERSCOREDISCLOSUREREPORT.NOTE' | translate }}
                
          </ruf-statusbar>
            <div role="region" aria-label="factorscore" class="mat-subheading-2 chex-page-left-right-alignment">{{ 'CONSUMERSCOREDISCLOSUREREPORT.FACTORSCORE' | translate }}</div>
            <div role="region" aria-label="factor" class="chex-page-left-right-alignment">{{ 'CONSUMERSCOREDISCLOSUREREPORT.FACTOR' | translate }}</div>
            <table rufId class="chex-page-left-right-alignment" fisStyle>
                <tr>
                    <th role="region" aria-label="reasoncode" style="border-bottom: 1px solid #95989A;" class="header">{{ 'CONSUMERSCOREDISCLOSUREREPORT.REASONCODE' | translate }}</th>
                    <th role="region" aria-label="desc" style="border-bottom: 1px solid #95989A;" class="header">{{ 'CONSUMERSCOREDISCLOSUREREPORT.DESCRIPTION' | translate }}</th>
                </tr>
                <tr *ngFor="let y of scoreData">
                    <td style="border-bottom: 1px solid #95989A;" class="tabledata">{{y.reason}}</td>
                    <td style="border-bottom: 1px solid #95989A;" class="tabledata">{{y.description}}</td>
                </tr> 
            </table>
        </div>
    <!-- </ruf-card>
</ruf-app-canvas> -->