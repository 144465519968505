import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import { Field, SitecoreRequestData } from '../../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../../_services/sitecore/sitecore.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'chx-disclosure-dispute-trans',
  templateUrl: './disclosure-dispute-trans.component.html',
  styleUrls: ['./disclosure-dispute-trans.component.scss'],
})


export class DisclosureDisputeTransComponent {
  disclosureDisputeContent: string = '';
  isLoader: boolean = false;
  submit=this._translate.instant('BUTTONS.SUBMIT');

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService,
  ) { 

    
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('disputeTabs');
    this.fetchDisclosureDisputeContent();
  }
  
  submitclick(){
    this.router.navigate( ['discloser-dispute']);
  }

  fetchDisclosureDisputeContent() {
    let disclosureDisputeContentPath: string = 'Disclosure Dispute Info/Data/Content/Data';
    this.isLoader = true;
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: disclosureDisputeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'disclosure dispute info') {
          this.disclosureDisputeContent = fieldObject.value;
        }
      });
      this.isLoader = false;

    }, 
    error => {
      this.isLoader = false;
    }, 
    ()=>{
      this.isLoader = false;
    }
    );
  } 


  }

  