export namespace disputesWithOutAttachReq{
  export class DisputeRequestRoot {
    disputeRequest: DisputeRequest
  }
  
  export class DisputeRequest {
    cnsmrPrsnlDetls: CnsmrPrsnlDetls
    cnsmrAddrDetls: CnsmrAddrDetls
    requestSrcCode: string
    recvdDtTm: string
    cnsmrRptOrderedInd: string
    dispdetls: Dispdetl[]
    rqstHdr: RqstHdr
    userName: string
    cellPhoneNumber: string
    correspondenceDeliveryPreference: string
    notificationEmailAddress: string
  }
  
  export class CnsmrPrsnlDetls {
    birthDt: string
    cnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls
    cnsmrNmDetls: CnsmrNmDetls
    govtNbr: string
    cnsmrId: string
    phoneNbr: string
  }
  
  export class CnsmrDrivLicenseDetls {
    drivLicenseIssuSt: string
    drivLicenseNbr: string
  }
  
  export class CnsmrNmDetls {
    suffixNm: string
    frstNm: string
    midNm: string
    lastNm: string
  }
  
  export class CnsmrAddrDetls {
    cnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    addrLine1: string
    addrLine2: string
    cityNm: string
    statCode: string
    zipCode: string
  }
  
  export class Dispdetl {
    infoType: string
    disputeSrc: string
    disputeReportDt: string
    disputeDesc: string
    disputeReasonCategory: string
    dataMartKey: string
    evntBnkCd: string
    evntAcctNbr: string
    personalInformationDetails: PersonalInformationDetails
    inquiryCustomerDetails: InquiryCustomerDetails
    inquiryMasterCustomerDetail: InquiryMasterCustomerDetail
    inquiryResellerCustomerDetail: InquiryResellerCustomerDetail
  }
  
  export class PersonalInformationDetails {
    disputeName: string
    disputeAddress: DisputeAddress
    disputePhoneNumber: string
    disputeDateOfBirth: string
    disputeEmailAddress: string
  }
  
  export class DisputeAddress {
    disputeStreetAddress: string
    disputeCity: string
    disputeState: string
    disputePostalCode: string
  }
  
  export class InquiryCustomerDetails {
    inquiryCustomerName: string
    inquiryCustomerAddress: InquiryCustomerAddress
    inquiryCustomerPhone: string
    inquiryCustomerDate: string
  }
  
  export class InquiryCustomerAddress {
    inquiryCustomerAddressLine1: string
    inquiryCustomerCity: string
    inquiryCustomerState: string
    inquiryCustomerZip: string
  }
  
  export class InquiryMasterCustomerDetail {
    inquiryMasterCustomerId: string
    inquiryMasterCustomerName: string
    inquiryMasterCustomerAddress: InquiryMasterCustomerAddress
    inquiryMasterCustomerPhone: string
  }
  
  export class InquiryMasterCustomerAddress {
    inquiryMasterCustomerAddressLine1: string
    inquiryMasterCustomerAddressLine2: string
    inquiryMasterCustomerCity: string
    inquiryMasterCustomerState: string
    inquiryMasterCustomerZip: string
  }
  
  export class InquiryResellerCustomerDetail {
    inquiryResellerCustomerId: string
    inquiryResellerCustomerName: string
    inquiryResellerCustomerAddress: InquiryResellerCustomerAddress
    inquiryResellerCustomerPhone: string
  }
  
  export class InquiryResellerCustomerAddress {
    inquiryResellerCustomerAddressLine1: string
    inquiryResellerCustomerAddressLine2: string
    inquiryResellerCustomerCity: string
    inquiryResellerCustomerState: string
    inquiryResellerCustomerZip: string
  }
  
  export class RqstHdr {
    rqstTyp: string
    rqstSubTyp: string
  }
  
}
//////////////////////////////////
