import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../../dailog/dialog-with-message/dialog-with-message.component';
import { Registrationresource } from '../../_models/registration/registrationresource.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}
  showErrorPopUp(error: HttpErrorResponse): void {
    const currentPortalUrl = this.document.location.href.toLowerCase();
    const isRegistration = 'registration';
    const isChangePassword = 'changepassword';
    if (Registrationresource.HttpErrorCodes.some((x) => x === error.status)) {
      if(currentPortalUrl.trim().indexOf(isChangePassword) >= 0){
          let messageText = '';
          if(error.status == 400 && error.error.error.trim().indexOf(Registrationresource.changepassword_prev_password_error) >= 0){
            messageText = this._translate.instant('CHANGEPASSWORD.PREVPASSWORDERROR');
          }
          else{
            messageText = this._translate.instant('CHANGEPASSWORD.FAILERROR');
          }
          const dialogMessage = new DialogMessage(
            messageText,
            'Go to Dashboard',
            false,
            Registrationresource.changepassword_failed_title
          );
          const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
            data: dialogMessage,
            panelClass: ['fis-style', 'custom-dialog'],
            disableClose: true,
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if ((result as string) == 'OK') {
              this.router.navigate(['dashboard']);
            }
          });
      }
      else{
      const dialogMessage = new DialogMessage(
        this._translate.instant('DASHBOARDCORRESPONDENCE.UNHANDLEERRORMESSAGE'),
        this._translate.instant('BUTTONS.OK'),
        false,
        this._translate.instant('DASHBOARDCORRESPONDENCE.UNHANDLEERRORTITLE')
      );
      const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
        panelClass: ['fis-style', 'custom-dialog-without-close'],
        data: dialogMessage,
        disableClose: true,
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if ((result as string) == 'OK') {
          if (currentPortalUrl.indexOf(isRegistration) >= 0) {
            window.location.reload();
          }else{
            this.router.navigate(['dashboard']);          }
        }
      });
    }
    }
  }
}
