import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogMessage{
  constructor(public messagetext: string,public buttontext:string, public title:string = ''){

  }
}
@Component({
  selector: 'chexsystem-ws-dialog-idle-timer',
  templateUrl: './dialog-idle-timer.component.html',
  styleUrls: ['./dialog-idle-timer.component.scss']
})
export class DialogIdleTimerComponent implements OnInit {
  message: string;
  buttontext: string;
  
    constructor(public dialogRef: MatDialogRef<DialogIdleTimerComponent>,
      @Inject(MAT_DIALOG_DATA) public data:DialogMessage) { 
        this.message = data.messagetext;
        this.buttontext = data.buttontext;
      }
  
    ngOnInit(): void {
    }
  
    onContinue(): void{
      this.dialogRef.close('continue');
    }

    onReset(): void{
      this.dialogRef.close('Reset');
      window.location.reload();
    }
  
  }

  // export class DialogMessage{
  //   constructor(public messagetext: string,public buttontext:string){
  
  //   }
  // }
