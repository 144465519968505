<div role="group" class="chex-phonecontrol-container" [formGroup]="parts"
  [attr.aria-labelledby]="_formField.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">


  <select fisStyle floatLabel="always" class="chex-phoneType-dropdown mat-body-2" formControlName="phonetype"
    #phonetype>
    <option value="mobile" selected>Mobile</option>
    <option value="phone">Phone</option>
  </select>

  <span style="margin-top: 10px">&ndash;</span>
  <input class="chex-phonecontrol-input-element mat-body-2 chex-area" fisStyle [type]="inputtype" formControlName="area"
    aria-label="Area code" [placeholder]="part1placeholder" [maxlength]="part1maxlength" [size]="part1maxlength"
    (input)="_handleInput(parts.controls['area'], exchange)" (keypress)="keyPressNumbers($event)"
    (focusout)="onBlur(parts.controls['area'])" #area autocompleteOff />
  <span style="margin-top: 10px">&ndash;</span>
  <input class="chex-phonecontrol-input-element mat-body-2 chex-exchange" fisStyle [type]="inputtype"
    formControlName="exchange" aria-label="Exchange code" [placeholder]="part2placeholder" [maxlength]="part2maxlength"
    [size]="part2maxlength" (input)="_handleInput(parts.controls['exchange'], subscriber)"
    (keypress)="keyPressNumbers($event)" (focusout)="onBlur(parts.controls['exchange'])" #exchange autocompleteOff />
  <span style="margin-top: 10px">&ndash;</span>
  <input class="chex-phonecontrol-input-element mat-body-2 chex-subscriber" fisStyle [type]="inputtype"
    formControlName="subscriber" aria-label="Subscriber number" [placeholder]="part3placeholder"
    [maxlength]="part3maxlength" [size]="part3maxlength" (input)="_handleInput(parts.controls['subscriber'])"
    (keypress)="keyPressNumbers($event)" (focusout)="onBlur(parts.controls['subscriber'])" #subscriber
    autocompleteOff />
</div>