<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
    <ruf-dialog-header role="region" aria-label="title" rufId="simple_dialog_header"#header>{{ 'DIALOGSCHLIFT.TITLE' | translate }}</ruf-dialog-header>
    <ruf-dialog-content rufId="dialog_content" rufId="dialog_content"  rufScrollbar [autoHide]=true>
        <div id="detailtxt" rufMarginBottom>
            <span [innerHTML]="schLiftText"></span>
        </div>
       
        <form id="formGroup" [formGroup]="formGroup"> 
            <div fxLayout="column" >
                <div  rufMarginBottom>
                    <mat-form-field  boxStyle floatLabel="always" >
                        <mat-label role="region" aria-label="startdate" id="fieldtxt" >{{ 'DIALOGSCHLIFT.STARTDATE' | translate }}</mat-label>
                        <input 
                        matInput id="StartDate" formControlName="startDateCtrl" [matDatepicker]="picker" placeholder="MM/DD/YYYY" [(ngModel)]="startdate"
                        (dateChange)="startDateChanged($event)" (focusout)="startDateChanged($event)" [matDatepickerFilter]="pastDateFilter"/>
                        <mat-datepicker-toggle fisStyle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker panelClass="fis-style" fisStyle #picker ></mat-datepicker>
                        <mat-error *ngIf="formGroup.get('startDateCtrl')?.hasError('required')">{{ 'DIALOGSCHLIFT.STARTDATEREQ' | translate }}</mat-error>
                        <mat-error *ngIf="formGroup.get('startDateCtrl')?.errors?.isInValid || formGroup.get('startDateCtrl')?.errors?.format">{{ 'DIALOGSCHLIFT.INVALIDDATE' | translate }}</mat-error>
                    </mat-form-field>  
                </div>
                <div  > 
                    <mat-form-field  boxStyle floatLabel="always">
                        <mat-label role="region" aria-label="enddate" id="fieldendtxt">{{ 'DIALOGSCHLIFT.ENDDATE' | translate }}</mat-label>
                        <input 
                        matInput id="EndDate" formControlName="endDateCtrl" [matDatepicker]="picker2" placeholder="MM/DD/YYYY" [(ngModel)]="enddate"
                        (dateChange)="endDateChanged($event)" (focusout)="endDateChanged($event)" [matDatepickerFilter]="endDateFilter"/>
                        <mat-datepicker-toggle fisStyle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker panelClass="fis-style" fisStyle #picker2 ></mat-datepicker>
                        <mat-error *ngIf="formGroup.get('endDateCtrl')?.hasError('required')">{{ 'DIALOGSCHLIFT.ENDDATEREQ' | translate }}</mat-error>
                        <mat-error *ngIf="formGroup.get('endDateCtrl')?.errors?.isInValid || formGroup.get('endDateCtrl')?.errors?.format">{{ 'DIALOGSCHLIFT.INVALIDDATE' | translate }}</mat-error>
                    </mat-form-field>   
                </div>
            </div>
         </form>
        </ruf-dialog-content>
        <ruf-dialog-footer rufId="dialog_footer">
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical rufId>
                <div>
                  <button role="region" aria-label="submit" id="submitBtn" mat-raised-button fisStyle color="primary"  rufId ruf-stepper-actions type="submit"  (click)="submitclick()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
                  <button role="region" aria-label="reset" id="resetBtn" mat-stroked-button fisStyle color="primary"  rufId ruf-stepper-actions type="button"  (click)="resetClick()" >{{ 'BUTTONS.RESET' | translate }}</button>
                </div>
              </div>
        </ruf-dialog-footer>
    </ruf-dialog-panel>
        

