import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SitecoreService } from '../../../_services/sitecore/sitecore.service';

@Component({
  selector: 'chexsystem-ws-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  color: string = '';
  snackBarContent: string = '';
  constructor(private snackBarRef: MatSnackBarRef<SnackbarComponent>,
    private sitecoreService: SitecoreService,
    @Inject(MAT_SNACK_BAR_DATA) public notificationData: string) {
    this.snackBarContent = notificationData;//"test snackbar data";//notificationData;
  }

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}
