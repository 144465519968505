import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { managefreeze } from '../../_models/classes/manage-freeze.model';
import { ApiService } from '../shared/api.service';
import { replacepin } from '../../_models/classes/replace-pin.model';
import { addfreeze } from '../../_models/classes/add-freeze.model';
import { addlift } from '../../_models/classes/add-lift.model';
import { removefreeze } from '../../_models/classes/remove-freeze.model';
import { freezeExists } from '../../_models/classes/freeze-exists.model';
import { searchfreezeexitenceresponse } from '../../_models/classes/searchfreezeexistence-reponse.model';
/**
 * Used to interact with ida endpoint
 */
export interface IManageFreezeService{
  LookupFreeze(lfRequest:managefreeze.LookupFreezeRequest):void;
}

@Injectable({
  providedIn: 'root'
})

/**
 * Used to interact with ida endpoint
 */
export class ManageFreezeService{
  private readonly Api_Base_URL = environment.apiurl;
  constructor(private httpClient : HttpClient, private _apiService: ApiService) { 
   
  }

 

  /**
   * get the freeze details
   * @param lfRequest
   * @returns response of api
   */
   LookupFreeze(lfRequest:managefreeze.LookupFreezeRequest):Observable<managefreeze.LookupFreezeRoot>{
      const url = this.Api_Base_URL.concat('/LookupFreeze');
      return this._apiService.post<managefreeze.LookupFreezeRoot>(
        url,
        lfRequest
      );
      // return this._apiService.post<managefreeze.LookupFreezeRoot>(url,lfRequest)
      // .pipe(      
      //   catchError(this.handleError)
      // );
   }

   /**
   * replace the pin without pin
   * @param rpRequest
   * @returns response of api
   */
    WithoutPinReplacement(rpRequest:replacepin.PinReplacementRequestRoot):Observable<replacepin.PinReplacementResponseRoot>{
      const url = this.Api_Base_URL.concat('/WithoutPinReplacement');
      return this._apiService.post<replacepin.PinReplacementResponseRoot>(url,rpRequest)
      .pipe(      
        catchError(this.handleError)
      );
   }

   /**
   * adda freeze
   * @param afRequest
   * @returns response of api
   */
    AddFreeze(afRequest:addfreeze.AddFreezeRoot):Observable<addfreeze.ResponseRoot>{
      const url = this.Api_Base_URL.concat('/AddFreeze');
      return this._apiService.post<addfreeze.ResponseRoot>(url,afRequest)
      .pipe(      
        catchError(this.handleError)
      );
   }

   /**
   * remove freeze
   * @param afRequest
   * @returns response of api
   */
    RemoveFreeze(rfRequest:removefreeze.RemoveFreezeRoot):Observable<removefreeze.RemoveFreezeResponseRoot>{
      const url = this.Api_Base_URL.concat('/RemoveFreeze');
      return this._apiService.post<removefreeze.RemoveFreezeResponseRoot>(url,rfRequest)
      .pipe(      
        catchError(this.handleError)
      );
   }

   /**
   * adda lift
   * @param alRequest
   * @returns response of api
   */
    LiftRequest(alRequest:addlift.LiftRequestsRoot):Observable<addlift.LiftResponseRoot>{
      const url = this.Api_Base_URL.concat('/LiftRequest');
      return this._apiService.post<addlift.LiftResponseRoot>(url,alRequest)
      .pipe(      
        catchError(this.handleError)
      );
   }

   /**
   * SrchFrzExistence
   * @param alRequest
   * @returns response of api
   */
    SrchFrzExistence(sfRequest:freezeExists.FreezeExistsRequestRoot):Observable<freezeExists.FreezeExistsResponseRoot>{
      const url = this.Api_Base_URL.concat('/SrchFrzExistence');
      return this._apiService.post<freezeExists.FreezeExistsResponseRoot>(
        url,
        sfRequest
      ).pipe(
        catchError(this.handleError)
      );
      // return this._apiService.post<freezeExists.FreezeExistsResponseRoot>(url,sfRequest)
      // .pipe(      
      //   catchError(this.handleError)
      // );
   }
   
  private handleError(error: HttpErrorResponse) {
    let errorMessage:string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

  
}
