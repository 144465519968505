
    <div role="region" aria-label="backtodashboard" class="chex-page-left-right-alignment  chex-top_label_link"><a  href="javascript:void(0);" (click)="backToSource()">{{ 'DISPUTESDETAIL.BACKTODASHBOARD' | translate }}</a></div>
    <ruf-page-header title="Dispute Center" description=" " ></ruf-page-header>
    <div role="region" aria-label="mydisputes" rufMarginBottom id="Heading" class="chex-page-left-right-alignment ruf-caption-2">{{ 'DISPUTESDETAIL.MYDISPUTES' | translate }}</div>
    <div class="chex-page-left-right-alignment">
      <ruf-dynamic-datatable matSort [showLoadingIndicator]="showLoadingIndicator"  #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)" [data]="items" [metadata]="columns">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid" class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted" aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font" data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" aria-label="noitems" class="chex-no-record-message">{{ 'DISPUTESDETAIL.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="confirmationNo" >
         <th role="region" aria-label="confirmation" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISPUTESDETAIL.CONFIRMATION' | translate }}</th>
         <td role="region" aria-label="confirmationelement" class="chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.confirmationNo}} </td>
       </ng-container> 
       <ng-container matColumnDef="receivedDate" >
          <th role="region" aria-label="date" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISPUTESDETAIL.DATE' | translate }}</th>
          <td role="region" aria-label="dateelement" class="chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.receivedDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="targetCompletionDate" >
         <th role="region" aria-label="target" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISPUTESDETAIL.TARGET' | translate }}</th>
         <td role="region" aria-label="targetelement" class="chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.targetCompletionDate | date:'MM/dd/yyyy'}} </td>
       </ng-container>
       <!-- <ng-container matColumnDef="OverallStatus" >
         <th role="region" aria-label="status" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISPUTESDETAIL.STATUS' | translate }}</th>
         <td role="region" aria-label="statuselement" class="chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell *matCellDef="let element" style="cursor:pointer;">{{element.OverallStatus}} </td>
       </ng-container> -->
      </ruf-dynamic-datatable>
     </div>