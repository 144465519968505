import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { disputes } from "../../_models/classes/consumer-discloser-dispute.model";
import { RPIData } from "../../_models/classes/reportedInfo.model";
import { DialogdisputeComponent } from '../dialog-dispute/dialog-dispute.component';
import { DialogdisclosureComponent } from '../dialog-disclosure/dialog-disclosure.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogDispute } from '../dialog-dispute/dialog-dispute.component';
import { DialogDisputeResult } from '../dialog-dispute/dialog-dispute.component';

export class DialogDisputeDetailsInfoItem{
  caption:string;
  value:string;
}
export class DialogDisputeDetailsInfo{
  header:string;
  category:string;
  isSectionDisputed:boolean = false;
  SectionButtonText ="Dispute";
  dialogDisputeDetailsInfoItemList: DialogDisputeDetailsInfoItem[] = [];
}

export class DialogInfo{
  constructor(public header:string){
  }
}
@Component({
    selector: 'chexsystem-ws-dialog-ReportedInfo',
    templateUrl: './dialog-ReportedInfo.component.html',
    styleUrls: ['./dialog-ReportedInfo.component.scss']
  })
  export class DialogReportedInfoComponent {
    reportedFor:string='';
  headerCaption:string ='';
  Rpti: RPIData.reportedInformation;
  itemInfo:DialogDisputeDetailsInfo;
  dialogWidth:string = "477px";
  dialogheight: string = "1178px";
  dialogDisputeResult:DialogDisputeResult = new DialogDisputeResult();

 
  
  constructor(public dialogRef: MatDialogRef<DialogReportedInfoComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public Data:DialogDisputeDetailsInfo) {
      // this.cddData.reportedInfo = this.initRptiData();
      //this.Rpti= this.ReportData();
      // alert('Hi');
      // alert(Data);
      this.headerCaption = Data.header;
      this.itemInfo = Data;
      
  }
  // ReportData(){
  //   var tempRPIData:RPIData.reportedInformation;
  //   tempRPIData = new RPIData.reportedInformation();
  //   tempRPIData.reportedFor="SUSPECTED FRAUD ACTIVITY";
  //   tempRPIData.reportedName= "JOHN CARTER CONSUMER"
  //   tempRPIData.reportedAddress= "123 MAIN ST ANYTOWN, MN 55125-0000";
  //   tempRPIData.reportedID="XXX-XX-1234";
  //   tempRPIData.driverLicense="XXXX9012";
  //   tempRPIData.rtn=" 012345678";
  //   tempRPIData.accountNumber="XXXXXXXXXXXX9876";
  //   tempRPIData.originalChargeOffAmount=175.79;
  //   tempRPIData.closureStatusDate="01/01/2021";
  //   return tempRPIData
  // }

  disputeClick(itemInfo:DialogDisputeDetailsInfo){
    this.dialogDisputeResult = new DialogDisputeResult();
    if(itemInfo.isSectionDisputed){
      return;
    }
    const dialogData = new DialogDispute(itemInfo.header, itemInfo.category);
    const dialogRef = this.dialog.open(DialogdisputeComponent,{position
        : {top: '0%', right: '0%'},
      width:this.dialogWidth,
      height:this.dialogheight,
      panelClass: ["fis-style", "custom-dialog"],
      data:dialogData,
      disableClose: true
    }
    );
    dialogRef.afterClosed().subscribe(result => { 
      var disptResult:DialogDisputeResult = result as DialogDisputeResult;
            if(disptResult.result === "OK"){
              this.dialogDisputeResult = disptResult;
              itemInfo.isSectionDisputed = true;
              itemInfo.SectionButtonText = "Selected";
              //alert(disptResult.description);
              //alert(disptResult.reason);
            }
     
    });
  }

  // onCancel(): void{
  //   //alert('HI');
  //   this.dialogRef.close(this.dialogDisputeResult);
  // }
  onOk(){
    this.dialogRef.close(this.dialogDisputeResult);
  }
  } 
 


