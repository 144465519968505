import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './registration/registration.component';
import { DashboardLandingComponent } from './common/dashboard-common/dashboard-landing/dashboard-landing.component';
import { AppComponent } from './app.component';
import { AuthGuard } from '@ruf/idp-auth';
import { CloseDialogsGuard } from '../app/_services/shared/close-dialogs.guard'
import { UserprofileComponent } from './user-profile/user-profile.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { DisputesDetailAllComponent } from './common/dashboard-common/disputes-main/disputes-detail-all/disputes-detail-all.component';
import { CorrespondenceAllComponent } from './common/dashboard-common/dashboard-correspondence/correspondence-all/correspondence-all.component';
import { PdfViewerComponent } from './common/pdfviewer/pdf-doc-viewer.component';
import { ConsumerDisclosureDisputeComponent } from './consumer-disclosure-dispute/consumer-disclosure-dispute.component';
import { ViewAllDocumentsComponent } from './common/dashboard-common/disputes-main/view-all-documents/view-all-documents.component';
import { ManageSecurityFreezeComponent } from './manage-freeze/manage-security-freeze.component';
import { ManageFreezeDetailsComponent } from './manage-freeze/manage-freeze-details/manage-freeze-details.component';
import { SecurityFreezeInfoComponent } from './security-freeze/security-freeze-info/security-freeze-info.component';
import { SecurityFreezeNoticeComponent } from './security-freeze/security-freeze-notice/security-freeze-notice.component';
import { PlaceFreezeComponent } from './place-freeze/place-freeze.component';
import { ReplaceSecurityPinComponent } from './replace-security-pin/replace-security-pin.component';
import { ConsumerScoreDisclosureReportComponent } from './consumer-score-disclosure-report/consumer-score-disclosure-report.component';
import { ConsumerScorePageComponent } from './consumer-score-disclosure-report/consumer-score-page/consumer-score-page.component';
import { DisclosureDisputeTransComponent } from './consumer-disclosure-dispute/disclosure-dispute-trans/disclosure-dispute-trans.component';
import { OptOutComponent } from './opt-out/opt-out.component';
import { PlaceSecurityAlertComponent } from './security-alerts/place-security-alert.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConsumerDisclosureTransitionComponent } from './consumer-disclosure-transition/consumer-disclosure-transition.component';
import { IdentityTheftInformationComponent } from './identity-theft-information/identity-theft-information.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SitecoresearchComponent } from './sitecore/sitecore-search/sitecore-search.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [CloseDialogsGuard],
    // canActivate: [AuthGuard],
    // component: AppComponent,
    children: [
      { path: 'welcome', component: AppComponent },
      { path: 'registration', component: RegistrationComponent },
      { path: 'sitecoresearch', component: SitecoresearchComponent , canActivate: [AuthGuard]},
      { path: 'dashboard', component: DashboardLandingComponent, canActivate: [AuthGuard] },
      { path: 'userprofile', component: UserprofileComponent, canActivate: [AuthGuard] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'confirm-pi', component: DisclosureComponent, canActivate: [AuthGuard] },
      { path: 'disputedetailall', component: DisputesDetailAllComponent, canActivate: [AuthGuard] },
      { path: 'correspondence-all', component: CorrespondenceAllComponent, canActivate: [AuthGuard] },
      { path: 'pdf-view-docs', component: PdfViewerComponent, canActivate: [AuthGuard] },
      { path: 'pdf-view-docs/:src/:id', component: PdfViewerComponent, canActivate: [AuthGuard] },
      { path: 'discloser-dispute', component: ConsumerDisclosureDisputeComponent, canActivate: [AuthGuard] },
      { path: 'view-all-docs', component: ViewAllDocumentsComponent, canActivate: [AuthGuard] },
      { path: 'manage-sec-frz', component: ManageSecurityFreezeComponent, canActivate: [AuthGuard] },
      { path: 'manage-frz-dtl', component: ManageFreezeDetailsComponent, canActivate: [AuthGuard] },
      { path: 'security-freeze-info', component: SecurityFreezeInfoComponent, canActivate: [AuthGuard] },
      { path: 'security-freeze-notice', component: SecurityFreezeNoticeComponent, canActivate: [AuthGuard] },
      { path: 'place-freeze', component: PlaceFreezeComponent, canActivate: [AuthGuard] },
      { path: 'replace-sec-pin', component: ReplaceSecurityPinComponent, canActivate: [AuthGuard] },
      { path: 'consumer-score', component: ConsumerScoreDisclosureReportComponent, canActivate: [AuthGuard] },
      { path: 'consumer-score-page', component: ConsumerScorePageComponent, canActivate: [AuthGuard] },
      { path: 'discloser-dispute-trans', component: DisclosureDisputeTransComponent, canActivate: [AuthGuard] },
      { path: 'opt-out', component: OptOutComponent, canActivate: [AuthGuard] },
      { path: 'place-alert', component: PlaceSecurityAlertComponent, canActivate: [AuthGuard] },
      { path: 'disclosure-report-info', component: ConsumerDisclosureTransitionComponent, canActivate: [AuthGuard] },
      { path: 'identity-theft-info', component: IdentityTheftInformationComponent, canActivate: [AuthGuard] },
      { path: 'about-us', component: AboutUsComponent, canActivate: [AuthGuard] },
      { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthGuard] },
      {
        path: '',
        redirectTo: '/confirm-pi',
        pathMatch: 'full',
        canActivateChild: [CloseDialogsGuard]
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
