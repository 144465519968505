<div class="chex-bottom_space">
  <ruf-page-header role="region" aria-label="title" rufId="page_header_aboutus_title" title="{{ 'CONTACTUS.TITLE' | translate }}" description=" ">
  </ruf-page-header>
  <div role="region" aria-label="consumerrelations" class="chex-page-left-right-alignment mat-subheading-2">{{ 'CONTACTUS.CONSUMER RELATIONS' | translate }}</div>
  <div class="chex-page-left-right-alignment">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex>
        <mat-card fxLayout="column" class="mail_info">
          <mat-icon class="icon_phone" rufId fontSet="fisfont-solid" fisIcon="telephone"></mat-icon>
          <div role="region" aria-label="phnnum" class="mat-body-2">{{ 'CONTACTUS.PHONE NUMBER' | translate }}</div>
          <div role="region" aria-label="info1" class="mat-body-2">{{ 'CONTACTUS.INFO1' | translate }}</div>
          <div role="region" aria-label="info2" class="mat-body-2">{{ 'CONTACTUS.INFO2' | translate }}</div>
          <div role="region" aria-label="info3" class="mat-body-2">{{ 'CONTACTUS.INFO3' | translate }}</div>
        </mat-card>
      </div>
      <div rufId fxFlex>
        <mat-card fxLayout="column" class="mail_info">
          <mat-icon class="icon_mail" rufId fontSet="fisfont-solid" fisIcon="email"></mat-icon>
          <div role="region" aria-label="mail" class="mat-body-2">{{ 'CONTACTUS.MAIL' | translate }}</div>
          <div role="region" aria-label="infoconsumer1" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER1' | translate }}</div>
          <div role="region" aria-label="infoconsumer2" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER2' | translate }}</div>
          <div role="region" aria-label="infoconsumer3" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER3' | translate }}</div>
          <div role="region" aria-label="infoconsumer4" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER4' | translate }}</div>
        </mat-card>
      </div>
    </div>
  </div>
  <div role="region" aria-label="securityfreeze" class="chex-page-left-right-alignment mat-subheading-2">{{ 'CONTACTUS.SECURITY FREEZE' | translate }}</div>
  <div class="chex-page-left-right-alignment">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex>
        <mat-card fxLayout="column" class="mail_info">
          <mat-icon class="icon_phone" rufId fontSet="fisfont-solid" fisIcon="telephone"></mat-icon>
          <div role="region" aria-label="phnnumber" class="mat-body-2">{{ 'CONTACTUS.PHN NUMBER' | translate }}</div>
          <div role="region" aria-label="infosec1" class="mat-body-2">{{ 'CONTACTUS.INFOSECURITY1' | translate }}</div>
          <div role="region" aria-label="infosec2" class="mat-body-2">{{ 'CONTACTUS.INFO2' | translate }}</div>
          <div role="region" aria-label="infosec3" class="mat-body-2">{{ 'CONTACTUS.INFOSECURITY2' | translate }}</div>
        </mat-card>
      </div>
      <div rufId fxFlex>
        <mat-card fxLayout="column" class="mail_info">
          <mat-icon class="icon_mail" rufId fontSet="fisfont-solid" fisIcon="email"></mat-icon>
          <div role="region" aria-label="mail" class="mat-body-2">{{ 'CONTACTUS.MAIL' | translate }}</div>
          <div role="region" aria-label="infocon1" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER1' | translate }}</div>
          <div role="region" aria-label="infomail1" class="mat-body-2">{{ 'CONTACTUS.INFOMAIL' | translate }}</div>
          <div role="region" aria-label="infocon2" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER3' | translate }}</div>
          <div role="region" aria-label="infocon3" class="mat-body-2">{{ 'CONTACTUS.INFOCONSUMER4' | translate }}</div>
        </mat-card>
      </div>
    </div>
  </div>
</div>