export namespace dashboard{
  //////////////////////////////////
  export class ViewDisputeListReqRoot {
    viewDisputeListReq: ViewDisputeListReq
  }
  
  export class ViewDisputeListReq {
    userName: string
  }
  //////////////////////////////////
  export class DashboardDisputeRoot {
    viewDisputeListRsp: ViewDisputeListRsp
  }
  
  export class ViewDisputeListRsp {
    userName: string
    disputeList: DisputeList[]
    code: string
    message: string
  }
  
  export class DisputeList {
    cnsmrRefId: string
    dateReceived: string
    targetCompletionDate: string
    overallStatus: string
  }
  //////////////////////////////////////////////////////////////////
  
  
  

}

export namespace dashboardEvent{

  export class DashboardDisputeDetailRoot {
    disputeItemDetailRsp: DisputeItemDetailRsp
  }

  export class DisputeItemDetailRsp {
    userName: string
    cnsmrRefId: string
    dateReceived: string
    targetCompletionDate: string
    disputeList: DisputeList[]
    code: string
    message: string
  }
  
  export class DisputeList {
    dataMartKey: string
    eventType: string
    providerName: string
    statusOfDispute: string
  }

  export class DisputeItemDetailReqRoot {
    disputeItemDetailReq: DisputeItemDetailReq
  }
  
  export class DisputeItemDetailReq {
    userName: string
    cnsmrRefId: string
    dateReceived: string
    targetCompletionDate: string
  }
}