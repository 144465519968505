
<div class="chex-timer_rect">
  <p role="region" aria-label="time" class="chex-timer_upperText">{{ 'QUIZTIMER.TIME' | translate }}</p>
  <div ctimer_min_sec_containerlsss="">
    <div class="chex-timer_minute_rect">
      <p role="region" aria-label="minutes" class="chex-timer_minute_grey_text">{{ 'QUIZTIMER.MINUTES' | translate }}</p>
      <p role="region" aria-label="minuteselement" class="chex-timer_minute_text">{{info.minutes}}</p>
    </div>
    <div class="chex-timer_seconds_rect">
      <p role="region" aria-label="secondelement" class="chex-timer_seconds_text">{{info.seconds}}</p>
      <p role="region" aria-label="second" class="chex-timer_seconds_grey_text">{{ 'QUIZTIMER.SECONDS' | translate }}</p>
    </div>
  </div>
  </div>
  
  