<div class="bottom_space">

  <ruf-page-header rufId="page_header_confirm_title" role="region" aria-label="title" title="{{ 'DISCLOSURE.TITLE' | translate }}">
  </ruf-page-header>
  <div role="region" class="chex-page-left-right-alignment mat-body-2" role="region" >
    {{ 'DISCLOSURE.INFO1' | translate }}
  </div>

  <div class="chex-page-left-right-alignment confirm-block">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex role="region" aria-label="firstname">
        <mat-label>{{ 'DISCLOSURE.FIRSTNAME' | translate }}<span *ngIf="isFirstNameMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
        <div id="firstName" class="mat-title">{{ userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="middlename">
        <mat-label>{{ 'DISCLOSURE.MIDDLENAME' | translate }}</mat-label>
        <div id="middleName" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="lastname">
        <mat-label>{{ 'DISCLOSURE.LASTNAME' | translate }}<span *ngIf="isLastNameMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
        <div id="lastName" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="suffix">
        <mat-label>{{ 'DISCLOSURE.SUFFIX' | translate }}</mat-label>
        <div id="suffixName" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm}}</div>
      </div>
    </div>
    <!-- <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex role="region" aria-label="dob">
        <mat-label>{{ 'DISCLOSURE.DOB' | translate }}<span *ngIf="isBirthDtMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
          <div id="birthDate" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.BirthDt}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="ssnum">
        <mat-label>{{ 'DISCLOSURE.USSOCIALSECURITYNUM' | translate }}<span *ngIf="isGovtNbrMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
        <div id="ssn" class="mat-title">{{ssnNoDisplay}}
          <span class="eyeIcon">
            <mat-icon [matTooltip]="eyeIconTooltipSSN" #maskEyeSSN [fisIcon]="eyeIconTypeSSN" (click)="_handleMasking('ssn', maskEyeSSN)"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" style="font-size: 20px;"></mat-icon>
          </span>
        </div>
      </div>
      <div *rufIfGtSm="true" rufId fxFlex>
      </div>
      <div *rufIfGtSm="true" rufId fxFlex>
      </div>
    </div> -->
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <!-- <div rufId fxFlex role="region" aria-label="dlnum">
        <mat-label>{{ 'DISCLOSURE.DLNUMBER' | translate }}</mat-label>
          <div id="dlNumber" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="stateofinsurance">
        <mat-label>{{ 'DISCLOSURE.STATEOFISSURANCE' | translate }}</mat-label>
          <div id="dlState" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt}}</div>
      </div> -->
      <div rufId fxFlex role="region" aria-label="email">
        <mat-label>{{ 'DISCLOSURE.EMAIL' | translate }}<span *ngIf="isEmailAddrMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
          <div id="email" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="phnnum">
        <mat-label>{{ 'DISCLOSURE.PHONENUMBER' | translate }}<span *ngIf="isPhoneMobileMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
          <div id="phNumber" class="mat-title">{{mobilePhone}}</div>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex role="region" aria-label="currentaddr1">
        <mat-label>{{ 'DISCLOSURE.CURRENTADDRESSLINE1' | translate }}<span *ngIf="isAddrLine1Mandatory" class="mandatoryFieldsWarning">*</span></mat-label>
        <div id="addLine1" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.AddrLine1}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="currentaddr2">
        <mat-label>{{ 'DISCLOSURE.CURRENTADDRESSLINE2' | translate }}</mat-label>
          <div id="addLine2" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.AddrLine2}}</div>
      </div>
      <div *rufIfGtSm="true" rufId fxFlex>
      </div>
      <div *rufIfGtSm="true" rufId fxFlex>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex role="region" aria-label="city">
        <mat-label>{{ 'DISCLOSURE.CITY' | translate }}<span *ngIf="isCityNmMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
          <div id="cityName" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.CityNm}}</div>
      </div>
      <div rufId fxFlex role="region" aria-label="state">
        <mat-label>{{ 'DISCLOSURE.STATE' | translate }}<span *ngIf="isStatCodeMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
        <div id="stateCode" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.StatCode}}</div>
      </div>
      <div *rufIfGtSm="true" rufId fxFlex>
      </div>
      <div *rufIfGtSm="true" rufId fxFlex>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <div rufId fxFlex role="region" aria-label="zipcode">
        <mat-label>{{ 'DISCLOSURE.ZIPCODE' | translate }}<span *ngIf="isZipCodeMandatory" class="mandatoryFieldsWarning">*</span></mat-label>
          <div id="zipCode" class="mat-title">{{userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.ZipCode}}</div>
      </div>    
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
      <button id="confirmBtn" role="region" aria-label="confirm" mat-raised-button fisStyle color="primary" class="form_button" rufId ruf-stepper-actions type="button"
      (click)="generateDisclosureReport()" [disabled] = "isMandatoryFieldsEmpty">{{ 'BUTTONS.CONFIRM' | translate }}</button>
    <button id="editBtn" role="region" aria-label="editprofile" mat-stroked-button fisStyle color="primary" class="form_button" rufId ruf-stepper-actions type="button" 
      (click)="editUser($event)">{{ 'BUTTONS.EDITPROFILE' | translate }}</button>
    </div>
    <div class="information mat-body-2 mandatoryFieldsWarning" *ngIf="isMandatoryFieldsEmpty" role="region" aria-label="info2">
      {{ 'DISCLOSURE.INFO2' | translate }}
    </div>
    <div class="information mat-body-2" role="region" aria-label="info3">
      <p>{{ 'DISCLOSURE.INFO3' | translate }}</p>
    </div>
  </div>  
</div>