<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
  <ruf-dialog-header role="region" aria-label="header" rufId="simple_dialog_header" #header>{{
    title
  }}</ruf-dialog-header>
  <ruf-dialog-content rufId="simple_dialog_content">
    <!-- {{message}} -->
    <span [innerHTML]="message"></span>
  </ruf-dialog-content>
  <ruf-dialog-footer rufId="dialog_footer">
    <button
      mat-raised-button
      fisStyle
      class="submit-btn"
      id="submitBtn"
      role="region" aria-label="submit"
      color="primary"
      rufId="dialog_footer_button"
      (click)="onSubmit()"
    >
      {{ buttontext }}
    </button>
    <button
      mat-stroked-button
      fisStyle
      class="form_button"
      id="cancelBtn"
      role="region" aria-label="no"
      rufId
      *ngIf="showcancel"
      (click)="onCancel()"
    >
    {{ 'BUTTONS.NO' | translate }}
    </button>
  </ruf-dialog-footer>
</ruf-dialog-panel>
