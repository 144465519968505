<div class="bottom_space">
  <ruf-page-header rufId="page_header_profile_title" role="region" 
    title="{{ 'USERPROFILE.TITLE' | translate }}">
  </ruf-page-header>
  <div class="chex-page-left-right-alignment">
    <form [formGroup]="userUpdateFromGroup" id="userUpdateFromGroup" autocompleteOff>
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="25px" rufMarginBottom="large">
        <mat-checkbox matInput fisStyle formControlName="isUpdatePersonalDetailCheck"
           (ngModelChange)="disablePersonalDetails($event)" [(ngModel)]="isUpdatePersonalDetail" ngDefaultControl>
          <span role="region" aria-label="updatepersonaldetails" class="ruf-caption-2">{{ 'USERPROFILE.UPDATE PERSONAL DETAILS' | translate }}</span>
        </mat-checkbox>
      </div>
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
        <mat-form-field role="region" aria-label="firstname" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
          <mat-label>{{ 'USERPROFILE.FIRST NAME' | translate }}</mat-label>
          <input id="firstNameCtrl" minlength="2" maxlength="20" formControlName="firstName" fisStyle matInput
            class="placeholder_field_lable" placeholder="Enter First Name" pattern="^(?=.+[A-Za-z])[a-zA-Z -]{2,20}$"
            autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.get('firstName')?.hasError('required')">{{ 'USERPROFILE.FIRST NAME
            ERR' | translate }}
          </mat-error>
          <mat-error
            *ngIf="userUpdateFromGroup.get('firstName')?.errors?.pattern || userUpdateFromGroup.get('firstName')?.errors?.name ==='pattern'"
            matTooltip="{{invalidErrorMessage.name}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle> First Name is
            invalid. Hover for details.</mat-error>
        </mat-form-field>
        <mat-form-field role="region" aria-label="middlename" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
          <mat-label>{{ 'USERPROFILE.MIDDLE NAME' | translate }}</mat-label>
          <input id="middleNameCtrl" minlength="1" maxlength="15" formControlName="middleName" fisStyle matInput
            placeholder="Enter Middle Name" pattern="^(?=.*[A-Za-z])[a-zA-Z -]{1,15}$" autocompleteOff>
          <mat-error
            *ngIf="userUpdateFromGroup.get('middleName')?.errors?.pattern || userUpdateFromGroup.get('middleName')?.errors?.name ==='pattern'"
            matTooltip="{{invalidErrorMessage.middleName}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Middle Name is
            invalid. Hover for details.</mat-error>
        </mat-form-field>
        <mat-form-field role="region" aria-label="lastname" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
          <mat-label>{{ 'USERPROFILE.LAST NAME' | translate }}</mat-label>
          <input id="lastNameCtrl" minlength="2" maxlength="30" formControlName="lastName" fisStyle matInput
            placeholder="Enter Last Name" pattern="^(?=.+[A-Za-z])[a-zA-Z.' -]{2,30}$" autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.get('lastName')?.hasError('required')">{{ 'USERPROFILE.LAST NAME ERR'
            | translate }}
          </mat-error>
          <mat-error
            *ngIf="userUpdateFromGroup.get('lastName')?.errors?.pattern || userUpdateFromGroup.get('lastName')?.errors?.name ==='pattern'"
            matTooltip="{{invalidErrorMessage.lastname}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Last Name is
            invalid. Hover for details.</mat-error>
        </mat-form-field>
        <mat-form-field boxStyle floatLabel="always" class="chex-suffix_dropdown">
          <mat-label role="region" aria-label="suffix" class="chex-form-field-label">{{ 'USERPROFILE.SUFFIX' | translate
            }}
          </mat-label>
          <mat-select [(ngModel)]="suffixDetail" formControlName="suffix" fisStyle placeholder="Select Suffix">
            <mat-option *ngFor="let suffix of suffixes" [value]="suffix.value">
              {{suffix.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
        <mat-form-field role="region" aria-label="dob" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
          <mat-label>{{ 'USERPROFILE.DATE OF BIRTH' | translate }}</mat-label>
          <input matInput id="dobCtrl" formControlName="dateOfBirth" [matDatepicker]="picker" placeholder="MM/DD/YYYY"
            [(ngModel)]="birthDate" [disabled]="datepickerDisabled" (dateChange)="dateChanged($event)" 
            [matDatepickerFilter]="featureDatesFilter" autocompleteOff />
          <mat-datepicker-toggle fisStyle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker panelClass="fis-style" fisStyle #picker></mat-datepicker>
          <mat-error *ngIf="userUpdateFromGroup.get('dateOfBirth')?.hasError('required')">{{ 'USERPROFILE.DATE OF
            BIRTH ERR' | translate }}
          </mat-error>
          <mat-error
            *ngIf="userUpdateFromGroup.get('dateOfBirth')?.errors?.isInValid || userUpdateFromGroup.get('dateOfBirth')?.errors?.format || userUpdateFromGroup.get('dateOfBirth')?.errors?.matDatepickerFilter"
            matTooltip="{{invalidErrorMessage.dateOfBirth}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Invalid Date of
            Birth.
            Hover for details.</mat-error>
        </mat-form-field>
        <div class="chex-form-ssn-field chex-boxed-input-medium-width" fxLayout="row" fxLayout.gt-sm="row"
          fxLayoutGap="5px">
          <mat-form-field role="region" aria-label="ussocialsecuritynumber" boxStyle floatLabel="always"
            class="chex-boxed-input-small-medium-width">
            <mat-label>{{ 'USERPROFILE.U.S.SOCIAL SECURITY NUMBER' | translate }}</mat-label>
            <chexsystem-ws-phonecontrol id="ssnCtrl1" #ssnCtrl1 formControlName="ssn" required fisStyle matInput
              [inputtype]="'password'" [displaymode]=true [part1maxlength]="3" [part2maxlength]="2" [part3maxlength]="4"
              [part1placeholder]="'xxx'" [part2placeholder]="'xx'" [part3placeholder]="'xxxx'" pattern=""
              (errorMessage)="addSSNErrorMessage($event)"></chexsystem-ws-phonecontrol>
            <mat-error class="error-label"
              *ngIf="!ssnMinLength && !ssnInvalid && userUpdateFromGroup.controls['ssn']?.errors?.required">{{
              'USERPROFILE.U.S.SOCIAL SECURITY NUMBER ERR' | translate }}</mat-error>
            <mat-error class="error-label" *ngIf="ssnMinLength">{{ 'USERPROFILE.U.S.SOCIAL SECURITY NUMBER MINLENGTH
              ERR' | translate }}</mat-error>
            <mat-error class="mat-body-1" *ngIf="ssnInvalid">{{ 'USERPROFILE.U.S.SOCIAL SECURITY NUMBER INVALID ERR' |
              translate }}</mat-error>
          </mat-form-field>
          <div class="eyeIcon" [ngClass]="{'disable':isEyeIconDisabled !== true}">
            <mat-icon [matTooltip]="(isEyeIconDisabled)? eyeIconTooltipSSN:''" #maskEyeSSN [fisIcon]="eyeIconTypeSSN"
              (click)="isEyeIconDisabled && handleMasking('ssn')" rufId fisStyle fontSet="fisfont-solid"
              class="fis-icon-accounts ruf-solid">
            </mat-icon>
          </div>
        </div>
        <div class="chex-form-ssn-field chex-boxed-input-medium-width" fxLayout="row" fxLayout.gt-sm="row"
          fxLayoutGap="5px">
          <mat-form-field role="region" aria-label="verifyussocialsecuritynumber" boxStyle floatLabel="always"
            class="chex-boxed-input-small-medium-width">
            <mat-label>{{ 'USERPROFILE.VERIFY U.S. SOCIAL SECURITY NUMBER' | translate }}</mat-label>
            <chexsystem-ws-phonecontrol id="verifyssnCtrl1" formControlName="verifySSN" #verifyssnCtrl1 required
              fisStyle [inputtype]="'password'" [displaymode]=true [part1maxlength]="3" [part2maxlength]="2"
              [part3maxlength]="4" [part1placeholder]="'xxx'" [part2placeholder]="'xx'" [part3placeholder]="'xxxx'"
              (errorMessage)="addVerifySSNErrorMessage($event)"></chexsystem-ws-phonecontrol>
            <mat-error class="error-label"
              *ngIf="!ssnNoMatch && !verifySSNInvalid && !verifySSNMinLength && userUpdateFromGroup.controls['verifySSN']?.errors?.required">
              {{ 'USERPROFILE.VERIFY U.S.SOCIAL SECURITY NUMBER ERR' | translate }}</mat-error>
            <mat-error class="error-label" *ngIf="verifySSNMinLength">{{ 'USERPROFILE.VERIFY U.S.SOCIAL SECURITY NUMBER
              MINLENGTH ERR' | translate }}</mat-error>
            <mat-error class="error-label" *ngIf="verifySSNInvalid">{{ 'USERPROFILE.VERIFY U.S.SOCIAL SECURITY NUMBER
              INVALID ERR' | translate }}</mat-error>
            <mat-error class="error-label" *ngIf="ssnNoMatch && !verifySSNInvalid && !verifySSNMinLength">{{
              'USERPROFILE.U.S.SOCIAL SECURITY NUMBER MISMATCH ERR' | translate }}</mat-error>
          </mat-form-field>
          <span class="eyeIcon" [ngClass]="{'disable':isEyeIconDisabled !== true}">
            <mat-icon [matTooltip]="(isEyeIconDisabled)? eyeIconTooltipVerifySSN : ''" #maskEyeVerifySSN
              [fisIcon]="eyeIconTypeVerifySSN" (click)="isEyeIconDisabled && handleMasking('verifyssn')" rufId fisStyle
              fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid"></mat-icon>
          </span>
        </div>
      </div> -->
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="medium">
        <mat-form-field role="region" aria-label="emailaddr" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
          <mat-label>{{ 'USERPROFILE.EMAIL ADDRESS' | translate }}</mat-label>
          <input id="email" minlength="4" maxlength="50" formControlName="emailCtrl" fisStyle matInput (blur)="checkforEmailAddressAvailability()"
            placeholder="Enter Email Address" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$" autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.controls['emailCtrl']?.hasError('required')">Email Address is
            required.</mat-error>
            <mat-error *ngIf="
                  userUpdateFromGroup
                    .get('emailCtrl')
                    ?.hasError('emailAddressAvailable')
                ">Email is already in use.</mat-error>
          <mat-error
            *ngIf="userUpdateFromGroup.get('emailCtrl')?.errors?.pattern || userUpdateFromGroup.get('emailCtrl')?.errors?.name ==='pattern'"
            matTooltip="{{invalidErrorMessage.email}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Email Address is
            invalid. Hover for details.</mat-error>
        </mat-form-field>
        <div class="chex-form-ssn-field" aria-labelledby="Phone Number">
          <mat-form-field role="region" aria-label="phnnumber" boxStyle floatLabel="always"
            class="chex-boxed-input-medium-width">
            <mat-label>{{ 'USERPROFILE.PHONE NUMBER' | translate }}</mat-label>
            <chexsystem-ws-phonenumbercontrol id="cellphoneCtrl1" #cellphoneCtrl1 [inputtype]="'text'"
              [part1maxlength]="3" [part2maxlength]="3" [part3maxlength]="4" [part1placeholder]="'xxx'"
              [part2placeholder]="'xxx'" [part3placeholder]="'xxxx'" formControlName="cellphoneCtrl"
              (errorMessage)="addPhoneNumberErrorMessage($event)" required fisStyle>
            </chexsystem-ws-phonenumbercontrol>
            <mat-error class="error-label"
              *ngIf="!phoneNumberMinLength && !phoneNumberInvalid && userUpdateFromGroup.controls['cellphoneCtrl']?.errors?.required">
              Mobile/Phone
              Number is required.</mat-error>
            <mat-error class="error-label" *ngIf="phoneNumberMinLength">Mobile/Phone Number is invalid.  10 digit
              numeric
              value must be given.</mat-error>
            <mat-error class="error-label" *ngIf="phoneNumberInvalid">Mobile/Phone Number is invalid.</mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="help-container chex-screen3-form-field"> -->
        <mat-form-field role="region" aria-label="dlnumber" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
            <mat-label>{{ 'USERPROFILE.DL NUMBER' | translate }} 
              <mat-icon rufId fisIcon="information" class="fis-icon-xs pe" [rufTrigger]="dropdownPanelDL" #triggerDL="rufTrigger" fisStyle tabindex="-1"></mat-icon>
         
            <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelDL rufId
              [origin]="triggerDL" [type]="panel.type" xPosition="center" yPosition="bottom">
              <div role="region" aria-label="correspondenceportal" class="mat-body-1 chex-popover-content">
                To enter a new Drivers License, State or Military ID Number, <br />
                you must first check the box beside ‘Update Personal Details’ and then <br />
                click the eye icon to unmask the current ID Number value.
              </div>
            </ruf-dropdown-panel> </mat-label>
          
          <input  formControlName="DLNumberCtrl" fisStyle matInput 
            placeholder="Enter DL, State or Military ID Number" (blur)="!showMaskDl ? LoadDLValidation(false): LoadDLValidation(true)" 
           (input)="onChangeDL()" autocompleteOff [readonly]="showMaskDl">

            <div class="eyeIcon" matSuffix [ngClass]="{'disable':isEyeIconDisabled !== true}">
              <mat-icon [matTooltip]="(isEyeIconDisabled)? eyeIconTooltipSSN:''" #maskEyeSSN [fisIcon]="eyeIconTypeSSN"
                (mousedown)="isEyeIconDisabled && handleMasking('dl')" rufId fisStyle fontSet="fisfont-solid" 
                class="fis-icon-accounts ruf-solid  icon-size">
              </mat-icon>
            </div>
          <mat-error *ngIf="userUpdateFromGroup.controls['DLNumberCtrl'].errors?.DLNumberEnter">Please enter Drivers
            License, State or Military ID Number.</mat-error>
          <mat-error *ngIf="userUpdateFromGroup.controls['DLNumberCtrl'].errors?.patterndoesnotmatch"
            matTooltip="{{invalidErrorMessage.driverLicense}}" matTooltipPosition="below"
            [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Invalid Drivers
            License, State or Military ID Number. Hover for details.</mat-error>
        </mat-form-field>
        <mat-form-field role="region" aria-label="stateofissurance" boxStyle floatLabel="always"
          class="chex-suffix_dropdown">
          <mat-label>{{ 'USERPROFILE.STATE OF ISSUANCE' | translate }}</mat-label>
          <mat-select [(ngModel)]="stateOfIssuance" fisStyle placeholder="State of Issuance"
            formControlName="DLStateCtrl" (selectionChange)="LoadDLValidation(true)">
            <mat-option value="">{{ 'USERPROFILE.STATE2' | translate }}</mat-option>
            <mat-option *ngFor="let state of dlstates" [value]="state.code">
              {{state.code}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userUpdateFromGroup.controls['DLStateCtrl'].errors?.stateselect">Please select state.
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="36px"  rufMarginBottom="medium">
        <div class="ruf-caption-2 chex-ruf-caption">
          <mat-checkbox matInput fisStyle formControlName="isUpdateDeliveryPreferenceCheck"
            [(ngModel)]="isUpdateDeliveryPreference" ngDefaultControl
            (change)="disableDeliveryPreferenceSection($event)">
            <span role="region"  id="correspondenceAddress" class="ruf-caption-2">{{
              'USERPROFILE.CORRESPONDENCE DELIVERY PREFERENCE' | translate }}</span>
          </mat-checkbox>
          <button role="region"  type="button" mat-icon-button
            [rufTrigger]="dropdownPanelCorrespondence" #triggerCorrespondence="rufTrigger" fisStyle tabindex="-1">
            <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
          </button>
          <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelCorrespondence rufId
            [origin]="triggerCorrespondence" [type]="panel.type" xPosition="center" yPosition="bottom">
            <div role="region" aria-label="correspondenceportal" class="mat-body-1 chex-popover-content">
              How would you like to receive documents from ChexSystems?<br />
              When 'portal' is selected, all future correspondence from ChexSystems will be delivered to the
              portal,<br />
              and you will receive an email when a document becomes available online.
            </div>
          </ruf-dropdown-panel>
        </div>        
      </div>
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="36px" rufMarginBottom="medium">
        <mat-radio-group rufThemeColor themeColorFor="mat-radio" fisStyle name="more_options"
          rufId="basic_example_radio_group" formControlName="deliveryPreference" [(ngModel)]="favoriteSeason"
          ngDefaultControl>
          <mat-radio-button fisStyle *ngFor="let season of seasonOptions" name="more_options" [value]="season"
            class="chex-profile-mat-radio-button">
            {{season}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
        <div rufId fxFlex class="chex-profile-address-update">
          <mat-checkbox matInput fisStyle formControlName="isUpdateAddressDetailCheck"
            [(ngModel)]="isUpdateAddressDetail" ngDefaultControl (ngModelChange)="disableAddressDetailSection($event)">
            <span role="region"  id="updateAddress" class="ruf-caption-2">Update Address
              Details</span>
          </mat-checkbox>
        </div>
      </div>
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
        <mat-form-field boxStyle floatLabel="always" class="chex-boxed-input-medium-width">
          <mat-label role="region" aria-label="currentaddrline1" class="form_field_label">{{ 'USERPROFILE.CURRENT
            ADDRESS LINE 1' | translate }}</mat-label>
          <input id="addressCtrl" minlength="2" maxlength="50" formControlName="addressLine1" matInput
            placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.get('addressLine1')?.hasError('required')">{{ 'USERPROFILE.CURRENT
            ADDRESS LINE 1 ERR' | translate }}</mat-error>
          <mat-error *ngIf="userUpdateFromGroup.get('addressLine1')?.errors?.pattern"
            matTooltip="{{invalidErrorMessage.address}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Current Address
            Line
            1 is invalid. Hover for details.</mat-error>
        </mat-form-field>
        <mat-form-field boxStyle floatLabel="always" class="chex-boxed-input-medium-width">
          <mat-label role="region" aria-label="currentaddrline2" class="form_field_label">{{ 'USERPROFILE.CURRENT
            ADDRESS LINE 2' | translate }}</mat-label>
          <input id="addressCtrl1" minlength="2" maxlength="50" formControlName="addressLine2" matInput
            placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.get('addressLine2')?.errors?.pattern"
            matTooltip="{{invalidErrorMessage.address}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Current Address
            Line
            2 is invalid. Hover for details.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
        <mat-form-field role="region" aria-label="city" boxStyle floatLabel="always"
          class="chex-boxed-input-medium-width">
          <mat-label>{{ 'USERPROFILE.CITY' | translate }}</mat-label>
          <input id="City" minlength="2" maxlength="30" formControlName="city" matInput placeholder="Enter City"
            (keypress)="keyPressAlphabets($event)" pattern="^[a-zA-Z ]{2,30}$" autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.get('city')?.hasError('required')">{{ 'USERPROFILE.CITY ERR' |
            translate }}</mat-error>
          <mat-error *ngIf="userUpdateFromGroup.get('city')?.errors?.pattern" matTooltip="{{invalidErrorMessage.city}}"
            matTooltipPosition="below" [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle> City is invalid.
            Hover for details.</mat-error>
        </mat-form-field>
        <div rufId fxFlex>
          <mat-form-field role="region" aria-label="state1" boxStyle floatLabel="always" class="chex-suffix_dropdown">
            <mat-label>{{ 'USERPROFILE.STATE1' | translate }}</mat-label>
            <mat-select fisStyle placeholder="Select State" [(ngModel)]="addressLine1State"
              formControlName="addressLine1State">
              <mat-option *ngFor="let state of states" [value]="state.code">
                {{state.code}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="medium">
        <mat-form-field role="region" aria-label="zipcode" class="chex-boxed-input-medium-width" boxStyle
          floatLabel="always">
          <mat-label>{{ 'USERPROFILE.ZIP CODE' | translate }}</mat-label>
          <input id="postalCode" minlength="5" maxlength="5" formControlName="zipCode" matInput size="5"
            placeholder="Enter Zip Code" (keypress)="keyPressNumbers($event)" pattern="^[0-9]{5}$" autocompleteOff>
          <mat-error *ngIf="userUpdateFromGroup.get('zipCode')?.hasError('required')">{{ 'USERPROFILE.ZIP CODE ERR' |
            translate }}
          </mat-error>
          <mat-error *ngIf="userUpdateFromGroup.get('zipCode')?.errors?.pattern"
            matTooltip="{{invalidErrorMessage['zipCode']}}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
            matTooltipShowDelay="0" matTooltipHideDelay="10000"
            [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>Zip Code is
            invalid.
            Hover for details.</mat-error>
          <mat-error *ngIf="userUpdateFromGroup.controls['zipCode'].errors?.isInValidRange">
            {{invalidErrorMessage.stateZipCodeRange}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
        <button role="region" aria-label="update" id="updateBtn" mat-raised-button fisStyle color="primary"
          class="form_submit_button" rufId ruf-stepper-actions type="submit" (click)="securityFreezeValidation($event)"
          [attr.form]="userUpdateFromGroup"
          [disabled]="(this.isPersonalDetailDisabled && this.isAddressDetailDisabled && this.isDeliveryPreferenceDisabled) ? 'disabled' : null">{{
          'BUTTONS.UPDATE' | translate }}</button>
        <button role="region" aria-label="cancel" id="cancelBtn" mat-stroked-button fisStyle class="form_button"
          (click)="cancelUserProfile($event)" type="button">{{ 'BUTTONS.CANCEL' | translate }}</button>
      </div>
    </form>
  </div>
</div>
