import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { addlift } from '../../_models/classes/add-lift.model';
import { ManageFreezeService } from '../../_services/manage-freeze/manage-freeze.service';
import { userinformation } from '../../_models/classes/user-information.model';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dialog-with-message/dialog-with-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
import { DateAdapter,  MAT_DATE_FORMATS,  MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalVariables } from '../../common/enums/global_variables';
export const MY_FORMATS = {
  parse: { dateInput: 'MM/DD/YYYY' },
  display: { dateInput: 'MM/DD/YYYY', monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',  monthYearA11yLabel: 'YYYY' }
};

export class DialogLiftInput{
  constructor(public freezeEndDate:string){

  }
}

@Component({
  selector: 'chexsystem-ws-dialog-sch-lift',
  templateUrl: './dialog-sch-lift.component.html',
  styleUrls: ['./dialog-sch-lift.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE],
    },
    { 
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS 
    }
  ]
})
export class DialogSchLiftComponent {
  formGroup: FormGroup;
  eyeIconTypeSSN: string = 'eye';
  eyeIconTooltipSSN: string = 'show';
  ssnNo: string;
  ssnNoMask: string;
  ssnNoDisplay: string;
  public enddate: Date;
  freezeEndDate:Date;
  public startdate: Date;
  securityPIN: string = '';
  schLiftText: string;
  today = new Date(moment().format('LL'))
  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private manageFreezeService: ManageFreezeService,
    private _translate: TranslateService,
    private dialog: MatDialog,
    private _dir: Directionality,
    public dialogRef: MatDialogRef<DialogSchLiftComponent>,
    @Inject(MAT_DIALOG_DATA) public Data:DialogLiftInput
  ) {

    this.freezeEndDate = new Date(Data.freezeEndDate);
    this.schLiftText = this._translate.instant(
      'MANAGEFREEZEDETAILS.SCHLIFTTEXT'
    );

    this.formGroup = this._formBuilder.group({
      endDateCtrl: ['', Validators.required],
      startDateCtrl: ['', Validators.required],
    });
  }

  endDateChanged(_$event: any) {
    // if (this.enddate) {
    //   this.formGroup.controls['endDateCtrl'].setErrors({ isInValid: true });
    //   } else {
    //     this.formGroup.controls['endDateCtrl'].setErrors(null);
    //   }
  }

  startDateChanged(_$event: any) {
    this.formGroup.controls['endDateCtrl'].reset();
    // if (this.enddate) {
    //   this.formGroup.controls['startDateCtrl'].setErrors({ isInValid: true });
    //   } else {
    //     this.formGroup.controls['startDateCtrl'].setErrors(null);
    //   }
  }
  submitclick() {
    if (this.formGroup.valid) {
      var strMessage:string = this._translate.instant(
        'MANAGEFREEZEDETAILS.SCHLIFTCONFIRMTEXT'
      )
      strMessage = strMessage.replace('###STARTDATE###', this.ConvertValidDateFormat(this.startdate));
      strMessage = strMessage.replace('###ENDDATE###', this.ConvertValidDateFormat(this.enddate));
      const dialogMessage = new DialogMessage(
        strMessage,
        this._translate.instant(
          'BUTTONS.SCHEDULELIFT'
        ),
        true,
        this._translate.instant(
          'MANAGEFREEZEDETAILS.SCHLIFTOVERRIDETITLE'
        )
      );
      const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
        panelClass: ['fis-style', 'custom-dialog-without-close'],
        data: dialogMessage,
        disableClose: true,
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if ((result as string) == 'OK') {
          this.submitclickHandler();
        }
      });
    }
  }
  submitclickHandler() {
    if (this.formGroup.valid) {
      this.securityPIN = GlobalVariables.globalvars.get('PIN') as string;
      //alert(this.securityPIN);
      if (this.securityPIN !== '') {
        const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
        if (strUserInfo) {
          const objUserInfo = JSON.parse(
            strUserInfo
          ) as userinformation.UserInformationRoot;
          const alertReq: addlift.LiftRequests = new addlift.LiftRequests();
          const alertReqRoot: addlift.LiftRequestsRoot =
            new addlift.LiftRequestsRoot();
          alertReq.ActionType = 'I';
          alertReq.PINNumber = this.securityPIN;
          alertReq.GovtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
          alertReq.CnsmrAddrDetls = new addlift.CnsmrAddrDetls();
          alertReq.CnsmrAddrDetls.CnsmrAddr = new addlift.CnsmrAddr();
          alertReq.CnsmrAddrDetls.CnsmrAddr.AddrLine1 =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.AddrLine2 =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.CityNm =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.StatCode =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
          alertReq.CnsmrAddrDetls.CnsmrAddr.ZipCode =
            objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
          alertReq.CnsmrPrsnlDetls = new addlift.CnsmrPrsnlDetls();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls = new addlift.CnsmrNmDetls();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
          alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName =
            objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName.trim();
          alertReq.LiftDates = new addlift.LiftDates();
          alertReq.LiftDates.LiftDate = [];
          const objLiftDate: addlift.LiftDate = new addlift.LiftDate();
          objLiftDate.StartDate = this.ConvertValidDateFormat(this.startdate);
          objLiftDate.EndDate = this.ConvertValidDateFormat(this.enddate);
          alertReq.LiftDates.LiftDate.push(objLiftDate);

          alertReq.RequestSrcCode = 'SECURE';
          var _userName = GlobalVariables.globalvars.get('UserId') || '';
          alertReq.UserName = _userName;
          alertReq.CellPhoneNbr = objUserInfo.CellPhoneNbr.trim();
          alertReq.CorrespondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference.trim();
          alertReq.NotificationEmailAddr = objUserInfo.NotificationEmailAddr.trim();
          alertReqRoot.LiftRequests = alertReq;

          this.manageFreezeService.LiftRequest(alertReqRoot).subscribe(
            (res) => {
              let strMessage = "";
              let strTitle = this._translate.instant(
                'MANAGEFREEZEDETAILS.SCHLIFTOVERRIDETITLE'
              );
              let strButton = this._translate.instant(
                'BUTTONS.OK'
              );
              if(res.code === "4004"){
                strMessage = this._translate.instant(
                  'MANAGEFREEZEDETAILS.SCHLIFTOVERRIDETEXT'
                );
              }
              else if (res.code === "200"){
                strMessage = this._translate.instant(
                  'MANAGEFREEZEDETAILS.SCHLIFTSUCCESSTEXT'
                );
                strMessage = strMessage.replace('#######', res.referenceId);
              }
              else{
                strMessage = res.message;
              }
              //const strMessage = res.message;
              const dialogMessage = new DialogMessage(
                strMessage,
                strButton,
                false,
                strTitle
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              });
              dialogRef2.afterClosed().subscribe((result) => {
                if ((result as string) == 'OK') {
                  this.dialogRef.close('OK');
                  //window.location.reload();
                }
              });
            },
            (error) => { },
            () => { }
          );
        }
      }
    }
  }
  resetClick() {
    this.formGroup.controls['startDateCtrl'].reset();
    this.formGroup.controls['endDateCtrl'].reset();
    //let tel = null;
    //this.formGroup.controls['ssnCtrl'].reset();
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    dateValue = new Date(dateValue);
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

  ngOnInit(): void {}

  pastDateFilter = (d: _moment.Moment | null): boolean => {
    if (!d) {
      return true;
    }
    const selectedEndDate = new Date((d|| moment()).format('LL'));
    let endDateFiletr: boolean = true;
    endDateFiletr = selectedEndDate < this.freezeEndDate;

    const today = new Date((d|| moment()).format('LL'));
    return (this.today <= today && endDateFiletr);
  };

  endDateFilter = (d: _moment.Moment | null): boolean => {
    if (!d) {
      return true;
    }
    const selectedEndDate = new Date((d|| moment()).format('LL'));
    let endDateFiletr: boolean = true;
    endDateFiletr = selectedEndDate < this.freezeEndDate;
    
    let startDateFiletr: boolean = true;
    startDateFiletr =
    selectedEndDate >= this.startdate;
    return (
      selectedEndDate >= new Date() &&
      startDateFiletr && endDateFiletr
    );
  };
}
