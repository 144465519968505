<ruf-dialog-panel [separator]="true"  rufId="simple_dailog">
    <ruf-dialog-header role="region" aria-label="title" rufId="simple_dialog_header"#header>{{ 'DIALOGSECURITYFREEZEPIN.TITLE' | translate }} </ruf-dialog-header>
    <ruf-dialog-content rufId="dialog_content"  rufScrollbar [autoHide]=true>
      <form [formGroup]="formGroup" class="chex-sec-pin-form" autocomplete="off" >
        <div role="region" aria-label="info1" id="explaintxt" class="form_text_explain">
          {{ 'DIALOGSECURITYFREEZEPIN.INFO1' | translate }}
        </div>
        <div role="region" aria-label="pin" class="chex-sec-pin-Security_Freeze">
          <label id="pintxt" class="chex-sec-pin-freeze_Pin"><span>*</span>{{ 'DIALOGSECURITYFREEZEPIN.PIN' | translate }}</label>
          <mat-form-field class="chex-sec-pin-form chex-sec-pin-box_pin" boxStyle floatLabel="always">
            <input formControlName="txtfreezepin" fisStyle matInput placeholder="Enter PIN" required maxlength="12" [(ngModel)]="answertext"/>
            <mat-error *ngIf="formGroup.get('txtfreezepin')?.hasError('required')">{{ 'DIALOGSECURITYFREEZEPIN.PINREQ' | translate }}</mat-error>
          </mat-form-field>
        </div>
        
        <mat-error class="center" *ngIf="attemptsRemaining>0 && attemptsRemaining!=attemptsAllowed">
          {{ 'DIALOGSECURITYFREEZEPIN.INFO2' | translate }}<b>&nbsp;{{attemptsRemaining}}&nbsp;{{ 'DIALOGSECURITYFREEZEPIN.ATTEMPT' | translate }}&nbsp;</b>{{ 'DIALOGSECURITYFREEZEPIN.LEFT' | translate }}
        </mat-error>
      </form>
    </ruf-dialog-content>
    <ruf-dialog-footer rufId="dialog_footer">
      <div role="region" aria-label="submit" class="center">
        <button id="submitBtn" mat-raised-button fisStyle color="primary" class="chex-sec-pin-form_button" rufId="dialog_footer_button" (click)="onSubmit()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
        <!-- <button mat-stroked-button fisStyle color="primary" class="form_button" rufId (click)="onCancel()">Cancel</button> -->
      </div>
    </ruf-dialog-footer>
  </ruf-dialog-panel>