<ruf-dialog-panel [separator]="true" rufId="simple_dailog">
  <ruf-dialog-header role="region" aria-label="header" rufId="simple_dialog_header" #header>{{
    title
  }}</ruf-dialog-header>
  <ruf-dialog-content rufId="simple_dialog_content">
    <!-- <div class="message_text" [innerHTML]="" ></div> -->

    <span [innerHTML]="message"></span>
  </ruf-dialog-content>
  <ruf-dialog-footer rufId="simple_dialog_footer">
    <button
      mat-raised-button
      fisStyle
      color="primary"
      role="region" aria-label="submit"
      rufId="dialog_footer_button"
      (click)="onSubmit()"
    >
      {{ buttontext }}
    </button>
  </ruf-dialog-footer>
</ruf-dialog-panel>
