import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SitecoreService } from '../../_services/sitecore/sitecore.service';
import { SiteCoreSearchResultData, Item, ResultWithTitle } from '../../_models/classes/sitecoresearch-reponse.model';
import { sitecoresearchmappingdata } from '../../_models/classes/sitecoresearchmappingdata-response.model'
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'chexsystem-ws-sitecoresearch',
  templateUrl: './sitecore-search.component.html',
  styleUrls: ['./sitecore-search.component.scss']
})
export class SitecoresearchComponent implements OnInit {
  searchFormGroup: FormGroup;
  searchResults: Item[];
  firstTimeLoad: boolean = true;
  filteredSearchResults: sitecoresearchmappingdata.item[] = [];//ResultWithTitle[] = new Array();
  sitecoreMappingData: sitecoresearchmappingdata.item[] = [];
  isLoader: boolean = false;
  title=this._translate.instant('SEARCH.TITLE');

  constructor(private _formBuilder: FormBuilder,
    private http: HttpClient,
    private sitecoreService: SitecoreService,
    private piConfirmService: PersonalInformationConfirmationService,
    private _translate: TranslateService) {
    this.searchFormGroup = this._formBuilder.group(
      {
        searchTextCtrl: ['', Validators.required],
      }
    );
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
    this.getSiteCoreSearchMappingData().subscribe((res) => {
      let mappingData = res as sitecoresearchmappingdata.sitecoresearchmappingdata;
      this.sitecoreMappingData = mappingData.data;
    });
  }

  getSiteCoreSearchMappingData() {
    return this.http.get('./././assets/jsondata/sitecoresearchmappingdata.json');
  }

  searchClick() {
    if (this.searchFormGroup.valid) {
      this.isLoader = true;
      this.filteredSearchResults = new Array();
      this.sitecoreService.getSearchResults(this.searchFormGroup.controls['searchTextCtrl']?.value?.trim()).subscribe((res) => {
        let result = res as SiteCoreSearchResultData;
        if (result.data.search.results.totalCount > 0) {
          this.searchResults = result.data.search.results.items;
          this.filteredSearchResults = this.sitecoreMappingData.filter(mappingdata =>
            this.searchResults.filter(searchResult => searchResult.path.toLowerCase().trim().indexOf(mappingdata.itemname.toLowerCase().trim()) > -1).length > 0
          );
        }
        this.firstTimeLoad = false;
        this.isLoader = false;
      });
    }
  }
}
