<div class="chex-bottom_space"> 
  <ruf-page-header role="region" aria-label="title" class="header_text" Id="page_header_disputeTab_title" title="{{ 'CONSUMERDISCLOSURETRANSITION.TITLE' | translate }}" description=" ">
  </ruf-page-header>
  <div role="region" aria-label="consumerDisclosureContent" class="chex-page-left-right-alignment mat-body-2" [innerHTML]="consumerDisclosureContent | sanitizeHtml"></div>

  <div role="region" aria-label="info4" class="chex-page-left-right-alignment mat-body-2">
    <p>{{ 'CONSUMERDISCLOSURETRANSITION.INFO4' | translate }} *</p>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical class="correspondence">
      <mat-radio-group themeColorFor="mat-radio" fisStyle name="more_options" rufId="basic_example_radio_group"
        ngDefaultControl>
        <mat-radio-button fisStyle *ngFor="let option of declineOptions" name="more_options" [value]="option"
          (change)="declineSelected($event)">
          {{option}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px"
    rufMarginVertical rufId>
    <button role="region"  mat-raised-button fisStyle color="primary" class="chex-cnsmr-dscl-trans-continue-btn chex-page-left-right-alignment" rufId
      ruf-stepper-actions type="submit" (click)="generateDisclosure()" [disabled]="!isDeclined">{{ 'BUTTONS.SUBMIT' | translate }}</button>
  </div>
</div>