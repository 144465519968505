<div class="headerContainer">

  <div class="menu_section" *rufIfGtXs="true" rufId="ruf_if_gtxs">
    <ruf-banner style="height: 40px;">
      <div style="display:flex;width:100%;margin-left:0%;margin-right:0%;">
        <div class="top" style="width:12%;margin-left:3%;">
          <ruf-banner-brand rufId="banner_brand_logo1" [logoUrl]="logoUrl"></ruf-banner-brand>
        </div>
        <div id="menu-container" class="menu_section" style="width:72%;margin-left:3%">
          <login-menubar-on-header></login-menubar-on-header>
        </div>
        <div class="combine"
          style="width:8%;margin-left:2%;margin-right:3%;margin-top:0.5%; display:flex;justify-content: flex-end;">
          <div class="search">
            <div [class]="menuState ? 'chex-hand-cursor-enabled' : 'chex-hand-cursor-disabled'" (click)="userSelect('search')">
              <mat-icon fisIcon="search" rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid"
              style="margin-top: 8px;" matTooltip="Search"></mat-icon>
            </div>
          </div>
          <div class="enclose_cont" style="display:flex; margin-left: 10%;">
            <ruf-avatar>
              <div class="profile_img" style="display:flex;">
                <ruf-avatar-image [image]="selectedProfileImage" style="flex: 1;"></ruf-avatar-image>
                <div [matMenuTriggerFor]="divider" style="height:50px;">
                  <mat-icon fisIcon="arrow-down" rufId fisStyle fontSet="fisfont-solid"
                    class="fis-icon-accounts ruf-solid user_profile_divider"></mat-icon>
                </div>
              </div>
              <mat-menu rufId #divider class="fis-style" [overlapTrigger]="false" xPosition="before" yPosition="below">
                <ng-container *ngFor="let item of iconItems; last as last">
                  <button mat-menu-item="" fisStyle (click)="userSelect(item.text)">
                    {{ item.text }}
                  </button>
                  <mat-divider fisStyle *ngIf="!last"></mat-divider>
                </ng-container>
              </mat-menu>
            </ruf-avatar>
          </div>
          <div class="notify" style="margin-left: 3%;">
          </div>
        </div>
      </div>
    </ruf-banner>
  </div>

  <div class="sidemenu_section"  *rufIfXs="true" rufId="ruf_if_xs">
    <header role="banner" class="ruf-banner-header sidemenu-header" style="position:sticky;top:0px;z-index:99;">
      <ruf-toolbar rufid="banner_toolbar" class="ruf-banner-toolbar fis-override ruf-toolbar">
        <div rufid="toolbar" class="ruf-toolbar-rows" style="width:100%;">
          <ruf-toolbar-row rufid="toolbar_row" role="toolbar" class="ruf-toolbar-first-row" aria-label="Toolbar">
            <div style="display:flex;width:100%;margin-left:0%;margin-right:0%;">
              <div class="top" style="margin-top: 8px;">
                <button rufid="demo_main_menu" mat-icon-button="" fisstyle="" (click)="examplebasicsidenav.toggle()"
                  class="mat-focus-indicator mat-icon-button mat-button-base fis-icon-button-md">
                  <span class="mat-button-wrapper">
                    <mat-icon fisIcon="menu" class="icon"></mat-icon>
                  </span>
                  <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                  <span class="mat-button-focus-overlay"></span>
                </button>
              </div>
              <div class="top">
                <ruf-banner-brand rufId="banner_brand_logo1" [logoUrl]="logoUrl"></ruf-banner-brand>
              </div>
            </div>

            <div class="combine"
              style="width:20%;margin-left:2%;margin-right:3%;margin-top:0.5%; display:flex;justify-content: flex-end;">            
              <div>
                <div class="demo-menu-section">
                  <mat-toolbar rufId fisStyle>
                    <button mat-icon-button="" aria-label="Open search" fisStyle [class]="menuState ? 'chex-hand-cursor-enabled' : 'chex-hand-cursor-disabled'" (click)="userSelect('search')">
                      <mat-icon fisIcon="search" matTooltip="Search"></mat-icon>
                    </button>
                    <button mat-icon-button="" [matMenuTriggerFor]="menu" aria-label="Open basic menu" fisStyle (click)="hideSideMenu()">
                      <mat-icon fisIcon="vertical-ellipsis"></mat-icon>
                    </button>
                  </mat-toolbar>

                  <mat-menu rufId #menu="matMenu" class="fis-style">
                    <button mat-menu-item="" *ngFor="let item of iconItems" (click)="userSelect(item.text)" fisStyle>
                      {{ item.text }}
                    </button>
                  </mat-menu>
                </div>
              </div>


              <div class="enclose_cont" style="display:flex; margin-left: 10%;">
                <ruf-avatar>
                  <div class="profile_img" style="display:flex;">
                    <ruf-avatar-image [image]="selectedProfileImage" style="flex: 1;"></ruf-avatar-image>
                    <div style="height:50px;">                     
                    </div>
                  </div>                  
                </ruf-avatar>
              </div>
              <div class="notify" style="margin-left: 3%;">
              </div>
            </div>

          </ruf-toolbar-row>
        </div>
      </ruf-toolbar>
    </header>    
    <div class="navmenu">
      <mat-sidenav-container fxFlex fisStyle [ngClass]="{'chex-logged-menu-disabled': menuState === false, 'chex-logged-menu-enabled' : menuState === true }">
        <mat-sidenav class="navborder" rufThemeColor themeColorFor="ruf-sidemenu" rufId="sidemenu_basic" mode="side"
          #examplebasicsidenav (keydown.escape)="close('escape')" disableClose>
          <ruf-dynamic-sidemenu rufId [items]="sidemenuItems" [style.height]="600" [slideMenu]="false" [multi]="false" [focusSelectedItem]="true"  
            (selectChange)="openbasic($event)" [selectedPath]="selectedPath"
            (selectChange)="selectedPath = $event.path">
          </ruf-dynamic-sidemenu>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </div>
</div>
