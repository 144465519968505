

export class IdaQuizQuestion {
    quizId: string;
    quizRemain: string;
    quizTime: string;
    transId: string;
    userReferenceText1: string;
    userReferenceText2: string;
    userReferenceText3: string;
    idaQuestion1 : QuestionDetail;
    idaQuestion2 : QuestionDetail;
    idaQuestion3 : QuestionDetail;
    idaQuestion4 : QuestionDetail;
    idaQuestion5 : QuestionDetail;
    /**
     * 
     * @param option Create an instance of Ida quiz question detail
     */
    constructor(option: { quizId: string,
        quizRemain: string,
        quizTime: string,
        transId: string,
        userReferenceText1: string,
        userReferenceText2: string,
        userReferenceText3: string,
        idaQuestion1 : QuestionDetail,
        idaQuestion2 : QuestionDetail,
        idaQuestion3 : QuestionDetail,
        idaQuestion4 : QuestionDetail,
        idaQuestion5 : QuestionDetail
    }){
        this.quizId = option.quizId;
        this.quizRemain = option.quizRemain;
        this.quizTime = option.quizTime;
        this.transId = option.transId;
        this.userReferenceText1 = option.userReferenceText1;
        this.userReferenceText2 = option.userReferenceText2;
        this.userReferenceText3 = option.userReferenceText3;
        this.idaQuestion1 = option.idaQuestion1;
        this.idaQuestion2 = option.idaQuestion2;
        this.idaQuestion3 = option.idaQuestion3;
        this.idaQuestion4 = option.idaQuestion4;
        this.idaQuestion5 = option.idaQuestion5;
    }
}

export interface QuestionDetail {
    id: number;
    question: string;
    answer1Id: string;
    answer1Text: string;
    answer2Id: string;
    answer2Text: string;
    answer3Id: string;
    answer3Text: string;
    answer4Id: string;
    answer4Text: string;
}

// export class QuizQuestion {
//     id: number;
//     question: string;
//     possibleAnswers: PossibleAnswer[];
//     answer:string;
//     /**
//      * Creates an instance of the Quiz question
//      * @param id Question Id
//      * @param question Ida Questions
//      * @param possibleAnswers Ida quiz question answers
//      * @param answer answer
//      */
//     constructor( id:number, question:string, possibleAnswers:PossibleAnswer[], answer:string)
//     {
//         this.id =id;
//         this.question =question;
//         this.possibleAnswers=possibleAnswers;
//         this.answer =answer;
//     }
// }

// export class PossibleAnswer {
//     value: string;
//     text: string;
//     /**
//      * Created an instance of Possible answers
//      * @param value Answer value
//      * @param text Answer text
//      */
//     constructor(value:string, text:string)
//     {
//         this.value = value;
//         this.text = text;
//     }
// }

export class IdaQuizAnswers{
    questionId:String;
    answerId:string;
    /**
     * Created an instance of Quix answer
     * @param options Answer value and description
     */
    constructor(options:{questionId:string, answerId:string})
    {
        this.answerId = options.answerId;
        this.questionId = options.questionId;
    }
}

export interface QuizStatus{
    status:string;
}

export enum QuizStatusValue {
    Pass = "pass",
    Fail = "fail",
  }

