import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogMessage,
  DialogwithmessagebtnComponent,
} from '../dailog/dialog-with-message-btn/dialog-with-message-btn.component';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { ManageFreezeService } from '../_services/manage-freeze/manage-freeze.service';
import { userinformation } from '../_models/classes/user-information.model';
import { replacepin } from '../_models/classes/replace-pin.model';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../common/enums/global_variables';
import { environment } from '../../environments/environment';

@Component({
  selector: 'chexsystem-ws-replace-security-pin',
  templateUrl: './replace-security-pin.component.html',
  styleUrls: ['./replace-security-pin.component.scss'],
})
export class ReplaceSecurityPinComponent implements OnInit {
  messagetext: string = '';
  dialogWidth: string = '700px';
  replaceSecurityPINContent:string = '';
  contactChexSystems = environment.contactChexSystemsUrl+ "#securityfreeze-sec";
  title=this._translate.instant('REPLACESECURITYPIN.TITLE');
  continue=this._translate.instant('BUTTONS.CONTINUE');
  cancel=this._translate.instant('BUTTONS.CANCEL');
  info2=this._translate.instant('REPLACESECURITYPIN.INFO2');

  constructor(private router: Router,public dialog: MatDialog
    ,private piConfirmService: PersonalInformationConfirmationService
    , private manageFreezeService: ManageFreezeService
    , private sitecoreService: SitecoreService
    , private _translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('ReplaceSecurityPIN');
    this.fetchReplaceSecurityPINContent();
  }

  fetchReplaceSecurityPINContent() {
    let replaceSecurityPINContentPath: string = 'Replace Security Pin/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: replaceSecurityPINContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'replace security freeze pin') {
          this.replaceSecurityPINContent = fieldObject.value;
        }
      });

    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }

  continueclick() {
    const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
    if (strUserInfo) {
      const objUserInfo = JSON.parse(
        strUserInfo
      ) as userinformation.UserInformationRoot;
      const pinReq: replacepin.PinReplacementRequest =
        new replacepin.PinReplacementRequest();
      const pinReqRoot: replacepin.PinReplacementRequestRoot =
        new replacepin.PinReplacementRequestRoot();
      pinReq.rqstHdr = new replacepin.RqstHdr();
      pinReq.rqstHdr.rqstSubTyp = 'CPR';
      pinReq.rqstHdr.rqstTyp = 'PR';
      pinReq.cellPhoneNbr = objUserInfo.CellPhoneNbr.trim();
      pinReq.cnsmrAddrDetls = new replacepin.CnsmrAddrDetls();
      pinReq.cnsmrAddrDetls.cnsmrAddr = new replacepin.CnsmrAddr();
      pinReq.cnsmrAddrDetls.cnsmrAddr.addrLine1 =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
      pinReq.cnsmrAddrDetls.cnsmrAddr.addrLine2 =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
      pinReq.cnsmrAddrDetls.cnsmrAddr.cityNm =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
      pinReq.cnsmrAddrDetls.cnsmrAddr.statCode =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
      pinReq.cnsmrAddrDetls.cnsmrAddr.zipCode =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
      pinReq.personalDtls = new replacepin.PersonalDtls();
      pinReq.personalDtls.cnsmrNmDetls = new replacepin.CnsmrNmDetls();
      pinReq.personalDtls.cnsmrNmDetls.frstNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
      pinReq.personalDtls.cnsmrNmDetls.lastNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
      pinReq.personalDtls.cnsmrNmDetls.midNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
      pinReq.personalDtls.cnsmrNmDetls.suffixNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
      pinReq.personalDtls.birthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
      pinReq.personalDtls.govtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
      pinReq.personalDtls.cnsmrDrivLicenseDetls =
        new replacepin.CnsmrDrivLicenseDetls();
      pinReq.personalDtls.cnsmrDrivLicenseDetls.drivLicenseIssuSt =
        objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
      pinReq.personalDtls.cnsmrDrivLicenseDetls.drivLicenseNbr =
        objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
      pinReq.personalDtls.phoneNbr =
        objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
      pinReq.correspondenceDeliveryPreference =
        objUserInfo.CorrespondenceDeliveryPreference.trim();
      pinReq.notificationEmailAddr = objUserInfo.NotificationEmailAddr.trim();
      pinReq.recvdDtTm = this.ConvertDateTime(new Date()); // DD/MM/YYYhh:mm:ss
      pinReq.requestSrcCode = "SECURE";
      pinReq.userName = objUserInfo.UserName;

      pinReqRoot.pinReplacementRequest = pinReq;
      this.manageFreezeService.WithoutPinReplacement(pinReqRoot).subscribe(
        (res) => {
          if(res.code === "00" || res.code === "200"){
            this.messagetext =
            this._translate.instant('POPUPS.REPLACESECURITYPINTEXT');
            this.messagetext = this.messagetext.replace('#######', res.referenceId);
          }
          else if(res.code === "4010"){
            this.messagetext =
            this._translate.instant('DASHBOARDCORRESPONDENCE.REPLACESECURITYPINNOFREEZEEXISTS') +
           "<a href ='" + this.contactChexSystems + "'> Security Freeze Department</a>"+ "." ;
          }
          else{
            this.messagetext = res.message;
          }
          
          const dialogMessage = new DialogMessage(
            this.messagetext,
            this._translate.instant('BUTTONS.GOTODASHBOARD'),
            true,
            this._translate.instant('POPUPS.REPLACESECURITYPINTITLE')
          );
          const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
            panelClass: ['fis-style', 'custom-dialog-without-close'],
            data: dialogMessage,
            disableClose: true,
          });
          dialogRef2.afterClosed().subscribe((result) => {});
        },
        (error) => { },
        () => { }
      );
    }
  }

  onCancel(): void {
    this.router.navigate(['dashboard']);
  }

  private ConvertDateTime(dateValue: Date): string {
    return (
      this.ConvertValidDateFormat(dateValue) +
      '' +
      dateValue.getHours() +
      ':' +
      dateValue.getMinutes() +
      ':' +
      dateValue.getSeconds()
    );
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }
}
