import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { PersonalInformationConfirmationService } from '../../../../_services/shared/pi-confirmation.service';
import { HttpClient } from '@angular/common/http';
import { dashboardCorrs } from '../../../../_models/classes/dashboard-correspondence.model';
import { DashboardService } from '../../../../_services/dashboard-services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../../enums/global_variables';

@Component({
  selector: 'chx-correspondence-all',
  templateUrl: './correspondence-all.component.html',
  styleUrls: ['./correspondence-all.component.scss'],
})


export class CorrespondenceAllComponent {
  corresRoot:dashboardCorrs.DashboardCorrespondenceRoot;
  back=this._translate.instant('CORRESPONDENCEALL.BACK');
  status=this._translate.instant('CORRESPONDENCEALL.STATUS');
  noitems=this._translate.instant('CORRESPONDENCEALL.NOITEMS');
  date=this._translate.instant('CORRESPONDENCEALL.DATE');
  communication=this._translate.instant('CORRESPONDENCEALL.COMMUNICATION');
  reference=this._translate.instant('CORRESPONDENCEALL.REFERENCE');
  showLoadingIndicator:boolean = false;
  constructor(
    private router: Router,
    private piConfirmService: PersonalInformationConfirmationService,
    private http: HttpClient,
    private dashboardService: DashboardService,
    private _translate: TranslateService,
  ) { 
    
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
    this.initData();
  }
  
  initData(){
    this.showLoadingIndicator = true;
    this.getCorrespondenceRespData().subscribe((res) => {
      this.corresRoot = res as dashboardCorrs.DashboardCorrespondenceRoot;
      var tempItems: DashboardCorrespondenceItems[] = [];
      ///Make the list in decending prder
      this.corresRoot.CorrespondenceDetailsResponse.CorrespondenceList = 
          this.corresRoot.CorrespondenceDetailsResponse.CorrespondenceList.sort((item1,item2) =>  new Date(item2.DateOfCorrespondence).getTime() - new Date(item1.DateOfCorrespondence).getTime());
          
 
      for (var val of this.corresRoot.CorrespondenceDetailsResponse.CorrespondenceList) {
  
          var item:DashboardCorrespondenceItems = new DashboardCorrespondenceItems();
          if(val.ConsumerReferenceId !== ""){
            item.referenceNumber = val.ConsumerReferenceId  as string;
            item.letterName = val.LetterName;
            item.dateOfCorrespondence = new Date(val.DateOfCorrespondence);
            item.ProcessOid = val.ProcessOid;
            item.DocumentOid = val.DocumentOid;
            tempItems.push(item);
          }
      }
      this.items = tempItems;
    }, 
    error => { this.showLoadingIndicator = false;},
    () => {this.showLoadingIndicator = false; });
  }
  getCorrespondenceRespData() {
    let _userName = GlobalVariables.globalvars.get("UserId") || '';
    let dcReqRoot: dashboardCorrs.CorrespondenceDetailsRequestRoot = new dashboardCorrs.CorrespondenceDetailsRequestRoot();
    dcReqRoot.CorrespondenceDetailsRequest = new dashboardCorrs.CorrespondenceDetailsRequest();
    dcReqRoot.CorrespondenceDetailsRequest.UserName = _userName;
    return this.dashboardService.CorrespondenceList(dcReqRoot);
    //return this.http.get('./././assets/jsondata/CorrespondenceList-Rsp.json');
  }

  items: DashboardCorrespondenceItems[] = [];

  openDisputeDetailAll(confirNo: string){
    //alert(confirNo);
    this.router.navigate( ['disputedetailall']);
    
    //this.router.navigateByUrl('/disputedetail')
  }

  openCorresLetter(dCorrespondenceItem: DashboardCorrespondenceItems){
    //alert(confirNo);
    // localStorage.setItem('DashboardCorrespondenceItems', JSON.stringify(dCorrespondenceItem));

    if (GlobalVariables.globalvars.has('DashboardCorrespondenceItems')){
      GlobalVariables.globalvars.delete('DashboardCorrespondenceItems');                
    }
    GlobalVariables.globalvars.set('DashboardCorrespondenceItems', JSON.stringify(dCorrespondenceItem));  

    this.router.navigate( ['pdf-view-docs', {src: "dashboard"}]);
    //this.router.navigate( ['pdf-view-docs', {src: "d", id: dCorrespondenceItem.referenceNumber}]);
    
    //this.router.navigateByUrl('/disputedetail')
  }

  backToSource(){
    //if(this.backSource === "d"){
      this.router.navigate( ['dashboard']);
    //}

  }
  columns: any[] = [
    {
      field: 'dateOfCorrespondence',
      name: 'Date of Correspondence',
      title: 'Date of Correspondence',
      width: '25%',
      dataType: {
        name: 'date',
        format: ['MM/dd/yyyy']
      }
    },
    {
      field: 'letterName',
      name: 'Communication Type',
      width: '25%',
      title: 'Communication Type'
    },
    {
      field: 'referenceNumber',
      name: 'Reference Number',
      title: 'Reference Number',
      width: '25%'
    }
  ];

  rowSelectionChange(event: any) {
  }

  onStateChange(event: any) {
  }


  }

  export class DashboardCorrespondenceItems {
    referenceNumber: string;
    letterName: string="";
    dateOfCorrespondence: Date;
    ProcessOid: string;
    DocumentOid: string;
  }