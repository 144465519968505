import { Component, OnInit,Inject,  Optional} from '@angular/core';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { FormBuilder } from '@angular/forms';
import { AfterViewChecked, AfterViewInit } from '@angular/core';
import { ChangeDetectorRef, ViewEncapsulation, ViewChild} from '@angular/core';
import { RufUploadStatus } from '@ruf/widgets';
import { interval, BehaviorSubject } from 'rxjs';
import { DialogagevalidityComponent } from '../dialog-age-validity/dialog-age-validity.component';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dialog-with-message/dialog-with-message.component';
import { disputes } from '../../_models/classes/consumer-discloser-dispute.model';
import jspdf from 'jspdf';
import { Router } from '@angular/router';
import { disputesWithAttachRes } from '../../_models/classes/dispute-with-attachment-res.model';
import { disputesWithAttachReq } from '../../_models/classes/dispute-with-attachment-req.model';
import { userinformation } from '../../_models/classes/user-information.model';
import { DisputeTabsService } from '../../_services/dispute-tabs/dispute-tabs.service';
import { DialogwithmessagebtnComponent } from '../dialog-with-message-btn/dialog-with-message-btn.component';
import { Multer } from 'multer';
import { Express } from 'express';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../common/enums/global_variables';
import { disputesWithOutAttachRes } from '../../_models/classes/dispute-without-attachment-res.model';
import { disputesWithOutAttachReq } from '../../_models/classes/dispute-without-attachment-req.model';
import { DialogwithmessageCancelComponent } from '../../dailog/dialog-with-message-cancel-btn/dialog-with-message-cancel-btn.component';

export class DialogUploaderInput{
  constructor(public input:disputes.DisputeDiscloserSubmitData[]  | null, public confirmationNo: string, public source: string = "Dashboard"){
  }
}

@Component({
  selector: 'chexsystem-ws-dialog-file-uploader',
  templateUrl: './dialog-file-attachment.component.html',
  styleUrls: ['./dialog-file-attachment.component.scss']
})
export class DialogFileUploaderComponent implements OnInit, AfterViewChecked {
  message: string;
  contactChexSystemsUrl : string='https://www.chexsystems.com/web/chexsystems/consumerdebit/footer/contactus/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDRxdHA1Ngg183AP83QwcXX39LIJDfYwMjMz0w_EqcDfTj8Ij7WtOQL8RVD8O4GhAQL-BOV77zYxh-nFbQJx-3B6kSD9QQRQl4W9mRNj-KFQl_h4hRkAlrj7uxkbehu6BJugKsAQxoUjCbwLQkQW5oWAQYZDpme6oqAgAPkZcHg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/'

    
      // filenamelabel ;
      // sizelabel ;
      // progresslabel ;
      loading = RufUploadStatus.New;
      pdfLoading = RufUploadStatus.New;
      image: any;
      file2:Express.Multer.File[] = [];
      previousFile: any;
      confirmationNo: string;
      muiltiUpload:boolean=true;
      multiSelection:boolean=true;
      continueUpload:boolean=false;
      Validation1: string = 'Total size must be less or equal to 25MB.';
      validationModel:UploaderValidationModel=new UploaderValidationModel();
      progress = 0;
      //fileExtn = '*';
      fileExtn = '.JPG,.BMP,.PNG,.TIF,.TXT,.RTF,.PDF,.DOC,.DOCX';
      maxSize = 25;
      totalSize = 0;
      ///////////////////
      interval$ = interval(1000);
      //////////////////////
      sizeArray:string[] = [];
      isDisabled:boolean = false;//true;
      disputeData: disputes.DisputeDiscloserSubmitData[]=[];
      source:string;
      isUploadButtonDisable:boolean=false;
      info1:string;
      info2:string;

      @ViewChild('rufUpload1', {static: false}) rufUpload1: any;
      @ViewChild('rufUpload2', {static: false}) rufUpload2: any;
      @ViewChild('rufUpload3', {static: false}) rufUpload3: any;
     
      constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<DialogFileUploaderComponent>,
        public dialog: MatDialog,
        private _translate: TranslateService,
        private disputeTabsService: DisputeTabsService,
        @Inject(MAT_DIALOG_DATA) public data:DialogUploaderInput
        
        ) { 
          this.info1 = this._translate.instant(
            'DIALOGFILEATTACHMENT.INFO1'
          );
          this.info2 = this._translate.instant(
            'DIALOGFILEATTACHMENT.INFO2'
          );
          this.source = data.source;
          this.setDisableStatus();
          this.confirmationNo = data.confirmationNo;
          this.disputeData.length = 0;
          //alert(this.confirmationNo);
          data.input?.forEach(element => {
            this.disputeData.push(element);
          });
      }
       
     
       ngAfterViewChecked(): void {
         this.changeDetectorRef.detectChanges();
       }
     
       setDisableStatus(){
        this.isDisabled = (this.file2.length > 0 && this.file2.length <= 5) ? false : true;
        this.isUploadButtonDisable = (this.file2.length >= 5) ? true : false;
        this.setUploadButtonStatus(this.isUploadButtonDisable);
        let anyVal = false;
        this.file2.forEach(element => {
          if(this.rufUpload2 && this.rufUpload2?.getLoadingStatus(element) === 1){
            anyVal = true;
          }
        });
        if(anyVal){
          this.isDisabled = true;
        }

        this.totalSize = this.getTotalSize();

        if(this.totalSize > this.maxSize){
          this.validationModel.isFileSize = false;    
          this.isDisabled = true;
        }
        else{
          this.validationModel.isFileSize = true;
          //this.isDisabled = false;
        }
       }
       setUploadButtonStatus(val:boolean){
        const child = document.querySelector<HTMLButtonElement >("#ruf_file_upload_0_button_0");
        if (child) {
          child.disabled = val;
        }
       }

       onError(event: any) {
          let errorType;
          errorType = event.invalidFileSize ? event.invalidFileSize.value : event.invalidFileType.value;
          errorType.forEach((element: any) => {
            let extensionList = this.fileExtn.split(",");
            if(!extensionList.includes( this.fileTypeCheck(element).toUpperCase()) ){
              this.rufUpload2.updateLoadingStatus(element, RufUploadStatus.Error);
              //this.isDisabled = true;
            }
            else{
              this.rufUpload2.updateLoadingStatus(element, RufUploadStatus.Success);
            }
          }
         );
         this.setDisableStatus();
       }
     

       fileTypeCheck(event:File) {
        //alert(event.name);
        let extension;
        if (event) {
          let extArray = [];
          extArray = event.name.split('.');
          extension = extArray[extArray.length -1];
        }
        return '.'+extension;
      }
      saveFile(event: any) {
        this.validationModel.isFileCountValid = true;
        this.validationModel.isFileSize = true;
       if (event) 
       {
        
            this.sizeArray.length=0;
            for (let i = 0; i < this.file2.length; i++) { 
              //alert((this.file2[i] as File).size);
              
              this.sizeArray.push(this.formatBytes(this.file2[i]['size']));
              //this.file3
            }
          
          if(this.file2.length <= 5){

            //this.continueUpload = true;
            this.rufUpload2.updateLoadingStatus(event, RufUploadStatus.Loading);
            setTimeout(() => {
              if (this.file2) {
                this.rufUpload2.updateLoadingStatus(event, RufUploadStatus.Success);
              }
            }, 1000);
            /////////////////////////
            const intervalSubs$ = this.interval$.subscribe(res => {
              this.progress += 100 / 5;
              this.changeDetectorRef.markForCheck();
            });
        
            this.loading = RufUploadStatus.Loading;
            setTimeout(() => {
              this.loading = RufUploadStatus.Success;
              //this.progress = 0;
              intervalSubs$.unsubscribe();
              this.changeDetectorRef.markForCheck();
              
            }, 5000);
            ////////////////////////////
            this.setDisableStatus();
          }
          else{
            this.validationModel.isFileCountValid = false;
            this.rufUpload2.model=false;
            for (let i = 0; i < event.length; i++) { 
              this.file2 = this.file2.filter(item => item !== event[i])
             }
            
          }
          
           this.totalSize = this.getTotalSize();

           if(this.totalSize > this.maxSize){
            this.validationModel.isFileSize = false;
           }
           else{
              this.validationModel.isFileSize = true;
           }
        }
        this.setDisableStatus();
        
      }
     
      getTotalSize(){
        var totSize:number=0;
        for (let i = 0; i < this.file2.length; i++) { 
          //alert((this.file2[i] as File).size);
          
          totSize = totSize + (((this.file2[i]).size)/1000000);
          //this.file3
         }
         return totSize;
      }
     
      readFile(file: any) {
       const reader = new FileReader();
       reader.onload = (event) => {
           this.changeDetectorRef.markForCheck();
       };

      }
     
      reset1(file: any) {
      }
     
      reset2(file: any) {
        this.file2 = this.file2.filter(item => item !== file);
        this.setDisableStatus();
       }
    ngOnInit(): void {
    }
 
    onSubmit(): void{
      this.submitWithAttachment();
      // if(this.source === "Dashboard"){
      //   this.submitWithAttachment();
      // }
      // else{
      //   if(this.file2.length > 0){
      //     this.submitWithAttachment();
      //   }
        // else{
        //   this.submitWithOutAttachment();
        // }
      // }
      
      //this.dialogRef.close('OK');
    }  

    submitWithAttachment(){
      const strUserInfo = GlobalVariables.globalvars.get("UserInformation");
      if(strUserInfo){
        const objUserInfo =  JSON.parse(strUserInfo)  as userinformation.UserInformationRoot;
        let reqRoot:disputesWithAttachReq.DisputeRequestRoot = new disputesWithAttachReq.DisputeRequestRoot();
        reqRoot.disputeRequest = new disputesWithAttachReq.DisputeRequest();
        reqRoot.disputeRequest.cellPhoneNumber = objUserInfo.CellPhoneNbr.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls = new disputesWithAttachReq.CnsmrAddrDetls();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr = new disputesWithAttachReq.CnsmrAddr();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.addrLine1 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.addrLine2 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.cityNm = objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.statCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.zipCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls = new disputesWithAttachReq.CnsmrPrsnlDetls();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls = new disputesWithAttachReq.CnsmrNmDetls();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.frstNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.lastNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.midNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.suffixNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.birthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
        reqRoot.disputeRequest.cnsmrPrsnlDetls.govtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls = new  disputesWithAttachReq.CnsmrDrivLicenseDetls();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseIssuSt = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseNbr = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.phoneNbr = objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrId = "";
        reqRoot.disputeRequest.correspondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference.trim();
        reqRoot.disputeRequest.notificationEmailAddress = objUserInfo.NotificationEmailAddr.trim();
        reqRoot.disputeRequest.cnsmrRptOrderedInd = "N";
        reqRoot.disputeRequest.recvdDtTm  = this.ConvertDateTime(new Date()) // DD/MM/YYYhh:mm:ss
        reqRoot.disputeRequest.requestSrcCode = "SECURE";
        reqRoot.disputeRequest.userName = objUserInfo.UserName;
        reqRoot.disputeRequest.rqstHdr = new disputesWithAttachReq.RqstHdr();
        
        reqRoot.disputeRequest.rqstHdr.rqstTyp = "DP";

        if(this.source === "Dashboard"){
          reqRoot.disputeRequest.rqstHdr.rqstSubTyp = "XD";
          //reqRoot.disputeRequest.dispdetls = this.getEmptyDisputeDetail();
          reqRoot.disputeRequest.cnsmrRefId = this.confirmationNo;
        }
        else{
          reqRoot.disputeRequest.rqstHdr.rqstSubTyp = "CD";
          reqRoot.disputeRequest.dispdetls = this.getDisputeDetail();
          reqRoot.disputeRequest.cnsmrRefId = "";
        }
        this.disputeTabsService.disputewithattachment(this.file2, reqRoot).subscribe(
          res => {
            let strMessage:string = "";
            if(res.code === "00"){//---------Success Scenarios start-----------------------------
              if(this.source === "Dashboard"){//----------------From Dashboard Start ----------------------
                strMessage = this._translate.instant(
                  'DISPUTETABS.DASHBOARDDOCUMENTUPLOADED'
                );
                strMessage = strMessage.replace('#######', res.referenceId);
                const dialogMessage = new DialogMessage(strMessage, this._translate.instant(
                  'BUTTONS.OK'
                ),false,this._translate.instant(
                  'DISPUTETABS.TRANSITIONPOPUPTITLE'
                ));
                const dialogRef = this.dialog.open(DialogwithmessageComponent,{
                  panelClass: ["fis-style", "custom-dialog-without-close"],
                  data: dialogMessage,
                  closeOnNavigation: true,
                  disableClose: true
                  }
                );
                dialogRef.afterClosed().subscribe(result => { 
                    if((result as string) == "OK"){
                      this.dialogRef.close('OK');
                    }
                });
              }//----------------From Dashboard End ----------------------
              else{//----------------From Dispute tabs Start ----------------------
                strMessage = this._translate.instant(
                  'DISPUTETABS.SUBMITDISPUTESUCCESS'
                );
                strMessage = strMessage.replace('#######', res.referenceId);
                const dialogMessage = new DialogMessage(
                  strMessage,
                  this._translate.instant(
                    'BUTTONS.GOTODASHBOARD'
                  ),
                  false,
                  this._translate.instant(
                    'DISPUTETABS.SUBMITDISPUTESUCCESSTITLE'
                  )
                );
                const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
                  panelClass: ["fis-style", "custom-dialog-without-close-scroll"],
                  data: dialogMessage,
                  disableClose: true
                });
                dialogRef2.afterClosed().subscribe((result) => {
                  if ((result as string) == 'OK') {
                    this.disputeTabsService.triggerResetAllAfterDelete();
                    this.router.navigate(['dashboard']);
                  }
                });
                
              }//----------------From Dispute tabs END ----------------------
            }//---------Success Scenarios End-----------------------------
            else if(res.code === "4055"){//---------Password Protected File Scenarios start-----------------------------
              if(this.source === "Dashboard"){//----------------From Dashboard Start ----------------------
                strMessage = this._translate.instant(
                  'DISPUTETABS.DISPUTEPASSWORDPROTECTEDTEXT2'
                );
                const dialogMessage = new DialogMessage(strMessage, this._translate.instant(
                  'BUTTONS.OK'
                ),false,this._translate.instant(
                  'DISPUTETABS.TRANSITIONPOPUPTITLE'
                ));
                const dialogRef = this.dialog.open(DialogwithmessageComponent,{
                  panelClass: ["fis-style", "custom-dialog-without-close"],
                  data: dialogMessage,
                  closeOnNavigation: true,
                  disableClose: true
                  }
                );
                dialogRef.afterClosed().subscribe(result => { 
                    if((result as string) == "OK"){
                      this.dialogRef.close('OK');
                    }
                });
              }//----------------From Dashboard End ----------------------
              else{//----------------From Dispute tabs Start ----------------------
                strMessage = this._translate.instant(
                  'DISPUTETABS.DISPUTEPASSWORDPROTECTEDTEXT'
                );
                const dialogMessage = new DialogMessage(
                  strMessage,
                  this._translate.instant(
                    'BUTTONS.CONTINUE'
                  ),
                  true,
                  this._translate.instant(
                    'DISPUTETABS.TRANSITIONPOPUPTITLE'
                  )
                );
                const dialogRef2 = this.dialog.open(DialogwithmessageCancelComponent, {
                  panelClass: ["fis-style", "custom-dialog-without-close"],
                  data: dialogMessage,
                  disableClose: true
                });
                dialogRef2.afterClosed().subscribe((result) => {
                  if ((result as string) == 'OK') {
                    ///Call Dispute without attachment
                    this.submitWithOutAttachment();
                  }
                  else{
                    this.router.navigate(['contact-us']);
                  }
                });
                
              }//----------------From Dispute tabs End ----------------------
            }//---------Password Protected File Scenarios End-----------------------------
            else{//---------Unhandled scenarios start-----------------------------
              strMessage = this._translate.instant(
                'DASHBOARDCORRESPONDENCE.UNHANDLEERRORMESSAGE'
              );
                  if(this.source === "Dashboard"){//----------------From Dashboard Start ----------------------
                    const dialogMessage = new DialogMessage(strMessage, this._translate.instant(
                      'BUTTONS.OK'
                    ),false,this._translate.instant(
                      'DISPUTETABS.TRANSITIONPOPUPTITLE'
                    ));
                    const dialogRef = this.dialog.open(DialogwithmessageComponent,{
                      panelClass: ["fis-style", "custom-dialog-without-close"],
                      data: dialogMessage,
                      closeOnNavigation: true,
                      disableClose: true
                      }
                    );
                    dialogRef.afterClosed().subscribe(result => { 
                        if((result as string) == "OK"){
                          this.dialogRef.close('OK');
                        }
                    });
                }//----------------From Dashboard End ----------------------
                else//----------------From Dispute tabs Start ----------------------
                {
                  const dialogMessage = new DialogMessage(
                    strMessage,
                    this._translate.instant(
                      'BUTTONS.GOTODASHBOARD'
                    ),
                    false,
                    this._translate.instant(
                      'DISPUTETABS.TRANSITIONPOPUPTITLE'
                    )
                  );
                  const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
                    panelClass: ["fis-style", "custom-dialog-without-close"],
                    data: dialogMessage,
                    disableClose: true
                  });
                  dialogRef2.afterClosed().subscribe((result) => {
                    if ((result as string) == 'OK') {
                      this.router.navigate(['dashboard']);
                    }
                  });
                }//----------------From Dispute tabs End ----------------------
            }//---------Unhandled scenarios End-----------------------------

          }, 
          error => { }, 
          ()=>{ });  
      }
    }

    submitWithOutAttachment(){
      const strUserInfo = GlobalVariables.globalvars.get("UserInformation");
      if(strUserInfo){
        const objUserInfo =  JSON.parse(strUserInfo)  as userinformation.UserInformationRoot;
        let reqRoot:disputesWithOutAttachReq.DisputeRequestRoot = new disputesWithOutAttachReq.DisputeRequestRoot();
        reqRoot.disputeRequest = new disputesWithOutAttachReq.DisputeRequest();
        reqRoot.disputeRequest.cellPhoneNumber = objUserInfo.CellPhoneNbr.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls = new disputesWithOutAttachReq.CnsmrAddrDetls();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr = new disputesWithOutAttachReq.CnsmrAddr();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.addrLine1 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.addrLine2 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.cityNm = objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.statCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
        reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.zipCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls = new disputesWithOutAttachReq.CnsmrPrsnlDetls();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls = new disputesWithOutAttachReq.CnsmrNmDetls();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.frstNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.lastNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.midNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.suffixNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.birthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
        reqRoot.disputeRequest.cnsmrPrsnlDetls.govtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls = new  disputesWithOutAttachReq.CnsmrDrivLicenseDetls();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseIssuSt = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseNbr = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.phoneNbr = objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
        reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrId = "";
        reqRoot.disputeRequest.correspondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference.trim();
        reqRoot.disputeRequest.notificationEmailAddress = objUserInfo.NotificationEmailAddr.trim();
        reqRoot.disputeRequest.recvdDtTm  = this.ConvertDateTime(new Date()) // DD/MM/YYYhh:mm:ss
        reqRoot.disputeRequest.requestSrcCode = "SECURE";
        reqRoot.disputeRequest.userName = objUserInfo.UserName;
        reqRoot.disputeRequest.rqstHdr = new disputesWithOutAttachReq.RqstHdr();
        reqRoot.disputeRequest.rqstHdr.rqstSubTyp = "CD";
        reqRoot.disputeRequest.rqstHdr.rqstTyp = "DP";
  
        reqRoot.disputeRequest.cnsmrRptOrderedInd = "N";
  
        reqRoot.disputeRequest.dispdetls = this.getDisputeDetail();
        
        this.disputeTabsService.disputewithnoattachment(reqRoot).subscribe(
          res => {
            let strMessage:string = "";
            let strTitle:string = "";
            if(res.code === "00"){
              strMessage = this._translate.instant(
                'DISPUTETABS.SUBMITDISPUTESUCCESS'
              );
              strMessage = strMessage.replace('#######', res.referenceId);
              strTitle = this._translate.instant(
                'DISPUTETABS.SUBMITDISPUTESUCCESSTITLE'
              );
            }
            else{
              strMessage = this._translate.instant(
                'DASHBOARDCORRESPONDENCE.UNHANDLEERRORMESSAGE'
              );
              strTitle = this._translate.instant(
                'DISPUTETABS.TRANSITIONPOPUPTITLE'
              );
            }
  
            const dialogMessage = new DialogMessage(
              strMessage,
              this._translate.instant(
                'BUTTONS.GOTODASHBOARD'
              ),
              true,
              strTitle
            );
            const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
              panelClass: ["fis-style", "custom-dialog-without-close"],
              data: dialogMessage,
              disableClose: true
            });
            dialogRef2.afterClosed().subscribe((result) => {
              if ((result as string) == 'OK') {
                this.disputeTabsService.triggerResetAllAfterDelete();
                this.router.navigate(['dashboard']);
                //window.location.reload();
              }
            });
            
          }, 
          error => {
            this.dialogRef.close('NO');
          }, 
          ()=>{
            this.dialogRef.close('NO');
          });  
      }
    }

    getInitEmptyObject(){
      let tempItem:disputesWithAttachRes.Dispdetl = new disputesWithAttachRes.Dispdetl();
      /////////////////////////////
      tempItem.personalInformationDetails = new disputesWithAttachRes.PersonalInformationDetails();
      tempItem.personalInformationDetails.disputeName = "";
      tempItem.disputeDesc = "";
      tempItem.disputeSrc = "";
      tempItem.infoType = "";
      tempItem.disputeReasonCategory = "";
      tempItem.personalInformationDetails.disputeAddress = new disputesWithAttachRes.DisputeAddress();
      tempItem.personalInformationDetails.disputeAddress.disputeStreetAddress = "";
      tempItem.personalInformationDetails.disputeAddress.disputePostalCode = "";
      tempItem.personalInformationDetails.disputeAddress.disputeState = "";
      tempItem.personalInformationDetails.disputeAddress.disputeCity = "";
      tempItem.personalInformationDetails.disputePhoneNumber = "";
      tempItem.personalInformationDetails.disputeEmailAddress = "";
      tempItem.personalInformationDetails.disputeDateOfBirth = "";
      tempItem.dataMartKey = "";
      tempItem.evntBnkCd = "";
      tempItem.evntAcctNbr = "";
      //////////////////////////////
      tempItem.inquiryCustomerDetails = new disputesWithAttachRes.InquiryCustomerDetails();
      tempItem.inquiryCustomerDetails.inquiryCustomerAddress = new disputesWithAttachRes.InquiryCustomerAddress();
      tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = "";
      tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = "";
      tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = "";
      tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = "";
      tempItem.inquiryCustomerDetails.inquiryCustomerDate = "";
      tempItem.inquiryCustomerDetails.inquiryCustomerName = "";
      tempItem.inquiryCustomerDetails.inquiryCustomerPhone = "";
  
      tempItem.inquiryMasterCustomerDetail = new disputesWithAttachRes.InquiryMasterCustomerDetail();
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress = new disputesWithAttachRes.InquiryMasterCustomerAddress();
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine1 = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine2 = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerCity = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerState = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerZip = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerId = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerName = "";
      tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone = "";
  
      tempItem.inquiryResellerCustomerDetail = new disputesWithAttachRes.InquiryResellerCustomerDetail();
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress = new disputesWithAttachRes.InquiryResellerCustomerAddress();
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine1 = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine2 = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerCity = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerState = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerZip = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerId = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerName = "";
      tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone = "";
      return tempItem;
    }
  
    getDisputeDetail(){
      let tempArray:disputesWithAttachRes.Dispdetl[] = [];
      this.disputeData.forEach(element => {
        let tempItem:disputesWithAttachRes.Dispdetl = this.getInitEmptyObject();
        tempItem.disputeDesc = element.description;
        tempItem.disputeReasonCategory = element.reason;
        if(element.type.toUpperCase() === "Name".toUpperCase()){
          tempItem.infoType = "Personal Information";
          tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
          tempItem.personalInformationDetails.disputeName = element.DisputeDataHolder.VarntNme.trim();
        }
        else if(element.type.toUpperCase() === "Address".toUpperCase()){
          tempItem.infoType = "Personal Information";
          tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
          const addressValues = element.DisputeDataHolder.VarntAddr.CtyStZip.split(',');
          const addressValues2 = addressValues[1].trim().split(' ');
          tempItem.personalInformationDetails.disputeAddress.disputeStreetAddress = element.DisputeDataHolder.VarntAddr.VarntAddrLn1.trim();
          tempItem.personalInformationDetails.disputeAddress.disputePostalCode = addressValues2[1].trim();
          tempItem.personalInformationDetails.disputeAddress.disputeState = addressValues2[0].trim();
          tempItem.personalInformationDetails.disputeAddress.disputeCity = addressValues[0].trim();;
        }
        else if(element.type.toUpperCase() === "Phone".toUpperCase()){
          tempItem.infoType = "Personal Information";
          tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
          tempItem.personalInformationDetails.disputePhoneNumber = element.DisputeDataHolder.PhnVartn.PhnNmbr.trim();
          tempItem.personalInformationDetails.disputePhoneNumber = tempItem.personalInformationDetails.disputePhoneNumber.replace(/\D/g,'').trim();
        }
        else if(element.type.toUpperCase() === "Email".toUpperCase()){
          tempItem.infoType = "Personal Information";
          tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
          tempItem.personalInformationDetails.disputeEmailAddress = element.DisputeDataHolder.EmailVartn.EmailAddr.trim();
  
        }
        else if(element.type.toUpperCase() === "Date of birth".toUpperCase()){
          tempItem.infoType = "Personal Information";
          tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
          tempItem.personalInformationDetails.disputeDateOfBirth = element.DisputeDataHolder.DateOfBirth.trim();
  
        }
        else if(element.type.toUpperCase() === "Reported information".toUpperCase()){
          tempItem.infoType = element.type;
          //tempItem.disputeSrc = element.DisputeDataHolder.ClsrEvent.ClsrPrvdrNme;
          tempItem.disputeSrc = element.DisputeDataHolder.ClsrEvent.ClsrPrvdrNme;
          tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
          tempItem.disputeReportDt = element.DisputeDataHolder.ClsrEvent.ClsrRptdDt.trim(); 
  
          tempItem.dataMartKey = element.DisputeDataHolder.ClsrEvent.ClsrDtaMrtKey.trim();
          tempItem.evntBnkCd = element.DisputeDataHolder.ClsrEvent.ClsrBnkCd.trim();
          tempItem.evntAcctNbr = element.DisputeDataHolder.ClsrEvent.ClsrAcctNbr.trim();
          
        }
        else if(element.type.toUpperCase() === "Inquiries Viewed by Others".toUpperCase() || element.type.toUpperCase() === "Inquiries Viewed Only by You".toUpperCase()){
          tempItem.infoType = element.type;
          if(element.DisputeDataHolder.InqryEvent.InqMstrCustDtl  && element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMastCustNme.trim() !== ""){
            tempItem.disputeSrc = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMastCustNme.trim();
  
          }
          else{
            tempItem.disputeSrc = element.DisputeDataHolder.InqryEvent.InqCustNme.trim();
          }
          tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
          tempItem.disputeReportDt = element.DisputeDataHolder.InqryEvent.InqDt.trim(); 
  
          tempItem.dataMartKey = element.DisputeDataHolder.InqryEvent.InqDtaMrtKey.trim();
          tempItem.evntBnkCd = element.DisputeDataHolder.InqryEvent.InqBnkCd.trim();
          tempItem.evntAcctNbr = element.DisputeDataHolder.InqryEvent.InqAcctNbr.trim();
  
          let zipCode = "";
          if(element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustZip.trim() !== ""){
            const zipArray = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustZip.trim().split('-');
            zipCode = zipArray[0].trim();
          }
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustAddrLn1.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustCty.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustSt.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = zipCode;
          tempItem.inquiryCustomerDetails.inquiryCustomerDate = element.DisputeDataHolder.InqryEvent.InqDt.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerName = element.DisputeDataHolder.InqryEvent.InqCustNme.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerPhone = element.DisputeDataHolder.InqryEvent.InqCustPhn.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerPhone = tempItem.inquiryCustomerDetails.inquiryCustomerPhone.replace(/\D/g,'').trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine1 = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustAddrLn1.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine2 = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustAddrLn2.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerCity = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustCty.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerState = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustSt.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerZip = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustZip.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerId = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustId.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerName = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMastCustNme.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustPhn.trim();
          tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone = tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone.replace(/\D/g,'').trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine1 = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustAddrLn1.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine2 = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustAddrLn2.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerCity = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustCty.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerState = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustSt.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerZip = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustZip.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerId = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustId.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerName = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustNme.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustPhn.trim();
          tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone = tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone.replace(/\D/g,'').trim();
          
        }
        else if(element.type.toUpperCase() === "Retail Information".toUpperCase()){
          tempItem.infoType = element.type;
          tempItem.disputeSrc = element.DisputeDataHolder.RtrnChkEvent.RtrnChkPrvdrNme.trim();
          tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
          tempItem.disputeReportDt = element.DisputeDataHolder.RtrnChkEvent.RtrnChkDt.trim(); 
  
          tempItem.dataMartKey = element.DisputeDataHolder.RtrnChkEvent.RtrnChkDtaMrtKey.trim();
          tempItem.evntBnkCd = element.DisputeDataHolder.RtrnChkEvent.RtrnChkBnkCd.trim();
          tempItem.evntAcctNbr = element.DisputeDataHolder.RtrnChkEvent.RtrnChkAcctNbr.trim();
  
        }
        else if(element.type.toUpperCase() === "Check Cashing Inquiries".toUpperCase()){
          tempItem.infoType = element.type;
          tempItem.disputeSrc = element.DisputeDataHolder.ChkCshEvent.ChkCshCustNme.trim();
          tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
          tempItem.disputeReportDt = element.DisputeDataHolder.ChkCshEvent.ChkCshInqDt.trim(); 
  
          tempItem.dataMartKey = element.DisputeDataHolder.ChkCshEvent.ChkCshDtaMrtKey.trim();
          // tempItem.evntBnkCd = element.DisputeDataHolder.ChkCshEvent.;
          // tempItem.evntAcctNbr = element.DisputeDataHolder.ChkCshEvent.;
          let strCity = "";
          let strState = "";
          let strZip = "";
          if(element.DisputeDataHolder.ChkCshEvent.ChkCshAddr.CtyStZip.trim() !== ""){
            const addressValues = element.DisputeDataHolder.ChkCshEvent.ChkCshAddr.CtyStZip.split(',');
            const addressValues2 = addressValues[1].trim().split(' ');
            strCity = addressValues[0].trim();
            strState = addressValues2[0].trim();
            let zipArray = addressValues2[1].trim().split('-');
            strZip = zipArray[0].trim();
          }
          
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = element.DisputeDataHolder.ChkCshEvent.ChkCshAddr.AptNbr.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = strCity;
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = strState;
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = strZip;
          tempItem.inquiryCustomerDetails.inquiryCustomerDate = element.DisputeDataHolder.ChkCshEvent.ChkCshInqDt.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerName = element.DisputeDataHolder.ChkCshEvent.ChkCshCustNme.trim();
          //tempItem.inquiryCustomerDetails.inquiryCustomerPhone = element.DisputeDataHolder.ChkCshEvent.;
        }
        else if(element.type.toUpperCase() === "History of Checks ordered".toUpperCase()){
          tempItem.infoType = element.type;
          tempItem.disputeSrc = element.DisputeDataHolder.ChkOrdEvent.ChkOrdPrvdrNme.trim();
          tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
          tempItem.disputeReportDt = element.DisputeDataHolder.ChkOrdEvent.ChkOrdShipDt.trim(); 
  
          tempItem.dataMartKey = element.DisputeDataHolder.ChkOrdEvent.ChkOrdDtaMrtKey.trim();
          tempItem.evntBnkCd = element.DisputeDataHolder.ChkOrdEvent.ChkOrdBnkCd.trim();
          tempItem.evntAcctNbr = element.DisputeDataHolder.ChkOrdEvent.ChkOrdAcctNbr.trim();
          let strCity = "";
          let strState = "";
          let strZip = "";
          if(element.DisputeDataHolder.ChkOrdEvent.ChkOrdDlvrAddr.CtyStZip.trim() !== ""){
          
            const addressValues = element.DisputeDataHolder.ChkOrdEvent.ChkOrdDlvrAddr.CtyStZip.split(' ');
            for (let i = 0; i < addressValues.length-2; i++) {
              strCity = strCity + addressValues[i] + " ";
            }
            strCity = strCity.trim();
            strState = addressValues[addressValues.length-2].trim();
            let zipArray = addressValues[addressValues.length-1].trim().split('-');
            strZip = zipArray[0].trim();
          }
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = element.DisputeDataHolder.ChkOrdEvent.ChkOrdDlvrAddr.AptNbr.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = strCity;
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = strState;
          tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = strZip;
          tempItem.inquiryCustomerDetails.inquiryCustomerDate = element.DisputeDataHolder.ChkOrdEvent.ChkOrdShipDt.trim();
          tempItem.inquiryCustomerDetails.inquiryCustomerName = element.DisputeDataHolder.ChkOrdEvent.ChkOrdCnsmrNme.ChkOrdCnsmrBusnNme.trim();
          //tempItem.inquiryCustomerDetails.inquiryCustomerPhone = element.DisputeDataHolder.ChkCshEvent.;
        }
  
        tempArray.push(tempItem);
      });
      return tempArray;
    }

    getEmptyDisputeDetail(){
      let tempArray:disputesWithAttachRes.Dispdetl[] = [];
      this.disputeData.forEach(element => {
        let tempItem:disputesWithAttachRes.Dispdetl = this.getInitEmptyObject();
        tempArray.push(tempItem);
      });
      return tempArray;
    }

    downloadPdf() {
      var doc = new jspdf();
      doc.text('Terms and Conditions', 10, 10);
      doc.save("Terms and Conditions.pdf");
     

    }

    formatBytes(bytes:number, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
  
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openDocsUploadedDetailAll(confirNo: string){
    //alert(confirNo);
    this.dialogRef.close('OK');
    this.router.navigate( ['view-all-docs']);
    
    //this.router.navigateByUrl('/disputedetail')
  }

  private ConvertDateTime(dateValue: Date): string {

    return this.ConvertValidDateFormat(dateValue) + '' + dateValue.getHours() + ':' + dateValue.getMinutes() + ':' + dateValue.getSeconds();
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

}

export class UploaderValidationModel{
  isModelValid:boolean = true;
  isFileSize:boolean = true;
  isFileCountValid:boolean = true;
}

