<div class="chex-bottom_space">
    <ruf-page-header role="region"  rufId="page_header_disputeTab_title" title="{{ 'OPTOUT.TITLE1' | translate }}" description=" ">
    </ruf-page-header>
      <div role="region"  class="chex-page-left-right-alignment mat-body-2" [innerHTML]="optOutContent | sanitizeHtml">
      </div>
        <ruf-statusbar class="chex-page-left-right-alignment statusbar-text" color="info" [closeable]="false" [border]="false"
            [icon]="'information'">
            <div role="region"  class="mat-body-2" [innerHTML]="optOutStatusBarContent | sanitizeHtml">
            </div>
        </ruf-statusbar>
    <div class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
    <button role="region" aria-label="submit" id="button" mat-raised-button fisStyle color="primary" class="submit-btn information-text chex-page-left-right-alignment" rufId
        ruf-stepper-actions type="submit" (click)="submitClick()">{{ 'BUTTONS.SUBMIT' | translate }}</button>
    </div>
</div>
