<div>
    <ruf-page-header rufId role="region" aria-label="title" title="{{ 'SEARCH.TITLE' | translate }}" description="">
    </ruf-page-header>

    <form [formGroup]="searchFormGroup" id="searchFormGroup">
        <div class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px"
            rufMarginVertical rufId>
            <div class="first-screen-form-section">
                <mat-form-field class="form_field" boxStyle floatLabel="always">
                    <mat-label class="form_field_label">Search Input</mat-label>
                    <input formControlName="searchTextCtrl" fisStyle matInput placeholder="Enter Search Input"
                        autocompleteOff>
                    <mat-error *ngIf="searchFormGroup.get('searchTextCtrl')?.hasError('required')">{{ 'SEARCH.SEARCHERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="button-place">
                <button mat-raised-button fisStyle color="primary" class="form_button" rufId ruf-stepper-actions
                    type="submit" (click)="searchClick()" [attr.form]="searchFormGroup">{{ 'SEARCH.BUTTONTEXT' | translate }}</button>
            </div>
        </div>
        
        <div class="chex-page-left-right-alignment" *ngIf="filteredSearchResults.length>0">
            <div class="ruf-caption-2">{{filteredSearchResults.length}} {{ 'SEARCH.RESULTSFOUND' | translate }}</div>
        </div>

        <div class="chex-page-left-right-alignment" fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="5px" rufMarginVertical *ngFor="let searchResult of filteredSearchResults">
            <div fxLayout="column" fxLayout.gt-sm="column" fxLayoutGap="5px" rufMarginVertical>
                <div class="ruf-caption-2">{{searchResult.category}}</div>
                <div class="mat-body-2"><a [routerLink]="searchResult.routecomponent">{{searchResult.title}}</a></div>
            </div>
        </div>
        <div class="chex-page-left-right-alignment" *ngIf="!firstTimeLoad && filteredSearchResults.length==0">
            <div class="ruf-caption-2">{{ 'SEARCH.NORESULTSFOUND' | translate }}</div>
        </div>
    </form>
</div>
