import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogUploaderInput, DialogFileUploaderComponent } from '../../../../dailog/dialog-attachment/dialog-file-attachment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogInfo, DialogIndividualDisputeComponent } from '../../../../dailog/dialog-individual-dispute/dialog-individual-dispute.component';
import { PersonalInformationConfirmationService } from '../../../../_services/shared/pi-confirmation.service';
import { HttpClient } from '@angular/common/http';
import { dashboard } from '../../../../_models/classes/dashboard-dispute.model';
import { dashboardEvent } from '../../../../_models/classes/dashboard-dispute.model';
import { DashboardService } from '../../../../_services/dashboard-services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../../enums/global_variables';

@Component({
  selector: 'chx-disputes-all',
  templateUrl: './disputes-detail-all.component.html',
  styleUrls: ['./disputes-detail-all.component.scss'],
})


export class DisputesDetailAllComponent{
  dialogWidth:string = "477px";
  dialogheight: string = "1178px";
  disputeRoot:dashboard.DashboardDisputeRoot;
  backtodashboard=this._translate.instant('DISPUTESDETAIL.BACKTODASHBOARD');
  mydisputes=this._translate.instant('DISPUTESDETAIL.MYDISPUTES');
  noitems=this._translate.instant('DISPUTESDETAIL.NOITEMS');
  confirmation=this._translate.instant('DISPUTESDETAIL.CONFIRMATION');
  date=this._translate.instant('DISPUTESDETAIL.DATE');
  target=this._translate.instant('DISPUTESDETAIL.TARGET');
  status=this._translate.instant('DISPUTESDETAIL.STATUS');
  showLoadingIndicator:boolean = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private piConfirmService: PersonalInformationConfirmationService,
    private http: HttpClient,
    private dashboardService: DashboardService,
    private _translate: TranslateService,
  ) { 
    
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation();
    this.initData();
  }

  initData(){
    this.showLoadingIndicator = true;
    this.getDisputeRespData().subscribe((res) => {
      this.disputeRoot = res as dashboard.DashboardDisputeRoot;
      var tempItems: DashboardDisputesItems[] = [];
      ///Make the list in decending prder
      this.disputeRoot.viewDisputeListRsp.disputeList = 
          this.disputeRoot.viewDisputeListRsp.disputeList.sort((item1,item2) =>  new Date(item2.dateReceived).getTime() - new Date(item1.dateReceived).getTime());
          
      for (var val of this.disputeRoot.viewDisputeListRsp.disputeList) {

          var item:DashboardDisputesItems = new DashboardDisputesItems();
          if(val.cnsmrRefId !== ""){
            item.confirmationNo = val.cnsmrRefId;
            if(val.targetCompletionDate.trim() !== ""){
              item.targetCompletionDate = new Date(val.targetCompletionDate);
            }
            //item.targetCompletionDate = new Date(val.targetCompletionDate);
            item.receivedDate = new Date(val.dateReceived);
            item.OverallStatus = val.overallStatus.toUpperCase();
            tempItems.push(item);
          }
          
      }
      this.items = tempItems;
    }, 
    error => {this.showLoadingIndicator = false; },
    () => { this.showLoadingIndicator = false;});

  }

  getDisputeRespData() {
    let _userName = GlobalVariables.globalvars.get("UserId") || '';
    let dashReqRoot:dashboard.ViewDisputeListReqRoot = new dashboard.ViewDisputeListReqRoot();
    dashReqRoot.viewDisputeListReq = new dashboard.ViewDisputeListReq();
    dashReqRoot.viewDisputeListReq.userName = _userName;
    return this.dashboardService.DisputeItemList(dashReqRoot);
    // return this.http.get('./././assets/jsondata/ViewDisputeList-Rsp.json');
  }

  items: DashboardDisputesItems[] = [];

  // openDisputeDetailSingle(confirNo: string){
  //   //alert(confirNo);
  //   //this.router.navigate( ['disputedetail', {src: "a", id: confirNo}]);
    
  //   //this.router.navigateByUrl('/disputedetail')
  //   const dialogData = new DialogInfo("Dispute Details", confirNo);
  //         const dialogRef = this.dialog.open(DialogIndividualDisputeComponent,{position
  //             : {top: '0%', right: '0%'},
  //           width:this.dialogWidth,
  //           height:this.dialogheight,
  //           data:dialogData,
  //           disableClose: true
  //         }
  //         );
  //         dialogRef.afterClosed().subscribe(result => { 
            
  //         });
  // }
  backToSource(){
    //if(this.backSource === "d"){
      this.router.navigate( ['dashboard']);
    //}

  }


    columns: any[] = [
      {
        field: 'confirmationNo',
        name: 'Confirmation #',
        width: '20%',
        title: 'Confirmation',
      },
      {
        field: 'receivedDate',
        name: 'Received Date',
        title: 'Received Date',
        width: '20%',
        dataType: {
          name: 'date',
          format: ['MM/dd/yyyy']
        }
      },
      {
        field: 'targetCompletionDate',
        name: 'Target Completion',
      },
      // commented the status field for part of this story US1353281
      // {
      //   field: 'OverallStatus',
      //   name: 'Overall Status',
      //   width: '20%',
      //   title: 'Overall Status'
      // },    
    ];
  
    rowSelectionChange(event: any) {
    }
  
    onStateChange(event: any) {
    }
    rowClickHandler(code: string, element:any){

      const dialogData = new DialogInfo("Dispute Details", element.confirmationNo, element.receivedDate, element.targetCompletionDate);
          const dialogRef = this.dialog.open(DialogIndividualDisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            
          });
          
    }
}
  export class DashboardDisputesItems {
    confirmationNo: string;
    receivedDate: Date;
    targetCompletionDate: Date;
    OverallStatus: string;
    // desc: string="";
    // status: string="";
    // disDate: string="";
  }