
export enum UserUpdateRequesterHeader
{
    RequestType             = "UR",
    NewRequestSubType       = "N",
    UpdateRequestSubType    = "U"
}

export enum PhoneType
{
    Phone                   = "phone",
    Mobile                  = "mobile"
}