import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessage } from '../dailog/dialog-with-message/dialog-with-message.component';
import { DialogwithmessagebtnComponent } from '../dailog/dialog-with-message-btn/dialog-with-message-btn.component';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import {
  SitecoreRequestData,
  SiteCoreResponseData,
  Field,
} from '../_models/classes/sitecore-reponse.model';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { addfreeze } from '../_models/classes/add-freeze.model';
import { ManageFreezeService } from '../_services/manage-freeze/manage-freeze.service';
import { userinformation } from '../_models/classes/user-information.model';
import { TranslateService } from '@ngx-translate/core';
import { timeStamp } from 'console';
import { GlobalVariables } from '../common/enums/global_variables';

@Component({
  selector: 'chexsystem-ws-place-freeze',
  templateUrl: './place-freeze.component.html',
  styleUrls: ['./place-freeze.component.scss'],
})
export class PlaceFreezeComponent implements OnInit {
  @ViewChild('placefreezecontent') placefreezecontent: ElementRef<HTMLElement>;
  contentText1: string ='';  
  freeze_title: string;
  title=this._translate.instant('PLACEFREEZE.TITLE');
  continue=this._translate.instant('BUTTONS.CONTINUE');
  cancel=this._translate.instant('BUTTONS.CANCEL');
  info=this._translate.instant('PLACEFREEZE.INFO');

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private manageFreezeService: ManageFreezeService,
    private _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('PlaceAFreeze');
    this.fetchPlaceFreezeContent();
  }

  fetchPlaceFreezeContent() {
    let itemPath = 'Place Security Freeze/Data/Content/Data/Place Security Freeze';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: itemPath }; 
    this.sitecoreService.FetchSitecoreContentByItemPath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreResponseData;
      let fieldObject = <Field>result.data.item.fields.find(field => field.name === 'Text');
        this.contentText1 =fieldObject?.value.toString();
        }, 
        error => {
        }, 
        ()=>{
        }
      );
  }

  continueclick() {
    const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
    var _userName = GlobalVariables.globalvars.get('UserId') || '';
    if (strUserInfo) {
      const objUserInfo = JSON.parse(
        strUserInfo
      ) as userinformation.UserInformationRoot;
      const alertReq: addfreeze.AddFreeze = new addfreeze.AddFreeze();
      const alertReqRoot: addfreeze.AddFreezeRoot =
        new addfreeze.AddFreezeRoot();
      alertReq.CellPhoneNbr = objUserInfo.CellPhoneNbr.trim();
      alertReq.CnsmrAddrDetls = new addfreeze.CnsmrAddrDetls();
      alertReq.CnsmrAddrDetls.CnsmrAddr = new addfreeze.CnsmrAddr();
      alertReq.CnsmrAddrDetls.CnsmrAddr.AddrLine1 =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
      alertReq.CnsmrAddrDetls.CnsmrAddr.AddrLine2 =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
      alertReq.CnsmrAddrDetls.CnsmrAddr.CityNm =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
      alertReq.CnsmrAddrDetls.CnsmrAddr.StatCode =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
      alertReq.CnsmrAddrDetls.CnsmrAddr.ZipCode =
        objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
      alertReq.CnsmrPrsnlDetls = new addfreeze.CnsmrPrsnlDetls();
      alertReq.CnsmrPrsnlDetls.CnsmrNmDetls = new addfreeze.CnsmrNmDetls();
      alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
      alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
      alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
      alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
      alertReq.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName =
        objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName.trim();
      alertReq.CnsmrPrsnlDetls.BirthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
      alertReq.CnsmrPrsnlDetls.GovtNbr =
        objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
      alertReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls =
        new addfreeze.CnsmrDrivLicenseDetls();
      alertReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
        objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
      alertReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
        objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
      alertReq.CnsmrPrsnlDetls.PhoneNbr =
        objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
      alertReq.CnsmrPrsnlDetls.ConsumerId = '';
      alertReq.CnsmrPrsnlDetls.EmailAddress = '';
      alertReq.CorrespondenceDeliveryPreference =
        objUserInfo.CorrespondenceDeliveryPreference.trim();
      alertReq.NotificationEmailAddr = objUserInfo.NotificationEmailAddr.trim();
      alertReq.RequestSrcCode = 'SECURE';
      alertReq.UserName = _userName;
      alertReqRoot.AddFreeze = alertReq;
      this.manageFreezeService.AddFreeze(alertReqRoot).subscribe(
        (res) => {
          // const strMessage = res.message;
          let strMessage = 'Empty!';
          if (
            res.code === '4012' ||
            res.message.toLowerCase() == 'freeze already exists'
          ) {
            this.freeze_title = this._translate.instant(
              'PLACEFREEZE.FREEZEEXISTSTITLE'
            );
            strMessage = this._translate.instant(
              'PLACEFREEZE.FREEZEEXISTSTEXT'
            );
          } else {
            this.freeze_title = this._translate.instant(
              'PLACEFREEZE.FREEZESUBMITTITLE'
            );
            strMessage = this._translate.instant(
              'PLACEFREEZE.FREEZESUBMITTEXT'
            );
            strMessage = strMessage.replace('#####', res.referenceId);
          }
          //const dialogMessage = new DialogMessage( this.messagetext, 'Go to Dashboard',true, 'Place a Freeze' );
          const dialogMessage = new DialogMessage(
            strMessage,
            this._translate.instant(
              'BUTTONS.GOTODASHBOARD'
            ),
            true,
            this.freeze_title
          );
          const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
            role: 'dialog',
            panelClass: ['fis-style', 'custom-dialog-without-close'],
            data: dialogMessage,
            disableClose: true,
          });

          dialogRef2.afterClosed().subscribe((result) => {});
        },
        (error) => { },
        () => { }
      );
    }
  }

  onCancel(): void {
    this.router.navigate(['dashboard']);
  }
}
