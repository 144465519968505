import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { removefreeze } from '../../_models/classes/remove-freeze.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageFreezeService } from '../../_services/manage-freeze/manage-freeze.service';
import { userinformation } from '../../_models/classes/user-information.model';
import { DialogMessage, DialogwithmessageComponent } from '../dialog-with-message/dialog-with-message.component';
import { TranslateService } from '@ngx-translate/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
import { DateAdapter,  MAT_DATE_FORMATS,  MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalVariables } from '../../common/enums/global_variables';
export const MY_FORMATS = {
  parse: { dateInput: 'MM/DD/YYYY' },
  display: { dateInput: 'MM/DD/YYYY', monthYearLabel: 'YYYY',
  dateA11yLabel: 'LL',  monthYearA11yLabel: 'YYYY' },
  providers: []
};
export class DialogInput{
  constructor(public effectiveDate:string, public endDate: string){

  }
}
@Component({
  selector: 'chexsystem-ws-dialog-rem-frz',
  templateUrl: './dialog-rem-frz.component.html',
  styleUrls: ['./dialog-rem-frz.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DialogRemFrzComponent implements OnInit {
  formGroup: FormGroup;
  id:String;
  effectiveDate:Date;
  endDate:Date;
  removeFreezeOnDate:Date;
  isFutureDate:boolean = false;
  today = new Date(moment().format('LL'));
  constructor(
    
    private router: Router,
    private _formBuilder: FormBuilder,
    private manageFreezeService: ManageFreezeService,
    private _translate: TranslateService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogRemFrzComponent>,
    @Inject(MAT_DIALOG_DATA) public Data:DialogInput
  ) { 

    this.effectiveDate = new Date(Data.effectiveDate);
    this.endDate = new Date(Data.endDate);
    this.formGroup = this._formBuilder.group(
      {
        removeFreezeOnDateCtrl: ['', Validators.required]
      }
    );

  }

  removeFreezeOnDateChanged(_$event: any) {
    // if (this.removeFreezeOnDate) {
    //   this.formGroup.controls['removeFreezeOnDateCtrl'].setErrors({ isInValid: true });
    //   } else {
    //     this.formGroup.controls['removeFreezeOnDateCtrl'].setErrors(null);
    //   }
    }

    
  submitclick(){
    if (this.formGroup.valid) {
      var strMessage:string = this._translate.instant(
        'REMOVEFREEZE.REMOVEFREEZECONFIRMTEXT'
      )
      strMessage = strMessage.replace('###ONDATE###', this.ConvertValidDateFormat(this.removeFreezeOnDate));
      const dialogMessage = new DialogMessage(
        strMessage,
        this._translate.instant(
          'BUTTONS.REMOVEFREEZE'
        ),
        true,
        this._translate.instant(
          'REMOVEFREEZE.REMOVEFREEZECONFIRMTITLE'
        )
      );
      const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
        panelClass: ['fis-style', 'custom-dialog-without-close'],
        data: dialogMessage,
        disableClose: true,
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if ((result as string) == 'OK') {
          this.submitclickHandler();
        }
      });
    }
  }

  submitclickHandler(){
    if (this.formGroup.valid) {
      this.isFutureDate = this.getFutureDateStatus();
      const strUserInfo = GlobalVariables.globalvars.get("UserInformation");
      if(strUserInfo){
        
        /*
    FreezeDate: FreezeDate


        */
          const objUserInfo =  JSON.parse(strUserInfo)  as userinformation.UserInformationRoot;
          const remFrzReq: removefreeze.RemoveFreeze = new removefreeze.RemoveFreeze();
          const remFrzReqRoot: removefreeze.RemoveFreezeRoot = new removefreeze.RemoveFreezeRoot();
          let securityPIN = GlobalVariables.globalvars.get('PIN') as string;   
          remFrzReq.PinNbr = securityPIN;
          remFrzReq.RequestSrcCode = "SECURE";
          var _userName = GlobalVariables.globalvars.get('UserId') || '';
          remFrzReq.UserName = _userName;
          remFrzReq.CellPhoneNbr = objUserInfo.CellPhoneNbr.trim();
          remFrzReq.CorrespondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference.trim();
          remFrzReq.NotificationEmailAddr = objUserInfo.NotificationEmailAddr.trim();

          remFrzReq.CnsmrAddrDetls = new removefreeze.CnsmrAddrDetls();
          remFrzReq.CnsmrAddrDetls.CnsmrAddr = new removefreeze.CnsmrAddr();
          remFrzReq.CnsmrAddrDetls.CnsmrAddr.AddrLine1 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
          remFrzReq.CnsmrAddrDetls.CnsmrAddr.AddrLine2 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
          remFrzReq.CnsmrAddrDetls.CnsmrAddr.CityNm = objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
          remFrzReq.CnsmrAddrDetls.CnsmrAddr.StatCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
          remFrzReq.CnsmrAddrDetls.CnsmrAddr.ZipCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
          remFrzReq.CnsmrPrsnlDetls = new removefreeze.CnsmrPrsnlDetls();
          remFrzReq.CnsmrPrsnlDetls.BirthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
          remFrzReq.CnsmrPrsnlDetls.ConsumerId = "";
          remFrzReq.CnsmrPrsnlDetls.EmailAddress = objUserInfo.NotificationEmailAddr.trim();
          remFrzReq.CnsmrPrsnlDetls.GovtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
          remFrzReq.CnsmrPrsnlDetls.PhoneNbr = objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls = new removefreeze.CnsmrDrivLicenseDetls();
          remFrzReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrNmDetls = new removefreeze.CnsmrNmDetls();
          remFrzReq.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
          remFrzReq.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName.trim();
          remFrzReq.FreezeDate = new removefreeze.FreezeDate();
          remFrzReq.FreezeDate.EndDate = this.ConvertValidDateFormat(this.removeFreezeOnDate);
          remFrzReq.FreezeDate.StartDate = this.ConvertValidDateFormat(this.effectiveDate);
          remFrzReqRoot.RemoveFreeze = remFrzReq;
          this.manageFreezeService.RemoveFreeze(remFrzReqRoot).subscribe(
            res => {
              let strMessage = "";
              let buttonText = "";
              if(res.code === "200"){
                strMessage = this._translate.instant(
                  'DASHBOARDCORRESPONDENCE.REMOVEFREEZESUCCESSMESSAGE1'
                );
                strMessage = strMessage.replace('#######', res.referenceId);
              }
              else{
                strMessage = res.message;
              }
              if(this.isFutureDate){
                buttonText = this._translate.instant(
                  'BUTTONS.OK'
                );
              }
              else{
                buttonText = this._translate.instant(
                  'BUTTONS.GOTODASHBOARD'
                );
              }
              const dialogMessage = new DialogMessage(
                strMessage,
                buttonText,
                false,
                this._translate.instant(
                  'DASHBOARDCORRESPONDENCE.REMOVEFREEZESUCCESSMESSAGETITLE'
                )
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ["fis-style", "custom-dialog-without-close"],
                data: dialogMessage,
                disableClose: true
              });
              dialogRef2.afterClosed().subscribe((result) => {
                if(this.isFutureDate){
                  this.dialogRef.close('OK');
                }
                else{
                  this.router.navigate(['dashboard']);
                }
              });
              
            }, 
            error => { }, 
            ()=>{ });  
        }
    }
  }
  resetClick(){
    this.formGroup.controls['removeFreezeOnDateCtrl'].reset();
  }
 
  

  ngOnInit(): void {
  }

  previousDateFilter = (d: _moment.Moment | null): boolean => {
    if (!d) {
      return true;
    }
    const selectedEndDate = new Date((d|| moment()).format('LL'));
    let endDateFiletr: boolean = true;
    endDateFiletr = selectedEndDate < this.endDate;
    const today = new Date((d|| moment()).format('LL'));
    return (this.today <= today && endDateFiletr);
  };

  private ConvertValidDateFormat(dateValue: Date): string {
    dateValue = new Date(dateValue);
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

  getFutureDateStatus(){
    this.removeFreezeOnDate = new Date(this.removeFreezeOnDate);
    let currentDate:Date = new Date();
    if(this.removeFreezeOnDate > currentDate){
      return true;
    }
    else{
      return false;
    }
  }
}
