import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
  forwardRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { city } from '../_models/classes/citydata-response.model';
import { states } from '../_models/classes/states-response.model';
import { idaquestions } from '../_models/classes/idaquestions-response.model';
import {
  suffix,
  suffixdata,
} from '../_models/classes/suffixdata-response.model';
import { searchfreezeexitenceresponse } from '../_models/classes/searchfreezeexistence-reponse.model';
import { searchfreezeexitencerequest } from '../_models/classes/searchfreezeexistence-request.model';
import { LookupFreezeResponse } from '../_models/classes/lookupfreeze-reponse.model';
import { LookupFreezeRequest } from '../_models/classes/lookupfreeze-request.model';
import { ViewSecurityAlertResponseData } from '../_models/classes/viewsecurityalert-reponse.model';
import { ViewSecurityAlertRequestData } from '../_models/classes/viewsecurityalert-request.model';
import { ValidateSecurityAlertResponseData } from '../_models/classes/validatesecurityalert-reponse.model';
import { ValidateSecurityAlertRequestData } from '../_models/classes/validatesecurityalert-request.model';
import { TMSessionData } from '../_models/classes/threatmetrixsession-reponse.model';

import { UserNameAvailabilityResponse } from '../_models/classes/usernameavailability-response.model';
import { dlstatevalidations } from '../_models/classes/dlstatevalidations-response.model';
import {
  MyTel,
  PhonecontrolComponent,
} from '../common/controls/phone-control/phone-control.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import {
  DialogsecurityfreezepinComponent,
  DialogsecurityfreezepinData,
} from '../dailog/dialog-security-freeze-pin/dialog-security-freeze-pin.component';
import {
  DialogsecurityalertquestionComponent,
  DialogsecurityalertquestionData,
} from '../dailog/dialog-security-alert-question/dialog-security-alert-question.component';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import { DialogtermsandconditionsComponent } from '../dailog/dialog-terms-and-conditions/dialog-terms-and-conditions.component';
import {
  RufStepperComponent,
  RufStepperModule,
  RufStepperStep,
} from '@ruf/widgets';
import { RufDropdownType } from '@ruf/shell';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { SSNValidation } from '../common/validation-common/ssn-validator';
import { PasswordValidation } from '../common/validation-common/password-validator';

import { Guid } from 'guid-typescript';

import { SecurityValidationService } from '../_services/registration/security-validations.service';

import {
  IdaQuizService,
  IIdaQuizService,
} from '../_services/registration/ida-quiz.service';
import {
  IdaQuizAnswers,
  IdaQuizQuestion,
  QuizStatus,
  QuizStatusValue,
} from '../_models/registration/idaquizquestion.model';
import { Registrationresource } from '../_models/registration/registrationresource.model';
import { Timerdetail, TimerOption } from '../_models/classes/timerdetail.model';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DialogagevalidityComponent } from '../dailog/dialog-age-validity/dialog-age-validity.component';
import { environment } from '../../environments/environment';
import {
  IdaDecisionRequest,
  IdaIdentification,
  IdaPerson,
  IdaQuestion,
  IdaQuiz,
  Question,
  QuizRequest,
  IdaAnswerDetail,
  AnswerDetail,
} from '../_models/registration/Quiz';
import {
  Department,
  FieldMaskingShowHide,
  IdpUser,
  PasswordType,
  ProfileRegToLogin,
} from '../_models/registration/IdpUser';
import { IdpService } from '../_services/registration/idp.service';
import { DialogIdleTimerComponent } from '../dailog/dialog-idle-timer/dialog-idle-timer.component';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { DOCUMENT } from '@angular/common';
import { TooltipPosition } from '@angular/material/tooltip';
import {
  CnsmrAddrDetls,
  CnsmrDrivLicenseDetls,
  CnsmrNmDetls,
  CnsmrPrsnlDetls,
  DecisionSolutionUserCreationRequest,
  PreviousAddrDetls,
  RqstHdr,
  UserRegistnReq,
} from '../_models/registration/DecisionSolutionUserDetail';
import { FormValidation } from '../common/validation-common/form-validator';
import {
  PhoneNumber,
  PhonenumbercontrolComponent,
} from '../common/controls/phone-number-control/phone-number-control.component';
import { PhoneType } from '../common/enums/registration_enum';
import { StateZipCode } from '../_models/registration/statezipcode';
import stateZipCodeJson from '../../assets/jsondata/stateZipCdeRange.json';
import { TranslateService } from '@ngx-translate/core';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalVariables } from '../common/enums/global_variables';
import {
  DialogPanel,
  DialogWithMessageCustomButtonComponent,
} from '../dailog/dialog-with-message-custom-button/dialog-with-message-custom-button.component';
export const MY_FORMATS = {
  parse: { dateInput: 'MM/DD/YYYY' },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare function generateSessionId(
  sessionId: string,
  url: string,
  orgId: string
): any;
@Component({
  selector: 'chexsystem-ws-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegistrationComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ssnCtrl1') ssncontrol: PhonecontrolComponent;
  @ViewChild('verifyssnCtrl1') verifyssncontrol: PhonecontrolComponent;
  @ViewChild('cellphoneCtrl1') phonenumbercontrol: PhonenumbercontrolComponent;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  public birthdate: Date;
  public age: number;

  bgColors = ['primary', 'default'];
  pbColors = ['primary', 'accent'];
  bgColor = 'default';
  pbColor = 'primary';
  showProgressBar = true;
  jumpStepTrigger = false;
  pbDirections = ['top', 'bottom'];
  pbDirection: 'top' | 'bottom' = 'top';

  checked_termsconditions: boolean = false;

  datepickerDisabled = false;
  disabledStyles = true;

  stepData: any = {};
  nextbuttontext: string = 'Continue';

  dialogWidth: string = '596px';

  freezeexists: boolean = false;
  alertquestionexists: boolean = false;
  protectedflagexists: boolean;

  freezeanswercount: number = 0;
  alertquestionanswercount: number = 0;
  isfreezeanswercorrect: boolean = false;
  isalertquestionanswercorrect: boolean = false;
  isThreatMetrixReject: boolean = true;

  isAgeCriteriaMet: boolean = true;
  buttontext: string = 'Contact ChexSystems';
  registrationsuccesstext: string =
    'Welcome, #####, to the ChexSystems Consumer Portal. Your registration has been successfully completed.';
  cancelpopuptext: string =
    'Are you sure you want to cancel the registration process?';
  incorrectFreezeAndAlertQuesAnswerText: string =
    'Your request cannot be submitted online because the information you entered did not match what is on file. Please contact ChexSystems to submit your request using another method.';
  protectedCustomerDialogText: string =
    'Registration could not be completed. The information entered indicates this is a Protected Consumer. All communications on behalf of a Protected Consumer must be submitted in writing and must include appropriate proof of authority and of identity.';
  threatmetrixRejectDialogText =
    'We apologize for the inconvenience, but we are unable to complete this request online. Please contact ChexSystems to submit your request using another method. You may also call 800.428.9623 and select Other Options for assistance with this request. Refer to error code 801.';
  contactChexSystemsUrl: string = environment.contactChexSystemsUrl;
  homeChexSystemsUrl: string = environment.homeChexSystemsUrl;
  ageValidation: string =
    'Registration cannot be completed. You must be 18 years of age or older to communicate with ChexSystems.';
  idleText: string = 'Page will reset in 5 minutes';

  securityfreezepopupoutput: { [key: string]: boolean } = {};
  securityalertpopupoutput: { [key: string]: boolean } = {};
  selectedOptionWithMessageDialog: string;

  idleTime: number = environment.idleTime;
  idleTimeout: number = environment.idleTimeout;
  totalTimeout: number = environment.totalTimeout;

  suffixdata: suffixdata;
  suffixes: suffix[];

  cityCtrl: FormControl;
  reactiveCities: Observable<city.item[]>;
  cities: city.item[];
  citydata: city.citydata;

  states: states.item[];
  statedata: states.statedata;

  dlstates: states.item[];
  dlstatedata: states.statedata;

  searchfreezeexistence: searchfreezeexitenceresponse;
  lookupfreeze: LookupFreezeResponse;
  viewsecurityalert: ViewSecurityAlertResponseData;
  validatesecurityalert: ValidateSecurityAlertResponseData;
  userNameAvailabilityResponse: UserNameAvailabilityResponse;
  phoneNumber: MyTel = new MyTel('', '', '');
  ssnNumber: MyTel = new MyTel('', '', '');
  verifyssnNumber: MyTel = new MyTel('', '', '');
  prevlName: string = '';
  tempUserName: string;
  userName: string = '';
  userNameAvailable: boolean = false;
  emailAddressAvailable: boolean = false;

  ssnminlength: boolean = false;
  verifyssnminlength: boolean = false;
  ssninvalid: boolean = false;
  verifyssninvalid: boolean = false;
  phonenominlength: boolean = false;
  phonenumberinvalid: boolean = false;
  ssnnomatch: boolean = false;
  eyeIconTypeVerifySSN: string = 'eye';
  eyeIconTooltipVerifySSN: string = 'show';
  ssnmodel = { message: '', value: new MyTel('', '', '') };
  phonemodel = { message: '', value: new PhoneNumber('', '', '') };

  termsnconditions: boolean = false;
  TMsessionId: string;
  TMSessiondata: TMSessionData;

  filtervalidationformats: dlstatevalidations.item[];

  checkOnline: boolean = false;
  checkMail: boolean = false;

  questions: idaquestions.questions[];
  questionsdata: idaquestions.questionsdata;
  group: any = [];
  isChallengeQuiz: boolean = false;
  panel = {
    type: RufDropdownType.PopoverMenu,
  };
  hasBackdrop = true;

  public ida_quiz_title = Registrationresource.ida_quiz_title;
  public timersInfo: Array<Timerdetail> = [];
  challengeQuizStatus: QuizStatus;
  idaQuizSection: IdaQuiz = new IdaQuiz();
  idaQuizAnswerDetail: IdaAnswerDetail = new IdaAnswerDetail();
  public ida_quiz_subtitle = Registrationresource.ida_quiz_subtitle;
  idleState = 'Not started.';
  timedOut = false;

  currentPasswordEyeIconType: string = FieldMaskingShowHide.Eye;
  currentPasswordEyeIconTooltip: string = FieldMaskingShowHide.Show;
  currentPasswordType = PasswordType.Password;

  deliverypreference = ['Portal', 'Mail'];
  defaultDeliverypreference: string;
  position: TooltipPosition = 'below';
  disabled = false;
  showDelay = 0;
  hideDelay = 0;
  showExtraClass = false;
  invalidErrorMessage: { [key: string]: string } =
    Registrationresource.invalidErrorMessage;
  suffixDetail: string;
  userRegistrationForm = 'firstFormGroup';
  userProfileRequest = 'ChexSystems OTP Regular';
  isrequired = 'required';
  isScreenIdlePopupOpened: boolean = false;
  stateZipCodeMapping: StateZipCode[];
  today = moment().toDate();
  flex_layout_margin = 'margin-bottom: 0px';
  title = this._translate.instant('REGISTRATION.TITLE');
  mandatoryfield = this._translate.instant(
    'REGISTRATION.MANDATORY FIELDS TEXT'
  );
  firstname = this._translate.instant('REGISTRATION.FIRST NAME');
  middlename = this._translate.instant('REGISTRATION.MIDDLE NAME');
  lastname = this._translate.instant('REGISTRATION.LAST NAME');
  suffix = this._translate.instant('REGISTRATION.SUFFIX');
  prevlastname = this._translate.instant('REGISTRATION.PREVIOUS LAST NAME');
  dob = this._translate.instant('REGISTRATION.DATE OF BIRTH');
  ussocialsecuritynumber = this._translate.instant(
    'REGISTRATION.U.S.SOCIAL SECURITY NUMBER'
  );
  verifysocialnumber = this._translate.instant(
    'REGISTRATION.VERIFY U.S. SOCIAL SECURITY NUMBER'
  );
  toolbartitle = this._translate.instant('REGISTRATION.TOOLBAR TITLE');
  currentaddressline1 = this._translate.instant(
    'REGISTRATION.CURRENT ADDRESS LINE 1'
  );
  currentaddressline2 = this._translate.instant(
    'REGISTRATION.CURRENT ADDRESS LINE 2'
  );
  city = this._translate.instant('REGISTRATION.CITY');
  state1 = this._translate.instant('REGISTRATION.STATE1');
  zipcode = this._translate.instant('REGISTRATION.ZIP CODE');
  fieldtext = this._translate.instant('REGISTRATION.MANDATORY FIELDS TEXT');
  username = this._translate.instant('REGISTRATION.USERNAME');
  password = this._translate.instant('REGISTRATION.PASSWORD');
  verifypswd = this._translate.instant('REGISTRATION.VERIFY PASSWORD');
  emailaddr = this._translate.instant('REGISTRATION.EMAIL ADDRESS');
  phnnumber = this._translate.instant('REGISTRATION.PHONE NUMBER');
  dlnumber = this._translate.instant('REGISTRATION.DL NUMBER');
  stateofissurance = this._translate.instant('REGISTRATION.STATE OF ISSUANCE');
  state2 = this._translate.instant('REGISTRATION.STATE2');
  correspondencedelivery = this._translate.instant(
    'REGISTRATION.CORRESPONDENCE Delivery Preference'
  );
  previousaddrlabel1 = this._translate.instant(
    'REGISTRATION.PREVIOUS ADDRESS LABEL 1'
  );
  previousaddrline1 = this._translate.instant(
    'REGISTRATION.PREVIOUS ADDRESS LINE 1'
  );
  previousaddrline2 = this._translate.instant(
    'REGISTRATION.PREVIOUS ADDRESS LINE 2'
  );
  previousaddrlabel2 = this._translate.instant(
    'REGISTRATION.PREVIOUS ADDRESS LABEL 2'
  );
  previousaddrlabel3 = this._translate.instant(
    'REGISTRATION.PREVIOUS ADDRESS LABEL 3'
  );
  cancel = this._translate.instant('BUTTONS.CANCEL');
  haveanacc1 = this._translate.instant('REGISTRATION.HAVEANACCOUNT1');

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    private _dir: Directionality,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private idaQuizService: IdaQuizService,
    private securityValidationService: SecurityValidationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private idpService: IdpService,
    private idle: Idle,
    private keepalive: Keepalive,
    private _translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    var guid = Guid.create();
    this.TMsessionId = guid.toString().replace('-', '');

    this.stateZipCodeMapping = stateZipCodeJson as StateZipCode[];
    this.firstFormGroup = this._formBuilder.group(
      {
        fnameCtrl: ['', Validators.required],
        mnameCtrl: [''],
        lnameCtrl: ['', Validators.required],
        prevlnameCtrl: [''],
        dateofbirthCtrl: ['', Validators.required],
        ssnCtrl: ['', Validators.required],
        verifyssnCtrl: ['', Validators.required],
        address1Ctrl: ['', Validators.required],
        address2Ctrl: [''],
        cityCtrl: ['', Validators.required],
        stateCtrl: ['', Validators.required],
        postalCodeCtrl: ['', Validators.required],
        suffix: [''],
      },
      {
        validators: [
          SSNValidation.FormatValidation('dateofbirthCtrl'),
          FormValidation.CustomNameValidation('fnameCtrl'),
          FormValidation.CustomNameValidation('mnameCtrl'),
          FormValidation.CustomNameSpecialCharacterValidation('lnameCtrl'),
          FormValidation.CustomNameSpecialCharacterValidation('prevlnameCtrl'),
          FormValidation.StateZipCodeValidation(
            'stateCtrl',
            'postalCodeCtrl',
            this.stateZipCodeMapping
          ),
          FormValidation.InvalidNameValidation('fnameCtrl'),
          FormValidation.InvalidNameValidation('mnameCtrl'),
          FormValidation.InvalidNameValidation('lnameCtrl'),
          FormValidation.InvalidNameValidation('prevlnameCtrl'),
        ],
      }
    );
    this.secondFormGroup = this._formBuilder.group({});
    this.thirdFormGroup = this._formBuilder.group(
      {
        userNameCtrl: ['', Validators.required],
        pwdCtrl: ['', Validators.required],
        verifypwdCtrl: ['', Validators.required],
        emailCtrl: ['', Validators.required],
        cellphoneCtrl: ['', Validators.required],
        DLNumberCtrl: [''],
        DLstateCtrl: [''],
        chkEmailCtrl: [''],
        chkCellPhoneCtrl: [''],
        chkBothCtrl: [''],
        chkOnlineCtrl: [''],
        chkMailCtrl: [''],
        prevaddress11Ctrl: [''],
        prevaddress12Ctrl: [''],
        prevcity1Ctrl: [''],
        prevstate1Ctrl: [''],
        prevaddress21Ctrl: [''],
        prevaddress22Ctrl: [''],
        prevcity2Ctrl: [''],
        prevstate2Ctrl: [''],
        prevaddress31Ctrl: [''],
        prevaddress32Ctrl: [''],
        prevcity3Ctrl: [''],
        prevstate3Ctrl: [''],
        deliverypreference: [''],
        prevZipCode1Ctrl: [''],
        prevZipCode2Ctrl: [''],
        prevZipCode3Ctrl: [''],
        phoneTypeCtrl: [''],
        termsnconditionsCheckCtrl: [],
      },
      {
        validators: [
          PasswordValidation.MatchPassword('pwdCtrl', 'verifypwdCtrl'),
          PasswordValidation.PasswordRestrict(
            'pwdCtrl',
            'userNameCtrl',
            this.firstFormGroup,
            'fnameCtrl',
            'mnameCtrl',
            'lnameCtrl'
          ),
          FormValidation.AddressValidation(
            'prevaddress11Ctrl',
            'prevaddress12Ctrl',
            'prevcity1Ctrl',
            'prevstate1Ctrl',
            'prevZipCode1Ctrl'
          ),
          FormValidation.AddressValidation(
            'prevaddress21Ctrl',
            'prevaddress22Ctrl',
            'prevcity2Ctrl',
            'prevstate2Ctrl',
            'prevZipCode2Ctrl'
          ),
          FormValidation.AddressValidation(
            'prevaddress31Ctrl',
            'prevaddress32Ctrl',
            'prevcity3Ctrl',
            'prevstate3Ctrl',
            'prevZipCode3Ctrl'
          ),
          FormValidation.EmailValidation('emailCtrl'),
          FormValidation.EmailCheckValidation('userNameCtrl'),
          FormValidation.StateZipCodeValidation(
            'prevstate1Ctrl',
            'prevZipCode1Ctrl',
            this.stateZipCodeMapping
          ),
          FormValidation.StateZipCodeValidation(
            'prevstate2Ctrl',
            'prevZipCode2Ctrl',
            this.stateZipCodeMapping
          ),
          FormValidation.StateZipCodeValidation(
            'prevstate3Ctrl',
            'prevZipCode3Ctrl',
            this.stateZipCodeMapping
          ),
        ],
      }
    );
    idle.setIdle(this.idleTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.idleTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    const dialogMessage = new DialogMessage(this.idleText, 'Continue', true);
    // becomes idle
    idle.onIdleStart.subscribe(() => {
      //check and allow to open the idle popup
      if (this.isScreenIdlePopupOpened) return;
      const dialogMessage = new DialogMessage(this.idleText, 'Continue', true);
      const dialogRef2 = this.dialog.open(DialogIdleTimerComponent, {
        panelClass: ['fis-style', 'custom-dialog'],
        data: dialogMessage,
        disableClose: true,
      });
      this.isScreenIdlePopupOpened = true;
      dialogRef2.afterClosed().subscribe((result) => {
        // set false while close
        this.isScreenIdlePopupOpened = false;
      });
    });
    // timed out countdown
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });
    // has timed out
    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      window.location.reload();
    });
    // do something when the user no longer idle.
    idle.onIdleEnd.subscribe(() => {});
    // will ping at this intervel while not in idle, in seconds(15 sec)
    keepalive.interval(15);
    this.termsnconditions = true;
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  ngOnDestroy(): void {
    this.hideCaptcha();
  }

  ngOnInit() {
    // Check the user logged-in or not, if logged-in the we have restrict registration and move dashboard.
    var _userName = GlobalVariables.globalvars.get('UserId') || '';
    if (_userName !== null && _userName !== '') {
      this.router.navigate(['dashboard']);
    }

    this.defaultDeliverypreference = 'Portal';
    this.showCaptcha();
    this.stepData = {
      actionType: '',
      isFirst: true,
      isLast: false,
    };
    // threatmetrix java script call
   // generateSessionId(
     // this.TMsessionId,
      //environment.threatMetrixUrl,
      //environment.threatMetrixOrgID
    //);
    // as per review, no need to call at initial load
    /*this.securityValidationService
      .GetTMSessionWithCaptcha(this.TMsessionId, '', 'true')
      .subscribe((res) => {
        this.TMSessiondata = res as TMSessionData;
        const dialogMessage = new DialogMessage('', '', false);
        //if threatmetrix reponse is a REJECT, display below error dialog
        if (
          !(
            this.TMSessiondata.request_result === 'success' &&
            this.TMSessiondata.review_status === 'pass'
          )
        ) {
          dialogMessage.messagetext = this.threatmetrixRejectDialogText;
          dialogMessage.buttontext = this.buttontext;
          dialogMessage.showCancel = false;
          dialogMessage.title = Registrationresource.unexpected_error_title;
          const dialogRefThreatMetrix = this.dialog.open(
            DialogwithmessageComponent,
            {
              panelClass: ['fis-style', 'custom-dialog'],
              data: dialogMessage,
              disableClose: true,
            }
          );
          dialogRefThreatMetrix.afterClosed().subscribe((result) => {
            //contact chexsystems
            if ((result as string) == '' || (result as string) == 'OK') {
              window.location.href = this.contactChexSystemsUrl;
            }
          });
        }
      }); */

    this.getSuffixData().subscribe((res) => {
      this.suffixdata = res as suffixdata;
      if (this.suffixdata.status) {
        this.suffixes = this.suffixdata.data;
      }
    });

    this.getStateData().subscribe((res) => {
      this.statedata = res as states.statedata;
      if (this.statedata.status) {
        this.states = this.statedata.data;
      }
    });

    this.getDLStateData().subscribe((res) => {
      this.dlstatedata = res as states.statedata;
      if (this.dlstatedata.status) {
        this.dlstates = this.dlstatedata.data;
      }
    });
    this.reset();
  }

  ngAfterViewInit() {}

  getSuffixData() {
    return this.http.get('./././assets/jsondata/suffixdata.json');
  }

  getStateData() {
    return this.http.get('./././assets/jsondata/statedata.json');
  }

  getDLStateData() {
    return this.http.get('./././assets/jsondata/dlstatedata.json');
  }

  getStateValidationsData() {
    return this.http.get('./././assets/jsondata/dlstatevalidationsregex.json');
  }

  getLookupFreezeData() {
    return this.http.get('./././assets/jsondata/lookupfreeze.json');
  }


  dateChanged(_$event: any) {
    if (this.birthdate != undefined || this.birthdate != null) {
      this.birthdate = new Date(this.birthdate);
      // const now: Date
      const now = new Date();
      if (this.birthdate < now && this.birthdate >= new Date(1899, 12)) {
        var timeDiff = Math.abs(Date.now() - this.birthdate.getTime());
        this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        if (this.age < 18) {
          const dialogMessage = new DialogMessage(
            this.ageValidation,
            'OK',
            true,
            Registrationresource.age_validation_title
          );
          const dialogRef2 = this.dialog.open(DialogagevalidityComponent, {
            panelClass: ['fis-style', 'custom-dialog'],
            data: dialogMessage,
            disableClose: true,
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if (
              (result as string) == '' ||
              (result as string) == 'OK' ||
              (result as string) == 'cancel'
            ) {
              this.isAgeCriteriaMet = false;
              this.firstFormGroup.controls['dateofbirthCtrl'].setErrors({
                isInValid: true,
              });
            }
          });
        } else {
          this.isAgeCriteriaMet = true;
          this.firstFormGroup.controls['dateofbirthCtrl'].setErrors(null);
        }
      } else {
        this.isAgeCriteriaMet = false;
        this.firstFormGroup.controls['dateofbirthCtrl'].setErrors({
          isInValid: true,
        });
      }
    }
  }

  submitClick(rufstepper: RufStepperComponent): void {
    if (rufstepper.stepStatus() == 'Step 1 of 3') {
      this.step1Validations(rufstepper);
    } else if (rufstepper.stepStatus() === 'Step 2 of 3') {
      this.onIdaQuizSubmit(rufstepper);
    } else if (rufstepper.stepStatus() === 'Step 3 of 3') {
      this.userRegistrationForm = 'thirdFormGroup';
      this.step3Validations(rufstepper);
    }
  }

  async step1Validations(rufstepper: RufStepperComponent) {
    let ssn = this.firstFormGroup.controls['ssnCtrl'].value as MyTel;
    let govtnumber = (ssn.area + ssn.exchange + ssn.subscriber) as string;
   
    // Token for Search Freeze Existence Call
     let capchaTokenFrSrchfrz; 
     try {
      capchaTokenFrSrchfrz = await this.recaptchaV3Service.execute('submit').toPromise();
    }  catch (error) {}

    //preparing req json for search freeze existence
    let searchFreezeExitenceRequestData: any = 
    { GovtNbr: govtnumber,
      UserName: '',
      RecapchaToken: capchaTokenFrSrchfrz
     };

        if (this.firstFormGroup.valid && !this.ssnnomatch) {
          let token;
          //cAPTHA
          try {
            token = await this.recaptchaV3Service.execute('submit').toPromise();
          } catch (error) {}
    
          //call threat metrix api
          const dialogMessage = new DialogMessage('', '', true);

          const tmObj = {
            firstName: this.firstFormGroup.get('fnameCtrl')?.value.trim(),
            lastName: this.firstFormGroup.get('lnameCtrl')?.value.trim(),
            addressLine1: this.firstFormGroup.get('address1Ctrl')?.value.trim(),
            govtNbr: govtnumber,
            state: this.firstFormGroup.get('stateCtrl')?.value.trim(),
            zip: this.firstFormGroup.get('postalCodeCtrl')?.value.trim(),
            city: this.firstFormGroup.get('cityCtrl')?.value.trim(),
            sessionId: window.GUID,
            recaptchaToken: token
          };
          this.securityValidationService
            .GetTMSessionWithCaptcha(tmObj)
            .subscribe(
              async (res) => {
                this.TMSessiondata = res as TMSessionData;
                this.hideCaptcha();
                //if threatmetrix reponse is a REJECT, display below error dialog
                if (
                  !(
                    this.TMSessiondata.request_result === 'success' &&
                    (this.TMSessiondata.review_status === 'pass' ||
                      this.TMSessiondata.review_status === 'review')
                  )
                ) {
                  dialogMessage.messagetext = this.threatmetrixRejectDialogText;
                  dialogMessage.buttontext = this.buttontext;
                  dialogMessage.showCancel = false;
                  dialogMessage.title = Registrationresource.unexpected_error_title;
                  const dialogRefThreatMetrix = this.dialog.open(
                    DialogwithmessageComponent,
                    {
                      panelClass: ['fis-style', 'custom-dialog-without-close'],
                      data: dialogMessage,
                      disableClose: true,
                    }
                  );
                  dialogRefThreatMetrix.afterClosed().subscribe((result) => {
                    //contact chexsystems
                    if ((result as string) == '' || (result as string) == 'OK') {
                      window.location.href =
                        this.contactChexSystemsUrl + '/#header';
                    }
                  });
                } else {
                  //if Theatmetrix pass, check the duplicate user
                    let duplicateUsrChktoken:any;
                    //cAPTHA
                    try {
                      duplicateUsrChktoken = await this.recaptchaV3Service.execute('submit').toPromise();
                    } catch (error) {}

                    // prepare userdata for duplicate user check
                    let userData = {
                      phoneNbr: '',
                      cellPhoneNumber: ''
                    }
                    const dsUserCreationRequest = await this.prepareUserForRegistration(userData);
                    dsUserCreationRequest.UserRegistnReq.RqstHdr.RqstSubTyp = 'D'
                    let userDetail: any = {
                      captchaToken:  duplicateUsrChktoken,
                      dsUserObj: dsUserCreationRequest
                    };
                    this.idpService.CreateUser(userDetail).subscribe(
                      async (res:any) => {
                      if ( res?.UserRegistnRsp && res?.UserRegistnRsp?.Code &&
                        res?.UserRegistnRsp?.Code == '4075') {
                        let messageText = Registrationresource.DuplicateUserRegOnPage1;
                        let failedPopup = Registrationresource.registration_failed_title;
                       this.userCreationFailure(messageText, failedPopup);
                      } else {
                        //if no duplicate user, check for freeze exists
                  this.securityValidationService
                  .SrchFrzExistenceForReg(searchFreezeExitenceRequestData)
                  .subscribe(async (resp) => {
                    this.searchfreezeexistence =
                      resp as searchfreezeexitenceresponse;
  
                    //if protected consumer, display protected consumer pop-up
                    if (this.searchfreezeexistence.ProtectedFlagInd == 'Y') {
                      dialogMessage.messagetext =
                        this.protectedCustomerDialogText;
                      dialogMessage.buttontext = this.buttontext;
                      dialogMessage.showCancel = false;
                      dialogMessage.title =
                        Registrationresource.request_could_not_processed;
                      const dialogRefThreatMetrix = this.dialog.open(
                        DialogwithmessageComponent,
                        {
                          panelClass: [
                            'fis-style',
                            'custom-dialog-without-close',
                          ],
                          data: dialogMessage,
                          disableClose: true,
                        }
                      );
                      dialogRefThreatMetrix.afterClosed().subscribe((result) => {
                        //contact chexsystems
                        if (
                          (result as string) == '' ||
                          (result as string) == 'OK'
                        ) {
                          window.location.href =
                            this.contactChexSystemsUrl + '/#header';
                        }
                      });
                    } else if (res?.UserRegistnRsp?.Code == '4074')  {
                      this.userCreationFailure(Registrationresource.SplHandlingCnsmr, Registrationresource.request_could_not_processed);
                    } else {
                      //if freeze PIN exist, display freeze PIN pop-up
                      if (this.searchfreezeexistence.FreezeExists) {
                        const dialogData = new DialogsecurityfreezepinData(
                          govtnumber,
                          true
                        );
                        const dialogRef = this.dialog.open(
                          DialogsecurityfreezepinComponent,
                          {
                            direction: this._dir.value,
                            panelClass: ['fis-style'],
                            data: dialogData,
                            disableClose: true,
                          }
                        );
  
                        dialogRef.afterClosed().subscribe(async (result) => {
                          this.securityfreezepopupoutput = result as {
                            [key: string]: boolean;
                          };
                          if (
                            this.securityfreezepopupoutput &&
                            !this.securityfreezepopupoutput.cancel
                          ) {
                            //freeze PIN incorrect
                            if (
                              !this.securityfreezepopupoutput
                                ?.isfreezeanswercorrect
                            ) {
                              dialogMessage.messagetext =
                                this.incorrectFreezeAndAlertQuesAnswerText;
                              dialogMessage.buttontext = this.buttontext;
                              dialogMessage.showCancel = false;
                              dialogMessage.title =
                                Registrationresource.request_could_not_processed;
                              const dialogRefThreatMetrix = this.dialog.open(
                                DialogwithmessageComponent,
                                {
                                  panelClass: [
                                    'fis-style',
                                    'custom-dialog-without-close',
                                  ],
                                  data: dialogMessage,
                                  disableClose: true,
                                }
                              );
                              dialogRefThreatMetrix
                                .afterClosed()
                                .subscribe((result) => {
                                  //contact chexsystems
                                  if (
                                    (result as string) == '' ||
                                    (result as string) == 'OK'
                                  ) {
                                    window.location.href =
                                      this.contactChexSystemsUrl + '/#header';
                                  }
                                });
                            } else {
                              //proceed with secuirty alert check
  
                          let capchaTokenFrViewAlert; 
                          try {
                            capchaTokenFrViewAlert = await this.recaptchaV3Service.execute('submit').toPromise();
                          }  catch (error) {}
  
                          let viewSecurityAlertRequestData: ViewSecurityAlertRequestData = <
                            ViewSecurityAlertRequestData
                          >{
                            viewSecurityAlertRequest: {
                              govtNbr: govtnumber,
                              userName: '',
                              recapchaToken: capchaTokenFrViewAlert
                            },
                          };
                              this.securityValidationService
                                .ViewSecurityAlertForReg(viewSecurityAlertRequestData)
                                .subscribe((res) => {
                                  this.viewsecurityalert =
                                    res as ViewSecurityAlertResponseData;
  
                                  //if security alert question exists, display alert question pop-up
                                  if (
                                    this.viewsecurityalert.securityAlert == 'Y'
                                  ) {
                                    const dialogData =
                                      new DialogsecurityalertquestionData(
                                        govtnumber,
                                        this.viewsecurityalert.questionCode,
                                        this.viewsecurityalert.questionText,
                                        true,
                                        null
                                      );
                                    const dialogRef = this.dialog.open(
                                      DialogsecurityalertquestionComponent,
                                      {
                                        direction: this._dir.value,
                                        panelClass: ['fis-style'],
                                        data: dialogData,
                                        disableClose: true,
                                      }
                                    );
  
                                    dialogRef
                                      .afterClosed()
                                      .subscribe((result) => {
                                        this.securityalertpopupoutput =
                                          result as {
                                            [key: string]: boolean;
                                          };
                                        if (
                                          this.securityalertpopupoutput &&
                                          !this.securityalertpopupoutput.cancel
                                        ) {
                                          if (
                                            !this.securityalertpopupoutput
                                              ?.issecurityanswercorrect
                                          ) {
                                            dialogMessage.messagetext =
                                              this.incorrectFreezeAndAlertQuesAnswerText;
                                            dialogMessage.buttontext =
                                              this.buttontext;
                                            dialogMessage.showCancel = false;
                                            dialogMessage.title =
                                              Registrationresource.request_could_not_processed;
                                            const dialogRefThreatMetrix =
                                              this.dialog.open(
                                                DialogwithmessageComponent,
                                                {
                                                  panelClass: [
                                                    'fis-style',
                                                    'custom-dialog-without-close',
                                                  ],
                                                  data: dialogMessage,
                                                  disableClose: true,
                                                }
                                              );
                                            dialogRefThreatMetrix
                                              .afterClosed()
                                              .subscribe((result) => {
                                                //contact chexsystems
                                                if (
                                                  (result as string) == '' ||
                                                  (result as string) == 'OK'
                                                ) {
                                                  window.location.href =
                                                    this.contactChexSystemsUrl +
                                                    '/#header';
                                                }
                                              });
                                          } else {
                                            rufstepper.changeStep('next');
                                            this.nextbuttontext = this.stepData
                                              .isLast
                                              ? 'Submit'
                                              : 'Continue';
                                            this.loadSecurityQuiz();
                                            this;
                                            return;
                                          }
                                        } else {
                                          window.location.reload();
                                        }
                                      });
                                  } else {
                                    //if security alert question does not exist
                                    rufstepper.changeStep('next');
                                    this.nextbuttontext = this.stepData.isLast
                                      ? 'Submit'
                                      : 'Continue';
                                    this.loadSecurityQuiz();
                                    return;
                                  }
                                });
                            }
                          } else {
                            window.location.reload();
                          }
                        });
                      } else {
                        //if freeze pin does not exist, proceed with secuirty alert check
                            let capchaTokenFrViewAlert; 
                            try {
                              capchaTokenFrViewAlert = await this.recaptchaV3Service.execute('submit').toPromise();
                            }  catch (error) {}
  
                            let viewSecurityAlertRequestData: ViewSecurityAlertRequestData = <
                              ViewSecurityAlertRequestData
                            >{
                              viewSecurityAlertRequest: {
                                govtNbr: govtnumber,
                                userName: '',
                                recapchaToken: capchaTokenFrViewAlert
                              },
                            };
                        this.securityValidationService
                          .ViewSecurityAlertForReg(viewSecurityAlertRequestData)
                          .subscribe((res) => {
                            this.viewsecurityalert =
                              res as ViewSecurityAlertResponseData;
  
                            //if security alert question exists
                            if (this.viewsecurityalert.securityAlert == 'Y') {
                              const dialogData =
                                new DialogsecurityalertquestionData(
                                  govtnumber,
                                  this.viewsecurityalert.questionCode,
                                  this.viewsecurityalert.questionText,
                                  true,
                                  null
                                );
                              const dialogRef = this.dialog.open(
                                DialogsecurityalertquestionComponent,
                                {
                                  direction: this._dir.value,
                                  panelClass: ['fis-style'],
                                  data: dialogData,
                                  disableClose: true,
                                }
                              );
  
                              dialogRef.afterClosed().subscribe((result) => {
                                this.securityalertpopupoutput = result as {
                                  [key: string]: boolean;
                                };
                                if (
                                  this.securityalertpopupoutput &&
                                  !this.securityalertpopupoutput.cancel
                                ) {
                                  //TO-DO
                                  if (
                                    !this.securityalertpopupoutput
                                      ?.issecurityanswercorrect
                                  ) {
                                    dialogMessage.messagetext =
                                      this.incorrectFreezeAndAlertQuesAnswerText;
                                    dialogMessage.buttontext = this.buttontext;
                                    dialogMessage.showCancel = false;
                                    dialogMessage.title =
                                      Registrationresource.request_could_not_processed;
                                    const dialogRefThreatMetrix =
                                      this.dialog.open(
                                        DialogwithmessageComponent,
                                        {
                                          panelClass: [
                                            'fis-style',
                                            'custom-dialog-without-close',
                                          ],
                                          data: dialogMessage,
                                          disableClose: true,
                                        }
                                      );
                                    dialogRefThreatMetrix
                                      .afterClosed()
                                      .subscribe((result) => {
                                        //contact chexsystems
                                        if (
                                          (result as string) == '' ||
                                          (result as string) == 'OK'
                                        ) {
                                          window.location.href =
                                            this.contactChexSystemsUrl +
                                            '/#header';
                                        }
                                      });
                                  } else {
                                    rufstepper.changeStep('next');
                                    this.nextbuttontext = this.stepData.isLast
                                      ? 'Submit'
                                      : 'Continue';
                                    this.loadSecurityQuiz();
                                    return;
                                  }
                                } else {
                                  window.location.reload();
                                  this.router.navigate(['/registration']);
                                }
                              });
                            } else {
                              //if security alert question does not exist
                              rufstepper.changeStep('next');
                              this.nextbuttontext = this.stepData.isLast
                                ? 'Submit'
                                : 'Continue';
                              this.loadSecurityQuiz();
                              return;
                            }
                          });
                      }
                    }
                  });
                      }
                    });


                  
                }
              },
              (error) => {},
              () => {}
            );
        } else {
          this.ssncontrol.handleValidation();
          this.verifyssncontrol.handleValidation();
          this.pageScrollTop();
        }
          
  }

  async step3Validations(rufstepper: RufStepperComponent) {
    const isEmptyString = (data: string): boolean =>
      typeof data === 'string' && data.trim().length == 0;
    if (this.thirdFormGroup.valid) {
      let userName = this.thirdFormGroup.get('userNameCtrl')?.value;
      let phonenumberarea =
        this.phonenumbercontrol.parts.controls['area']?.value;
      let phonenumbersubscriber =
        this.phonenumbercontrol.parts.controls['subscriber']?.value;
      let phonenumberexchange =
        this.phonenumbercontrol.parts.controls['exchange']?.value;
      let phonenumbertype =
        this.phonenumbercontrol.parts.controls['phonetype']?.value;

      let phoneNumber = (phonenumberarea +
        phonenumberexchange +
        phonenumbersubscriber) as string;
      let phoneNbr: string = '',
        cellPhoneNumber: string = '';
      if (phonenumbertype === PhoneType.Mobile) cellPhoneNumber = phoneNumber;
      else if (phonenumbertype === PhoneType.Phone) phoneNbr = phoneNumber;

      let chexsystemDepartment: Department = {
        departmentId: environment.chexsyStemDepartmentId,
        departmentName: environment.chexsyStemDepartmentName,
        primaryDept: environment.chexsyStemDepartmentPrimaryDept,
      };
      let profileRegToLogins: ProfileRegToLogin[] = [
        {
          id: environment.chexSystemsOTPRegularProfileID,
          exclusion: 0,
          description:
            'A Default Profile has been created for users that require access to common settings like Changing Password, Managing OTP Devices, et cetera.',
          profileRegistryName: 'ChexSystems OTP Regular',
          restricted: false,
        },
      ];

      let token:any;
      //cAPTHA
      try {
        token = await this.recaptchaV3Service.execute('submit').toPromise();
      } catch (error) {}

      let userDetail: any = {
        firstName: this.firstFormGroup.get('fnameCtrl')?.value,
        lastName: this.firstFormGroup.get('lnameCtrl')?.value,
        emailAddress: this.thirdFormGroup.get('emailCtrl')?.value,
        loginName: this.thirdFormGroup.get('userNameCtrl')?.value,
        providerConfigurationID: -1,
        role: environment.chexsyStemUserRole,
        loginRole: environment.chexsyStemLoginRole,
        password: this.thirdFormGroup.get('pwdCtrl')?.value,
        autoGeneratePassword: false,
        departments: [chexsystemDepartment],
        phone: !isEmptyString(phoneNbr) ? '+1' + phoneNbr : '',
        userProfile: this.userProfileRequest,
        profileRegToLogins: profileRegToLogins,
        type: 1,
        typeCode: 'User',
        mobile: !isEmptyString(cellPhoneNumber) ? '+1' + cellPhoneNumber : '',
        sendPasswordEmailNotification: true,
        successfulLogin: true,
        passwordUpdate: true,
        emailUpdate: true,
        mobileNumberUpdate: false,
        passwordViolation: false,
        otPViolation: false,
        accountLockViolation: true,
        rejectedLogin: true,
        timezone: Registrationresource.Timezone,  
        captchaToken: token      
      };

      // 
      var userData: any = {};
      userData.phoneNbr = phoneNbr;
      userData.cellPhoneNumber = cellPhoneNumber;
      const dsUserCreationRequest = await this.prepareUserForRegistration(userData);
      userDetail.dsUserObj = dsUserCreationRequest;
      
      this.idpService.CreateUser(userDetail).subscribe(
        async (res: any) => {
          if ( res.UserRegistnRsp && res.UserRegistnRsp.Message &&
            res.UserRegistnRsp.Message.toUpperCase() ==
              'SUCCESS' &&
            res.UserRegistnRsp.Code == '0'
          ) {
            this.registrationsuccesstext =
              this.registrationsuccesstext.replace(
                '#####',
                res.userDisplayName
              );
            const dialogMessage = new DialogMessage(
              this.registrationsuccesstext,
              'Login',
              false,
              Registrationresource.registration_complete_title
            );
            const dialogRef2 = this.dialog.open(
              DialogagevalidityComponent,
              {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              }
            );
            dialogRef2.afterClosed().subscribe((result) => {
              //chexsystems public home page
              if (
                (result as string) == '' ||
                (result as string) == 'OK'
              ) {
                window.location.href = environment.auth.redirectUri;
              }
            });
          } else {
            if ((res.UserRegistnRsp && res.UserRegistnRsp.Code) &&
                  (res.UserRegistnRsp.Code == '4113' ||
                  res.UserRegistnRsp.Code == '4135' ||  res.UserRegistnRsp.Code == '4074')
                ) {
                  let messageText = res.UserRegistnRsp.Code == '4074'? Registrationresource.SplHandlingCnsmr : Registrationresource.DuplicateUserRegistration;
                  let failedPopup = res.UserRegistnRsp.Code == '4074' ? Registrationresource.request_could_not_processed :Registrationresource.registration_failed_title;
                  this.userCreationFailure(messageText, failedPopup); // Error message popup
                } else {
                  let message ='Registration was not successful. We apologize for the incovenience. Please try again.';
                  this.userCreationFailure(message, Registrationresource.registration_failed_title); // Error message popup
                }
          }
        },
        (err) => {
          const dialogMessage = new DialogMessage(
            'Registration was not successful. We apologize for the inconvenience. Please try again.',
            'Ok',
            false,
            Registrationresource.registration_failed_title
          );
          const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
            // width: this.dialogWidth,
            panelClass: ['fis-style', 'custom-dialog-without-close'],
            data: dialogMessage,
            disableClose: true,
          });
          dialogRef2.afterClosed().subscribe((result) => {
            //chexsystems public home page
            if ((result as string) == 'OK') {
              window.location.reload();
            }
          });
        },
        () => {}
      );
    } else {
      this.phonenumbercontrol.handleValidation();
      this.pageScrollTop();
    }
  }

  async prepareUserForRegistration(userData: any) {
    // Create user in Decision solution
          // #region Prepare user creation request
    let token;
    let ssn = this.firstFormGroup.controls['ssnCtrl'].value as MyTel;
    let govtnumber = (ssn.area + ssn.exchange + ssn.subscriber) as string;
    let requestHeader: RqstHdr = {
      RqstTyp: 'UR',
      RqstSubTyp: 'N',
    };
    let consumerAddressDetail: CnsmrAddrDetls = {
      CnsmrAddr: {
        AddrLine1: this.firstFormGroup.get('address1Ctrl')?.value,
        AddrLine2: this.firstFormGroup.get('address2Ctrl')?.value,
        CityNm: this.firstFormGroup.get('cityCtrl')?.value,
        StatCode: this.firstFormGroup.get('stateCtrl')?.value,
        ZipCode: this.firstFormGroup.get('postalCodeCtrl')?.value,
      },
    };
    let consumerDriveLicenseDetail: CnsmrDrivLicenseDetls = {
      DrivLicenseIssuSt: this.thirdFormGroup.get('DLstateCtrl')?.value,
      DrivLicenseNbr: this.thirdFormGroup.get('DLNumberCtrl')?.value,
    };
    let userSuffix =
      this.firstFormGroup.get('suffix')?.value == 'None'
        ? ''
        : this.firstFormGroup.get('suffix')?.value;
    let consumerNameDetail: CnsmrNmDetls = {
      FrstNm: this.firstFormGroup.get('fnameCtrl')?.value,
      LastNm: this.firstFormGroup.get('lnameCtrl')?.value,
      MidNm: this.firstFormGroup.get('mnameCtrl')?.value,
      SuffixNm: userSuffix,
      PreviousLastNm: this.firstFormGroup.get('prevlnameCtrl')?.value,
    };
    let consumerPersonalDetail: CnsmrPrsnlDetls = {
      BirthDt: this.convertValidDateFormat(this.birthdate), // MM/DD/YYYYHH:MM:SS
      CnsmrDrivLicenseDetls: consumerDriveLicenseDetail,
      CnsmrNmDetls: consumerNameDetail,
      EmailAddr: this.thirdFormGroup.get('emailCtrl')?.value,
      GovtNbr: govtnumber,
      PhoneNbr: userData.phoneNbr,
      CellPhoneNbr: userData.cellPhoneNumber,
      CorrespondencDeliveryPreference:
        this.thirdFormGroup.get('deliverypreference')?.value,
    };
    let previousAddressDetail: PreviousAddrDetls = {
      PreviousAddr1: {
        PreviousAddr1Line1:
          this.thirdFormGroup.get('prevaddress11Ctrl')?.value,
        PreviousAddr1Line2:
          this.thirdFormGroup.get('prevaddress12Ctrl')?.value,
        PreviousCityNm1: this.thirdFormGroup.get('prevcity1Ctrl')?.value,
        PreviousStatCode1:
          this.thirdFormGroup.get('prevstate1Ctrl')?.value,
        PreviousZipCode1:
          this.thirdFormGroup.get('prevZipCode1Ctrl')?.value,
      },
      PreviousAddr2: {
        PreviousAddr2Line1:
          this.thirdFormGroup.get('prevaddress21Ctrl')?.value,
        PreviousAddr2Line2:
          this.thirdFormGroup.get('prevaddress22Ctrl')?.value,
        PreviousCityNm2: this.thirdFormGroup.get('prevcity2Ctrl')?.value,
        PreviousStatCode2:
          this.thirdFormGroup.get('prevstate2Ctrl')?.value,
        PreviousZipCode2:
          this.thirdFormGroup.get('prevZipCode2Ctrl')?.value,
      },
      PreviousAddr3: {
        PreviousAddr3Line1:
          this.thirdFormGroup.get('prevaddress31Ctrl')?.value,
        PreviousAddr3Line2:
          this.thirdFormGroup.get('prevaddress32Ctrl')?.value,
        PreviousCityNm3: this.thirdFormGroup.get('prevcity3Ctrl')?.value,
        PreviousStatCode3:
          this.thirdFormGroup.get('prevstate3Ctrl')?.value,
        PreviousZipCode3:
          this.thirdFormGroup.get('prevZipCode3Ctrl')?.value,
      },
    };
    let userRegisterRequest: UserRegistnReq = {
      RqstHdr: requestHeader,
      CnsmrAddrDetls: consumerAddressDetail,
      CnsmrPrsnlDetls: consumerPersonalDetail,
      PreviousAddrDetls: previousAddressDetail,
      UserName: '',
      RecvdDtTm: this.convertDateTime(new Date()), // DD/MM/YYYhh:mm:ss
    };
    let dsUserCreationRequest: DecisionSolutionUserCreationRequest = {
      UserRegistnReq: userRegisterRequest,
    };// #endregion

    try {
      token = await this.recaptchaV3Service.execute('submit').toPromise();
    } catch (error) {}

    // this.idaQuizService
    //   .CreateUserInDecisionSolution(dsUserCreationRequest,token)
    //   .subscribe(
    //     (res) => {},
    //     (error) => {
    //         // At this point user needs to deleted from IDP. 
    //         // the same has been taken care of in the wrapper (backend code)
    //         // this has been done to avoid exposing unauthenticated IDP user delete call
    //         this.userCreationFailure(); // Error message popup                  
    //     },
    //     () => {}
    //   )
    return dsUserCreationRequest;
  }

  cancelClick(rufstepper: RufStepperComponent): void {
    const dialogMessage = new DialogMessage(
      this._translate.instant('POPUPS.CANCELREGISTRATIONTEXT'),
      this._translate.instant('BUTTONS.YES'),
      true,
      this._translate.instant('POPUPS.CANCELREGISTRATIONTITLE')
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      // width: this.dialogWidth,
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      //chexsystems public home page
      if ((result as string) == 'OK') {
        window.location.href = this.homeChexSystemsUrl + '#head';
      }
    });
  }

  navigateToTermsandConditions(event: any) {
    event.preventDefault();
    const dialogRefTermsandConditions = this.dialog.open(
      DialogtermsandconditionsComponent,
      {
        disableClose: true,
      }
    );
    dialogRefTermsandConditions.afterClosed().subscribe((result) => {});
  }

  navigateToLogin(event: any) {
    window.location.href = environment.auth.redirectUri;
  }

  onStepSelect(event: any) {
    this.stepData = event;
    this.nextbuttontext = this.stepData.isLast ? 'Submit' : 'Continue';
  }

  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  addSSNErrorMessage(data: any) {
    this.ssnmodel = data;
    this.ssnminlength = false;
    this.ssninvalid = false;

    if (this.ssnmodel.message == 'minlength') {
      this.ssnminlength = true;
    } else {
      if (
        !this.securityValidationService.IsValidSSN(
          this.ssnmodel.value.area,
          this.ssnmodel.value.exchange,
          this.ssnmodel.value.subscriber
        )
      ) {
        this.ssninvalid = true;
        this.ssncontrol.parts.controls['area'].setErrors({ invalid: true });
      } else {
        this.ssncontrol.parts.controls['area'].setErrors({ invalid: null });
        this.ssncontrol.parts.controls['area'].updateValueAndValidity();

        if (this.ssnmodel.message == 'checkformatch') {
          let verifyssnarea =
            this.verifyssncontrol.parts.controls['area']?.value;
          let verifyssnsubscriber =
            this.verifyssncontrol.parts.controls['subscriber']?.value;
          let verifyssnexchange =
            this.verifyssncontrol.parts.controls['exchange']?.value;

          if (
            !(
              verifyssnarea == '' &&
              verifyssnsubscriber == '' &&
              verifyssnexchange == ''
            ) &&
            !(
              verifyssnarea == this.ssnmodel.value.area &&
              verifyssnsubscriber == this.ssnmodel.value.subscriber &&
              verifyssnexchange == this.ssnmodel.value.exchange
            )
          ) {
            this.ssnnomatch = true;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: true,
            });
          } else {
            this.ssnnomatch = false;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: null,
            });
            this.verifyssncontrol.parts.controls[
              'area'
            ].updateValueAndValidity();
          }
        }
      }
    }
    if (this.ssnmodel.message == '' || this.ssnminlength || this.ssninvalid) {
      this.ssncontrol.ChangeErrorStyles(true);
    } else {
      this.ssncontrol.ChangeErrorStyles(false);
    }

    if (
      this.ssnnomatch ||
      this.verifyssnminlength ||
      this.verifyssninvalid ||
      this.verifyssncontrol.errorState
    ) {
      this.verifyssncontrol.ChangeErrorStyles(true);
    } else {
      this.verifyssncontrol.ChangeErrorStyles(false);
    }
  }

  addVerifySSNErrorMessage(data: any) {
    this.ssnmodel = data;
    this.verifyssnminlength = false;
    this.verifyssninvalid = false;

    if (this.ssnmodel.message == 'minlength') {
      this.verifyssnminlength = true;
    } else {
      if (
        !this.securityValidationService.IsValidSSN(
          this.ssnmodel.value.area,
          this.ssnmodel.value.exchange,
          this.ssnmodel.value.subscriber
        )
      ) {
        this.verifyssninvalid = true;
        this.verifyssncontrol.parts.controls['area'].setErrors({
          invalid: true,
        });
      } else {
        this.verifyssncontrol.parts.controls['area'].setErrors({
          invalid: null,
        });
        this.verifyssncontrol.parts.controls['area'].updateValueAndValidity();
        if (this.ssnmodel.message == 'checkformatch') {
          let ssnarea = this.ssncontrol.parts.controls['area']?.value;
          let ssnsubscriber =
            this.ssncontrol.parts.controls['subscriber']?.value;
          let ssnexchange = this.ssncontrol.parts.controls['exchange']?.value;

          if (
            !(ssnarea == '' && ssnsubscriber == '' && ssnexchange == '') &&
            !(
              ssnarea == this.ssnmodel.value.area &&
              ssnsubscriber == this.ssnmodel.value.subscriber &&
              ssnexchange == this.ssnmodel.value.exchange
            )
          ) {
            this.ssnnomatch = true;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: true,
            });
          } else {
            this.ssnnomatch = false;
            this.verifyssncontrol.parts.controls['area'].setErrors({
              mismatch: null,
            });
            this.verifyssncontrol.parts.controls[
              'area'
            ].updateValueAndValidity();
          }
        }
      }
    }
    if (
      this.ssnmodel.message == '' ||
      this.verifyssnminlength ||
      this.verifyssninvalid ||
      this.ssnnomatch
    ) {
      this.verifyssncontrol.ChangeErrorStyles(true);
    } else {
      this.verifyssncontrol.ChangeErrorStyles(false);
    }
  }

  addPhoneNumberErrorMessage(data: any) {
    this.phonemodel = data;
    this.phonenominlength = false;
    this.phonenumberinvalid = false;

    if (this.phonemodel.message == 'minlength') {
      this.phonenominlength = true;
    } else {
      if (
        !this.securityValidationService.IsValidPhoneNumber(
          this.phonemodel.value.area,
          this.phonemodel.value.exchange,
          this.phonemodel.value.subscriber
        )
      ) {
        this.phonenumberinvalid = true;
        this.phonenumbercontrol.parts.controls['area'].setErrors({
          invalid: true,
        });
      } else {
        this.phonenumbercontrol.parts.controls['area'].setErrors({
          invalid: null,
        });
        this.phonenumbercontrol.parts.controls['area'].updateValueAndValidity();
      }
    }

    if (
      this.phonenominlength ||
      this.phonemodel.message == '' ||
      this.phonenumberinvalid
    ) {
      this.phonenumbercontrol.ChangeErrorStyles(true);
    } else {
      this.phonenumbercontrol.ChangeErrorStyles(false);
    }
  }

  loadDLValidation() {
    var statecode = this.thirdFormGroup.get('DLstateCtrl')?.value;
    var dlnumber = this.thirdFormGroup.get('DLNumberCtrl')?.value;
    var validation = false;

    if (statecode == '' && dlnumber != '') {
      this.thirdFormGroup.get('DLstateCtrl')?.setErrors({ stateselect: true });
    } else if (statecode != '' && dlnumber == '') {
      this.thirdFormGroup
        .get('DLNumberCtrl')
        ?.setErrors({ DLNumberEnter: true });
    } else if (statecode == '' && dlnumber == '') {
      this.thirdFormGroup.get('DLstateCtrl')?.setErrors({ stateselect: null });
      this.thirdFormGroup.get('DLstateCtrl')?.updateValueAndValidity();
      this.thirdFormGroup
        .get('DLNumberCtrl')
        ?.setErrors({ patterndoesnotmatch: null });
      this.thirdFormGroup
        .get('DLNumberCtrl')
        ?.setErrors({ DLNumberEnter: null });
      this.thirdFormGroup.get('DLNumberCtrl')?.updateValueAndValidity();
    } else {
      this.getStateValidationsData().subscribe((res: any) => {
        let dlstatevalidationsdata =
          res as dlstatevalidations.dlstatevalidationsdata;
        if (dlstatevalidationsdata.status) {
          let dlstatevalidationformats =
            dlstatevalidationsdata.data as dlstatevalidations.item[];
          this.filtervalidationformats = dlstatevalidationformats.filter(
            (item) => item.state === statecode
          );

          validation =
            this.filtervalidationformats?.length === 0 ? true : false;
          this.filtervalidationformats[0]?.validationformats.forEach(
            (validationformat) => {
              let regexPattern = RegExp(validationformat);
              if (regexPattern.test(dlnumber)) {
                validation = true;
              }
            }
          );
          if (!validation) {
            this.thirdFormGroup
              .get('DLNumberCtrl')
              ?.setErrors({ patterndoesnotmatch: true });
          } else {
            this.thirdFormGroup
              .get('DLNumberCtrl')
              ?.setErrors({ patterndoesnotmatch: null });
            this.thirdFormGroup.get('DLNumberCtrl')?.updateValueAndValidity();
          }
        }
      });
    }
  }

 async checkforUserNameAvailability(event: any) {
    if (
      this.userName?.trim() != this.tempUserName?.trim() &&
      this.userName?.trim() != ''
    ) {
        //token generation for usercount API
      let capchaToken: any;
      try {
        capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
      }  catch(err) {}

      const reqObj = {
        data: this.userName,
        isUsername: true,
        token: capchaToken
      }
      // call idp webapi here for username availability
      this.idpService.CheckUserNameCountForReg(reqObj).subscribe(
        (res: any) => {
          if (res > 0 ) {
            this.userNameAvailable = true;
            this.thirdFormGroup
              .get('userNameCtrl')
              ?.setErrors({ usernamenotavailable: true });
          } else {
            this.userNameAvailable = false;
            this.thirdFormGroup
              .get('userNameCtrl')
              ?.setErrors({ usernamenotavailable: null });
            this.thirdFormGroup.get('userNameCtrl')?.updateValueAndValidity();
          }
        },
        (err) => {},
        () => {}
      );
    }
  }
  async checkforEmailAddressAvailability(event: any) {
    let emailaddress = this.thirdFormGroup.get('emailCtrl')?.value;
    if ( emailaddress !== '' && emailaddress !== undefined) {

      let capchaToken: any;
      try {
        capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
      }catch (error) {}
      const reqObj = {
        data: emailaddress.trim(),
        isUsername: false,
        token: capchaToken
      }
      // call idp webapi here for username availability
      this.idpService.CheckUserNameCountForReg(reqObj).subscribe(
        (res:any) => {
          if (res > 0 ) {
            this.emailAddressAvailable = true;
            this.thirdFormGroup
              .get('emailCtrl')
              ?.setErrors({ emailAddressAvailable: true });
          } else {
            this.emailAddressAvailable = false;
            this.thirdFormGroup
              .get('emailCtrl')
              ?.setErrors({ emailAddressAvailable: null });
            this.thirdFormGroup.get('emailCtrl')?.updateValueAndValidity();
          }
        },
        (err) => {},
        () => {}
      );
    }
  }
  keyPressAlphabets(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (
      charCode == 32 ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  /**
   * To validate the ida quiz answers
   */
 async onIdaQuizSubmit(rufstepper: RufStepperComponent) {
    if (this.secondFormGroup.valid) {
      this.stopQuizTimer();
      let answers: IdaQuizAnswers[] = [];
      let quizStatus: QuizStatus = { status: QuizStatusValue.Fail };
      let valueConst: IdaAnswerDetail = new IdaAnswerDetail();
      let indexValue: number = 1;
      this.idaQuizAnswerDetail = new IdaAnswerDetail();
      for (const field in this.secondFormGroup.controls) {
        let key = ('idaQuestion' + indexValue) as keyof typeof valueConst;
        let val: AnswerDetail = new AnswerDetail();
        val.questionId = field;
        val.responseId = this.secondFormGroup.controls[field].value;
        this.idaQuizAnswerDetail[key] = val;
        indexValue++;
        answers.push(
          new IdaQuizAnswers({
            questionId: field,
            answerId: this.secondFormGroup.controls[field].value,
          })
        );
      }
      let decisionRequest = this.getQuizDecisionRequestPayload(
        this.idaQuizSection.quizId,
        this.idaQuizSection.transId,
        this.idaQuizAnswerDetail
      );
      if (!this.isChallengeQuiz) {
        let capchaToken: any;
        try {
          capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
        }  catch (error) {}
      
        this.idaQuizService
          .idaDecisionValidate(decisionRequest, capchaToken)
          .subscribe((res) => {
            if (res !== null) {
              if (res.decision?.toLowerCase() == 'pass') {
                this.termsnconditions = false;
                rufstepper.changeStep('matStepperNext');
                this.phonenumbercontrol.parts.controls['phonetype']?.setValue(
                  'mobile'
                );
                this.pageScrollTop();
              }
              if (res.decision?.toLowerCase() == 'fail') {
                quizStatus.status = QuizStatusValue.Fail;
                this.termsnconditions = false;
                this.quizFailedPopup();
              }
              if (res.decision?.toLowerCase() == 'challenge') {
                this.pageScrollTop();
                this.ida_quiz_title = Registrationresource.challenge_quiz_title;
                this.ida_quiz_subtitle =
                  Registrationresource.challenge_quiz_subtitle;
                if (res.idaQuiz != null) {
                  this.idaQuizSection.quizId = res.idaQuiz.quizId;
                  this.idaQuizSection.quizRemain = res.idaQuiz.quizRemain;
                  this.idaQuizSection.quizTime = res.idaQuiz.quizTime;
                  this.idaQuizSection.transId = res.idaQuiz.transId;
                  this.idaQuizSection.userReferenceText1 =
                    res.idaQuiz.userReferenceText1;
                  this.idaQuizSection.userReferenceText2 =
                    res.idaQuiz.userReferenceText2;
                  this.idaQuizSection.userReferenceText3 =
                    res.idaQuiz.userReferenceText3;

                  let idaQuizDetail: Question[] = new Array();

                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion1);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion2);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion3);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion4);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion5);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion6);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion7);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion8);
                  idaQuizDetail.push(<Question>res.idaQuiz.idaQuestion9);

                  let idaQuestions: IdaQuestion[] = [];
                  idaQuizDetail.forEach((quest) => {
                    if (
                      quest != null &&
                      quest != undefined &&
                      quest.id != null
                    ) {
                      let question: IdaQuestion = new IdaQuestion();
                      question.id = quest.id;
                      question.questionText = quest.questionText;
                      question.options = [];
                      question.tagName = quest.questionText;
                      if (quest.answer1Id !== null) {
                        question.options.push({
                          value: quest.answer1Id,
                          text: quest.answer1Text,
                        });
                      }
                      if (quest.answer2Id !== null) {
                        question.options.push({
                          value: quest.answer2Id,
                          text: quest.answer2Text,
                        });
                      }
                      if (quest.answer3Id !== null) {
                        question.options.push({
                          value: quest.answer3Id,
                          text: quest.answer3Text,
                        });
                      }
                      if (quest.answer4Id !== null) {
                        question.options.push({
                          value: quest.answer4Id,
                          text: quest.answer4Text,
                        });
                      }
                      if (quest.answer5Id !== null) {
                        question.options.push({
                          value: quest.answer5Id,
                          text: quest.answer5Text,
                        });
                      }
                      if (quest.answer6Id !== null) {
                        question.options.push({
                          value: quest.answer6Id,
                          text: quest.answer6Text,
                        });
                      }
                      idaQuestions.push(question);
                    }
                  });

                  this.timersInfo = [];
                  this.idaQuizSection.idaQuestion = idaQuestions;
                  let group: any = {};
                  this.idaQuizSection.idaQuestion.forEach((question) => {
                    group[question.id] = new FormControl('', [
                      Validators.required,
                    ]);
                  });
                  this.secondFormGroup.reset();
                  this.secondFormGroup = this._formBuilder.group(group);

                  let timerInfo = new Timerdetail();
                  timerInfo.title =
                    Registrationresource.challenge_quiz_subtitle;
                  timerInfo.totalTime =
                    Registrationresource.challenge_quiz_timer_seconds;
                  this.timersInfo.push(timerInfo);
                }
              }
              if (
                res.errorCd != null &&
                res.errorCd != '' &&
                res.errorCd == '4036'
              ) {
                const warningPopup: { [key: string]: string } =
                  Registrationresource.idaQuizNotGenerated;
                  var msg = warningPopup.message1 + 
                  "<a href='" + environment.contactChexSystemsUrl + "'>ChexSystems</a>" + 
                  warningPopup.message2;
                this.idaQuizErrorPopup(msg, warningPopup.title);
              }
            }
          });
      }
    } else {
      const popupMessage: { [key: string]: string } =
        Registrationresource.idaPopupMessageDetail;
      const idaQuizMessage = popupMessage.message
        .replace('#minutes#', this.timersInfo[0].minutes.toString())
        .replace('#seconds#', this.timersInfo[0].seconds.toString());
      const dialogMessage = new DialogMessage(
        idaQuizMessage,
        popupMessage.buttonText,
        false,
        popupMessage.title
      );
      const dialogRef2 = this.dialog.open(DialogagevalidityComponent, {
        panelClass: ['fis-style', 'custom-dialog'],
        data: dialogMessage,
        disableClose: true,
      });
      dialogRef2.afterClosed().subscribe((result) => {
        if ((result as string) == '' || (result as string) == 'OK') {
          this.pageScrollTop();
        }
      });
    }
  }
  private quizFailedPopup() {
    const dialogMessage = new DialogMessage(
      Registrationresource.quiz_validation_message,
      this.buttontext,
      false,
      Registrationresource.request_could_not_processed
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) == '' || (result as string) == 'OK') {
        window.location.href = this.contactChexSystemsUrl + '/#header';
      }
    });
  }

  /**
   * To validate the challenge quiz answers
   */
  onIdaQuizValidate() {
    let quizRequest = new QuizRequest();
    this.idaQuizService.GetIdaQuizQuestions(quizRequest);
  }

  /**
   * To validate the challenge quiz answers
   */
  onIdaChallengeQuizSubmit() {
    let quizRequest = new QuizRequest();
    const idaQuizQuestions =
      this.idaQuizService.GetIdaQuizQuestions(quizRequest);
  }

  selectionChange(event: StepperSelectionEvent) {
    //let stepLabel = event.selectedStep.label
    if (event.selectedIndex === 1) {
      if (this.timersInfo.length <= 0) {
        // let timerInfo = new Timerdetail();
        // timerInfo.totalTime = Registrationresource.ida_quiz_timer_seconds;
        // this.timersInfo.push(timerInfo);
      }
    } else if (event.selectedIndex === 0 || event.selectedIndex === 1) {
      this.stopQuizTimer();
    }
  }

  private stopQuizTimer() {
    if (this.timersInfo.length > 0) {
      let info = new Timerdetail();
      info.type = TimerOption.Pause;
      info.id = this.timersInfo[0].id;
      info.minutes = this.timersInfo[0].minutes;
      info.seconds = this.timersInfo[0].seconds;
      this.timersInfo[0] = info;
    }
  }

  quizEnd(min: any) {
    this.stopQuizTimer();
    const dialogMessage = new DialogPanel(
      this._translate.instant('QUIZTIMER.TIMEEXCEEDMESSAGE'),
      this._translate.instant('BUTTONS.CONTACTCHEXSYSTEMS'),
      this._translate.instant('BUTTONS.OK'),
      this._translate.instant('QUIZTIMER.TIMEEXCEEDMESSAGETITLE')
    );
    const dialogRef2 = this.dialog.open(
      DialogWithMessageCustomButtonComponent,
      {
        panelClass: ['fis-style', 'custom-dialog-without-close'],
        data: dialogMessage,
        disableClose: true,
      }
    );
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string).toLowerCase() == 'ok') {
        window.location.reload();
      }
      if ((result as string).toLowerCase() == 'contact chexsystems') {
        window.location.href = this.contactChexSystemsUrl + '/#header';
      }
    });
  }

   private  getQuizRequestPayload(): any {
   
    let ssn = this.firstFormGroup.controls['ssnCtrl'].value as MyTel;
    let govtnumber = (ssn.area + ssn.exchange + ssn.subscriber) as string;
    let quizPayloadRequest = new QuizRequest();
    let idaIdentification: IdaIdentification = {
      acquirerid: environment.acquirerid,
      configkey: environment.configkey,
      customerid: environment.customerid,
      locationid: '',
      productcd: '',
      productversion: '',
      staging: '',
      userdefinedtransactiontext1: '',
      userdefinedtransactiontext2: '',
      userdefinedtransactiontext3: '',
      aqrcustomerid: environment.aqrCustomerId,
      aqr: '',
      instancecd: environment.instanceId,
      msgUuid: null,
    };
    let idaPerson: IdaPerson = {
      address: (
        this.firstFormGroup.get('address1Ctrl')?.value +
        ' ' +
        this.firstFormGroup.get('address2Ctrl')?.value
      ).trim(),
      city: this.firstFormGroup.get('cityCtrl')?.value.trim(),
      dob: this.convertValidDateFormat(this.birthdate).split('/').join(''),
      firstName: this.firstFormGroup.get('fnameCtrl')?.value.trim(),
      idvOid: environment.idaQuizPerson.idv0id,
      lastName: this.firstFormGroup.get('lnameCtrl')?.value.trim(),
      middleName: this.firstFormGroup.get('mnameCtrl')?.value.trim(),
      govtNbr: govtnumber,
      state: this.firstFormGroup.get('stateCtrl')?.value,
      zip: this.firstFormGroup.get('postalCodeCtrl')?.value,
    };
    quizPayloadRequest.idaIdentification = idaIdentification;
    quizPayloadRequest.idaPerson = idaPerson;
    return quizPayloadRequest;
  }

  private getQuizDecisionRequestPayload(
    quizId: string,
    transId: string,
    idaAnswerDetail: IdaAnswerDetail
  ): any {
    let idaDecisionRequest: IdaDecisionRequest = new IdaDecisionRequest();
    idaDecisionRequest.idaIdentification = {
      acquirerid: environment.acquirerid,
      configkey: environment.configkey,
      customerid: environment.customerid,
      locationid: '',
      productcd: '',
      productversion: '',
      staging: '',
      userdefinedtransactiontext1: '',
      userdefinedtransactiontext2: '',
      userdefinedtransactiontext3: '',
      aqrcustomerid: environment.customerid,
      aqr: '',
      instancecd: environment.instanceId,
      msgUuid: null,
    };
    idaDecisionRequest.ans = {
      answer1QuestionId: idaAnswerDetail.idaQuestion1?.questionId,
      answer1ResponseId: idaAnswerDetail.idaQuestion1?.responseId,
      answer2QuestionId: idaAnswerDetail.idaQuestion2?.questionId,
      answer2ResponseId: idaAnswerDetail.idaQuestion2?.responseId,
      answer3QuestionId: idaAnswerDetail.idaQuestion3?.questionId,
      answer3ResponseId: idaAnswerDetail.idaQuestion3?.responseId,
      answer4QuestionId: idaAnswerDetail.idaQuestion4?.questionId,
      answer4ResponseId: idaAnswerDetail.idaQuestion4?.responseId,
      answer5QuestionId: idaAnswerDetail.idaQuestion5?.questionId,
      answer5ResponseId: idaAnswerDetail.idaQuestion5?.responseId,
      answer6QuestionId: idaAnswerDetail.idaQuestion6?.questionId,
      answer6ResponseId: idaAnswerDetail.idaQuestion6?.responseId,
      answer7QuestionId: idaAnswerDetail.idaQuestion7?.questionId,
      answer7ResponseId: idaAnswerDetail.idaQuestion7?.responseId,
      answer8QuestionId: idaAnswerDetail.idaQuestion8?.questionId,
      answer8ResponseId: idaAnswerDetail.idaQuestion8?.responseId,
      answer9QuestionId: idaAnswerDetail.idaQuestion9?.questionId,
      answer9ResponseId: idaAnswerDetail.idaQuestion9?.responseId,
      quizId: quizId,
      transId: transId,
      userDefinedTransactionText1: '',
      userDefinedTransactionText2: '',
      userDefinedTransactionText3: '',
      msgUuid: '',
    };
    return idaDecisionRequest;
  }
  showCaptcha() {
    this.document.body.classList.add('recaptcha');
  }

  hideCaptcha() {
    this.document.body.classList.remove('recaptcha');
  }

  async loadSecurityQuiz() {
    this.pageScrollTop();
    let group: any = {};
    let capchaToken: any; 
    try {
      capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
    }  catch (error) {}
    let quizRequest =  this.getQuizRequestPayload();
    let ssn = this.firstFormGroup.controls['ssnCtrl'].value as MyTel;
    let govtNumberMock = (ssn.area + ssn.exchange + ssn.subscriber) as string;
    /** new api  */
    this.idaQuizService
      .FetchIdaQuizQuestions(quizRequest, capchaToken)
      .subscribe((idaQuizQuestion) => {
        if (
          idaQuizQuestion.errorCd != undefined &&
          idaQuizQuestion.errorCd != null
        ) {
          const warningPopup: { [key: string]: string } =
            Registrationresource.idaVelocityWarningDetail;
          const errorCode = ['4025', '4035', '4039'];
          let errorMessage: string = '';
          if (errorCode.some((x) => x == idaQuizQuestion.errorCd?.trim())) {
            errorMessage = warningPopup.idaQuestionsNotGenerate;
          } else if (idaQuizQuestion.errorCd.trim() == '4019') {
            errorMessage = warningPopup.vel_message;
          } else {
            errorMessage = warningPopup.unexpectedError;
          }
          this.idaQuizErrorPopup(errorMessage, warningPopup.title);
        } else {
          this.idaQuizSection.quizId = idaQuizQuestion.quizId;
          this.idaQuizSection.quizRemain = idaQuizQuestion.quizRemain;
          this.idaQuizSection.quizTime = idaQuizQuestion.quizTime;
          this.idaQuizSection.transId = idaQuizQuestion.transId;
          this.idaQuizSection.userReferenceText1 =
            idaQuizQuestion.userReferenceText1;
          this.idaQuizSection.userReferenceText2 =
            idaQuizQuestion.userReferenceText2;
          this.idaQuizSection.userReferenceText3 =
            idaQuizQuestion.userReferenceText3;

          let idaQuizDetail: Question[] = new Array();
          if (idaQuizQuestion != null && idaQuizQuestion != undefined) {
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion1);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion2);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion3);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion4);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion5);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion6);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion7);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion8);
            idaQuizDetail.push(<Question>idaQuizQuestion.idaQuestion9);
          }
          let idaQuestions: IdaQuestion[] = [];
          idaQuizDetail.forEach((quest) => {
            if (quest != null && quest != undefined && quest.id != null) {
              let question: IdaQuestion = new IdaQuestion();
              question.id = quest.id;
              question.questionText = quest.questionText;
              question.options = [];
              question.tagName = quest.questionText;
              if (quest.answer1Id !== null) {
                question.options.push({
                  value: quest.answer1Id,
                  text: quest.answer1Text,
                });
              }
              if (quest.answer2Id !== null) {
                question.options.push({
                  value: quest.answer2Id,
                  text: quest.answer2Text,
                });
              }
              if (quest.answer3Id !== null) {
                question.options.push({
                  value: quest.answer3Id,
                  text: quest.answer3Text,
                });
              }
              if (quest.answer4Id !== null) {
                question.options.push({
                  value: quest.answer4Id,
                  text: quest.answer4Text,
                });
              }
              if (quest.answer5Id !== null) {
                question.options.push({
                  value: quest.answer5Id,
                  text: quest.answer5Text,
                });
              }
              if (quest.answer6Id !== null) {
                question.options.push({
                  value: quest.answer6Id,
                  text: quest.answer6Text,
                });
              }
              idaQuestions.push(question);
            }
          });
          this.idaQuizSection.idaQuestion = idaQuestions;
          this.idaQuizSection.idaQuestion.forEach((question) => {
            group[question.id] = new FormControl('', [Validators.required]);
          });
          this.secondFormGroup = this._formBuilder.group(group);
          let timerInfo = new Timerdetail();
          timerInfo.title = Registrationresource.ida_quiz_subtitle;
          timerInfo.totalTime = Registrationresource.ida_quiz_timer_seconds;
          this.timersInfo.push(timerInfo);
          this.pageScrollTop();
        }
      });
    /*** new api end */
  }



  private idaQuizErrorPopup(message: string, title: string) {
    const warningPopup: { [key: string]: string } =
      Registrationresource.idaVelocityWarningDetail;
    const dialogMessage = new DialogMessage(
      message,
      warningPopup.buttonText,
      false,
      title
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) == '' || (result as string) == 'OK') {
        window.location.reload();
      }
    });
  }

  private convertDateTime(dateValue: Date): string {
    return (
      this.convertValidDateFormat(dateValue) +
      '' +
      this.padLeadingZeros(dateValue.getHours(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getMinutes(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getSeconds(), 2)
    );
  }

  private convertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

  featureDateFilter = (d: Date | null): boolean => {
    if (!d) {
      return true;
    }
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return (
      d.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) &&
      d >= new Date(1899, 12)
    );
  };

  private userCreationFailure(message: string, title: string): void {
    // Error message
    const dialogMessage = new DialogMessage(
      message,
      'Ok',
      false,
      title
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      //chexsystems public home page
      if ((result as string) == 'OK') {
        window.location.reload();
      }
    });
  }

  /**
   * Move the current page scrolltop.
   */
  private pageScrollTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private padLeadingZeros(num: number, size: number) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  featureDatesFilter = (m: _moment.Moment | null): boolean => {
    const year = (m || moment()).toDate();
    const startDate = moment([1899, 11, 31]).toDate();
    return year <= this.today && year > startDate;
  };

  handleCurrentMasking(code: string) {
    if (code === 'password') {
      if (this.currentPasswordEyeIconType === FieldMaskingShowHide.Eye) {
        this.currentPasswordEyeIconType = FieldMaskingShowHide.Normal;
        this.currentPasswordEyeIconTooltip = FieldMaskingShowHide.Hide;
        this.currentPasswordType = PasswordType.Text;
      } else if (
        this.currentPasswordEyeIconType === FieldMaskingShowHide.Normal
      ) {
        this.currentPasswordEyeIconType = FieldMaskingShowHide.Eye;
        this.currentPasswordEyeIconTooltip = FieldMaskingShowHide.Show;
        this.currentPasswordType = PasswordType.Password;
      }
    }
  }

  handleSSNMasking() {
    if (this.eyeIconTypeVerifySSN === 'eye') {
      this.eyeIconTypeVerifySSN = 'eye-hide';
      this.eyeIconTooltipVerifySSN = 'hide';
      this.ssncontrol.handleMasking('text');
      this.verifyssncontrol.handleMasking('text');
    } else if (this.eyeIconTypeVerifySSN === 'eye-hide') {
      this.eyeIconTypeVerifySSN = 'eye';
      this.eyeIconTooltipVerifySSN = 'show';
      this.ssncontrol.handleMasking('password');
      this.verifyssncontrol.handleMasking('password');
    }
  }

  // generateCaptchaToken():string
  // { 
  //     let token : string;    
  //     token = "Unable to generate captcha"
  //     try {        
  //       this.recaptchaV3Service.execute('submit').subscribe((val : string) => {
  //         token= val;
  //       });        
  //     } catch (error) { }
  //     return token;
  // }
}
