import { FormGroup, Validators } from "@angular/forms";
import { Registrationresource } from "../../_models/registration/registrationresource.model";
import { StateZipCode } from "../../_models/registration/statezipcode";

export class FormValidation {

    /**
     * 
     * @param addressLine1ControlName 
     * @param cityControlName 
     * @param stateControlName 
     * @param zipCodeControlName 
     * @returns 
     */
    static AddressValidation(addressLine1ControlName: string,
        addressLine2ControlName: string,
        cityControlName: string,
        stateControlName: string,
        zipCodeControlName: string) {
        return (formGroup: FormGroup) => {
            const addressLine1Control = formGroup.controls[addressLine1ControlName];
            const addressLine2Control = formGroup.controls[addressLine2ControlName];
            const cityControl = formGroup.controls[cityControlName];
            const stateControl = formGroup.controls[stateControlName];
            const zipControl = formGroup.controls[zipCodeControlName];
            const validateFormControls = [
                addressLine1Control,
                //addressLine2Control,
                cityControl,
                stateControl,
                zipControl
            ];
            const validateFormControlValues = [
                addressLine1Control.value,
                //addressLine2Control.value,
                cityControl.value,
                stateControl.value,
                zipControl.value
            ]
            if (validateFormControlValues.every(x => x === '') || validateFormControlValues.every(x => x !== '')) {
                validateFormControls.forEach(x => {
                    if (x.value === '')
                        x.setErrors(null);
                })
                return null;
            }
            else {
                validateFormControls.forEach(x => {
                    if (x.value === '')
                        x.setErrors(Validators.required);
                });
            };
            return null;
        };
    }

    static CustomNameValidation(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID")
                return null;

            // check the status
            const controlValues = [
                control.value.split(' ').length - 1,  // Space count
                control.value.split('-').length - 1,  // Hyphen count`
            ];
            if (controlValues.some(x => x > 1))
                control.setErrors(Validators.pattern);
            else
                control.setErrors(null);

            return null;
        };
    }
    static CustomNameSpecialCharacterValidation(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID")
                return null;

            // check the status
            const controlValues = [
                control.value.split(' ').length - 1,  // Space count
                control.value.split('-').length - 1,  // Hyphen count`
                control.value.split('.').length - 1,  // Space count
                control.value.split("'").length - 1,  // Space count
            ];
            if (controlValues.some(x => x > 1))
                control.setErrors(Validators.pattern);
            else
                control.setErrors(null);

            return null;
        };
    }

    static EmailValidation(controlName: string){
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID") {
                return null;
            }
            const validateFormControlValues:string = control.value;
            let length = validateFormControlValues.length;
            let ampersandIndex = validateFormControlValues.indexOf('@');
            if((ampersandIndex === -1) || (ampersandIndex === 0) || (validateFormControlValues.indexOf('@')=== length-1))
            {
                control.setErrors(Validators.pattern);
            }
            let periodIndex = validateFormControlValues.indexOf('.');
            if((periodIndex === -1) || (periodIndex === 0) || (validateFormControlValues.indexOf('.')=== length-1))
            {
                control.setErrors(Validators.pattern);
            }
            if((validateFormControlValues.indexOf('@.') !== -1) || (validateFormControlValues.indexOf('.@') !== -1))
            {
                control.setErrors(Validators.pattern);
            }
            return null;
        };
    }

    static EmailCheckValidation(controlName: string){
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID") {
                return null;
            }
            const validateFormControlValues:string = control.value;
            let isEmail = this.validateEmail(validateFormControlValues);
            if (isEmail) {
               control.setErrors(Validators.email);
            }else
            { 
                control.setErrors(null);            
            }
            return null;
        };
    }

    static CommentTextValidation(controlName: string){
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID") {
                return null;
            }
            const validateFormControlValues:string = control.value;
            let isComment = this.validatCommentText(validateFormControlValues);
            if (!isComment) {
               control.setErrors({ invalidSplChar: true });
            }else
            { 
                control.setErrors(null);            
            }
            return null;
        };
    }

    static StateZipCodeValidation(stateControlName: string, zipCodeControlName: string, statZipCodes:StateZipCode[]){
        return (formGroup: FormGroup) => {
            const stateControl = formGroup.controls[stateControlName];
            const zipCodeControl = formGroup.controls[zipCodeControlName];
            if (zipCodeControl.errors && ( zipCodeControl.errors.minlength || zipCodeControl.errors.required) ) {
                return null;
            }
            const stateControlValue:string = stateControl.value;
            const zipCodeControlValue:number  = zipCodeControl.value;
            const mappedStateZipCodes = statZipCodes.filter(task => task.state ==  stateControlValue);
            if(zipCodeControl.value.length >0  && mappedStateZipCodes != null && mappedStateZipCodes != undefined && mappedStateZipCodes.length > 0)
            {
                let startRange : number = parseInt(mappedStateZipCodes[0].startRange);
                let endRange : number = parseInt(mappedStateZipCodes[0].endRange);
                if( startRange <= zipCodeControlValue && zipCodeControlValue <= endRange)
                    zipCodeControl.setErrors(null);  
                else
                    zipCodeControl.setErrors({ isInValidRange: true });
            }            
            return null;
        };
    }
    private static validateEmail(email:string) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

      private static validatCommentText(commentText: string){
          const ct= /^[ \w~`!@#$%^&*()\-+={}\[\]/\\|:;'",.?\n]*$/;
          return ct.test(commentText);
      }

      static SpecialCharacterValidationForSecurityAlert(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID")
                return null;

            // check the status
            const controlValues = [
                control.value.split('^').length,  // ^ count
                control.value.split(';').length,  // ; count`
                control.value.split('[').length,  // [ count
                control.value.split("]").length,  // ] count
                control.value.split(",").length,  // , count
            ];
            if (controlValues.some(x => x > 1))
                control.setErrors({ invalidChar: true });
            else
                control.setErrors(null);

            return null;
        };
    }

    static InvalidNameValidation(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            if (control.status === "INVALID")
                return null;
            // check the status
            const validateFormControlValues:string = control.value;
            if (Registrationresource.InvalidNames.some(x => x.toLowerCase() == validateFormControlValues.toLowerCase()))
                control.setErrors(Validators.pattern);
            else
                control.setErrors(null);
            
            return null;
        };
    }
}