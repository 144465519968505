import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { FormBuilder, FormGroup, Validators, FormControl, NgModel } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ValidateSecurityAlertResponseData } from '../../_models/classes/validatesecurityalert-reponse.model';
import { ValidateSecurityAlertRequestData } from '../../_models/classes/validatesecurityalert-request.model';
import { SecurityValidationService } from '../../_services/registration/security-validations.service';
import { GlobalVariables } from '../../common/enums/global_variables';
import { ReCaptchaV3Service } from 'ng-recaptcha';

export class DialogsecurityalertquestionData {
  constructor(public govtnumber: string, public questioncode: string, public questiontext: string, public isReg: boolean, public userUpdateObj: any) {

  }
}

@Component({
  selector: 'chexsystem-ws-dialogsecurityalertquestion',
  templateUrl: './dialog-security-alert-question.component.html',
  styleUrls: ['./dialog-security-alert-question.component.scss']
})
export class DialogsecurityalertquestionComponent implements OnInit {

  formGroup: FormGroup;

  govtnumber: string = '';
  questiontext: string;
  questioncode: string;
  answertext: string;
  isReg: boolean;
  attemptsAllowed: number = environment.securityAlertQuestionAttempts;
  attemptsRemaining: number = this.attemptsAllowed;
  userName:string = '';
  userUpdateObj: any;
  validatesecurityalert: ValidateSecurityAlertResponseData;

  securityAlertQuesOutput: any;
  constructor(private _formBuilder: FormBuilder, private securityValidationService: SecurityValidationService, public dialogRef: MatDialogRef<DialogsecurityalertquestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogsecurityalertquestionData, private recaptchaV3Service: ReCaptchaV3Service,) {

    this.govtnumber = data.govtnumber;
    this.questiontext = data.questiontext;
    this.questioncode = data.questioncode;
    this.isReg = data.isReg;
    this.userUpdateObj = data.userUpdateObj !== null ? data.userUpdateObj: {};

    this.formGroup = this._formBuilder.group({
      txtsecurityalertanswer: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.userName = GlobalVariables.globalvars.get('UserId') || '';
  }

  async onSubmit()  {

    if (this.formGroup.valid) {
      let capchaToken; 
      if(this.isReg) {
      try {
        capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
      }  catch (error) {}
    }
      let validateSecurityAlertRequestData: ValidateSecurityAlertRequestData = <ValidateSecurityAlertRequestData>{
        validateSecurityAlertRequest: {
          govtNbr: this.govtnumber,//this.govtnumber,//"123456789",
          userName: this.userName,
          recapchaToken: this.isReg ? capchaToken: '',
          securityQuesandAns: {
            questionCode: this.questioncode,
            ansText: this.answertext
            
          },
          isReg: this.isReg
        }
      }
      this.userUpdateObj.validateSecurityAlertObj = validateSecurityAlertRequestData;
      this.securityValidationService.ValidateSecurityAlert(this.userUpdateObj).subscribe(res => {
        this.validatesecurityalert = res;
        if (this.validatesecurityalert?.answerFlag?.toString() == 'Y') {
          //if('Y'=='Y'){
          this.securityAlertQuesOutput = { issecurityanswercorrect: true,
            dsUpdateUserResp: this.validatesecurityalert
           };
          this.dialogRef.close(this.securityAlertQuesOutput);
        }
        else {
          this.answertext='';
          this.attemptsRemaining--;
          if (this.attemptsRemaining == 0) {
            this.securityAlertQuesOutput = { issecurityanswercorrect: false};
            this.dialogRef.close(this.securityAlertQuesOutput);
          }
        }
      },error => { }, 
      ()=>{ });
    }
  }

  onCancel(): void {
    this.securityAlertQuesOutput = { cancel: true}
    this.dialogRef.close(this.securityAlertQuesOutput);
  }

}

// export class DialogsecurityalertquestionData {
//   constructor(public govtnumber: string, public questioncode: string, public questiontext: string) {

//   }
// }