import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

export class DialogIdleTimerMessage {
  constructor(public messageText: string, public continueButtonText: string, public cancelButtonText: string, public title: string = '') {

  }
}

@Component({
  selector: 'chexsystem-ws-dialog-idle-timer-after-login',
  templateUrl: './dialog-idle-timer-after-login.component.html',
  styleUrls: ['./dialog-idle-timer-after-login.component.scss']
})
export class DialogIdleTimerAfterLoginComponent implements OnInit {
  timerId: ReturnType<typeof setTimeout> | any;
  startBlinking: ReturnType<typeof setTimeout> | any;
  totalTimeInSeconds: number = 0;
  minutesLeft: string;
  secondsLeft: string;
  message1:string = '';
  message2:string = '';
  prevTitle: string = '';

  title: string;
  message: string;
  continueButtonText: string;
  cancelButtonText: string;

  constructor(public dialogRef: MatDialogRef<DialogIdleTimerAfterLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogIdleTimerMessage,
    public browserTitle: Title,
    private translate: TranslateService) {
    this.message = data.messageText;
    this.title = data.title;
    this.continueButtonText = data.continueButtonText;
    this.cancelButtonText = data.cancelButtonText;
  }

  clearInterval() {   
    if(this.timerId){
      clearInterval(this.timerId);
    }      
    this.timerId = undefined;
  }

  startTimer() {
    this.totalTimeInSeconds = environment.loggedinSessionTimeoutSurplus;
    this.clearInterval();
    this.timerId = setInterval( () => {
      if(this.totalTimeInSeconds > 0) {
        this.totalTimeInSeconds--;
        let minutesLeft = Math.floor(this.totalTimeInSeconds / 60);
        let secondsLeft = (this.totalTimeInSeconds - minutesLeft * 60);
        this.minutesLeft = minutesLeft.toString().length == 1 ? "0"+minutesLeft : minutesLeft.toString();
        this.secondsLeft = secondsLeft.toString().length == 1 ? "0"+secondsLeft : secondsLeft.toString();
      } else {
        this.clearInterval();   
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.message1 = this.translate.instant('IDLETIMER.MESSAGE1');
    this.message2 = this.translate.instant('IDLETIMER.MESSAGE2');
    this.startTimer();    

    const stopBlinking = () => {
      this.browserTitle.setTitle(prevTitle);
      clearInterval(this.startBlinking);
    };

    const prevTitle = this.browserTitle.getTitle();
    let message = 'Session Timeout Alert';

    this.startBlinking = setInterval( () => {
    const newTitle = this.browserTitle.getTitle() === message ? prevTitle : message;
    this.browserTitle.setTitle(newTitle);
    }, 1000); 

    window.addEventListener("focus", function(event) { 
        stopBlinking();
  });
  }

  onContinue(): void {
    this.dialogRef.close('resume');
  }

  onReset(): void {
    this.dialogRef.close('logout');
  }

}
