export namespace addfreeze{


  export class AddFreezeRoot {
    AddFreeze: AddFreeze
  }
  
  export class AddFreeze {
    CellPhoneNbr: string
    CnsmrAddrDetls: CnsmrAddrDetls
    CnsmrPrsnlDetls: CnsmrPrsnlDetls
    CorrespondenceDeliveryPreference: string
    NotificationEmailAddr: string
    RequestSrcCode: string
    UserName: string
  }
  
  export class CnsmrAddrDetls {
    CnsmrAddr: CnsmrAddr
  }
  
  export class CnsmrAddr {
    AddrLine1: string
    AddrLine2: string
    CityNm: string
    StatCode: string
    ZipCode: string
  }
  
  export class CnsmrPrsnlDetls {
    BirthDt: string
    CnsmrDrivLicenseDetls: CnsmrDrivLicenseDetls
    CnsmrNmDetls: CnsmrNmDetls
    ConsumerId: string
    EmailAddress: string
    GovtNbr: string
    PhoneNbr: string
  }
  
  export class CnsmrDrivLicenseDetls {
    DrivLicenseIssuSt: string
    DrivLicenseNbr: string
  }
  
  export class CnsmrNmDetls {
    FrstNm: string
    LastNm: string
    MidNm: string
    PreviousLastName: string
    SuffixNm: string
  }
  
  export class ResponseRoot {
    message: string
    referenceId: string
    code: string
  }
      
}
//////////////////////////////////
