import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';
import {
  MyTel,
  PhonecontrolComponent,
} from '../common/controls/phone-control/phone-control.component';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { ManageFreezeService } from '../_services/manage-freeze/manage-freeze.service';
import {
  UserDetail,
  UserLookupRequest,
} from '../_models/registration/UserDetail';
import { IdaQuizService } from '../_services/registration/ida-quiz.service';
import { managefreeze } from '../_models/classes/manage-freeze.model';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../common/enums/global_variables';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'chx-manage-security-freeze',
  templateUrl: './manage-security-freeze.component.html',
  styleUrls: ['./manage-security-freeze.component.scss'],
})
export class ManageSecurityFreezeComponent {
  formGroup: FormGroup;
  eyeIconTypeSSN: string = 'eye';
  eyeIconTooltipSSN: string = 'show';
  ssnNo: string;
  ssnNoMask: string;
  ssnNoDisplay: string;
  manageSecurityFreezeContent: string = '';
  title=this._translate.instant('MANAGESECURITYFREEZE.TITLE');
  personaldetails=this._translate.instant('MANAGESECURITYFREEZE.PERSONALDETAILS');
  ussocialsecurity=this._translate.instant('MANAGESECURITYFREEZE.USSOCIALSECURITY');
  pin=this._translate.instant('MANAGESECURITYFREEZE.PIN');
  buttons=this._translate.instant('BUTTONS.SUBMIT');
  userName = "";
  public isLoader: boolean = false;
  @ViewChild('ssnCtrl1') ssncontrol: PhonecontrolComponent;

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private piConfirmService: PersonalInformationConfirmationService,
    private idaQuizService: IdaQuizService,
    private manageFreezeService: ManageFreezeService,
    private dialog: MatDialog,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {

    this.formGroup = this._formBuilder.group({
      ssnCtrl: ['', Validators.required],
      txtfreezepin: ['', Validators.required],
    });

    //this.initPersonalInfo();
  }

  ngOnInit(): void {
    this.initPersonalInfo();
    this.fetchManageSecurityFreezeContent();
  }

  fetchManageSecurityFreezeContent() {
    let manageSecurityFreezeContentPath: string = 'Manage Security Freeze/Data/Content/Data';
    this.isLoader = true;
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: manageSecurityFreezeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());
        if (child.name.toLowerCase() == 'manage security freeze') {
          const divOptOut = document.createElement('div');
          console.log(fieldObject.value.toString());
          divOptOut.innerHTML = fieldObject.value.toString();
          var anchorelements = divOptOut.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            console.log(anchorelement.href);
            console.log(anchorelement.onclick);
            // if (anchorelement.href != '') {
            //   anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
            // }
            if (anchorelement.onclick != null) {
              anchorelement.onclick = anchorelement.onclick;
            }
          });

          this.manageSecurityFreezeContent = divOptOut.innerHTML;
        }
      });
      this.isLoader = false;

    },
      error => {
        this.isLoader = false;
      },
      () => {
        this.isLoader = false;
      }
    );
  }

  private ConvertUSSSNFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{2})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatedPhoneNumber[0],
      formatedPhoneNumber[1],
      formatedPhoneNumber[2]
    );
  }

  async submitclick() {
    if (this.formGroup.valid) {
      // let capchaToken: any; 
      // try {
      //   capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
      // }  catch (error) {}
      let pin = this.formGroup.controls['txtfreezepin'].value;
      let lkfRequest: managefreeze.LookupFreezeRequest =
        new managefreeze.LookupFreezeRequest();
      lkfRequest.GovtNbr = this.ssnNo;
      lkfRequest.PinNbr = pin;
      lkfRequest.UserName = this.userName;
      lkfRequest.RecapchaToken = '';
      // lkfRequest.GovtNbr = "491713274";//Harsh
      // lkfRequest.PinNbr = "665902278747";
      // pin = "665902278747";
      // this.ssnNo = "491713274";//Harsh
      this.manageFreezeService.LookupFreeze(lkfRequest).subscribe(
        (res) => {
          let strMessage = 'Empty!';
          if (res.LookupFreeze.code !== '200') {
            // const strMessage = res.LookupFreeze.message;
            strMessage = this._translate.instant(
              'POPUPS.MANAGESECURITYFREEZETEXT'
            );
            const dialogMessage = new DialogMessage(
              strMessage,
              this._translate.instant('BUTTONS.GOTODASHBOARD'),
              false,
              this._translate.instant('POPUPS.MANAGESECURITYFREEZETITLE')
            );
            const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
              panelClass: ['fis-style', 'custom-dialog'],
              data: dialogMessage,
              disableClose: true,
            });
            dialogRef2.afterClosed().subscribe((result) => {
              if ((result as string) == 'OK') {
                this.router.navigate(['dashboard']);
                //window.location.reload();
              }
            });
          } else {
            // localStorage.setItem('LookupFreezeResp', JSON.stringify(res));
            // localStorage.setItem('PIN', pin);
            // localStorage.setItem('SSN', this.ssnNo);

            if (GlobalVariables.globalvars.has('LookupFreezeResp')){
              GlobalVariables.globalvars.delete('LookupFreezeResp');                
            }
            GlobalVariables.globalvars.set('LookupFreezeResp', JSON.stringify(res)); 

            if (GlobalVariables.globalvars.has('PIN')){
              GlobalVariables.globalvars.delete('PIN');                
            }
            GlobalVariables.globalvars.set('PIN', pin); 

            if (GlobalVariables.globalvars.has('SSN')){
              GlobalVariables.globalvars.delete('SSN');                
            }
            GlobalVariables.globalvars.set('SSN', this.ssnNo); 

            if (GlobalVariables.globalvars.has('UserId')){
              GlobalVariables.globalvars.delete('UserId');                
            }
            GlobalVariables.globalvars.set('UserId', this.userName);
    
            //alert(localStorage.getItem('PIN'));
            this.router.navigate(['manage-frz-dtl']);
          }
        },
        (error) => { },
        () => { }
      ); 
    }
  }
  resetClick() {
    this.formGroup.controls['txtfreezepin'].reset();
  }

  _handleMasking(code: string, control4: any) {
    if (code === 'ssn') {
      if (this.eyeIconTypeSSN === 'eye') {
        this.eyeIconTypeSSN = 'eye-hide';
        this.eyeIconTooltipSSN = 'hide';
        this.ssncontrol.handleMasking('text');
      } else if (this.eyeIconTypeSSN === 'eye-hide') {
        this.eyeIconTypeSSN = 'eye';
        this.eyeIconTooltipSSN = 'show';
        this.ssncontrol.handleMasking('password');
      }
    }
  }

  initPersonalInfo() {
    // get user detail by username
    this.piConfirmService.checkPersonalInformationConfirmation(
      'ManageSecurityFreeze'
    );
    this.userName = GlobalVariables.globalvars.get('UserId') || '';

    let lookupRequest: UserLookupRequest = new UserLookupRequest();
    lookupRequest.UserLookupReq = { UserName: this.userName, Cid: '' };
    this.idaQuizService
      .getUserDetailFromDecisionSolution(lookupRequest)
      .subscribe(
        (res) => {
          let usSSNumber = this.ConvertUSSSNFormate(
            res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr
          );
          this.formGroup.get('ssnCtrl')?.setValue(usSSNumber);
          this.ssnNo = (usSSNumber.area +
            usSSNumber.exchange +
            usSSNumber.subscriber) as string;
        },
        (error) => { },
        () => { }
      );
  }

  allowOnlyNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  goToReplacePin() {
    this.router.navigate(['replace-sec-pin']);
  }
}
