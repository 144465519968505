import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldName } from '../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SiteCoreNodePathResponseData } from '../../_models/classes/sitecore-nodepath-reponse.model';
import { Field, SitecoreRequestData } from '../../_models/classes/sitecore-reponse.model';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import { SitecoreService } from '../../_services/sitecore/sitecore.service';

@Component({
  selector: 'chexsystem-ws-security-freeze-info',
  templateUrl: './security-freeze-info.component.html',
  styleUrls: ['./security-freeze-info.component.scss']
})
export class SecurityFreezeInfoComponent implements OnInit {
  isLoader: boolean = false;
  securityFreezeInfoContent: string = '';
  securityFreezeMailInfoContent: string = '';
  securityFreezeContactInfoContent: string = '';
  hawaiiAndNewHampshireContent: string = '';
  title=this._translate.instant('SECURITYFREEZEINFO.TITLE');

  constructor(private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('SecurityFreezeInformation');
    this.fetchSecurityFreezeInfoContent();
  }

  fetchSecurityFreezeInfoContent() {
    let manageSecurityFreezeContentPath: string = 'Security Freeze Info/Data/Content/Data';
    this.isLoader = true;
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: manageSecurityFreezeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'security freeze info') {
          this.securityFreezeInfoContent = fieldObject.value;
        }
        else if (child.name.toLowerCase() == 'contact info') {
          this.securityFreezeContactInfoContent = fieldObject.value;
        }
        else if (child.name.toLowerCase() == 'mail info') {
          this.securityFreezeMailInfoContent = fieldObject.value;
        }
        else if (child.name.toLowerCase() == 'hawaii and new hampshire info') {
          this.hawaiiAndNewHampshireContent = fieldObject.value;
        }
        
      });
      this.isLoader = false;

    }, 
    error => {
      this.isLoader = false;
    }, 
    ()=>{
      this.isLoader = false;
    }
    );
  }

}
