import { Component, ViewChild, TemplateRef, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Field, SitecoreRequestData, SiteCoreResponseData } from '../../../_models/classes/sitecore-reponse.model';
import { DashBoardCarouselResponseData, FieldName } from '../../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { DashBoardCarousel } from '../../../_models/classes/dashboard-carousel.model';
import { PersonalInformationConfirmationService } from '../../../_services/shared/pi-confirmation.service';
import { SitecoreService } from '../../../_services/sitecore/sitecore.service';
import { environment } from '../../../../environments/environment';
import { LocationStrategy } from '@angular/common';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../snackbar/snackbar/snackbar.component';
import { DashboardService } from '../../../_services/dashboard-services/dashboard.service';
import { GlobalVariables } from '../../enums/global_variables';

@Component({
  selector: 'chx-dashboard-landing',
  templateUrl: './dashboard-landing.component.html',
  styleUrls: ['./dashboard-landing.component.scss'],
})


export class DashboardLandingComponent implements OnInit {
  carouselItems: DashBoardCarousel.item[] = [];
  position: string = 'top-center';
  panelClass = ['fis-style', 'ruf-info', 'ruf-inkbar-bottom'];
  disputesText: string = '';
  correspondenceText: string = '';
  carouselImgPath = [
      {
          "carouselimagepath": "assets/image/carousel_image_1.png",
          "carouseltext": "<h1>Chex Systems, Inc. (ChexSystems&reg;) is a nationwide specialty consumer reporting agency under the Fair Credit Reporting Act (FCRA).</h1>",
  },
      {
          "carouselimagepath": "assets/image/carousel_image_2.jpg",
          "carouseltext": "<h1 class=\"content-style\">Introducing OnAlert® from ChexSystems®, an all-in-one solution for your financial wellness and identity theft protection needs.</h1>\n<a href=\"https://www.chexsystems.com/onalert\" class=\"btn-primary-blue-on-dark\">Learn More</a>",
      }
  ];
  constructor(private router: Router, private sitecoreService: SitecoreService, private piConfirmService: PersonalInformationConfirmationService,
    private locationStrategy: LocationStrategy,
    private snackBar: MatSnackBar,
    private dashboardService: DashboardService) {
      dashboardService.loderDisputeEvent$.subscribe(item => this.eventHandler1(item));
      dashboardService.loderCorrespondanceEvent$.subscribe(item => this.eventHandler2(item));
    }
    eventHandler1(val:boolean){
    }
    eventHandler2(val:boolean){
    }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('dashboard');
    this.fetchCarouselContent();
  }

  fetchCarouselContent() {
    let carouselContentPath: string = 'Secure Portal Home Page/Data/Content';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: carouselContentPath };
    this.sitecoreService.FetchDashBoardCarouselContent(siteCoreRequestData).subscribe((res) => {
      let result = res as DashBoardCarouselResponseData;
      this.carouselItems = [];
      result.data.item.children.forEach((child) => {
        let item: DashBoardCarousel.item = new DashBoardCarousel.item();
        item.carouselimagepath = '';
        item.carouseltext = '';
        
        child.children?.forEach((subChild) => {
          let fieldObject = <Field>subChild.fields?.find(field => field.name == FieldName.Text.toString());
          if (subChild.name.toLowerCase() == 'carousel image') {
            let imagePath: string = '';
            const divcarousel = document.createElement('div');
            divcarousel.innerHTML = fieldObject.value;
            var imagelements = divcarousel.querySelectorAll("img");
            imagelements.forEach(imagelement => {
              imagePath = imagelement.src.replace(imagelement.baseURI, environment.sitecorebaseurl);
            });
            item.carouselimagepath = imagePath;
          }
          else if (subChild.name.toLowerCase() == 'carousel text') {
            item.carouseltext = fieldObject.value;
          }
          else if (subChild.name.toLowerCase() == 'disputes text') {
            this.disputesText = fieldObject.value;
          }
          else if (subChild.name.toLowerCase() == 'correspondence text') {
            this.correspondenceText = fieldObject.value;
          }

        });

        if (item.carouselimagepath?.trim() != '' && item.carouseltext?.trim() != '') {
          this.carouselItems.push(item);
        }
        
      });

      if(GlobalVariables.globalvars.get("NotificationOpened") != 'Y'){
        this.openSnackBar();
      }
    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }

  createSnackBarConfig() {
    const sposition = this.position.split('-');
    const config = new MatSnackBarConfig();
    config.duration = environment.snackbarautohideduration;
    config.panelClass = this.panelClass;
    config.verticalPosition = <MatSnackBarVerticalPosition>sposition[0];
    config.horizontalPosition = <MatSnackBarHorizontalPosition>sposition[1];
    return config;
  }

  openSnackBar() {
    const config = this.createSnackBarConfig();
    let itemPath = 'Notifications/Data/Content/Data/Notifications';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: itemPath };
    this.sitecoreService.FetchSitecoreContentByItemPath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreResponseData;
      let fieldObject = <Field>result.data.item.fields.find(field => field.name == FieldName.Text.toString());
      config.data = fieldObject?.value.toString();
      //localStorage.setItem("NotificationOpened", 'Y');
      if(config.data.trim()!=''){//Snackbar hidden in case of no content
        if (GlobalVariables.globalvars.has('NotificationOpened')){
          GlobalVariables.globalvars.delete('NotificationOpened');                
        }
        GlobalVariables.globalvars.set('NotificationOpened', 'Y'); 

        this.snackBar.openFromComponent(SnackbarComponent, config);
      }
    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }
}