import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogMessage, DialogwithmessageComponent } from '../dailog/dialog-with-message/dialog-with-message.component';
import { AutomationRulesCheck, CnsmrAddr } from '../_models/classes/automation-rules-check-request.model';
import { MailDisclosureReportRequest } from '../_models/classes/mail-disclosure-report-request';
import { userinformation } from '../_models/classes/user-information.model';
import { DisclosureRequestheader } from '../_models/consumer-disclosure/consumer-disclosure.model';
import { UserdisclosureService } from '../_services/disclosure/user-disclosure.service';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { GlobalVariables } from '../common/enums/global_variables';

@Component({
  selector: 'chex-consumer-disclosure-transition',
  templateUrl: './consumer-disclosure-transition.component.html',
  styleUrls: ['./consumer-disclosure-transition.component.scss'],
})


export class ConsumerDisclosureTransitionComponent {

  declineOptions = ['Yes', 'No'];
  isDeclined = false;
  consumerDisclosureContent: string = '';
  title=this._translate.instant('CONSUMERDISCLOSURETRANSITION.TITLE');
  info4=this._translate.instant('CONSUMERDISCLOSURETRANSITION.INFO4');
  continue=this._translate.instant('BUTTONS.SUBMIT');

  constructor(
    private router: Router,
    private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private userdisclosureService: UserdisclosureService) {
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('consumerdisclosure');
    this.fetchConsumerDisclosureContent();
  }

  generateDisclosure() {

    const userProfileInfo = GlobalVariables.globalvars.get('UserInformation');
    if (userProfileInfo) {
      const userProfile = JSON.parse(userProfileInfo) as userinformation.UserInformationRoot;
      let automationRulesCheckRequest: AutomationRulesCheck =
      {
        ChexAutoDisClsReq: {
          CnsmrAddrDetls: {
            CnsmrAddr: {
              CurrAddrLine1: userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim(),
              CurrAddrLine2: userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim(),
              CurrCityNm: userProfile.CnsmrAddrDetls.CnsmrAddr.CityNm.trim(),
              CurrStatCode: userProfile.CnsmrAddrDetls.CnsmrAddr.StatCode.trim(),
              CurrZipCode: userProfile.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim(),
            }
          },
          CnsmrPrsnlDetls: {
            BirthDt: userProfile.CnsmrPrsnlDetls.BirthDt.trim(),
            CnsmrDrivLicenseDetls: {
              DrivLicenseIssuSt: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim(),
              DrivLicenseNbr: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim()
            },
            CnsmrNmDetls: {
              FrstNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
              LastNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
              MidNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim(),
              SuffixNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim()
            },
            Email: userProfile.NotificationEmailAddr.trim(),
            GovtNbr: userProfile.CnsmrPrsnlDetls.GovtNbr.trim(),
            PhoneNbr: userProfile.CnsmrPrsnlDetls.PhoneNbr.trim()

          },
          RqstHdr: {
            RqstSubTyp: DisclosureRequestheader.AutomationRulesCheckSubType,
            RqstTyp: DisclosureRequestheader.AutomationRulesCheckType
          },
          CellPhoneNbr: userProfile.CellPhoneNbr,
          CorrespondenceDeliveryPreference : userProfile.CorrespondenceDeliveryPreference,
          NotificationEmailAddr: userProfile.NotificationEmailAddr,
          UserName: userProfile.UserName,
          RequestSrcCode : DisclosureRequestheader.RequestSrcCode,
        }
      };

      this.userdisclosureService.checkAutomationrulescheck(automationRulesCheckRequest).subscribe(
        (response) => {
          if (response != undefined && response != null) {
            if (response.AutomationRuleRsp.automtnRuleChkPass.toLowerCase() === "y") {
              // navigate to pdf viewer page
              this.router.navigate(['pdf-view-docs', { src: "disclosure" }]);
            } else {
              this.mailDisclosureReport(userProfile);
            }
          }
        },
        (error) => { },
        () => { });
    }
  }

  declineSelected(event: any) {
    this.isDeclined = true;
  }

  fetchConsumerDisclosureContent() {
    let consumerDisclosureContentPath: string = 'Disclosure Report Info/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: consumerDisclosureContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'disclosure report') {
          this.consumerDisclosureContent = fieldObject.value;
        }
      });

    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }
  
  private mailDisclosureReport(userProfile: userinformation.UserInformationRoot) {
    const ConsumerReferenceId: string = Math.floor(Math.random() * 1000000000).toString();
    let cstDateTimeNow: Date = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
    );
    const cstDateTimeNowValue: string = this.ConvertDateTime(cstDateTimeNow);
    let previousAddress = {
      addrLine1: "",
      addrLine2: "",
      cityNm: "",
      statCode: "",
      zipCode: ""
    }
    let mailDisclosureRequest: MailDisclosureReportRequest = {
      disclosureReq: {
        cnsmrAddrDetls: {
          cnsmrAddr: {
            addrLine1: userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine1,
            addrLine2: userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine2,
            cityNm: userProfile.CnsmrAddrDetls.CnsmrAddr.CityNm,
            statCode: userProfile.CnsmrAddrDetls.CnsmrAddr.StatCode,
            zipCode: userProfile.CnsmrAddrDetls.CnsmrAddr.ZipCode
          }
        },
        cnsmrPrsnlDetls: {
          birthDt: userProfile.CnsmrPrsnlDetls.BirthDt,
          cnsmrDrivLicenseDetls: {
            drivLicenseIssuSt: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim(),
            drivLicenseNbr: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim()
          },
          cnsmrNmDetls: {
            frstNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
            lastNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
            midNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim(),
            previousLastName: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName,
            suffixNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim(),
          },
          consumerId: ConsumerReferenceId,
          email: userProfile.NotificationEmailAddr.trim(),
          govtNbr: userProfile.CnsmrPrsnlDetls.GovtNbr.trim(),
          phoneNbr: userProfile.CnsmrPrsnlDetls.PhoneNbr.trim()
        },
        previousAddrDetls: {
          prevAddr1: previousAddress,
          prevAddr2: previousAddress,
          prevAddr3: previousAddress
        },
        recvdDtTm: cstDateTimeNowValue,
        requestSrcCode: DisclosureRequestheader.RequestSrcCode,
        requestTypCode: DisclosureRequestheader.MailType,
        userName: userProfile.UserName,
        cellPhoneNbr: userProfile.CellPhoneNbr,
        correspondenceDeliveryPreference: userProfile.CorrespondenceDeliveryPreference,
        notificationEmailAddr: userProfile.NotificationEmailAddr,
        rqstHdr: {
          rqstSubTyp: DisclosureRequestheader.MailSybType,
          rqstTyp: DisclosureRequestheader.MailType
        }
      }
    };
    this.userdisclosureService.mailDisclosureRequest(mailDisclosureRequest).subscribe(
      (response) => {
        if (response.code != undefined && response.code != null && response.code == "00") {
          let messageContent = this._translate.instant('MAILDISCLOSURE.MESSAGE');
          messageContent = messageContent.replace('#####', response.disclosureRsp.cnsmrRefId);
          const dialogMessage = new DialogMessage(
            messageContent,
            this._translate.instant('BUTTONS.OK'),
            false,
            this._translate.instant('MAILDISCLOSURE.TITLE')
          );
          const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
            panelClass: ['fis-style', 'custom-dialog-without-close'],
            data: dialogMessage,
            disableClose: true,
          });

          dialogRef2.afterClosed().subscribe((result) => {
            //chexsystems public home page
            if ((result as string) == 'OK') {
              this.router.navigate(['dashboard']);
            } else {
            }
          });
        }else if(response.rspCode != undefined && response.rspCode != null && response.rspCode === "4062"){
          this.disclosureFailPopup(this._translate.instant('MAILDISCLOSURE.DAILYLIMITS'))
        }else
        {
          if (userProfile.CorrespondenceDeliveryPreference === 'Mail') {
            this.disclosureFailPopup(this._translate.instant('POPUPS.DISCLOSURESERVICEEMAILERRORMESSAGE'))
          } else if ( userProfile.CorrespondenceDeliveryPreference === 'Portal' ) {
            this.disclosureFailPopup(this._translate.instant('POPUPS.DISCLOSURESERVICEPORTALERRORMESSAGE'))
          }
        }
      },
      (error) => { },
      () => { });
  }

  private ConvertDateTime(dateValue: Date): string {
    return (
      this.ConvertValidDateFormat(dateValue) +
      'T' +
      this.padLeadingZeros(dateValue.getHours(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getMinutes(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getSeconds(), 2)
    );
  }
  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = yyyy + '-' + mm + '-' + dd;
    return validDate;
  }
  private padLeadingZeros(num: number, size: number) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  private disclosureFailPopup(messageContent: string):void {
    const dialogMessage = new DialogMessage(
      messageContent,
      this._translate.instant('BUTTONS.OK'),
      false,
      this._translate.instant('MAILDISCLOSURE.TITLE1')
    );
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });

    dialogRef2.afterClosed().subscribe((result) => {
      //chexsystems public home page
      if ((result as string) == 'OK') {
        this.router.navigate(['dashboard']);
      } else {
      }
    });

  }
}

