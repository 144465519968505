import { AbstractControl, FormGroup } from '@angular/forms';
import { Registrationresource } from '../../_models/registration/registrationresource.model';
import { MyTel } from '../controls/phone-control/phone-control.component';

export class SSNValidation {

    static MatchSSN(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }

            let ssn = control.value as MyTel;
            let matchingssn = matchingControl.value as MyTel;
            // set error on matchingControl if validation fails
            if (!(ssn.area == matchingssn.area && ssn.subscriber == matchingssn.subscriber && ssn.exchange == matchingssn.exchange)) {
                matchingControl.setErrors({ mustMatch: true });
            }
            else {
                matchingControl.setErrors({ mustMatch: false });
            }
            return null;
        };
    }

    /**
     * 
     * @param controlName Control name
     * @returns return with error message
     */
    static FormatValidation(controlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            // check the status
            if(control.status === "INVALID" && control.errors?.required && control.errors?.matDatepickerParse != null)
            {
                control.setErrors({ format: true });
            }            
            return null;
        };
    }
}