import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { FormBuilder } from '@angular/forms';
import { DialogUploaderInput, DialogFileUploaderComponent } from '../dialog-attachment/dialog-file-attachment.component';
import { disputesWithOutAttachRes } from '../../_models/classes/dispute-without-attachment-res.model';
import { disputesWithOutAttachReq } from '../../_models/classes/dispute-without-attachment-req.model';
import { userinformation } from '../../_models/classes/user-information.model';
import { DisputeTabsService } from '../../_services/dispute-tabs/dispute-tabs.service';
import { disputes } from '../../_models/classes/consumer-discloser-dispute.model';
import { Router } from '@angular/router';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dialog-with-message/dialog-with-message.component';
import { DialogwithmessagebtnComponent } from '../dialog-with-message-btn/dialog-with-message-btn.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../common/enums/global_variables';
import { IdaQuizService } from '../../_services/registration/ida-quiz.service';
import { UserDetail, UserLookupRequest } from '../../_models/registration/UserDetail';
import { MyTel } from '../../common/controls/phone-control/phone-control.component';

@Component({
  selector: 'chexsystem-ws-dialog-submit-dispute-trans',
  templateUrl: './dialog-submit-dispute-trans.component.html',
  styleUrls: ['./dialog-submit-dispute-trans.component.scss'],
})
export class DialogSubmitDisputeTransComponent implements OnInit {
  message: string;
  title: string;
  disputeData: disputes.DisputeDiscloserSubmitData[]=[];
  source:string;
  confirmationNo: string;
  userDisclosureDetail: UserDetail;
  userInformation: userinformation.UserInformationRoot =
    new userinformation.UserInformationRoot();

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogSubmitDisputeTransComponent>,
    public dialog: MatDialog,
    private disputeTabsService: DisputeTabsService,
    private _translate: TranslateService,
    private idaQuizService: IdaQuizService,
    @Inject(MAT_DIALOG_DATA) public data: DialogUploaderInput
  ) {
      this.message = this._translate.instant(
        'DISPUTETABS.TRANSITIONPOPUPTEXT'
      );
      this.title = this._translate.instant(
        'DISPUTETABS.TRANSITIONPOPUPTITLE'
      );

      this.source = data.source;
      this.confirmationNo = data.confirmationNo;
      this.disputeData.length = 0;
  
      data?.input?.forEach(element => {
        this.disputeData.push(element);
      });

      this.userDisclosureDetail = {
        UserLookupRsp: {
          CnsmrAddrDetls: {
            CnsmrAddr: {
              AddrLine1: '',
              AddrLine2: '',
              CityNm: '',
              StatCode: '',
              ZipCode: '',
            },
          },
          CnsmrPrsnlDetls: {
            BirthDt: '',
            CnsmrDrivLicenseDetls: { DrivLicenseIssuSt: '', DrivLicenseNbr: '' },
            CnsmrNmDetls: {
              FrstNm: '',
              LastNm: '',
              MidNm: '',
              SuffixNm: '',
              PreviousLastNm: '',
            },
            EmailAddr: '',
            GovtNbr: '',
            PhoneNbr: '',
            CellPhoneNbr: '',
            CorrespondenceDlvryPref: '',
          },
          UserName: '',
          Cid: '',
          Code: '',
          Message: '',
          Id: '',
        },
      };
  }

  ngOnInit(): void {
    var _userName = GlobalVariables.globalvars.get('UserId') || '';
    let lookupRequest: UserLookupRequest = new UserLookupRequest();
    lookupRequest.UserLookupReq = { UserName: _userName, Cid: '' };
    this.idaQuizService
    .getUserDetailFromDecisionSolution(lookupRequest)
    .subscribe(
      (res) => {
        this.userDisclosureDetail = res;
        this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
        /////////////////////SuffixNm
        //alert(this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls);
        this.userInformation.CnsmrPrsnlDetls =
          new userinformation.CnsmrPrsnlDetls();
        this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls =
          new userinformation.CnsmrDrivLicenseDetls();
        this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls =
          new userinformation.CnsmrNmDetls();
        if (
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls
            ?.CnsmrDrivLicenseDetls
        ) {
          this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
            this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls?.CnsmrDrivLicenseDetls?.DrivLicenseIssuSt;
          this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
            this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls?.CnsmrDrivLicenseDetls?.DrivLicenseNbr;
        } else {
          this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
            '';
          this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
            '';
        }
        this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
        this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm;
        this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm;
        this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm;
        this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastNm;
        this.userInformation.CnsmrPrsnlDetls.GovtNbr =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr;
        this.userInformation.CnsmrPrsnlDetls.BirthDt =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.BirthDt;
        this.userInformation.CnsmrAddrDetls =
          this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls;
        //this.userInformation.CellPhoneNbr = this.ConvertPhoneFormate(this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr);
        this.userInformation.UserName = _userName;
        this.userInformation.UserID =
          this.userDisclosureDetail.UserLookupRsp.Id;
        this.userInformation.CorrespondenceDeliveryPreference =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CorrespondenceDlvryPref;
        this.userInformation.NotificationEmailAddr =
          this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr;
        let cellularPhoneFormat: MyTel = new MyTel('', '', '');
        let homePhoneFormat: MyTel = new MyTel('', '', '');
        let cellularPhone: string = '';
        let homePhone: string = '';
        if (res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() !== '') {
          cellularPhoneFormat = this.ConvertPhoneFormate(
            res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr
          );
          cellularPhone =
            cellularPhoneFormat.area +
            cellularPhoneFormat.exchange +
            cellularPhoneFormat.subscriber;
        }
        if (res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr.trim() !== '') {
          homePhoneFormat = this.ConvertPhoneFormate(
            res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr
          );
          homePhone =
            homePhoneFormat.area +
            homePhoneFormat.exchange +
            homePhoneFormat.subscriber;
        }
        this.userInformation.CellPhoneNbr = cellularPhone;
        this.userInformation.CnsmrPrsnlDetls.PhoneNbr = homePhone;

        if (GlobalVariables.globalvars.has('UserInformation')) {
          GlobalVariables.globalvars.delete('UserInformation');
        }
        GlobalVariables.globalvars.set(
          'UserInformation',
          JSON.stringify(this.userInformation)
        );

      },
      (error) => {},
      () => {}
    );
  }

  private ConvertPhoneFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formatePhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatePhoneNumber[0],
      formatePhoneNumber[1],
      formatePhoneNumber[2]
    );
  }

  onYes(): void {
    const inputData:DialogUploaderInput = new DialogUploaderInput(this.disputeData, "", "DisputeTab");
    const dialogRef2 = this.dialog.open(
      DialogFileUploaderComponent,
      {
        //width: '700px',
        disableClose: true,
        data: inputData
      }
    );
    dialogRef2.afterClosed().subscribe(() => { 
      this.dialogRef.close('YES');
    });
    
  }

  onNo(): void {
    this.submitWithOutAttachment();
    
  }

  submitWithOutAttachment(){
    const strUserInfo = GlobalVariables.globalvars.get("UserInformation");
    if(strUserInfo){
      const objUserInfo =  JSON.parse(strUserInfo)  as userinformation.UserInformationRoot;
      let reqRoot:disputesWithOutAttachReq.DisputeRequestRoot = new disputesWithOutAttachReq.DisputeRequestRoot();
      reqRoot.disputeRequest = new disputesWithOutAttachReq.DisputeRequest();
      reqRoot.disputeRequest.cellPhoneNumber = objUserInfo.CellPhoneNbr.trim();
      reqRoot.disputeRequest.cnsmrAddrDetls = new disputesWithOutAttachReq.CnsmrAddrDetls();
      reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr = new disputesWithOutAttachReq.CnsmrAddr();
      reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.addrLine1 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
      reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.addrLine2 = objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
      reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.cityNm = objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
      reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.statCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
      reqRoot.disputeRequest.cnsmrAddrDetls.cnsmrAddr.zipCode = objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls = new disputesWithOutAttachReq.CnsmrPrsnlDetls();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls = new disputesWithOutAttachReq.CnsmrNmDetls();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.frstNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.lastNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.midNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrNmDetls.suffixNm = objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.birthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
      reqRoot.disputeRequest.cnsmrPrsnlDetls.govtNbr = objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls = new  disputesWithOutAttachReq.CnsmrDrivLicenseDetls();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseIssuSt = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseNbr = objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.phoneNbr = objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
      reqRoot.disputeRequest.cnsmrPrsnlDetls.cnsmrId = "";
      reqRoot.disputeRequest.correspondenceDeliveryPreference = objUserInfo.CorrespondenceDeliveryPreference.trim();
      reqRoot.disputeRequest.notificationEmailAddress = objUserInfo.NotificationEmailAddr.trim();
      reqRoot.disputeRequest.recvdDtTm  = this.ConvertDateTime(new Date()) // DD/MM/YYYhh:mm:ss
      reqRoot.disputeRequest.requestSrcCode = "SECURE";
      reqRoot.disputeRequest.userName = objUserInfo.UserName;
      reqRoot.disputeRequest.rqstHdr = new disputesWithOutAttachReq.RqstHdr();
      reqRoot.disputeRequest.rqstHdr.rqstSubTyp = "CD";
      reqRoot.disputeRequest.rqstHdr.rqstTyp = "DP";

      reqRoot.disputeRequest.cnsmrRptOrderedInd = "N";

      reqRoot.disputeRequest.dispdetls = this.getDisputeDetail();
      
      this.disputeTabsService.disputewithnoattachment(reqRoot).subscribe(
        res => {
          let strMessage:string = "";
          let strTitle:string = "";
          if(res.code === "00"){
            strMessage = this._translate.instant(
              'DISPUTETABS.SUBMITDISPUTESUCCESS'
            );
            strMessage = strMessage.replace('#######', res.referenceId);
            strTitle = this._translate.instant(
              'DISPUTETABS.SUBMITDISPUTESUCCESSTITLE'
            );
          }
          else{
            strMessage = this._translate.instant(
              'DASHBOARDCORRESPONDENCE.UNHANDLEERRORMESSAGE'
            );
            strTitle = this._translate.instant(
              'DISPUTETABS.TRANSITIONPOPUPTITLE'
            );
          }
          

          const dialogMessage = new DialogMessage(
            strMessage,
            this._translate.instant(
              'BUTTONS.GOTODASHBOARD'
            ),
            true,
            strTitle
          );
          const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
            panelClass: ["fis-style", "custom-dialog-without-close-scroll"],
            data: dialogMessage,
            disableClose: true
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if ((result as string) == 'OK') {
              this.disputeTabsService.triggerResetAllAfterDelete();
              this.router.navigate(['dashboard']);
              //window.location.reload();
            }
          });
          
        }, 
        error => {
          this.dialogRef.close('NO');
        }, 
        ()=>{
          this.dialogRef.close('NO');
        });  
    }
  }

  getInitEmptyObject(){
    let tempItem:disputesWithOutAttachRes.Dispdetl = new disputesWithOutAttachRes.Dispdetl();
    /////////////////////////////
    tempItem.personalInformationDetails = new disputesWithOutAttachRes.PersonalInformationDetails();
    tempItem.personalInformationDetails.disputeName = "";
    tempItem.disputeDesc = "";
    tempItem.disputeSrc = "";
    tempItem.infoType = "";
    tempItem.disputeReasonCategory = "";
    tempItem.personalInformationDetails.disputeAddress = new disputesWithOutAttachRes.DisputeAddress();
    tempItem.personalInformationDetails.disputeAddress.disputeStreetAddress = "";
    tempItem.personalInformationDetails.disputeAddress.disputePostalCode = "";
    tempItem.personalInformationDetails.disputeAddress.disputeState = "";
    tempItem.personalInformationDetails.disputeAddress.disputeCity = "";
    tempItem.personalInformationDetails.disputePhoneNumber = "";
    tempItem.personalInformationDetails.disputeEmailAddress = "";
    tempItem.personalInformationDetails.disputeDateOfBirth = "";
    tempItem.dataMartKey = "";
    tempItem.evntBnkCd = "";
    tempItem.evntAcctNbr = "";
    //////////////////////////////
    tempItem.inquiryCustomerDetails = new disputesWithOutAttachRes.InquiryCustomerDetails();
    tempItem.inquiryCustomerDetails.inquiryCustomerAddress = new disputesWithOutAttachRes.InquiryCustomerAddress();
    tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = "";
    tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = "";
    tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = "";
    tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = "";
    tempItem.inquiryCustomerDetails.inquiryCustomerDate = "";
    tempItem.inquiryCustomerDetails.inquiryCustomerName = "";
    tempItem.inquiryCustomerDetails.inquiryCustomerPhone = "";

    tempItem.inquiryMasterCustomerDetail = new disputesWithOutAttachRes.InquiryMasterCustomerDetail();
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress = new disputesWithOutAttachRes.InquiryMasterCustomerAddress();
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine1 = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine2 = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerCity = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerState = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerZip = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerId = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerName = "";
    tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone = "";

    tempItem.inquiryResellerCustomerDetail = new disputesWithOutAttachRes.InquiryResellerCustomerDetail();
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress = new disputesWithOutAttachRes.InquiryResellerCustomerAddress();
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine1 = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine2 = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerCity = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerState = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerZip = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerId = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerName = "";
    tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone = "";
    return tempItem;
  }

  getDisputeDetail(){
    let tempArray:disputesWithOutAttachRes.Dispdetl[] = [];
    this.disputeData.forEach(element => {
      let tempItem:disputesWithOutAttachRes.Dispdetl = this.getInitEmptyObject();
      tempItem.disputeDesc = element.description;
      tempItem.disputeReasonCategory = element.reason;
      if(element.type.toUpperCase() === "Name".toUpperCase()){
        tempItem.infoType = "Personal Information";
        tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
        tempItem.personalInformationDetails.disputeName = element.DisputeDataHolder.VarntNme.trim();
      }
      else if(element.type.toUpperCase() === "Address".toUpperCase()){
        tempItem.infoType = "Personal Information";
        tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
        const addressValues = element.DisputeDataHolder.VarntAddr.CtyStZip.split(',');
        const addressValues2 = addressValues[1].trim().split(' ');
        tempItem.personalInformationDetails.disputeAddress.disputeStreetAddress = element.DisputeDataHolder.VarntAddr.VarntAddrLn1.trim();
        tempItem.personalInformationDetails.disputeAddress.disputePostalCode = addressValues2[1].trim();
        tempItem.personalInformationDetails.disputeAddress.disputeState = addressValues2[0].trim();
        tempItem.personalInformationDetails.disputeAddress.disputeCity = addressValues[0].trim();;
      }
      else if(element.type.toUpperCase() === "Phone".toUpperCase()){
        tempItem.infoType = "Personal Information";
        tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
        tempItem.personalInformationDetails.disputePhoneNumber = element.DisputeDataHolder.PhnVartn.PhnNmbr.trim();
        tempItem.personalInformationDetails.disputePhoneNumber = tempItem.personalInformationDetails.disputePhoneNumber.replace(/\D/g,'').trim();
      }
      else if(element.type.toUpperCase() === "Email".toUpperCase()){
        tempItem.infoType = "Personal Information";
        tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
        tempItem.personalInformationDetails.disputeEmailAddress = element.DisputeDataHolder.EmailVartn.EmailAddr.trim();

      }
      else if(element.type.toUpperCase() === "Date of birth".toUpperCase()){
        tempItem.infoType = "Personal Information";
        tempItem.disputeReportDt = this.ConvertValidDateFormat(new Date()); 
        tempItem.personalInformationDetails.disputeDateOfBirth = element.DisputeDataHolder.DateOfBirth.trim();

      }
      else if(element.type.toUpperCase() === "Reported information".toUpperCase()){
        tempItem.infoType = element.type;
        //tempItem.disputeSrc = element.DisputeDataHolder.ClsrEvent.ClsrPrvdrNme;
        tempItem.disputeSrc = element.DisputeDataHolder.ClsrEvent.ClsrPrvdrNme;
        tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
        tempItem.disputeReportDt = element.DisputeDataHolder.ClsrEvent.ClsrRptdDt.trim(); 

        tempItem.dataMartKey = element.DisputeDataHolder.ClsrEvent.ClsrDtaMrtKey.trim();
        tempItem.evntBnkCd = element.DisputeDataHolder.ClsrEvent.ClsrBnkCd.trim();
        tempItem.evntAcctNbr = element.DisputeDataHolder.ClsrEvent.ClsrAcctNbr.trim();
        
      }
      else if(element.type.toUpperCase() === "Inquiries Viewed by Others".toUpperCase() || element.type.toUpperCase() === "Inquiries Viewed Only by You".toUpperCase()){
        tempItem.infoType = element.type;
        if(element.DisputeDataHolder.InqryEvent.InqMstrCustDtl && element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMastCustNme.trim() !== ""){
          tempItem.disputeSrc = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMastCustNme.trim();

        }
        else{
          tempItem.disputeSrc = element.DisputeDataHolder.InqryEvent.InqCustNme.trim();
        }
        tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
        tempItem.disputeReportDt = element.DisputeDataHolder.InqryEvent.InqDt.trim(); 

        tempItem.dataMartKey = element.DisputeDataHolder.InqryEvent.InqDtaMrtKey.trim();
        tempItem.evntBnkCd = element.DisputeDataHolder.InqryEvent.InqBnkCd.trim();
        tempItem.evntAcctNbr = element.DisputeDataHolder.InqryEvent.InqAcctNbr.trim();

        let zipCode = "";
        if(element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustZip.trim() !== ""){
          const zipArray = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustZip.trim().split('-');
          zipCode = zipArray[0].trim();
        }
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustAddrLn1.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustCty.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = element.DisputeDataHolder.InqryEvent.InqCustAddr.InqCustSt.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = zipCode;
        tempItem.inquiryCustomerDetails.inquiryCustomerDate = element.DisputeDataHolder.InqryEvent.InqDt.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerName = element.DisputeDataHolder.InqryEvent.InqCustNme.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerPhone = element.DisputeDataHolder.InqryEvent.InqCustPhn.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerPhone = tempItem.inquiryCustomerDetails.inquiryCustomerPhone.replace(/\D/g,'').trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine1 = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustAddrLn1.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerAddressLine2 = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustAddrLn2.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerCity = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustCty.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerState = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustSt.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerAddress.inquiryMasterCustomerZip = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustAddr.InqMstrCustZip.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerId = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustId.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerName = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMastCustNme.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone = element.DisputeDataHolder.InqryEvent.InqMstrCustDtl.InqMstrCustPhn.trim();
        tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone = tempItem.inquiryMasterCustomerDetail.inquiryMasterCustomerPhone.replace(/\D/g,'').trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine1 = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustAddrLn1.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerAddressLine2 = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustAddrLn2.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerCity = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustCty.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerState = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustSt.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerAddress.inquiryResellerCustomerZip = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustAddr.InqRslrCustZip.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerId = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustId.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerName = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustNme.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone = element.DisputeDataHolder.InqryEvent.InqRslrCustDtl.InqRslrCustPhn.trim();
        tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone = tempItem.inquiryResellerCustomerDetail.inquiryResellerCustomerPhone.replace(/\D/g,'').trim();
        
      }
      else if(element.type.toUpperCase() === "Retail Information".toUpperCase()){
        tempItem.infoType = element.type;
        tempItem.disputeSrc = element.DisputeDataHolder.RtrnChkEvent.RtrnChkPrvdrNme.trim();
        tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
        tempItem.disputeReportDt = element.DisputeDataHolder.RtrnChkEvent.RtrnChkDt.trim(); 

        tempItem.dataMartKey = element.DisputeDataHolder.RtrnChkEvent.RtrnChkDtaMrtKey.trim();
        tempItem.evntBnkCd = element.DisputeDataHolder.RtrnChkEvent.RtrnChkBnkCd.trim();
        tempItem.evntAcctNbr = element.DisputeDataHolder.RtrnChkEvent.RtrnChkAcctNbr.trim();

      }
      else if(element.type.toUpperCase() === "Check Cashing Inquiries".toUpperCase()){
        tempItem.infoType = element.type;
        tempItem.disputeSrc = element.DisputeDataHolder.ChkCshEvent.ChkCshCustNme.trim();
        tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
        tempItem.disputeReportDt = element.DisputeDataHolder.ChkCshEvent.ChkCshInqDt.trim(); 

        tempItem.dataMartKey = element.DisputeDataHolder.ChkCshEvent.ChkCshDtaMrtKey.trim();
        // tempItem.evntBnkCd = element.DisputeDataHolder.ChkCshEvent.;
        // tempItem.evntAcctNbr = element.DisputeDataHolder.ChkCshEvent.;
        let strCity = "";
        let strState = "";
        let strZip = "";
        if(element.DisputeDataHolder.ChkCshEvent.ChkCshAddr.CtyStZip.trim() !== ""){
          const addressValues = element.DisputeDataHolder.ChkCshEvent.ChkCshAddr.CtyStZip.split(',');
          const addressValues2 = addressValues[1].trim().split(' ');
          strCity = addressValues[0].trim();
          strState = addressValues2[0].trim();
          let zipArray = addressValues2[1].trim().split('-');
          strZip = zipArray[0].trim();
        }
        
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = element.DisputeDataHolder.ChkCshEvent.ChkCshAddr.AptNbr.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = strCity;
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = strState;
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = strZip;
        tempItem.inquiryCustomerDetails.inquiryCustomerDate = element.DisputeDataHolder.ChkCshEvent.ChkCshInqDt.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerName = element.DisputeDataHolder.ChkCshEvent.ChkCshCustNme.trim();
        //tempItem.inquiryCustomerDetails.inquiryCustomerPhone = element.DisputeDataHolder.ChkCshEvent.;
      }
      else if(element.type.toUpperCase() === "History of Checks ordered".toUpperCase()){
        tempItem.infoType = element.type;
        tempItem.disputeSrc = element.DisputeDataHolder.ChkOrdEvent.ChkOrdPrvdrNme.trim();
        tempItem.disputeSrc = tempItem.disputeSrc.replace(/[^a-zA-Z0-9 ]/g, '').trim();// Removing special character
        tempItem.disputeReportDt = element.DisputeDataHolder.ChkOrdEvent.ChkOrdShipDt.trim(); 

        tempItem.dataMartKey = element.DisputeDataHolder.ChkOrdEvent.ChkOrdDtaMrtKey.trim();
        tempItem.evntBnkCd = element.DisputeDataHolder.ChkOrdEvent.ChkOrdBnkCd.trim();
        tempItem.evntAcctNbr = element.DisputeDataHolder.ChkOrdEvent.ChkOrdAcctNbr.trim();
        let strCity = "";
        let strState = "";
        let strZip = "";
        if(element.DisputeDataHolder.ChkOrdEvent.ChkOrdDlvrAddr.CtyStZip.trim() !== ""){
          
          const addressValues = element.DisputeDataHolder.ChkOrdEvent.ChkOrdDlvrAddr.CtyStZip.split(' ');
          for (let i = 0; i < addressValues.length-2; i++) {
            strCity = strCity + addressValues[i] + " ";
          }
          strCity = strCity.trim();
          strState = addressValues[addressValues.length-2].trim();
          let zipArray = addressValues[addressValues.length-1].trim().split('-');
          strZip = zipArray[0].trim();
        }
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerAddressLine1 = element.DisputeDataHolder.ChkOrdEvent.ChkOrdDlvrAddr.AptNbr.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerCity = strCity;
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerState = strState;
        tempItem.inquiryCustomerDetails.inquiryCustomerAddress.inquiryCustomerZip = strZip;
        tempItem.inquiryCustomerDetails.inquiryCustomerDate = element.DisputeDataHolder.ChkOrdEvent.ChkOrdShipDt.trim();
        tempItem.inquiryCustomerDetails.inquiryCustomerName = element.DisputeDataHolder.ChkOrdEvent.ChkOrdCnsmrNme.ChkOrdCnsmrBusnNme.trim();
        //tempItem.inquiryCustomerDetails.inquiryCustomerPhone = element.DisputeDataHolder.ChkCshEvent.;
      }

      tempArray.push(tempItem);
    });
    return tempArray;
  }

  private ConvertDateTime(dateValue: Date): string {

    return this.ConvertValidDateFormat(dateValue) + '' + dateValue.getHours() + ':' + dateValue.getMinutes() + ':' + dateValue.getSeconds();
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }
}
